import { titleCase } from 'listo/src/utils/strings';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/Button';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

export function Invite() {
  const utils = trpc.useContext();
  const { contractId } = useParams();
  if (!contractId) throw new Error('contractId is required');
  const navigate = useNavigate();
  const {
    data: contract,
    isLoading: contractLoading,
    error,
  } = trpc.u.contracts.contract.useQuery(contractId);

  const { mutate, isLoading } = trpc.u.contracts.invite.useMutation({
    onSuccess: () => {
      utils.u.contracts.contract.invalidate(contractId).catch(() => {});
      navigate(`/contracts/${contractId}/sign`);
    },
  });

  if (contractLoading) return <Loader />;
  if (error)
    return <ErrorState trpcError={error} parentComponentName="Contract.tsx" />;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-b-none mt-6 p-6">
      <main>
        <div className="mx-auto px-4 sm:px-6 lg:px-8">
          <div className="mx-auto pt-8">
            <h1 className="text-3xl font-bold tracking-tight text-gray-900">
              Invite
            </h1>
          </div>
          {!contract.invitationLink ? (
            <>
              <div className="mx-auto pt-4">
                <p className="text-sm text-gray-500">
                  Click below to invite the employee to enter their information.
                  This is required for us to be able to issue the employment
                  contract.
                </p>
              </div>
              <div className="mt-6">
                <div className="mt-1 flex justify-end">
                  <p className="text-lg font-medium text-gray-900 pr-4 pt-4">
                    {titleCase(contract.workerProfile.fullName ?? '')}:{' '}
                    {contract.worker.credential.email}
                  </p>
                  <Button
                    className="rounded-md border border-transparent bg-indigo-600 py-3 px-4 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-50"
                    text="Invite"
                    loading={isLoading}
                    onClick={() => {
                      mutate({
                        contractId,
                        email: contract.worker.credential.email,
                      });
                    }}
                  />
                </div>
              </div>{' '}
            </>
          ) : (
            <div className="mx-auto pt-4">
              <p className="text-sm text-gray-500">
                The worker has been invited to enter their information. This is
                required for us to be able to issue the employment contract.
              </p>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}
