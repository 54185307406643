import { zodResolver } from '@hookform/resolvers/zod';
import jwtDecode from 'jwt-decode';
import {
  SetPasswordType,
  setPasswordZod,
} from 'listo/src/zodObjects/credentials';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';
import { handleLoginRedirect } from '../../lib/utilities';
import Logo from '../../public/images/circle-logo.svg';

export function SetPassword() {
  const navigate = useNavigate();
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = useLocation();
  const [searchParams] = useSearchParams();
  const [passwordSuccess, setPasswordSuccess] = useState(false);
  const [invalidToken, setInvalidToken] = useState(false);
  const setJwt = useAuth((s) => s.setJwt);
  const jwt = useAuth((s) => s.jwt);
  const claims = useAuth((s) => s.claims);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<SetPasswordType>({ resolver: zodResolver(setPasswordZod) });
  const email = searchParams.get('email');

  const redirect = searchParams.get('redirect');

  useEffect(() => {
    if (passwordSuccess && jwt && claims) {
      handleLoginRedirect(navigate, claims, redirect, state);
    }
  }, [passwordSuccess, jwt, claims, navigate, redirect, state]);

  const { mutate, isLoading, error } = trpc.p.auth.setPassword.useMutation({
    onSuccess: ({ jwt: responseJwt }) => {
      setJwt(responseJwt);
      setPasswordSuccess(true);
    },
  });

  const onSubmit: SubmitHandler<SetPasswordType> = (data) => {
    mutate(data);
  };

  const resetToken = searchParams.get('resetToken');
  if (!resetToken) return null;

  useEffect(() => {
    const decodedToken = jwtDecode(resetToken);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
    const jwtIsExpired = Date.now() >= (decodedToken as any).exp * 1000;
    if (jwtIsExpired) {
      setInvalidToken(true);
    }
  }, [resetToken]);

  return (
    <div className="flex h-screen">
      <main className="container mx-auto my-auto">
        <div className="min-h-full flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img className="h-12 w-auto" src={Logo} alt="logo" />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Set your password
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Or{' '}
                  <a
                    href="https://www.listoglobal.com/contact"
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    contact us if you need help
                  </a>
                </p>
              </div>

              {error || invalidToken ? (
                <div className="bg-red-50 border-l-4 border-red-800 p-2 mt-8">
                  <div className="flex">
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        {invalidToken
                          ? 'Your invitation is expired. Please contact your client to re-send the link.'
                          : 'Could not set password.'}
                      </h3>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="mt-4">
                {/* <SocialSignIns /> */}

                <div className="mt-6">
                  <form
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    className="space-y-6"
                  >
                    {email ? (
                      <div>
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Email address
                        </label>
                        <div className="mt-1">
                          <input
                            value={email}
                            id="email"
                            type="email"
                            autoComplete="email"
                            disabled
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    ) : null}

                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          autoComplete="new-password"
                          autoFocus
                          id="password"
                          type="password"
                          {...register('password')}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {errors.password && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <input
                      id="resetToken"
                      type="hidden"
                      value={resetToken}
                      {...register('resetToken')}
                    />

                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password Confirm
                      </label>
                      <div className="mt-1">
                        <input
                          id="passwordConfirm"
                          autoComplete="new-password"
                          type="password"
                          {...register('passwordConfirm')}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {errors.passwordConfirm && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="password-error"
                          >
                            {errors.passwordConfirm.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div>
                      {!isLoading ? (
                        <button
                          type="submit"
                          disabled={invalidToken}
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Set Password
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <svg
                            className="animate-spin h-5 w-5 mr-3 ..."
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      </main>
    </div>
  );
}
