import {
  PaperClipIcon,
  DocumentArrowDownIcon,
} from '@heroicons/react/24/outline';
import { formatDate } from 'listo/src/utils/dates';
import Loader from '../../components/Loader';

import { trpc } from '../../lib/trpc';

function Paystubs({ contractId }: { contractId: string }) {
  const { data, isLoading, error } = trpc.w.contracts.paystubs.useQuery(
    {
      contractId,
    },
    {
      enabled: !!contractId,
      trpc: {},
    },
  );

  if (!contractId) {
    return (
      <div className="pt-4 pb-6 px-6">
        <div className="flow-root mt-6">
          <div className="sm:flex sm:items-center mb-10">
            <div className="sm:flex-auto">
              <p className="text-sm text-gray-700">Payment Documents</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (isLoading) return <Loader />;
  if (error) return <p>{error.message}</p>;

  return (
    <div className="pt-4 pb-6 px-6">
      <div className="flow-root mt-6">
        <div className="sm:flex sm:items-center mb-10">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">Payment Documents</p>
          </div>
        </div>
        <ul className="-my-5 divide-y divide-gray-200">
          {data.map((paystub) => (
            <li
              key={paystub.id}
              className="py-4  cursor-pointer hover:bg-gray-100"
            >
              <a
                className="flex items-center space-x-4"
                href={paystub.upload?.url}
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <PaperClipIcon className="h-5 w-5 rounded-full" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {paystub.name}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {`uploaded: ${formatDate(paystub.createdAt)}`}
                  </p>
                </div>
                <div>
                  <DocumentArrowDownIcon className="w-5 h-5" />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
export default Paystubs;
