import { CalendarIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import {
  Bars3Icon,
  BuildingOfficeIcon,
  ClockIcon,
  GlobeAltIcon,
} from '@heroicons/react/24/outline';
import { countryNameByIsoCode } from 'listo/src/countries';
import { formatDate, stringFromDateOrString } from 'listo/src/utils/dates';
import { classNames } from 'listo/src/utils/strings';
import { Link } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import EmptyState from '../../components/EmptyState';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import useSidebar from '../../hooks/useSidebar';
import { trpc } from '../../lib/trpc';
import { OnboardingCard } from './OnboardingCard';
import { OnboardingSteps } from './OnboardingSteps';
import { PayData } from './PayData';

const actions = [
  {
    title: 'Hiring Guides',
    href: 'https://www.listoglobal.com/global-hiring-guide',
    icon: GlobeAltIcon,
    iconForeground: 'text-green-700',
    iconBackground: 'bg-green-50',
    description: 'View the guides for hiring anywhere in the world.',
  },
  {
    title: 'Issue a Contract',
    href: '/create-contract',
    icon: ClockIcon,
    iconForeground: 'text-indigo-700',
    iconBackground: 'bg-indigo-50',
    description: 'Onboard your first employee or contractor.',
  },
];

function Actions() {
  return (
    <div className="rounded-lg bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
      {actions.map((action, actionIdx) => (
        <div
          key={action.title}
          className={classNames(
            actionIdx === 0
              ? 'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none'
              : '',
            actionIdx === 1 ? 'sm:rounded-tr-lg' : '',
            actionIdx === actions.length - 2 ? 'sm:rounded-bl-lg' : '',
            actionIdx === actions.length - 1
              ? 'rounded-bl-lg rounded-br-lg sm:rounded-bl-none'
              : '',
            'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
          )}
        >
          <div>
            <span
              className={classNames(
                action.iconBackground,
                action.iconForeground,
                'rounded-lg inline-flex p-3 ring-4 ring-white',
              )}
            >
              <action.icon className="h-6 w-6" aria-hidden="true" />
            </span>
          </div>
          <div className="mt-4">
            <h3 className="text-lg font-medium">
              {action.href.includes('http') ? (
                <a
                  href={action.href}
                  className="focus:outline-none"
                  rel="noreferrer"
                  target="_blank"
                >
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </a>
              ) : (
                <Link to={action.href} className="focus:outline-none">
                  {/* Extend touch target to entire panel */}
                  <span className="absolute inset-0" aria-hidden="true" />
                  {action.title}
                </Link>
              )}
            </h3>
            <p className="mt-2 text-sm text-gray-500">{action.description}</p>
          </div>
          <span
            className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
            aria-hidden="true"
          >
            <svg
              className="h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="currentColor"
              viewBox="0 0 24 24"
            >
              <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
            </svg>
          </span>
        </div>
      ))}
    </div>
  );
}

export function Dashboard() {
  const { sidebarIsOpen, setSidebarIsOpen } = useSidebar();
  const { data, isLoading, error } = trpc.u.dashboard.dashboard.useQuery();

  if (isLoading) {
    return <Loader />;
  }

  if (error)
    return <ErrorState trpcError={error} parentComponentName="Dashboard.tsx" />;

  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <div className="bg-white shadow">
        <div className="sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
          <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
            <div className="flex-1 min-w-0">
              {/* Profile */}
              <div className="flex items-center">
                {/* <img
                  className="hidden h-16 w-16 rounded-full lg:block"
                  src={data.profile.avatarUrl}
                  alt="profile"
                /> */}
                <Avatar
                  className="h-16 w-16 rounded-full text-xl"
                  name={data.profile.clientName ?? ''}
                  image={data.user?.userUpload?.url ?? ''}
                />
                <div>
                  <div className="flex items-center">
                    {/* <img
                      className="h-16 w-16 rounded-full hidden"
                      src={data.profile.avatarUrl}
                      alt=""
                    /> */}
                    {/* <Avatar
                      className="h-16 w-16 rounded-full hidden text-xl"
                      name={data.profile.clientName ?? ''}
                    /> */}
                    <button
                      id="open-sidebar"
                      type="button"
                      className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                      onClick={() => {
                        setSidebarIsOpen(!sidebarIsOpen);
                      }}
                    >
                      <span className="sr-only">Open sidebar</span>
                      <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                      {`Hello${
                        data.profile.firstName
                          ? `, ${data.profile.firstName}`
                          : ''
                      }`}
                    </h1>
                  </div>
                  <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap lg:flex">
                    <dt className="sr-only">Company</dt>
                    <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                      <BuildingOfficeIcon
                        className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      {data.profile.clientName}
                    </dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8 grid">
        <div className="grid sm:grid-cols-1 lg:grid-cols-2 gap-5 mt-8">
          <div className="row-span-1 col-span-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Action Items
            </h3>
            <div className="mt-5 px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
              <OnboardingCard />
            </div>
            <div>
              <div className="mt-5 bg-white shadow rounded-lg overflow-hidden ">
                <OnboardingSteps hasPaymentMethod={data.hasPaymentMethod} />
              </div>
            </div>
          </div>

          <div className="row-span-1">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Tools
            </h3>
            <div className="mt-5 bg-white shadow rounded-lg overflow-hidden ">
              <Actions />
            </div>
          </div>
          <PayData />
        </div>

        {/* {data.actionItems.length > 0
        ? data.actionItems.map((ai) => (
            <div key={ai.id} className="mt-8">
              <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:p-6">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      {ai.unstructuredData.displayTitle}
                    </h3>
                    <div className="mt-2 sm:flex sm:items-start sm:justify-between">
                      <div className="max-w-xl text-sm text-gray-500">
                        <p>{ai.unstructuredData.description}</p>
                      </div>
                      <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            navigate(`${ai.unstructuredData.href}`);
                          }}
                          type="button"
                          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                        >
                          {ai.unstructuredData.actionButtonText}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        : null} */}

        {Object.keys(data.byCountry).length > 0 ? (
          <div className="mt-8">
            <div className="bg-white shadow overflow-hidden sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {Object.keys(data.byCountry).map((c: string) => {
                  const contracts = data.byCountry[c];

                  if (!contracts) return null;
                  return (
                    <li key={c}>
                      <Link
                        to={`/team?country=${c}`}
                        className="block hover:bg-gray-50"
                      >
                        <div className="px-4 py-4 flex items-center sm:px-6">
                          <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                            <div className="truncate">
                              <div className="flex text-sm">
                                <p className="font-medium text-indigo-600 truncate">
                                  Your team
                                </p>
                                <p className="ml-1 flex-shrink-0 font-normal text-gray-500">
                                  in {countryNameByIsoCode(c)}
                                </p>
                              </div>
                              <div className="mt-2 flex">
                                <div className="flex items-center text-sm text-gray-500">
                                  <CalendarIcon
                                    className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <p>{`${contracts.length} head count`}</p>
                                </div>
                              </div>
                            </div>
                            <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                              <div className="flex overflow-hidden -space-x-1">
                                {contracts.slice(0, 15).map((con) => (
                                  <Avatar
                                    key={con.id}
                                    image={
                                      con.workerProfile.workerProfileUpload
                                        ?.url ?? ''
                                    }
                                    name={con.name}
                                    className="w-[35px] h-[35px]"
                                  />
                                ))}
                                {contracts.length > 15 ? (
                                  <Avatar
                                    name="+"
                                    className="w-[35px] h-[35px]"
                                  />
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="ml-5 flex-shrink-0">
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : null}

        {/* only grab 5 */}
        <div className="mt-8">
          <div className="bg-white shadow sm:rounded-lg">
            <div className="px-4 py-5 sm:p-6">
              <h2 className="font-semibold text-gray-900">Recent Events</h2>
              {data.events.length > 0 ? (
                <ol className="mt-2 divide-y divide-gray-200 text-sm leading-6 text-gray-500">
                  {data.events.map((e) => (
                    <li className="py-4 sm:flex" key={e.id}>
                      <time
                        dateTime={stringFromDateOrString(e.timestamp)}
                        className="w-28 flex-none"
                      >
                        {formatDate(e.timestamp)}
                      </time>
                      <p className="mt-2 flex-auto sm:mt-0">
                        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any */}
                        {(e.unstructuredData as any).displayTitle}
                      </p>
                    </li>
                  ))}
                </ol>
              ) : (
                <EmptyState entity="Event" />
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
