import Fallback from '../../components/Fallback';

export function Page404() {
  return (
    <Fallback
      message="404"
      subMessage="If you're lost, talk to technical support for more help."
      title="Not found"
    />
  );
}
