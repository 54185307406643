import {
  ClockIcon,
  HandThumbUpIcon,
  HandThumbDownIcon,
} from '@heroicons/react/24/solid';

import { XCircleIcon } from '@heroicons/react/24/outline';

import { TimeOffRequestStatusEnum } from 'listo/src/zodObjects/requestTimeOff';

export const statusChipConfig = {
  [TimeOffRequestStatusEnum.Values.APPROVED]: {
    icon: <HandThumbUpIcon className="h-4 w-4" aria-hidden="true" />,
    color: 'text-white',
    bgColor: 'bg-green-600',
  },
  [TimeOffRequestStatusEnum.Values.DECLINED]: {
    icon: <HandThumbDownIcon className="h-4 w-4" aria-hidden="true" />,
    color: 'text-white',
    bgColor: 'bg-red-400',
  },
  [TimeOffRequestStatusEnum.Values.PENDING]: {
    icon: <ClockIcon className="h-4 w-4" aria-hidden="true" />,
    color: 'text-gray-800',
    bgColor: 'bg-gray-300',
  },
  [TimeOffRequestStatusEnum.Values.CANCELLED]: {
    icon: <XCircleIcon className="h-4 w-4" aria-hidden="true" />,
    color: 'text-gray-500',
    bgColor: 'bg-gray-300',
  },
};
