import { groupBy } from 'lodash';
import {
  ExpenseReimbursementStatusEnum,
  ExpenseReimbursementStatus,
} from 'listo/src/zodObjects/expenseReimbursement';
import {
  IOption,
  TNormalizeFiltersForMutation,
  TNormalizeFiltersResult,
} from './types';

export const dateRangeFilters = [
  {
    label: 'Recently Added',
    type: 'recently-added',
    value: 'recently-added',
  },
] as IOption[];

export const dateRangeFilterValues = dateRangeFilters.map(
  (v: IOption) => v.value,
) as string[];

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const defaultFilter = dateRangeFilters[0]!;

export const options = [
  {
    label: 'Pending',
    type: 'status',
    value: ExpenseReimbursementStatusEnum.Values.PENDING,
  },
  {
    label: 'Approved',
    type: 'status',
    value: ExpenseReimbursementStatusEnum.Values.APPROVED,
  },
  {
    label: 'Declined',
    type: 'status',
    value: ExpenseReimbursementStatusEnum.Values.DECLINED,
  },

  ...dateRangeFilters,
] as IOption[];

export function normalizeFiltersForMutation({
  workerProfileId,
  clientId,
  startDate,
  endDate,
  filterBy,
}: TNormalizeFiltersForMutation): TNormalizeFiltersResult {
  const filterDetails = filterBy.map((v: string) => {
    const details = options.find((o) => o.value === v);
    return details;
  }) as IOption[];

  const groupedDetails = groupBy(filterDetails, 'type');

  let filters = {
    workerProfileId,
    clientId,
    startDate,
    endDate,
  } as TNormalizeFiltersResult;

  if (Object.keys(groupedDetails).length > 0) {
    if (groupedDetails.status) {
      const status = groupedDetails.status.map(
        (v) => v.value,
      ) as ExpenseReimbursementStatus[];

      filters = {
        ...filters,
        status,
      };
    }

    if (groupedDetails['recently-added']) {
      filters = {
        ...filters,
        startDate: '',
        endDate: '',
        recentlyAdded: true,
      };
    }
  }

  return filters;
}
