import { ChevronRightIcon, CheckIcon } from '@heroicons/react/24/outline';
import { centsToDollars } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import Logo from '../../public/images/circle-logo.svg';

export default function InvoicePaid() {
  const { id } = useParams();
  if (!id) throw new Error('no id');

  const {
    data: invoices,
    isLoading,
    error,
  } = trpc.u.invoices.invoice.useQuery(id);

  if (isLoading) return <Loader />;
  if (error) return <div>Error</div>;

  const shortId = id.split('-')[0];

  if (invoices.stripeInvoiceStatus === 'paid') {
    return (
      <div className="flex ml-3">
        <div>
          <div className=" flex justify-center px-4 min-h-full text-start flex-col">
            <div className="flex justify-center sm:pr-80 ">
              <img className="h-7 flex mr-2" src={Logo} alt="logo" />
              <div>Listo Global</div>
            </div>
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 ">
              <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-6 sm:w-full sm:max-w-md sm:p-6">
                <div className="">
                  <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
                    <CheckIcon
                      className="h-6 w-6 text-green-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    <div className="text-sm font-medium leading-6 text-gray-500">
                      Invoice paid
                    </div>

                    <div className="text-4xl font-semibold">
                      {centsToDollars(invoices.amountInCents)}
                    </div>
                    <Link to="/invoices">
                      <div className="mt-2 flex justify-center items-center">
                        <p className="text-sm text-gray-500">
                          View invoice details
                        </p>
                        <p>
                          <ChevronRightIcon
                            className="h-4 w-4 text-gray-400"
                            aria-hidden="true"
                          />
                        </p>
                      </div>
                    </Link>
                  </div>
                </div>
                <div className=" mt-10">
                  <div className="flex justify-between text-sm text-gray-400 mb-1">
                    Invoice number
                    <div className="text-gray-600 text-ellipsis overflow-hidden">
                      {shortId}...
                    </div>
                  </div>
                  <div className="flex justify-between text-sm text-gray-400">
                    Payment date
                    <div className="text-gray-600">
                      {formatDate(invoices.createdAt)}
                    </div>
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-2 sm:text-sm"
                    // onClick={() => setOpen(true)}
                  >
                    Download receipt
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:col-start-1 sm:mt-0 sm:text-sm"
                    // onClick={() => setOpen(true)}
                  >
                    Download invoice
                  </button>
                </div>
              </div>
            </div>
            <div className="text-xs text-gray-500 flex justify-center mt-5">
              Powered by <b className="mr-3 ml-1">Listo</b> |
              <a
                className="mx-3 underline decoration-dotted"
                href="https://www.listoglobal.com/terms-of-use"
                target="_blank"
                rel="noreferrer"
              >
                Terms
              </a>
              <a
                className="underline decoration-dotted"
                href="https://www.listoglobal.com/privacy-policy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
}
