import { zodResolver } from '@hookform/resolvers/zod';
import { currencySelectOptions } from 'listo/src/countries';
import { floatInDollarsToCents } from 'listo/src/utils/currency';
import {
  ContractorContractCreate,
  contractorFields,
  contractorJobFields,
  contractorPaymentFields,
  ContractorPaymentFields,
} from 'listo/src/zodObjects/contracts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { CurrencyInput } from 'ui/src/components/Currency/CurrencyInput';
import Form from '../../components/Form';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { trpc } from '../../lib/trpc';
import { useCreateContractForm } from './hooks/useCreateContractForm';

export function FixedRatePayment() {
  const { type } = useParams();
  if (!type) throw new Error('Missing type');
  const navigate = useNavigate();
  const resetForm = useCreateContractForm((s) => s.resetForm);
  const fields = useCreateContractForm((s) => s.fields);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<ContractorPaymentFields>({
    resolver: zodResolver(contractorPaymentFields),
  });

  const createContract = trpc.u.contracts.contractor.useMutation({
    onSuccess: ({ id }) => {
      resetForm();
      navigate(`/contracts/${id}`);
    },
  });

  const onSubmit: SubmitHandler<ContractorPaymentFields> = (data) => {
    const contractorStep = contractorFields.safeParse(fields);

    if (!contractorStep.success) {
      navigate(`/create-contract/${type}/contractor?autoValidate=true`);
      return;
    }

    const jobStep = contractorJobFields.safeParse(fields);
    if (!jobStep.success) {
      navigate(`/create-contract/${type}/job?autoValidate=true`);
      return;
    }

    createContract.mutate({
      ...fields,
      ...data,
      contractType: 'FIXED_RATE',
      name: `${fields?.firstName ?? ''} ${fields?.lastName ?? ''}`.trim(),
      billingAmountInCents: floatInDollarsToCents(data.billingAmountInCents),
    } as ContractorContractCreate);
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Submit"
      secondaryButtonText="Back"
      secondaryButtonOnClick={() => navigate(`/create-contract/${type}/job`)}
    >
      <>
        <Section
          heading="Billing"
          subheading="Enter details regarding how the contractor should bill. This will determine the totals generated for your monthly invoices."
        >
          <>
            <CurrencyInput
              currencyField="billingCurrency"
              amountField="billingAmountInCents"
              register={register}
              errors={errors}
              label="Contract Amount"
              control={control}
              defaultValues={{
                currency: fields?.billingCurrency,
                amount: fields?.billingAmountInCents,
              }}
              cols={3}
            />
            <SelectInput
              cols={3}
              register={register}
              fieldName="billingFrequency"
              label="Billing Frequency"
              defaultValue={(fields?.billingFrequency as string) || 'HOURLY'}
              error={errors.billingFrequency}
              options={[{ label: 'Monthly', value: 'MONTHLY' }].map(
                (frequency) => ({
                  label: frequency.label,
                  value: frequency.value,
                }),
              )}
            />
          </>
        </Section>
        <Section
          heading="Distribution"
          subheading="How should the contractor be paid? Monthly distributions are the most common option around the world."
        >
          <>
            <SelectInput
              cols={3}
              register={register}
              fieldName="distributionCurrency"
              label="Currency"
              error={errors.distributionCurrency}
              defaultValue={fields?.distributionCurrency ?? 'USD'}
              options={currencySelectOptions}
            />
            <SelectInput
              cols={3}
              register={register}
              fieldName="distributionFrequency"
              label="Frequency"
              defaultValue={(fields?.billingFrequency as string) || 'MONTHLY'}
              error={errors.billingFrequency}
              options={[
                { label: 'Monthly', value: 'MONTHLY' },
                { label: 'Semi-Monthly', value: 'SEMI_MONTHLY' },
              ].map((frequency) => ({
                label: frequency.label,
                value: frequency.value,
              }))}
            />
          </>
        </Section>
      </>
    </Form>
  );
}
