import { z } from 'zod';

export const inviteGuestClientSignZod = z.object({
  email: z.string().email(),
  contractId: z.string().uuid(),
});

export type InviteGuestClientSignType = z.infer<
  typeof inviteGuestClientSignZod
>;
