import { zodResolver } from '@hookform/resolvers/zod';
import { countries } from 'listo/src/countries';
import {
  ContractorFields,
  contractorFields,
} from 'listo/src/zodObjects/contracts';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import Form from '../../components/Form';
import { Header } from '../../components/Form/Header';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { TextInput } from '../../components/Form/TextInput';
import { useAuth } from '../../hooks/useAuth';
import { useCreateContractForm } from './hooks/useCreateContractForm';

export function Contractor() {
  const { type } = useParams();
  if (!type) throw new Error('type is required');
  const claims = useAuth((state) => state.claims);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const setFields = useCreateContractForm((s) => s.setFields);
  const fields = useCreateContractForm((s) => s.fields);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors },
  } = useForm<ContractorFields>({ resolver: zodResolver(contractorFields) });

  const onSubmit: SubmitHandler<ContractorFields> = useCallback(
    (data) => {
      setFields({ ...data, clientId: claims?.activeClientId });
      navigate(`/create-contract/${type}/job`);
    },
    [claims?.activeClientId, navigate, setFields, type],
  );

  const email = searchParams.get('workerEmail');

  useEffect(() => {
    if (email) {
      setFields({ email });
      setValue('email', email);
    }
  }, [email, setValue, setFields]);

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    if (autoValidate) {
      handleSubmit(onSubmit)().catch(() => {});
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Next"
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => navigate('/create-contract')}
    >
      <>
        <Header
          heading="Contractor Information"
          subheading="Enter the contractor's details. This information will be used to send the contract"
        />
        <Section>
          <>
            <TextInput
              cols={3}
              register={register}
              fieldName="firstName"
              label="First Name"
              defaultValue={fields?.firstName}
              error={errors.firstName}
            />
            <TextInput
              cols={3}
              register={register}
              fieldName="lastName"
              label="Last Name"
              defaultValue={fields?.lastName}
              error={errors.lastName}
            />
            <TextInput
              cols={3}
              register={register}
              fieldName="email"
              label="Email Address"
              defaultValue={fields?.email ?? ''}
              error={errors.email}
            />
            <SelectInput
              cols={3}
              register={register}
              fieldName="isoCountryCode"
              label="Tax Residence"
              error={errors.isoCountryCode}
              defaultValue={fields?.isoCountryCode}
              defaultOption={{ label: 'Select', value: '' } as const}
              options={countries.map((country) => ({
                label: country.name,
                value: country.alpha2,
              }))}
            />
          </>
        </Section>
      </>
    </Form>
  );
}
