import { countryNameByIsoCode } from 'listo/src/countries';
import { formatDate, stringFromDateOrString } from 'listo/src/utils/dates';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { trpc } from '../../lib/trpc';
import { Nullable } from '../../types/common';
import { Attachments } from '../Contract/Attachments';

export default function ProfileDetails() {
  const { id } = useParams();
  const [uploadUrl, setUploadUrl] = useState<Nullable<string>>(null);
  if (!id) throw new Error('id is required');
  const { data } = trpc.u.workers.worker.useQuery(id, {
    onSuccess: (d) => {
      if (d.contracts[0]?.upload?.url) {
        setUploadUrl(d.contracts[0].upload.url);
      }
    },
    trpc: {},
  });

  if (data === undefined) return null;
  const { workerProfile } = data;
  return (
    <>
      <div className="px-4 pt-5 sm:px-6">
        {/* <h2
            id="applicant-information-title"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            Profile Information
          </h2> */}
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Profile details and overview.
        </p>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Member Since</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatDate(stringFromDateOrString(data.workerProfile.createdAt))}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {workerProfile.credential.email}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Phone Number</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {workerProfile.phone}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Address 1</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {workerProfile.address1}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Address 2</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {workerProfile.address2}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">City</dt>
            <dd className="mt-1 text-sm text-gray-900">{workerProfile.city}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Postal Code</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {workerProfile.postalCode}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Zone Code</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {workerProfile.zoneCode}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Tax Residence</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {countryNameByIsoCode(workerProfile.isoCountryCode ?? '')}
            </dd>
          </div>
        </dl>
      </div>
      {uploadUrl ? <Attachments uploadUrl={uploadUrl} /> : null}
    </>
  );
}
