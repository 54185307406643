import * as Sentry from '@sentry/react';
import { TRPCClientErrorLike } from '@trpc/react-query';
import { AppRouter } from 'api/src/trpc/trpcRouter';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { IS_PROD } from '../../lib/constants';
import Fallback from '../Fallback';

export function ErrorState({
  trpcError,
  parentComponentName,
}: {
  trpcError: TRPCClientErrorLike<AppRouter>;
  parentComponentName: string;
}) {
  const [showError, setShowError] = useState(false);
  const [searchParams] = useSearchParams();
  const paramsShowError = searchParams.get('showError');

  useEffect(() => {
    Sentry.captureMessage(
      `error in ${parentComponentName}. message: ${trpcError.message}`,
    );
  }, [parentComponentName, trpcError]);

  useEffect(() => {
    if (paramsShowError || !IS_PROD) {
      setShowError(true);
    }
  }, [paramsShowError]);

  return (
    <>
      {showError ? <h1>{trpcError.message.toString()}</h1> : null}
      <Fallback />;
    </>
  );
}
