import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';
import InvoicesTable from './InvoicesTable';

export function Invoices() {
  const invoiceStats = trpc.u.invoices.stats.useQuery();

  if (invoiceStats.isLoading) return <Loader />;
  if (invoiceStats.error) {
    return (
      <ErrorState
        trpcError={invoiceStats.error}
        parentComponentName="Invoices.tsx"
      />
    );
  }

  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen" data-testid="main">
      <TopBar title="Invoices" />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12">
        {/* <InvoiceStats
          isLoading={invoiceStats.isLoading}
          totalDue={invoiceStats.data.totalDue}
          invoices={invoiceStats.data.invocies}
        /> */}
        <InvoicesTable />
      </div>
    </main>
  );
}
