import { useState } from 'react';
import useIsMounted from '../../hooks/useIsMounted';
import { trpc } from '../../lib/trpc';
import Button from '../Button';

interface ISignatureErrors {
  client?: string;
  worker?: string;
}

interface IErrors {
  signature?: ISignatureErrors;
}

export function SignatureSection({
  contractId,
  onCancel,
  signer,
  onSuccess,
}: {
  contractId: string;
  onCancel: () => void;
  signer: 'client' | 'worker';
  onSuccess: () => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const isMounted = useIsMounted();
  const [errors, setErrors] = useState<IErrors>({});
  const [clientSignature, setClientSignature] = useState('');
  const [workerSignature, setWorkerSignature] = useState('');
  const [persistedClientSignature, setPersistedClientSignature] = useState<
    string | null
  >('');
  const [persistedWorkerSignature, setPersistedWorkerSignature] = useState<
    string | null
  >('');
  const { mutateAsync: signContract } = trpc.l.legal.sign.useMutation();
  trpc.l.legal.signatureData.useQuery(
    {
      contractId,
    },
    {
      onSuccess: (data) => {
        if (isMounted()) {
          setPersistedClientSignature(data.clientSignature);
          setPersistedWorkerSignature(data.workerSignature);
        }
      },
    },
  );

  const isFullySigned =
    !!persistedClientSignature && !!persistedWorkerSignature;

  const handleAddSignature = (
    signerType: 'worker' | 'client',
    value: string,
  ) => {
    const setSignature =
      signerType === 'client' ? setClientSignature : setWorkerSignature;

    if (errors.signature?.[signerType]) {
      setErrors((oldErrors: IErrors) => {
        const newErrors = oldErrors;
        if (newErrors.signature?.[signerType]) {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete newErrors.signature[signerType];
        }
        return newErrors;
      });
    }
    setSignature(value);
  };

  return (
    <div className="w-full pt-8">
      <div className="grid grid-cols-2">
        <div className="mx-auto">
          <input
            className="focus:outline-none outline-none ring-transparent focus:ring-transparent w-full"
            disabled={signer === 'worker' || !!persistedClientSignature}
            style={{
              fontFamily: "'Dancing Script', cursive",
              fontSize: 30,
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              borderBottom: '1px solid #e0e0e0',
              outline: 'outline: 0px solid transparent',
              textIndent: 2,
            }}
            onChange={(e) => {
              handleAddSignature('client', e.target.value);
            }}
            type="text"
            value={persistedClientSignature ?? clientSignature}
            placeholder={signer === 'client' ? 'Sign Here' : undefined}
          />
          <p className="mt-2 text-sm">Client Signature</p>
          {errors.signature?.client && (
            <p className="mt-2 text-sm text-red-500">
              * {errors.signature.client}
            </p>
          )}
        </div>
        <div className="mx-auto">
          <input
            disabled={signer === 'client' || !!persistedWorkerSignature}
            className="focus:outline-none outline-none ring-transparent focus:ring-transparent w-full"
            style={{
              fontFamily: "'Dancing Script', cursive",
              fontSize: 30,
              borderTop: 0,
              borderLeft: 0,
              borderRight: 0,
              borderBottom: '1px solid #e0e0e0',
              outline: 'outline: 0px solid transparent',
              textIndent: 2,
            }}
            onChange={(e) => {
              handleAddSignature('worker', e.target.value);
            }}
            value={persistedWorkerSignature ?? workerSignature}
            placeholder={
              signer === 'worker' && !workerSignature ? 'Sign Here' : undefined
            }
            type="text"
          />
          <p className="mt-2 text-sm">Contractor Signature</p>
          {errors.signature?.worker && (
            <p className="mt-2 text-sm text-red-500">
              * {errors.signature.worker}
            </p>
          )}
        </div>
      </div>
      <div className="flex justify-end pt-8">
        <Button text="Close" variant="secondary" onClick={() => onCancel()} />
        {!isFullySigned ? (
          <Button
            loading={isLoading}
            className="ml-3"
            text="Accept"
            onClick={() => {
              setIsLoading(true);

              let signature;

              if (signer === 'client') signature = clientSignature;
              if (signer === 'worker') signature = workerSignature;
              if (!signature) {
                setIsLoading(false);

                setErrors({
                  ...errors,
                  signature: {
                    [signer]: 'Signature is required',
                  },
                });
                return;
              }

              signContract({
                contractId,
                signingParty: signer,
                signature,
              })
                .then((res) => res)
                .then(() => {
                  onSuccess();
                })
                .catch((e) => {
                  // eslint-disable-next-line no-console
                  console.log('error signing contract', e);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            }}
          />
        ) : null}
      </div>
    </div>
  );
}
