import { ContractorContractCreate } from 'listo/src/zodObjects/contracts';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface MultiStepForm {
  setFields: (fields: Partial<ContractorContractCreate>) => void;
  fields: undefined | Partial<ContractorContractCreate>;
  resetForm: () => void;
}

export const useCreateContractForm = create<MultiStepForm>()(
  persist(
    (set) => ({
      fields: undefined,
      setFields: (fields: Partial<ContractorContractCreate>) =>
        set((state) => ({ fields: { ...state.fields, ...fields } })),
      resetForm: () => set(() => ({ fields: undefined })),
    }),
    {
      name: 'create-contract-form',
    },
  ),
);
