import { Fragment, useEffect } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useForm } from 'react-hook-form';
import { Button } from 'ui/src/components/Button';

export interface IApprovalModalForm {
  message: string;
}

interface IApproveModal {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: IApprovalModalForm) => void;
  title?: string;
}

export function ApproveModal({
  open,
  onClose,
  onSubmit,
  title,
}: IApproveModal) {
  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting },
  } = useForm<IApprovalModalForm>();

  useEffect(() => {
    reset();
  }, [open, reset]);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onClose}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="bg-white p-6 pb-[0px]">
                    <div className="sm:flex sm:items-start">
                      <div className="w-full text-center sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-medium text-gray-900"
                        >
                          {title}
                        </Dialog.Title>

                        <div className="mt-6">
                          <textarea
                            rows={6}
                            className="w-full rounded-md border-gray-300"
                            placeholder="Enter your message (optional)"
                            {...register('message')}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-6 mb-2 flex items-center justify-end gap-4">
                    <Button
                      type="button"
                      variant="secondary"
                      onClick={onClose}
                      text="Cancel"
                    />

                    <Button
                      type="submit"
                      loading={isSubmitting}
                      text="Confirm"
                    />
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default ApproveModal;
