import {
  BriefcaseIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  MapIcon,
} from '@heroicons/react/20/solid';

import { countryNameByIsoCode } from 'listo/src/countries';
import { formatDate } from 'listo/src/utils/dates';
import { titleCase } from 'listo/src/utils/strings';
import { BillingCycle } from 'ui/src/components/Currency/Currency';
import { RouterOutput } from '../../lib/trpc';
import { Attachments } from './Attachments';

export function Header({
  contract,
}: {
  contract: RouterOutput['u']['contracts']['contract'];
}) {
  const uploadUrl = contract.upload?.url ?? contract.customContractUpload?.url;
  return (
    <div className="">
      <div className="lg:flex lg:items-center lg:justify-between  sm:justify-start border-b border-gray-200 pl-6 pt-3">
        <div className="min-w-0 flex-1 pb-3 ">
          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
            {titleCase(contract.workerProfile.fullName ?? '')}
          </h2>
          <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <BriefcaseIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {titleCase(contract.engagementType)}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <MapIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {countryNameByIsoCode(contract.isoCountryCode)}
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CurrencyDollarIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              <BillingCycle
                currency={contract.billingCurrency}
                amount={contract.billingAmountInCents}
                frequency={contract.billingFrequency}
                isCents
              />
            </div>
            <div className="mt-2 flex items-center text-sm text-gray-500">
              <CalendarIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {`Starting on ${formatDate(contract.startDate)}`}
            </div>
          </div>
        </div>
      </div>
      <div className="p-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-3">
          <div className="sm:col-span-1 rounded-md">
            <dt className="text-sm font-medium text-gray-500 ">Email</dt>
            <dd className="mt-1 text-sm text-gray-900 text-ellipsis overflow-hidden">
              {contract.worker.credential.email}
            </dd>
          </div>
          {contract.engagementType === 'CONTRACTOR' ? (
            <>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Worker Signature
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {contract.workerSignature}
                </dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Client Signature
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {contract.clientSignature}
                </dd>
              </div>
            </>
          ) : null}
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Job Title</dt>
            <dd className="mt-1 text-sm text-gray-900">{contract.jobTitle}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Seniority Level
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {contract.seniorityLevel}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Scope of Work</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {contract.scopeOfWork}
            </dd>
          </div>
        </dl>
      </div>
      {uploadUrl ? <Attachments uploadUrl={uploadUrl} /> : null}
    </div>
  );
}
