import { ChangeEventHandler, useState, useRef, useEffect } from 'react';
import { isArray } from 'lodash';
import axios from 'axios';
import AvatarEditor from 'react-avatar-editor';
import MagnifyingGlassPlusIcon from '@heroicons/react/24/outline/MagnifyingGlassPlusIcon';

import { useNotification } from 'ui/src/components/Notification/useNotification';
import { Button } from 'ui/src/components/Button';
import { trpc } from '../../lib/trpc';
import { useAuth } from '../../hooks/useAuth';
import Avatar from '../Avatar';
import Modal from '../Modal';

enum AssestType {
  PROFILE_PICTURE = 'profilePicture',
}

interface ProfilePictureProps {
  fullName?: string;
  pictureUrl?: string;
  uploadPicture: (id: string, url: string) => void;
  entityId?: string; // for enitiy logo
}

export function ProfilePictureUploader({
  fullName,
  pictureUrl = '',
  uploadPicture,
  entityId,
}: ProfilePictureProps) {
  const { claims } = useAuth();

  const avatarEditor = useRef<AvatarEditor>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const setNotification = useNotification((state) => state.setNotification);
  const [fileFromUploader, setFileFromUploader] = useState<File>();
  const [clientId, setClientId] = useState(
    entityId ?? claims?.activeClientId ?? '',
  );
  const [imageForAvatarEditor, setImageForAvatarEditor] = useState('');
  const [scale, setScale] = useState(1);
  const [loading, setLoading] = useState(false);

  // setting fallback client id for to get signed URL
  useEffect(() => {
    if (!clientId || clientId === 'n/a') {
      if (claims && isArray(claims.users)) {
        setClientId(claims.users[0]?.clientId ?? '');
      }
    }
  }, [claims, clientId]);

  const { mutateAsync: getSignedUrlForUpload } =
    trpc.a.assets.getSignedUrl.useMutation();
  const { mutateAsync: uploadProfile } =
    trpc.a.assets.uploadSuccess.useMutation();

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileList = event.target.files;

    if (fileList) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedFile = fileList[0]!;
      const image = URL.createObjectURL(selectedFile);
      setImageForAvatarEditor(image);
      setFileFromUploader(selectedFile);
    }
  };

  const resetUploader = () => {
    setImageForAvatarEditor('');
    if (fileInput.current) {
      fileInput.current.value = '';
    }
  };

  const handleImageUpload = async (selectedFile: File) => {
    try {
      setLoading(true);

      const fileName = selectedFile.name;
      const mimeType = selectedFile.type;

      if (mimeType && fileName) {
        const { signedUrl, cloudStorageKey } = await getSignedUrlForUpload({
          listoAssetType: AssestType.PROFILE_PICTURE,
          mimeType,
          clientId,
        });

        await axios.put(signedUrl, selectedFile, {
          headers: {
            'Content-Type': selectedFile.type,
          },
        });

        const { id, url } = await uploadProfile({
          clientId,
          fileName,
          mimeType,
          cloudStorageKey,
        });

        await Promise.resolve(uploadPicture(id, url));
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Something went wrong',
      });
    } finally {
      setLoading(false);
      resetUploader();
    }

    return 1;
  };

  const handleImageSave = async () => {
    try {
      if (avatarEditor.current && fileFromUploader) {
        const mimeType = fileFromUploader.type;
        const canvas = avatarEditor.current.getImage();
        const blob = await new Promise((resolve) => {
          canvas.toBlob(resolve, mimeType);
        });
        const imageBlob = blob as Blob;
        const convertedFile = new File([imageBlob], 'avatar', {
          type: mimeType,
        });

        await handleImageUpload(convertedFile);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Something went wrong',
      });
    }
  };

  return (
    <>
      <div className="lg:hidden">
        <div className="flex items-center">
          <Avatar
            name={fullName ?? ''}
            image={pictureUrl}
            className="relative h-24 w-24 border border-gray-200 rounded-full bg-gray-200 text-3xl"
          />

          <div className="ml-5 rounded-md shadow-sm">
            <div className="group relative flex items-center justify-center rounded-md border border-gray-300 py-2 px-3 focus-within:ring-2 focus-within:ring-sky-500 focus-within:ring-offset-2 hover:bg-gray-50">
              <label
                htmlFor="profile-upload"
                className="pointer-events-none relative text-sm font-medium leading-4 text-gray-700"
              >
                <span>Change</span>
                <span className="sr-only"> user photo</span>
              </label>

              <input
                type="file"
                id="profile-upload"
                accept="image/*"
                ref={fileInput}
                name="user-photo"
                onChange={handleChange}
                className="absolute h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                multiple={false}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="relative hidden overflow-hidden rounded-full lg:inline-block">
        <Avatar
          name={fullName ?? ''}
          image={pictureUrl}
          className="relative h-24 w-24 border border-gray-200 rounded-full bg-gray-200 text-3xl"
        />

        <label
          htmlFor="profile-upload"
          className="absolute inset-0 flex h-full w-full items-center justify-center bg-black bg-opacity-75 text-sm font-medium text-white opacity-0 cursor-pointer	 focus-within:opacity-100 hover:opacity-100"
        >
          <span>Change</span>
          <span className="sr-only"> user photo</span>
          <input
            type="file"
            id="profile-upload"
            accept="image/*"
            name="user-photo"
            onChange={handleChange}
            className="hidden"
            multiple={false}
          />
        </label>
      </div>

      <Modal isOpen={!!imageForAvatarEditor} setIsOpen={() => resetUploader()}>
        <div className="bg-white py-8 px-8">
          <h3 className="text-xl font-medium text-gray-900">Crop Image</h3>

          <div className="my-4">
            <AvatarEditor
              ref={avatarEditor}
              image={imageForAvatarEditor}
              scale={scale}
              width={300}
              height={300}
              border={20}
              borderRadius={200}
              rotate={0}
            />
          </div>

          <div className="mb-8">
            <label
              htmlFor="crop-range"
              className="block text-sm font-medium text-gray-900"
            >
              <span className="flex items-center gap-2">
                <MagnifyingGlassPlusIcon className="h-5" />

                <span className="font-medium text-gray-700">
                  Slide to adjust the image
                </span>
              </span>
            </label>

            <input
              id="crop-range"
              type="range"
              className="w-full h-2 bg-gray-900 rounded-lg appearance-none cursor-pointer"
              min={1}
              max={2}
              step={0.01}
              value={scale}
              onChange={(e) => setScale(e.target.valueAsNumber)}
            />
          </div>

          <div className="flex items-center justify-end gap-3">
            <Button
              text="Cancel"
              type="button"
              onClick={resetUploader}
              variant="secondary"
            />

            <Button
              text="Save"
              type="button"
              loading={loading}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={handleImageSave}
              variant="primary"
            />
          </div>
        </div>
      </Modal>
    </>
  );
}
