import { useParams } from 'react-router-dom';
import { ContractStatusEnum } from 'listo/src/zodObjects/contracts';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { useSignedModal } from '../../components/SignedContract/useSignedContract';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import { ContratorSignature } from '../Profile/ContractorSignature';

function ClientContractStatus() {
  const { contractId } = useParams();
  const setSignedModalOpen = useSignedModal((state) => state.setIsOpen);
  const setNotification = useNotification((state) => state.setNotification);
  const { data: contract, isLoading } = trpc.u.contracts.contract.useQuery(
    contractId ?? '',
  );

  const { mutate: requestContract, isLoading: requestContractLoading } =
    trpc.u.contracts.requestContract.useMutation({
      onSuccess: () =>
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Contract Requested',
        }),
    });

  if (isLoading) {
    return <Loader />;
  }

  if (!contract) {
    return null;
  }

  const {
    address1,
    address2,
    phone,
    postalCode,
    taxIdNumber,
    firstName,
    lastName,
    isoCountryCode,
  } = contract.workerProfile;

  const hasWorkerInformation =
    (address1 || address2) &&
    phone &&
    postalCode &&
    taxIdNumber &&
    firstName &&
    lastName &&
    isoCountryCode;

  const handleRequestContract = () => {
    if (contractId) {
      return requestContract({ contractId });
    }

    return null;
  };

  if (contract.status !== ContractStatusEnum.enum.EXECUTED) {
    return (
      <section aria-labelledby="client-contract-status">
        <div className="px-4 py-5 bg-white shadow sm:rounded-lg sm:px-6">
          <div className="flex flex-col items-start justify-start gap-2">
            <h2
              id="timeline-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Status: {contract.status}
            </h2>

            {hasWorkerInformation ? (
              <div className="flex flex-col items-start gap-2 w-full my-4">
                <p className="text-sm text-gray-500">
                  Employment Contract can be requested.
                </p>

                <Button
                  id="ClientContractStatus-Action-RequestContract"
                  text="Request to upload the contract"
                  loading={requestContractLoading}
                  onClick={handleRequestContract}
                  className="w-full"
                />
              </div>
            ) : (
              <p className="text-sm text-gray-500">
                Please invite your employee to fill the their information.
              </p>
            )}
          </div>

          <ContratorSignature
            invitationLink={contract.invitationLink}
            workerSignature={contract.workerSignature}
            workerId={contract.workerId}
            workerEmail={contract.worker.credential.email}
            contractId={contract.id}
            status={contract.status}
          />
        </div>
      </section>
    );
  }

  return (
    <section aria-labelledby="contract-status">
      <div className="mb-5 bg-white shadow sm:rounded-lg">
        <div className="flex flex-col items-start justify-start gap-2 px-4 py-5 sm:px-6">
          <h2
            id="timeline-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Status: {contract.status}
          </h2>

          <p className="max-w-2xl text-sm text-gray-500">
            We&apos;ve issued your compliant employment contract. Click below to
            view the contract.
          </p>

          <Button
            id="ClientContractStatus-Action-openSignedModal"
            text="View Contract"
            onClick={() => setSignedModalOpen(true)}
            className="w-full"
          />
        </div>

        <div className="px-4 pb-4 sm:px-6">
          <ContratorSignature
            invitationLink={contract.invitationLink}
            workerSignature={contract.workerSignature}
            workerId={contract.workerId}
            workerEmail={contract.worker.credential.email}
            contractId={contract.id}
            status={contract.status}
          />
        </div>
      </div>
    </section>
  );
}

export default ClientContractStatus;
