import { useState } from 'react';
import { isArray, isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { DateTime } from 'listo/src/luxonUtc';
import { formatDate } from 'listo/src/utils/dates';
import { Input } from 'ui/src/components/Input';
import AlertModal from 'ui/src/components/AlertModal';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { downloadFromURL } from '../../../utility/download';
import Avatar from '../../components/Avatar';
import EmptyState from '../../components/EmptyState';
import { Spinner } from '../../components/Loader';
import TableLoader from '../../components/TableLoader';
import TopBar from '../../components/TopBar';
import { useAuth } from '../../hooks/useAuth';
import { RouterOutput, trpc } from '../../lib/trpc';
import { useTimeTrackingDates } from './useTimeTrackingDates';
import { IlabelOptions } from './types';
import { TimeSheetModal } from './TimeSheetModal';
import Modal from '../../components/Modal';

const pageSize = 10;

function variables({
  page,
  workerProfileId,
  startDate,
  endDate,
  labels,
}: {
  page: number;
  workerProfileId: string;
  startDate: DateTime;
  endDate: DateTime;
  labels?: string[];
}) {
  return {
    page,
    limit: pageSize,
    workerProfileId,
    startDate: startDate.toJSDate(),
    endDate: endDate.toJSDate(),
    labels,
  };
}

function Data({
  data,
  refetch,
}: {
  data: RouterOutput['u']['timeCards']['getByWorkerProfile'];
  refetch: () => Promise<unknown>;
}) {
  const [openDeleteModalFor, setOpenDeleteModalFor] = useState('');
  const [open, setOpenModal] = useState(false);
  const [currentData, setCurrentData] = useState({});

  const setNotification = useNotification((state) => state.setNotification);

  const {
    mutate: deleteAllWorkerTimeCards,
    isLoading: isLoadingDeleteAllTimeCards,
  } = trpc.u.timeCards.deleteWorkerTimeCard.useMutation({
    onSuccess: () => refetch(),
    onError: () => {
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Your request has failed. Please contact us.',
      });
    },
    onSettled: () => setOpenDeleteModalFor(''),
  });

  const handleConfirmDelete = () =>
    deleteAllWorkerTimeCards({
      timeCardId: openDeleteModalFor,
    });

  const handleDelete = (timeCardId: string) =>
    setOpenDeleteModalFor(timeCardId);

  return (
    <>
      {data.timecards.map((timecard) => (
        <tr key={timecard.id}>
          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-6">
            <div className="flex items-center space-x-3">
              <div className="flex overflow-hidden">
                <Avatar
                  name={timecard.worker.workerProfile.fullName ?? ''}
                  image={timecard.workerProfile.workerProfileUpload?.url ?? ''}
                  className="w-10 h-10 font-medium"
                />
                <p className="mt-2 ml-2 font-medium text-indigo-600 capitalize truncate">
                  {timecard.worker.workerProfile.fullName ?? ''}
                </p>
              </div>
            </div>
          </td>
          <td className="py-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-4">
            <div className="flex items-center space-x-3">
              {formatDate(timecard.date)}
            </div>
            <p className="text-gray-500 capitalize truncate font-small">
              {timecard.description}
            </p>
          </td>

          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-4">
            <div className="flex items-center justify-center space-x-3">
              {timecard.hours} {timecard.hours > 1 ? 'Hrs' : 'Hr'}
            </div>
          </td>

          <td className="py-4 pl-4 pr-3 text-sm font-medium text-gray-900 whitespace-nowrap sm:pl-4 sm:pr-6">
            <div className="flex items-center justify-center space-x-3">
              {timecard.projectLabel ? (
                <span className="px-2 py-1 bg-gray-200 rounded-md">
                  {timecard.projectLabel}
                </span>
              ) : (
                'No Label'
              )}
            </div>
          </td>

          <td className="py-4 pl-4 pr-3 text-sm font-medium text-center text-gray-900 whitespace-nowrap sm:pl-4 sm:pr-6">
            <button
              type="button"
              className="text-indigo-600 hover:text-indigo-900"
              onClick={() => {
                setCurrentData(timecard);

                setOpenModal(true);
              }}
            >
              Edit
            </button>
            <button
              type="button"
              data-testid="worker-time-tracking-card-action-delete"
              className="text-rose-800 hover:text-rose-900 ml-4"
              onClick={() => handleDelete(timecard.id)}
            >
              Delete
            </button>

            <AlertModal
              open={openDeleteModalFor === timecard.id}
              setOpen={() => setOpenDeleteModalFor('')}
              title="Delete?"
              loadingConfirm={isLoadingDeleteAllTimeCards}
              description={`Are you sure you want to delete timecard of ${
                timecard.worker.workerProfile.fullName ?? ''
              }? This action cannot be undone.`}
              onConfirm={() => handleConfirmDelete()}
            />
          </td>
        </tr>
      ))}
      {!isEmpty(currentData) && (
        <Modal isOpen={open} setIsOpen={setOpenModal}>
          <TimeSheetModal
            setModalOpen={setOpenModal}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            currentData={currentData}
          />
        </Modal>
      )}
    </>
  );
}

export default function WorkerTimeTrackingTable() {
  const { claims } = useAuth();
  const setNotification = useNotification((state) => state.setNotification);
  const [page] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<string[]>();
  const { startDate, endDate, setStartDate, setEndDate } =
    useTimeTrackingDates();

  const { workerProfileId } = useParams<{ workerProfileId: string }>();
  const clientId = claims?.activeClientId ?? '';

  if (!workerProfileId) throw new Error('workerProfileId is required');

  const { data, isLoading, error, refetch } =
    trpc.u.timeCards.getByWorkerProfile.useQuery(
      variables({
        page,
        workerProfileId,
        startDate,
        endDate,
        labels: selectedOptions,
      }),
    );

  const { data: labels } = trpc.u.labels.list.useQuery({
    clientId,
  });

  const { mutate: exportTimeCardsCSV, isLoading: exportCSVLoading } =
    trpc.u.timeCards.exportTimeCardsDetailsToCSV.useMutation({
      onSuccess({ url }: { url: string }) {
        downloadFromURL(url);

        return setNotification({
          type: 'success',
          title: 'Success',
          message: 'Download completed',
        });
      },
      onError(errorCSV: unknown) {
        // eslint-disable-next-line no-console
        console.error(errorCSV);

        return setNotification({
          type: 'error',
          title: 'Error',
          message: 'Download failed',
        });
      },
    });

  const handleFilterChange = (options: IlabelOptions) => {
    if (options.length) {
      setSelectedOptions(options.map((option) => option.value));
    } else {
      setSelectedOptions(undefined);
    }

    return refetch();
  };

  const handleExportCSV = () =>
    exportTimeCardsCSV(
      variables({ page, workerProfileId, startDate, endDate }),
    );

  if (error) return <div>Error</div>;

  return (
    <main className="flex-1 min-h-screen pb-8 bg-gray-100">
      <TopBar title="Time Tracking" />
      <div className="max-w-6xl px-4 mx-auto mt-8 sm:px-6 lg:px-8">
        <div className="flex flex-wrap items-center justify-between gap-6 mb-8">
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-2">
              <Input
                inputProps={{
                  value: startDate.toISODate(),
                  onChange: ({ target: { value } }) => {
                    setStartDate(DateTime.fromISO(value).startOf('day'));
                  },
                }}
                inputType="date"
              />
              -
              <Input
                inputProps={{
                  value: endDate.toISODate(),
                  onChange: ({ target: { value } }) => {
                    setEndDate(DateTime.fromISO(value).startOf('day'));
                  },
                }}
                inputType="date"
              />
            </div>

            <Select
              isMulti
              name="filter"
              placeholder="Filter..."
              options={
                labels?.values && isArray(labels.values)
                  ? labels.values.map((option) => ({
                      label: option as string,
                      value: option as string,
                    }))
                  : []
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChange={(options) =>
                handleFilterChange(options as IlabelOptions)
              }
              className="mt-1 text-sm basic-multi-select"
              classNamePrefix="select"
              styles={{
                input: (baseStyles) => ({
                  ...baseStyles,

                  'input[type = Text]': {
                    ':focus': {
                      outline: 'none !important',
                      border: 'none !important',
                      boxShadow: 'none !important',
                    },
                  },
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: '#f2f2f2',
                  display: 'flex',
                  borderRadius: '4px',
                }),
                multiValueLabel: (baseStyles) => ({
                  ...baseStyles,
                  color: '#737373',
                  fontSize: '13px',
                }),
                multiValueRemove: (baseStyles) => ({
                  ...baseStyles,
                  margin: '3px',
                  padding: '3px',
                  borderRadius: '4px',
                  color: '#737373',
                  ':hover': {
                    backgroundColor: '#d9d9d9',
                    color: 'white',
                  },
                }),
              }}
            />
          </div>
          <button
            type="button"
            className="inline-flex items-start justify-center gap-2 text-base text-gray-700 hover:text-gray-900 disabled:opacity-75"
            onClick={handleExportCSV}
            disabled={exportCSVLoading}
          >
            <span>Export</span>
            {exportCSVLoading ? (
              <Spinner height={5} width={5} />
            ) : (
              <ArrowDownTrayIcon className="w-5 h-5" aria-hidden="true" />
            )}
          </button>
        </div>

        {isLoading ? (
          <TableLoader />
        ) : (
          <table className="min-w-full divide-y divide-gray-300">
            <thead className="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                >
                  Worker&apos;s Name
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-4"
                >
                  Date
                  <span className="sr-only">Date</span>
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-4"
                >
                  Hours
                  <span className="sr-only">Hours</span>
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-4 sm:pr-6"
                >
                  Label
                  <span className="sr-only">Label</span>
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-4 pr-3 text-center text-sm font-semibold text-gray-900 sm:pl-4 sm:pr-6"
                >
                  Actions
                  <span className="sr-only">Label</span>
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              <Data data={data} refetch={refetch} />
            </tbody>
          </table>
        )}

        {data !== undefined && data.timecards.length === 0 && page === 0 ? (
          <EmptyState entity="Timecards" />
        ) : null}

        {/* {data !== undefined &&
        data.timecards.length === 0 &&
        page === 0 ? null : (
          <Pagination
            setPageNumber={setPage}
            nextPage={data?.nextPage}
            previousPage={data?.prevPage}
          />
        )} */}
      </div>
    </main>
  );
}
