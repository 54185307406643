import { formatDate } from 'listo/src/utils/dates';
import { titleCase } from 'listo/src/utils/strings';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import Loader from '../../components/Loader';
import RadixAvatar from '../../components/RadixAvatar';
import { RouterOutput, trpc } from '../../lib/trpc';
import { useApprovalModal } from './useApprovalModal';

export function Approval({
  payPeriod,
}: {
  payPeriod: RouterOutput['u']['payPeriods']['payPeriod'];
}) {
  const utils = trpc.useContext();
  const setApprovalModalOpen = useApprovalModal((state) => state.setIsOpen);
  const setNotification = useNotification((state) => state.setNotification);
  const { mutate: approvePayroll, isLoading: approvalLoading } =
    trpc.u.payPeriods.approve.useMutation({
      onSuccess: () => {
        utils.u.payPeriods.payPeriod
          .invalidate({
            payPeriodId: payPeriod.id,
          })
          .catch(() => {});
        setApprovalModalOpen(false);
        setNotification({
          type: 'success',
          title: 'Pay Period Approved',
          message: 'Pay period successfully approved.',
        });
      },
      onError: () => {
        setNotification({
          type: 'error',
          title: 'Pay Period Approval Failed',
          message: 'Pay Period approval failed. Please contact us.',
        });
      },
    });

  const {
    data: approvalData,
    isLoading,
    error,
  } = trpc.u.payPeriods.approval.useQuery({
    payPeriodId: payPeriod.id,
  });

  if (isLoading)
    return (
      <div className="sm:max-w-4xl sm:w-[60vw] h-[80vh] w-[90vw] bg-white">
        <Loader />;
      </div>
    );
  if (error) return <div>{error.message}</div>;

  return (
    <div className="sm:max-w-5xl sm:w-[60vw] md:h-[80vh] w-[90vw] bg-white overflow-y-scroll">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="-ml-4 -mt-4 flex flex-wrap items-center justify-between sm:flex-nowrap">
          <div className="ml-4 mt-4">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              {titleCase(approvalData.payPeriod.periodType)} Pay Period
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Pay period data for the{' '}
              {approvalData.payPeriod.periodType.toLowerCase()} period starting
              on{' '}
              <span className="font-medium">
                {formatDate(approvalData.payPeriod.periodStart)}{' '}
              </span>
              and ending on
              <span className="font-medium">
                {' '}
                {formatDate(approvalData.payPeriod.periodEnd)}
              </span>
              .
            </p>
          </div>
          <div className="ml-4 mt-4 flex-shrink-0">
            <Button
              text="Close"
              variant="secondary"
              className="mr-4"
              onClick={() => setApprovalModalOpen(false)}
            />
            <Button
              loading={approvalLoading}
              disabled={approvalLoading}
              text="Approve"
              variant="primary"
              onClick={() => {
                approvePayroll({ payPeriodId: payPeriod.id });
              }}
            />
          </div>
        </div>
      </div>
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-medium font-medium leading-6 text-gray-900 border-b border-gray-200 pb-4">
          Hourly Workers
        </h3>
        <ul className="divide-y divide-gray-200">
          {approvalData.hourlyRateWorkerProfilesWithPayForPeriod.map((data) => (
            <li key={data.contract.id}>
              <div className="flex items-center py-4">
                <div className="flex min-w-0 flex-1 items-center truncate">
                  <div className="hidden md:flex-shrink-0">
                    <div className="flex overflow-hidden">
                      <RadixAvatar
                        fallbackString={data.workerProfile.fullName ?? ''}
                      />
                    </div>
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {titleCase(data.workerProfile.fullName)}
                    </div>
                    <div className="text-sm text-gray-500">
                      {data.workerProfile.credential.email}
                    </div>
                  </div>
                </div>
                <div className="hidden md:flex justify-end w-40">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {data.formattedBillingRate}{' '}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-28">
                  <div className="ml-4 mr-4">
                    <div className="text-sm font-medium text-gray-900">
                      {data.hours} hours
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-28">
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {data.formattedPay}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="px-4 sm:px-6">
        <h3 className="text-medium font-medium leading-6 text-gray-900 border-b border-gray-200 pb-4">
          Fixed Rate Workers
        </h3>
        <ul className="divide-y divide-gray-200">
          {approvalData.annualRateWorkerProfilesWithPayForPeriod.map((data) => (
            <li key={data.contract.id}>
              <div className="flex items-center py-4">
                <div className="flex min-w-0 flex-1 items-center truncate">
                  <div className="flex-shrink-0">
                    <div className="flex overflow-hidden">
                      <RadixAvatar
                        fallbackString={data.workerProfile.fullName ?? ''}
                      />
                    </div>
                  </div>
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {titleCase(data.workerProfile.fullName)}
                    </div>
                    <div className="text-sm text-gray-500">
                      {data.workerProfile.credential.email}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-40">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {data.formattedBillingRate}
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-28">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {data.hours} hours
                    </div>
                  </div>
                </div>
                <div className="flex justify-end w-28">
                  <div className="ml-4">
                    <div className="text-sm font-medium text-gray-900">
                      {data.formattedPay}
                    </div>
                  </div>
                </div>
              </div>
            </li>
          ))}
          {approvalData.monthlyRateWorkerProfilesWithPayForPeriod.map(
            (data) => (
              <li key={data.contract.id}>
                <div className="flex items-center py-4">
                  <div className="flex min-w-0 flex-1 items-center truncate">
                    <div className="flex-shrink-0">
                      <div className="flex overflow-hidden">
                        <RadixAvatar
                          fallbackString={data.workerProfile.fullName ?? ''}
                        />
                      </div>
                    </div>
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {titleCase(data.workerProfile.fullName)}
                      </div>
                      <div className="text-sm text-gray-500">
                        {data.workerProfile.credential.email}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end w-40">
                    <div className="ml-4">
                      <div className="text-sm font-medium text-gray-900">
                        {data.formattedBillingRate}
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end w-28">
                    <div className="ml-4 mr-4">
                      <div className="text-sm font-medium text-gray-900">
                        {data.hours} hours
                      </div>
                    </div>
                  </div>
                  <div className="flex justify-end w-28">
                    <div>
                      <div className="text-sm font-medium text-gray-900">
                        {data.formattedPay}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ),
          )}
        </ul>
      </div>
    </div>
  );
}
