import { Fragment, useState } from 'react';
import { QueryObserverResult } from '@tanstack/react-query';
import { Menu, Transition } from '@headlessui/react';
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline';
import { classNames } from 'listo/src/utils/strings';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';
import Modal from '../../components/Modal';
import UploadBox from '../../components/UploaderWithProgressBar';

enum ImportUploadType {
  CSV = '.csv',
  XLSX = '.xlsx',
}

export function TimeTrackingStats({
  totalHours,
  refetch,
}: {
  totalHours: number | string;
  refetch: () => Promise<QueryObserverResult>;
}) {
  const { claims } = useAuth();
  const activeClientId = claims?.activeClientId ?? '';
  const setNotification = useNotification((state) => state.setNotification);
  // const utils = trpc.useContext();
  const [loading, setLoading] = useState(false);
  const [openCurrentUploaderForType, setOpenCurrentUploaderForType] =
    useState('');

  const { mutate: importTimeCard } =
    trpc.u.timeCards.importTimeCardsCSV.useMutation({
      onSuccess: async () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Timecards imported',
        });

        // utils.u.timeCards.list.invalidate(activeClientId).
        await refetch();
      },
      onError: (error) => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: error.message.toString(),
        });
      },
    });

  const handleModal = (type: string) => setOpenCurrentUploaderForType(type);

  const uploadTimeCard = (url: string) => {
    if (!url) return;

    importTimeCard({ url });
    setOpenCurrentUploaderForType('');
    setLoading(false);
  };

  return (
    <div>
      <dl className="flex justify-between bg-white rounded-lg shadow">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">Total Hours</dt>
          <dd className="flex items-baseline justify-between mt-1 md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
              {totalHours || '-'}
            </div>
          </dd>
        </div>

        <div className="px-4 py-5 sm:p-6">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="flex items-start gap-2 text-gray-700 border-none rounded-full hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                Import
                <ArrowUpTrayIcon className="w-5 h-5" aria-hidden="true" />
              </Menu.Button>
            </div>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-20 w-32 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                <div className="py-1">
                  {Object.entries(ImportUploadType).map(([key, value]) => (
                    <Menu.Item key={`menu-item-${key}`}>
                      {({ active }) => (
                        <button
                          type="button"
                          className={classNames(
                            active
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-700',
                            'py-2 text-sm w-full',
                          )}
                          onClick={() => handleModal(value)}
                        >
                          {key}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </dl>

      <Modal
        isOpen={!!openCurrentUploaderForType}
        setIsOpen={() =>
          setOpenCurrentUploaderForType(
            loading ? openCurrentUploaderForType : '',
          )
        }
        extraClasses="max-w-lg w-full py-4"
      >
        <div className="px-4 ">
          <div className="mt-3 text-center sm:mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray-900">
              Upload CSV
            </h3>

            <div className="mt-2">
              <UploadBox
                clientId={activeClientId}
                allowedTypes={[openCurrentUploaderForType]}
                loading={loading}
                setLoading={setLoading}
                readOnly={false}
                listoAssetType="timeCardsImport"
                onFileUploaded={(f) => {
                  uploadTimeCard(f.url);
                }}
              />
            </div>

            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                disabled={loading}
                className="inline-flex justify-center w-full px-4 py-2 mt-3 text-base font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm disabled:bg-gray-50 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                onClick={() => setOpenCurrentUploaderForType('')}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}
