import { StepLink } from '../../workerPages/WorkerDashboard/StepLink';

export function OnboardingSteps({
  hasPaymentMethod,
}: {
  hasPaymentMethod: boolean;
}) {
  return (
    <nav aria-label="Progress" className="px-4 py-5 sm:p-6">
      <ol className="overflow-hidden">
        <li className="pb-4 relative">
          <StepLink
            heading="Link Bank Account"
            subheading="Connect your bank account to Listo so you can send payments to your team"
            to="/settings/payment-methods"
            complete={hasPaymentMethod}
          />
        </li>
      </ol>
    </nav>
  );
}
