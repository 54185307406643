import { TRPCClientError, TRPCLink } from '@trpc/client';
import { getHTTPStatusCodeFromError } from '@trpc/server/http';
import { observable } from '@trpc/server/observable';
import type { AppRouter } from 'api/src/trpc/trpcRouter';
import { HEY_SENTRY_DONT_CAPTURE_THIS } from 'listo/src/constants';
import { DateTime } from 'listo/src/luxonUtc';

export const customLink: TRPCLink<AppRouter> =
  () =>
  ({ next, op }) =>
    observable((observer) => {
      const unsubscribe = next(op).subscribe({
        next(value) {
          observer.next(value);
        },
        error(err: TRPCClientError<AppRouter>) {
          if (err.message.includes(HEY_SENTRY_DONT_CAPTURE_THIS)) {
            observer.error(err);
            return;
          }

          const statusCode = getHTTPStatusCodeFromError({
            ...err,
            code: err.data?.code ?? 'INTERNAL_SERVER_ERROR',
          });

          if (statusCode === 401) {
            localStorage.removeItem('jwt');
            window.location.href = '/login?unauthenticated=true';
          }

          if (statusCode === 500 || statusCode === 400) {
            fetch('https://app.listoglobal.com/latestVersion.txt')
              .then((res) => res.text())
              .then((version) => {
                const latestVersion = DateTime.fromISO(
                  version.replace(/\n/g, ''),
                );
                localStorage.setItem(
                  'latestVersion',
                  JSON.stringify(latestVersion.toISO()),
                );
              })
              .catch(() => {});
          }

          observer.error(err);
        },
        complete() {
          observer.complete();
        },
      });
      return unsubscribe;
    });
