import {
  HomeIcon,
  UserGroupIcon,
  UserIcon,
  ClockIcon,
  CalendarIcon,
  CreditCardIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline';
import { ErrorBoundary } from '@sentry/react';
import { useQueryClient } from '@tanstack/react-query';
import { WORKER_PORTAL_MAGIC_STRING_SRY } from 'listo/src/constants';
import posthog from 'posthog-js';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { NotificationRegion } from 'ui/src/components/Notification';
import { useAuth } from '../../hooks/useAuth';
import { SidebarProvider } from '../../hooks/useSidebar';
import { ADMIN_URL } from '../../lib/constants';
import Fallback from '../Fallback';
import Sidebar from '../Sidebar';
import { Accounts } from '../Sidebar/AccountSelect';
import { RequireAuth } from '../Utility/RequireAuth';

const navigation = [
  { name: 'Dashboard', href: '/wrkr', icon: HomeIcon },
  { name: 'My Contracts', href: '/wrkr/my-contracts', icon: UserGroupIcon },
  { name: 'Profile', href: '/wrkr/profile/info', icon: UserIcon },
  { name: 'Time Cards', href: '/wrkr/timecards', icon: ClockIcon },
  {
    name: 'Request Time Off',
    href: '/wrkr/request-time-off',
    icon: CalendarIcon,
  },
  {
    name: 'Request Reimbursement',
    href: '/wrkr/request-reimbursement',
    icon: CreditCardIcon,
  },
  {
    name: 'My Documents',
    href: '/wrkr/documents',
    icon: DocumentTextIcon,
  },
];

export function WorkerAppShell() {
  const [accounts, setAccounts] = useState<Accounts>([]);
  const location = useLocation();
  const claims = useAuth((state) => state.claims);
  const clear = useAuth((state) => state.clear);
  const queryClient = useQueryClient();

  const logout = useCallback(() => {
    posthog.reset();
    queryClient.clear();
    clear();
  }, [clear, queryClient]);

  useEffect(() => {
    if (!claims) return;

    const hasContractorPortal = claims.workers.length > 0;

    const usrs = claims.users.map((user) => ({
      clientName: user.clientName,
      clientId: user.clientId,
      workerOrUserId: user.id,
    }));

    setAccounts([
      ...usrs,
      ...(hasContractorPortal
        ? [
            {
              clientName: 'Worker Portal',
              clientId: 'worker',
              workerOrUserId: WORKER_PORTAL_MAGIC_STRING_SRY,
            },
          ]
        : []),
    ]);
  }, [claims]);
  return (
    <RequireAuth requiredAuthType="worker">
      <div className="min-h-full">
        {claims?.impersonated && (
          <div className="relative bg-listo-400">
            <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
              <div className="pr-16 sm:px-16 sm:text-center">
                <p className="font-medium text-gray-600">
                  <span className="hidden md:inline">
                    You are currently impersonating
                  </span>
                  <span className="block sm:ml-2 sm:inline-block">
                    <a
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      href={ADMIN_URL}
                      onClick={(e) => {
                        e.preventDefault();
                        logout();
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        window.location.href = ADMIN_URL;
                      }}
                      className="font-bold text-gray-600 underline"
                    >
                      Stop Impersonating
                      <span aria-hidden="true"> &rarr;</span>
                    </a>
                    &nbsp; Worker
                  </span>
                </p>
              </div>
            </div>
          </div>
        )}
        <SidebarProvider>
          <Sidebar
            navigation={navigation}
            secondaryNavigation={[]}
            accounts={accounts}
          />
          <div className="min-h-full lg:pl-64 flex flex-col flex-1">
            <ErrorBoundary key={location.pathname} fallback={<Fallback />}>
              <Outlet />
            </ErrorBoundary>
          </div>
        </SidebarProvider>
        <NotificationRegion />
      </div>
    </RequireAuth>
  );
}
