import * as Avatar from '@radix-ui/react-avatar';
import { classNames } from 'listo/src/utils/strings';
import { getLetters } from '../Avatar/Avatar';

export function RadixAvatar({
  size = 10,
  fallbackString,
  backgroundColorClass,
}: {
  size?: number;
  fallbackString: string;
  backgroundColorClass?: string;
}) {
  const actualBackgroundColorClass = backgroundColorClass ?? 'bg-gray-100';
  return (
    <Avatar.Root>
      <Avatar.Image />
      <Avatar.Fallback>
        <div
          className={classNames(
            `h-${size} w-${size} rounded-full font-medium text-indigo-600 border border-gray-200 flex items-center justify-center`,
            actualBackgroundColorClass,
          )}
        >
          {getLetters(fallbackString)}
        </div>
      </Avatar.Fallback>
    </Avatar.Root>
  );
}
