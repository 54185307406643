import { ArrowDownTrayIcon } from '@heroicons/react/24/outline';
import { downloadFromURL } from '../../../utility/download';
import { Spinner } from '../../components/Loader';
import { trpc } from '../../lib/trpc';

export interface SelectedFilter {
  filterName: string;
  optionLabel: string;
  optionValue: string | null;
}

interface TeamExportCSVExportProps {
  searchTerm: string | undefined;
  selectedFilters: SelectedFilter[];
  sort: string;
}

function variables({
  searchTerm,
  sort,
  selectedFilters,
}: {
  page?: number;
  searchTerm: string | undefined;
  sort: string | undefined;
  selectedFilters: SelectedFilter[];
}) {
  const countryFilters = selectedFilters
    .filter((filter) => filter.filterName === 'Country' && !!filter.optionValue)
    .map((filter) => filter.optionValue);

  const otherFilters = selectedFilters
    .filter((f) => f.filterName !== 'Country' && !!f.optionValue)
    .reduce<Record<string, boolean>>((acc, filter) => {
      if (!filter.optionValue) return acc;

      acc[filter.optionValue] = !!filter.optionValue;
      return acc;
    }, {});

  return {
    searchTerm,
    sort,
    filters: {
      isoCountryCode: countryFilters.length
        ? countryFilters.join('|')
        : undefined,
      ...otherFilters,
    },
  };
}

export default function TeamExportCSVExport({
  searchTerm,
  sort,
  selectedFilters,
}: TeamExportCSVExportProps) {
  const { mutate, isLoading } = trpc.u.contracts.exportCSV.useMutation({
    onSuccess: (results) => {
      downloadFromURL(results.url);
    },
  });

  const handleExportCSV = () => {
    mutate(variables({ searchTerm, sort, selectedFilters }));
  };

  return (
    <button
      type="button"
      className="inline-flex items-center gap-1 text-sm text-gray-700 hover:text-gray-900 disabled:opacity-75"
      onClick={handleExportCSV}
      disabled={isLoading}
    >
      <span>Export</span>
      {isLoading ? (
        <Spinner height={5} width={5} />
      ) : (
        <ArrowDownTrayIcon className="w-5 h-5" aria-hidden="true" />
      )}
    </button>
  );
}
