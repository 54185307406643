import {
  ClockIcon,
  CurrencyDollarIcon,
  ShieldCheckIcon,
} from '@heroicons/react/24/outline';
import { classNames } from 'listo/src/utils/strings';
import { Link, useLocation } from 'react-router-dom';
import TopBar from '../../components/TopBar';

export function CreateContract() {
  const location = useLocation();

  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Issue Contract" />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Employees
          </h2>
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div
                className={classNames(
                  'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none',
                  'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                )}
              >
                <div>
                  <span
                    className={classNames(
                      'bg-turquoise-50',
                      'text-turquoise-700',
                      'rounded-lg inline-flex p-3 ring-4 ring-white',
                    )}
                  >
                    <ShieldCheckIcon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium">
                    <Link
                      to="/create-contract/eor/employee-info"
                      className="focus:outline-none"
                    >
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      Employer of Record (EOR) Contract
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    EOR contracts should be used to hire when you are expecting
                    to dictate the working hours, issue equipment and provide
                    other benefits for an employee.
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            Contractors
          </h2>
          <div className="rounded-lg mt-5 bg-gray-200 overflow-hidden shadow divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-px">
            <div className="bg-white shadow overflow-hidden">
              <div
                className={classNames(
                  'rounded-tl-lg rounded-tr-lg sm:rounded-tr-none',
                  'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                )}
              >
                <div>
                  <span
                    className={classNames(
                      'text-yellow-700',
                      'bg-yellow-50',
                      'rounded-lg inline-flex p-3 ring-4 ring-white',
                    )}
                  >
                    <CurrencyDollarIcon
                      className="h-6 w-6"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium">
                    <Link
                      to={`/create-contract/fixed-rate/contractor${location.search}`}
                      className="focus:outline-none"
                    >
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      Fixed Rate
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    For contracts that pay on a fixed rate interval every
                    payment cycle.
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            </div>
            <div className="bg-white shadow overflow-hidden">
              <div
                className={classNames(
                  'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                )}
              >
                <div>
                  <span
                    className={classNames(
                      'bg-sky-50',
                      'text-sky-700',
                      'rounded-lg inline-flex p-3 ring-4 ring-white',
                    )}
                  >
                    <ClockIcon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium">
                    <Link
                      to={`/create-contract/pay-as-you-go/contractor${location.search}`}
                      className="focus:outline-none"
                    >
                      {/* Extend touch target to entire panel */}
                      <span className="absolute inset-0" aria-hidden="true" />
                      Hourly
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    Contractors submit time sheets and require approval each pay
                    cycle.
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div>
          <h2 className="text-lg leading-6 font-medium text-gray-900">
            PayPeriod (Custom Entity)
          </h2>
          <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
            <div className="bg-white shadow rounded-lg overflow-hidden">
              <div
                className={classNames(
                  'relative group bg-white p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500',
                )}
              >
                <div>
                  <span
                    className={classNames(
                      'bg-rose-50',
                      'text-rose-700',
                      'rounded-lg inline-flex p-3 ring-4 ring-white',
                    )}
                  >
                    <CheckBadgeIcon className="h-6 w-6" aria-hidden="true" />
                  </span>
                </div>
                <div className="mt-8">
                  <h3 className="text-lg font-medium">
                    <Link
                      to="/create-contract/payPeriod"
                      className="focus:outline-none"
                    >
                      <span className="absolute inset-0" aria-hidden="true" />
                      PayPeriod only
                    </Link>
                  </h3>
                  <p className="mt-2 text-sm text-gray-500">
                    When you have a legal entity in the country where the
                    employee will be working.
                  </p>
                </div>
                <span
                  className="pointer-events-none absolute top-6 right-6 text-gray-300 group-hover:text-gray-400"
                  aria-hidden="true"
                >
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path d="M20 4h1a1 1 0 00-1-1v1zm-1 12a1 1 0 102 0h-2zM8 3a1 1 0 000 2V3zM3.293 19.293a1 1 0 101.414 1.414l-1.414-1.414zM19 4v12h2V4h-2zm1-1H8v2h12V3zm-.707.293l-16 16 1.414 1.414 16-16-1.414-1.414z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </main>
  );
}
