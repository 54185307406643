import axios from 'axios';
import * as Sentry from '@sentry/react';
import { AppJwt as BbJwt } from 'api/src/services/authService';
import jwt_decode from 'jwt-decode';
import posthog from 'posthog-js';
import create from 'zustand';
import { API_URL, IS_PROD } from '../lib/constants';
import { listoAxios } from '../lib/listoAxios';

export type AppJwt = BbJwt & { exp: number };

interface UseAuthType {
  jwt: string | undefined | null;
  claims: AppJwt | undefined | null;
  setJwt: (jwtPayload: string) => void;
  verifyJwt: () => void;
  clear: () => void;
}

const refreshWindowInDays = 4;

function tokenExpiry(exp: number) {
  if (Date.now() >= exp * 1000) return 'expired';

  const diff = exp - Date.now() / 1000;
  const days = Math.floor(diff / (24 * 60 * 60));

  if (days < refreshWindowInDays) {
    // eslint-disable-next-line no-console
    console.log('token will expire in ', days, ' days');
    return 'expiring';
  }
  return 'valid';
}

function safelyDecode(jwt: string) {
  try {
    return jwt_decode<AppJwt>(jwt);
  } catch (e) {
    return null;
  }
}

export function getJwt() {
  const jwt = window.localStorage.getItem('jwt');

  if (!jwt) return jwt;

  return jwt;
}

export const useAuth = create<UseAuthType>((set, get) => ({
  clear: () => {
    window.localStorage.removeItem('jwt');
    set({ jwt: undefined, claims: undefined });
  },
  claims: undefined,
  jwt: undefined,
  setJwt: (jwt: string) => {
    if (!jwt) throw new Error('jwt is undefined');
    window.localStorage.setItem('jwt', jwt);

    const decoded = safelyDecode(jwt);

    if (decoded) {
      listoAxios.defaults.headers.common.Authorization = `Bearer ${jwt}`;

      if (IS_PROD) {
        Sentry.setUser({
          id: decoded.sub,
          email: decoded.email,
          segment: decoded.activeClientId,
        });
        posthog.identify(decoded.sub, {
          email: decoded.email,
          credentialId: decoded.sub,
          accessType: decoded.accessType,
        });
        posthog.people.set({ email: decoded.email });
        posthog.group('company', `id:${decoded.activeClientId}`);
      }

      set(() => ({ jwt, claims: decoded }));
    } else {
      set(() => ({ jwt: null, claims: null }));
    }
  },
  /*
    verifyJwt is a function that will be called on each page change

    it is responsible for a few side effects:

    - verify the current jwt expiration status
    - set the jwt to localstorage if it is present
    - if the jwt is in a refresh window, refresh it
    - if the jwt is expired, delete the jwt
  */
  verifyJwt: () => {
    const jwt = getJwt();

    if (!jwt) {
      set(() => ({ jwt: null, claims: null }));
      return;
    }

    const decoded = safelyDecode(jwt);

    if (!decoded) {
      set(() => ({ jwt: null, claims: null }));
      return;
    }

    const expiry = tokenExpiry(decoded.exp);

    if (expiry === 'expired') {
      window.localStorage.removeItem('jwt');
      set(() => ({ jwt: null, claims: null }));
      return;
    }

    // temporary fix to clear sessions that are not valid
    if (decoded.accessType === 'worker' && !decoded.workerProfileId) {
      window.localStorage.removeItem('jwt');
      set(() => ({ jwt: null, claims: null }));
      return;
    }

    if (expiry === 'expiring') {
      axios
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        .post(`${API_URL}/auth/refresh-token`, {
          token: jwt,
          id: decoded.sub,
        })
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        .then((res) => res.data)
        .then((data) => {
          const { setJwt } = get();
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
          setJwt(data.jwt);
        })
        .catch((e) => {
          // eslint-disable-next-line no-console
          console.error('failed to refresh token', e);
          set(() => ({ jwt: null, claims: null }));
        });
    }

    if (expiry === 'valid') {
      listoAxios.defaults.headers.common.Authorization = `Bearer ${jwt}`;
      set(() => ({ jwt, claims: decoded }));
    }
  },
}));
