import { zodResolver } from '@hookform/resolvers/zod';
import { eorJobFields, EorJobFields } from 'listo/src/zodObjects/contracts';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import Form from '../../components/Form';
import { DateInput } from '../../components/Form/DateInput';
import { Header } from '../../components/Form/Header';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { TextAreaInput } from '../../components/Form/TextAreaInput';
import { TextInput } from '../../components/Form/TextInput';
import { useEorForm } from './hooks/useEorForm';

const seniorityLevels = [
  { label: 'Not Applicable', value: 'not-applicable' },
  { label: 'Junior', value: 'junior' },
  { label: 'Mid', value: 'mid' },
  { label: 'Senior', value: 'senior' },
  { label: 'Lead', value: 'lead' },
  { label: 'Principal', value: 'principal' },
  { label: 'Director', value: 'director' },
  { label: 'Department Head', value: 'department-head' },
  { label: 'Vice President', value: 'vice-president' },
  { label: 'Senior Vice President', value: 'senior-vice-president' },
  { label: 'C-Level Executive', value: 'c-level-executive' },
];

export function Job() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { jobTitle, seniorityLevel, jobDescription, startDate } = useEorForm(
    (state) => ({
      jobTitle: state.fields?.jobTitle,
      seniorityLevel: state.fields?.seniorityLevel,
      jobDescription: state.fields?.jobDescription,
      startDate: state.fields?.startDate,
    }),
    shallow,
  );

  const setFields = useEorForm((state) => state.setFields);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EorJobFields>({ resolver: zodResolver(eorJobFields) });

  const onSubmit: SubmitHandler<EorJobFields> = useCallback(
    (data) => {
      setFields(data);
      navigate('/create-contract/eor/compensation');
    },
    [navigate, setFields],
  );

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    if (autoValidate) {
      handleSubmit(onSubmit)().catch(() => {});
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Next"
      secondaryButtonText="Back"
      secondaryButtonOnClick={() =>
        navigate('/create-contract/eor/employee-info')
      }
    >
      <>
        <Header
          heading="Job Information"
          subheading="We are drafting a compliant employment contract. Regulations and requirements vary by country. View the global hiring for (Country)."
        />
        <Section>
          <>
            <TextInput
              cols={3}
              register={register}
              fieldName="jobTitle"
              label="Job Title"
              defaultValue={jobTitle ?? ''}
              error={errors.jobTitle}
            />
            <SelectInput
              cols={3}
              register={register}
              fieldName="seniorityLevel"
              label="Seniority Level"
              error={errors.seniorityLevel}
              defaultValue={seniorityLevel ?? ''}
              defaultOption={{ label: 'Select', value: '' }}
              options={seniorityLevels.map((level) => ({
                label: level.label,
                value: level.value,
              }))}
            />
            <TextAreaInput
              cols={6}
              register={register}
              fieldName="jobDescription"
              label="Job Description"
              description="Write a few sentences explaining the role and responsibilities."
              defaultValue={jobDescription ?? ''}
              error={errors.jobDescription}
            />
          </>
        </Section>

        <Section
          heading="Start Date"
          subheading="The earliest possible start date for this contract may vary due
                to regulatory requirements. Please note that visa and/or work permit requirements may cause delays."
        >
          <DateInput
            cols={3}
            register={register}
            fieldName="startDate"
            label="Start Date"
            defaultValue={startDate ?? ''}
            error={errors.startDate}
          />
        </Section>
      </>
    </Form>
  );
}
