import { PaperClipIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { formatDate } from 'listo/src/utils/dates';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

function PaymentForAContract({ contractId }: { contractId: string }) {
  const { data, isLoading, error } = trpc.u.contracts.paystubs.useQuery(
    {
      contractId,
    },
    {
      enabled: !!contractId,
      trpc: {},
    },
  );

  if (isLoading) return <Loader />;
  if (error) return <p>{error.message}</p>;

  return (
    <div className="pt-4 pb-6 px-6">
      <div className="flow-root mt-6">
        <ul className="-my-5 divide-y divide-gray-200">
          {data.map((paystub) => (
            <li
              key={paystub.id}
              className="py-4  cursor-pointer hover:bg-gray-100"
            >
              <a
                className="flex items-center space-x-4"
                href={paystub.upload?.url}
                target="_blank"
                rel="noreferrer"
              >
                <div>
                  <PaperClipIcon className="h-5 w-5 rounded-full" />
                </div>
                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {paystub.name}
                  </p>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {`uploaded: ${formatDate(paystub.createdAt.toString())}`}
                  </p>
                </div>
                <div>
                  <ArrowDownIcon className="w-5 h-5" />
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default function Payments() {
  const { id } = useParams();

  if (!id) throw new Error('id is required');

  const { data } = trpc.u.workers.worker.useQuery(id);

  const contracts = data?.contracts;

  return (
    <div>
      <div className="px-4 pt-5 sm:px-6">
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Payment Documents:
        </p>
      </div>

      {contracts?.map((contract) => (
        <div key={contract.id}>
          <PaymentForAContract contractId={contract.id} />
        </div>
      ))}
    </div>
  );
}
