import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreateTimeOffRequestType,
  createTimeOffRequestZod,
  TimeOffRequestStatusEnum,
} from 'listo/src/zodObjects/requestTimeOff';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { Input } from 'ui/src/components/Input';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';

export function TimeOffRequestForm({
  afterSubmit,
}: {
  afterSubmit?: () => void;
}) {
  const { claims } = useAuth();
  const today = new Date().toISOString().split('T')[0];
  const setNotification = useNotification((state) => state.setNotification);
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<CreateTimeOffRequestType>({
    defaultValues: {
      workerProfileId: claims?.workerProfileId,
      startDate: today,
      status: TimeOffRequestStatusEnum.Values.PENDING,
    },
    resolver: zodResolver(createTimeOffRequestZod),
  });

  const startDate = watch('startDate');

  const { data: contracts, isLoading: loadingContracts } =
    trpc.w.contracts.list.useQuery();
  const { mutateAsync: timeOffRequest } =
    trpc.w.timeOffRequest.create.useMutation({
      onSuccess: () => {
        reset();

        if (afterSubmit) {
          afterSubmit();
        }

        return setNotification({
          type: 'success',
          title: 'Success',
          message: 'Your time off request has been submitted successfully.',
        });
      },
      onError: () =>
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Your time off request has failed. Please contact us.',
        }),
    });

  const onSubmit: SubmitHandler<CreateTimeOffRequestType> = async (data) => {
    try {
      await timeOffRequest(data);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    }
  };

  return (
    <div className="w-full md:min-w-[500px] p-4 sm:p-6">
      <h2 className="font-semibold text-xl text-gray-900 mb-6">
        Request Time Off
      </h2>

      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 gap-y-8">
          <div className="sm:col-span-1">
            {loadingContracts ? null : (
              <>
                <label
                  htmlFor="clientId"
                  className="block text-sm text-gray-700 font-medium"
                >
                  Client *
                </label>

                <select
                  id="clientId"
                  className="block w-full mt-2 p-2 shadow-sm border border-gray-300 rounded-md resize-none focus:outline-none sm:text-sm"
                  {...register('clientId')}
                >
                  <option value="">None</option>
                  {contracts?.results.map((contract) => (
                    <option value={contract.client.id} key={contract.id}>
                      {contract.client.name} ({contract.engagementType})
                    </option>
                  ))}
                </select>

                {errors.clientId?.message ? (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.clientId.message}
                  </p>
                ) : null}
              </>
            )}
          </div>

          <div className="sm:col-span-1">
            <div className="grid grid-cols-2 gap-x-4">
              <Input
                inputType="date"
                label="Start Date *"
                inputProps={{
                  min: today,
                }}
                reactHookForm={{
                  register,
                  fieldName: 'startDate',
                  errors,
                }}
              />

              <Input
                inputType="date"
                label="End Date *"
                inputProps={{
                  min: startDate,
                }}
                reactHookForm={{
                  register,
                  fieldName: 'endDate',
                  errors,
                }}
              />
            </div>
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="reason"
              className="block text-sm text-gray-700 font-medium"
            >
              Reason *
            </label>

            <div className="grid grid-cols-1 gap-x-4 mt-2">
              <textarea
                rows={6}
                id="reason"
                {...register('reason')}
                className="block w-full p-2 shadow-sm border border-gray-300 rounded-md resize-none focus:outline-none sm:text-sm"
              />
            </div>

            {errors.reason?.message ? (
              <p className="mt-2 text-sm text-red-600">
                {errors.reason.message}
              </p>
            ) : null}
          </div>
        </div>

        <div className="pt-2">
          <div className="flex justify-end">
            <Button
              type="submit"
              loading={isSubmitting}
              className="w-full inline-flex justify-center px-4 py-3 shadow-sm border border-transparent rounded-md bg-indigo-600 text-white font-medium hover:bg-indigo-700 focus:ring-2 focus:outline-none focus:ring-offset-2"
              text="Submit"
            />
          </div>
        </div>
      </form>
    </div>
  );
}
