import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { countryNameByIsoCode } from 'listo/src/countries';
import { formatPaymentAmount } from 'listo/src/utils/currency';
import { classNames } from 'listo/src/utils/strings';
import { Link } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import EmptyState from '../../components/EmptyState';
import Pagination from '../../components/Pagination/Pagination';
import TopBar from '../../components/TopBar';
import { RouterOutput, trpc } from '../../lib/trpc';

/* This example requires Tailwind CSS v2.0+ */
export function PaymentDue() {
  return (
    <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Make Payment
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>One of your payments is due.</p>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Table({
  data,
  isLoading,
  onPageChange,
}: {
  data: RouterOutput['w']['contracts']['list'] | undefined;
  isLoading: boolean;
  onPageChange?: (page: number) => void;
}) {
  const hasData = !!data?.results.length;

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (!hasData) return <EmptyState entity="Contract" />;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {data.results.map((contract) => (
          <li key={contract.id}>
            <Link
              to={`/wrkr/my-contracts/${contract.id}`}
              className="block hover:bg-gray-50"
            >
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm items-center">
                      <div className="mt-4 flex-shrink-0 sm:mt-0">
                        <div className="flex overflow-hidden">
                          <Avatar
                            name={contract.name}
                            image={contract.workerProfileUpload?.url ?? ''}
                            className="w-[25px] h-[25px]"
                          />
                        </div>
                      </div>
                      <p className="font-medium text-indigo-600 truncate ml-2">
                        {contract.name}
                      </p>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        {/* <ReactCountryFlag
                          className="emojiFlag"
                          countryCode={contract.isoCountryCode}
                          aria-label={contract.isoCountryCode}
                        /> */}
                        <p className="">
                          {countryNameByIsoCode(contract.isoCountryCode)}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex items-center">
                      <div>
                        <div
                          className={classNames(
                            contract.contractType === 'FIXED_RATE'
                              ? 'bg-sky-100 text-sky-800'
                              : 'bg-green-100 text-green-800',
                            'inline-flex items-baseline px-2.5 py-0.5 rounded-full text-sm font-medium md:mt-2 lg:mt-0',
                          )}
                        >
                          <span>{contract.status}</span>
                        </div>
                      </div>
                      {/* <div className="ml-2 lg:ml-6 text-sm font-medium text-gray-500">
                        <span>{position.closeDateFull}</span>
                      </div> */}
                      <div className="ml-2 lg:ml-6">
                        <h1>
                          {formatPaymentAmount(
                            contract.billingAmountInCents,
                            contract.billingFrequency,
                            contract.billingCurrency,
                          )}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <Pagination paging={data.paging} onPageChange={onPageChange} />
    </div>
  );
}

export function MyContracts() {
  const { data, isLoading } = trpc.w.contracts.list.useQuery();

  const hasData = !!data?.results.length;
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Contracts" />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div>
          <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
            <div className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                Total Contracts
              </dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                  {hasData ? data.results.length : 0}
                  <span className="ml-2 text-sm font-medium text-gray-500">
                    {/* {item.previousStat} */}
                  </span>
                </div>
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-8" />
        <Table data={data} isLoading={isLoading} />
      </div>
    </main>
  );
}
