import { useState, useEffect } from 'react';
import { Input } from 'ui/src/components/Input';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EntityType, entityZod } from 'listo/src/zodObjects/entities';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { ProfilePictureUploader } from '../../components/ProfilePictureUploader';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { SelectInput } from '../../components/Form/SelectInput';
import { trpc, RouterOutput } from '../../lib/trpc';

const types = [
  { label: 'LLC', value: 'llc' },
  { label: 'S-corp', value: 's-corp' },
  { label: 'C-corp', value: 'c-corp' },
  { label: 'Sole Proprietorship', value: 'sole-proprietorship' },
  { label: 'unknown', value: 'Unknown' },
];

function EntityLogo() {
  const setNotification = useNotification((state) => state.setNotification);

  const { data, refetch } = trpc.u.dashboard.settings.useQuery();

  const fullName = '+';
  const clientId = data?.client.id ?? '';
  const pictureUrl = data?.client.clientUpload?.url ?? '';

  const { mutate: uploadProfilePicture } =
    trpc.u.users.addOrganizationProfilePicture.useMutation({
      onSuccess: async () => {
        setNotification({
          type: 'success',
          title: 'Profile updated',
          message: 'Organization picture updated successfully',
        });

        await refetch();
      },

      onError: () => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Something went wrong',
        });
      },
    });

  const uploadPicture = (id: string) =>
    uploadProfilePicture({ clientId, clientUploadId: id });

  return (
    <ProfilePictureUploader
      fullName={fullName}
      pictureUrl={pictureUrl}
      uploadPicture={uploadPicture}
      entityId={clientId}
    />
  );
}

export function Entity() {
  const [entity, setEntity] = useState<
    RouterOutput['u']['entities']['list'][0] | null
  >();
  const { data, isLoading, error } = trpc.u.entities.list.useQuery();
  useEffect(() => {
    if (data) {
      setEntity(data[0]);
    }
  }, [data]);
  const { mutate: updateEntity, isLoading: updateLoading } =
    trpc.u.entities.update.useMutation();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EntityType>({
    resolver: zodResolver(entityZod),
  });

  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  if (!entity) return <div>no data</div>;

  const onSubmit: SubmitHandler<EntityType> = (value) => {
    updateEntity({ ...value, entityId: entity.id });
  };

  return (
    <>
      <div className="mt-10">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Entity Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          View and edit entity information.
        </p>
      </div>
      <div className="mt-5 border-t border-gray-200">
        <div className="mt-5">
          <EntityLogo />
        </div>
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <Input
                inputType="text"
                label=" Name"
                inputProps={{
                  defaultValue: entity.name,
                }}
                reactHookForm={{
                  register,
                  fieldName: 'name',
                  errors,
                }}
              />
            </div>

            <SelectInput
              cols={2}
              register={register}
              fieldName="corporationType"
              label="corporationType"
              error={errors.corporationType}
              defaultValue={entity.corporationType ?? ''}
              defaultOption={{ label: 'Select', value: '' } as const}
              options={types.map(({ label, value }) => ({
                label,
                value,
              }))}
            />
          </div>
          <div className="sm:col-span-3 pt-4">
            <Input
              inputType="text"
              label="Tax Id Number"
              inputProps={{
                defaultValue: entity.taxIdNumber ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'taxIdNumber',
                errors,
              }}
            />{' '}
            <div className="flex justify-end mt-4">
              <Button type="submit" text="Update" loading={updateLoading} />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
