import { useNavigate } from 'react-router-dom';
import { formatDate } from 'listo/src/utils/dates';
import { classNames } from 'listo/src/utils/strings';
import { Button } from 'ui/src/components/Button';
import { Currency } from 'ui/src/components/Currency/Currency';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

export default function Payments({ contractId }: { contractId: string }) {
  const getStatusClass = (status: string, weight: number) => {
    switch (status) {
      case 'PAID':
        return `text-green-${weight}`;
      case 'GENERATED':
        return `text-yellow-${weight}`;
      case 'FAILED':
        return `text-red-${weight}`;
      default:
        return `text-white-${weight}`;
    }
  };

  const {
    data: distributions,
    isLoading,
    error,
  } = trpc.u.distributions.list.useQuery({ contractId });

  const navigate = useNavigate();

  if (isLoading) return <Loader />;

  if (error)
    return <ErrorState trpcError={error} parentComponentName="Payments.tsx" />;

  return (
    <div className="overflow-hidden bg-white shadow sm:rounded-md lg:col-span-2">
      <div className="flex justify-between px-4 pb-1 mt-5 border-b sm:px-6 shadow-gray-500">
        <h3 className="py-3 pt-1 text-lg font-medium leading-6 text-gray-900">
          Distributions
        </h3>
        <Button
          id="payments-action-navigate-to-one-off-payments"
          text="1 off payments"
          variant="secondary"
          onClick={() => navigate(`/contracts/${contractId}/payments`)}
        />
      </div>
      <ul className="divide-y divide-gray-200">
        {distributions.map((distribution) => {
          const distributedAt = distribution.payPeriod?.distributedAt;

          const d = distributedAt ? formatDate(distributedAt) : '';
          return (
            <li
              key={distribution.id}
              className="relative px-4 py-5 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50"
            >
              <div className="flex justify-between space-x-3">
                <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true" />
                  <p className="text-sm font-medium text-gray-900 capitalize truncate">
                    {distribution.workerProfile.fullName}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    Status:
                    <span
                      className={classNames(
                        getStatusClass(distribution.status, 800),
                        'inline-flex items-center rounded-md  px-2.5 py-0.5 text-sm font-medium ',
                      )}
                    >
                      <svg
                        className={classNames(
                          getStatusClass(distribution.status, 400),
                          '-ml-0.5 mr-1.5 h-2 w-2',
                        )}
                        fill="currentColor"
                        viewBox="0 0 8 8"
                      >
                        <circle cx={4} cy={4} r={3} />
                      </svg>
                      {distribution.status}
                    </span>
                    Confirmation Number: {distribution.confirmationNumber}
                  </p>
                </div>
                <time
                  dateTime={d}
                  className="flex-shrink-0 text-sm text-gray-500 whitespace-nowrap"
                >
                  {d}
                </time>
              </div>
              <div className="mt-1">
                <p className="text-sm text-gray-600 line-clamp-2">
                  <Currency
                    currencyCode={distribution.currency}
                    value={distribution.amountInCents}
                    isCents
                  />
                </p>
              </div>
            </li>
          );
        })}
        {distributions.length === 0 && (
          <li className="relative px-6 py-5 pt-3 bg-white focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-50">
            <p>No Distributions Found</p>
          </li>
        )}
      </ul>
    </div>
  );
}
