import { Fragment, useEffect, useState, FormEvent } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import Button from '../Button';
import { IRequestReimbursementProps } from './types';
import { options } from './utils';

export function ReimbursementFilters({
  defaultValue,
  onSubmit,
  popupDirection = 'right',
}: IRequestReimbursementProps) {
  const [value, setValue] = useState([] as string[]);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleChange = (filter: string, checked: boolean) => {
    if (checked) {
      return setValue((existingValue) => [...existingValue, filter]);
    }

    return setValue((existingValue) => {
      const result = existingValue.filter((v) => v !== filter);
      return result;
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>, close: () => void) => {
    e.preventDefault();
    close();
    return onSubmit(value);
  };

  return (
    <Popover
      as="div"
      id="reimbursement-filter-menu"
      className="relative z-10 inline-block text-left ml-5"
    >
      <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
        <span>Filter</span>

        {defaultValue.length > 0 ? (
          <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
            {defaultValue.length}
          </span>
        ) : null}

        <ChevronDownIcon
          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
          aria-hidden="true"
        />
      </Popover.Button>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Popover.Panel
          className={`origin-top-right absolute ${
            popupDirection === 'right' ? 'right-0' : 'left-0'
          }right-0 mt-4 bg-white rounded-md shadow-2xl py-1 px-4 ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          {({ close }) => (
            <form onSubmit={(e) => handleSubmit(e, close)}>
              {options.map((option) => (
                <div key={option.value} className="flex items-center my-3">
                  <input
                    id={`filter-reimbursement-${option.value}`}
                    checked={value.includes(option.value)}
                    type="checkbox"
                    className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                    onChange={({ target: { checked } }) =>
                      handleChange(option.value, checked)
                    }
                  />
                  <label
                    htmlFor={`filter-reimbursement-${option.value}`}
                    className="ml-3 pr-6 text-sm font-light text-gray-900 whitespace-nowrap"
                  >
                    {option.label}
                  </label>
                </div>
              ))}

              <Button
                type="submit"
                text="Submit"
                className="py-2 my-2 w-full justify-center"
              />
            </form>
          )}
        </Popover.Panel>
      </Transition>
    </Popover>
  );
}
