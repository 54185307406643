import { AssetTypes } from 'listo/src/zodObjects/assets';
import { useState } from 'react';
import { RouterOutput } from '../../lib/trpc';
import FilePreview from '../FilePreview';
import { Spinner } from '../Spinner/Spinner';
import Uploader from '../Uploader';

interface UploadBoxProps {
  clientId: string;
  listoAssetType: AssetTypes;
  allowedTypes?: string[];
  text?: string;
  onFileUploaded: (file: RouterOutput['a']['assets']['uploadSuccess']) => void;
  readOnly?: boolean;
  value?: Partial<RouterOutput['a']['assets']['uploadSuccess']>;
}

const emptyFile = {
  id: '',
  cloudStorageKey: '',
  fileName: '',
  mimeType: '',
  url: '',
  clientId: '',
};

export function UploadBox({
  clientId,
  value,
  allowedTypes,
  listoAssetType,
  text = 'Upload',
  onFileUploaded,
  readOnly,
}: UploadBoxProps) {
  const [loading, setLoading] = useState(false);
  const allowedTypeString = allowedTypes
    ?.map((item) => item.replace(/\./g, ''))
    .map((item) => item.toLocaleUpperCase())
    .join(',');

  const cancelUploadedFile: React.MouseEventHandler<HTMLButtonElement> = (
    e,
  ) => {
    e.preventDefault();
    onFileUploaded(emptyFile);
  };

  if (readOnly && !value?.fileName) return <div> </div>;

  if (!loading && value?.fileName)
    return (
      <FilePreview
        file={value as RouterOutput['a']['assets']['uploadSuccess']}
        cancel={cancelUploadedFile}
        readOnly={readOnly}
      />
    );

  return (
    <div className="sm:col-span-6 mt-4">
      <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
        {!loading && !value?.fileName && (
          <div className="space-y-1 text-center">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              stroke="currentColor"
              fill="none"
              viewBox="0 0 48 48"
              aria-hidden="true"
            >
              <path
                d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <div className="flex text-sm text-gray-600">
              <label
                htmlFor="file-upload"
                className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
              >
                <Uploader
                  listoAssetType={listoAssetType}
                  allowedTypes={allowedTypes}
                  setLoading={setLoading}
                  setUploadedFile={onFileUploaded}
                  clientId={clientId}
                >
                  <span>{text}</span>
                </Uploader>
              </label>
              <p className="pl-1">or drag and drop</p>
            </div>
            <p className="text-xs text-gray-500">
              {allowedTypeString} up to 10MB
            </p>
          </div>
        )}
        {loading && <Spinner />}
      </div>
    </div>
  );
}
