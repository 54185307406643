import { zodResolver } from '@hookform/resolvers/zod';
import { floatInDollarsToCents } from 'listo/src/utils/currency';
import {
  eorBenefits,
  EorBenefits,
  eorCompensationFields,
  EorContractCreate,
  eorEmployeeFields,
  eorJobFields,
} from 'listo/src/zodObjects/contracts';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Form from '../../components/Form';
import { Header } from '../../components/Form/Header';
import { RadioInput } from '../../components/Form/RadioInput';
import { trpc } from '../../lib/trpc';
import { useEorForm } from './hooks/useEorForm';

export function Benefits() {
  const navigate = useNavigate();
  const utils = trpc.useContext();
  const resetForm = useEorForm((state) => state.resetForm);
  const [searchParams] = useSearchParams();
  const { benefits } = useEorForm((state) => ({
    benefits: state.fields?.benefits,
  }));
  const fields = useEorForm((state) => state.fields);
  const createContract = trpc.u.contracts.eor.useMutation({
    onSuccess: ({ workerId, id }) => {
      resetForm();
      utils.u.workers.worker.invalidate(workerId).catch(() => {});
      navigate(`/contracts/${id}`);
    },
  });

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<EorBenefits>({ resolver: zodResolver(eorBenefits) });

  const onSubmit: SubmitHandler<EorBenefits> = useCallback(
    (data) => {
      const employeeDetailsStep = eorEmployeeFields.safeParse(fields);
      if (!employeeDetailsStep.success) {
        navigate(`/create-contract/eor/employee-info?autoValidate=true`);
        return;
      }

      const jobStep = eorJobFields.safeParse(fields);
      if (!jobStep.success) {
        navigate(`/create-contract/eor/job?autoValidate=true`);
        return;
      }

      const compensationStep = eorCompensationFields.safeParse(fields);
      if (!compensationStep.success) {
        navigate(`/create-contract/eor/compensation?autoValidate=true`);
        return;
      }

      createContract.mutate({
        eorContractCreate: {
          ...(fields as EorContractCreate),
          ...data,
          billingAmountInCents: floatInDollarsToCents(
            fields?.billingAmountInCents,
          ),
        },
      });
    },
    [createContract, fields, navigate],
  );

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    if (autoValidate) {
      handleSubmit(onSubmit)().catch(() => {});
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Submit"
      secondaryButtonText="Back"
      secondaryButtonOnClick={() =>
        navigate('/create-contract/eor/compensation')
      }
    >
      <>
        <Header
          heading="Private Supplemental Benefits"
          subheading="Are you interested in offering private supplemental benefits to the employee? Benefits may include medical, dental, vision, and maternity."
        />
        <div>
          <RadioInput
            defaultValue={benefits}
            options={[
              { label: 'Yes', value: 'true' },
              { label: 'No', value: 'false' },
            ]}
            register={register}
            fieldName="benefits"
            cols={6}
            error={errors.benefits}
          />
        </div>
      </>
    </Form>
  );
}
