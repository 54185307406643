import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css';

if (!window.location.href.includes('localhost')) {
  Sentry.init({
    dsn: 'https://7a2c98b13837413f87ad8bb18b20d353@o1289245.ingest.sentry.io/6507627',
    integrations: [new BrowserTracing(), new Sentry.Replay()],
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    release: import.meta.env.VITE_CI_COMMIT_SHORT_SHA,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
}

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
