import { formatCentsToDollars } from 'listo/src/utils/currency';
import { titleCase } from 'listo/src/utils/strings';
import { trpc, RouterOutput } from '../../lib/trpc';
import Loader from '../../components/Loader';

function Data({
  data,
}: {
  data: RouterOutput['w']['contracts']['distributions'] | undefined;
}) {
  if (!data) return null;
  return (
    <>
      {data.map((distribution) => (
        <tr key={distribution.id}>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <div className="flex items-center space-x-3 lg:pl-2">
              {titleCase(distribution.workerProfile.fullName)}
            </div>
          </td>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
            <div className="flex items-center space-x-3 lg:pl-2">
              {formatCentsToDollars(
                distribution.amountInCents,
                distribution.currency,
              )}
            </div>
          </td>
        </tr>
      ))}
    </>
  );
}

function Payments({ contractId }: { contractId: string }) {
  const { data, isLoading, error } = trpc.w.contracts.distributions.useQuery(
    {
      contractId,
    },
    {
      enabled: !!contractId,
      trpc: {},
    },
  );

  if (!contractId) {
    return (
      <div className="pt-4 pb-6 px-6">
        <div className="flow-root mt-6">
          <div className="sm:flex sm:items-center mb-10">
            <div className="sm:flex-auto">
              <p className="text-sm text-gray-700">Payment Documents</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
  if (isLoading) return <Loader />;
  if (error) return <p>{error.message}</p>;

  return (
    <div className="pt-4 pb-6 px-6">
      <div className="flow-root mt-6">
        <div className="sm:flex sm:items-center mb-10">
          <div className="sm:flex-auto">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Worker
                  </th>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    Amount
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <span className="sr-only">Actions</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                <Data data={data} />
              </tbody>
            </table>{' '}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Payments;
