import { zodResolver } from '@hookform/resolvers/zod';
import { countries } from 'listo/src/countries';
import {
  workerAddressFields,
  WorkerAddressFields,
} from 'listo/src/zodObjects/worker';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Input } from 'ui/src/components/Input';
import { SelectInput } from 'ui/src/components/SelectInput';
import Form from '../../components/Form';
import { Footer } from '../../components/Form/Footer';
import { Section } from '../../components/Form/Section';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import { useAcceptContractForm } from './hooks/useAcceptContractForm';

export function Address() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  if (!id) throw new Error('Contract ID is required');
  const contract = trpc.w.contracts.contract.useQuery(id);

  const setFields = useAcceptContractForm((s) => s.setFields);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<WorkerAddressFields>({
    resolver: zodResolver(workerAddressFields),
  });

  const onSubmit: SubmitHandler<WorkerAddressFields> = useCallback(
    (data) => {
      setFields(data);
      navigate(`/wrkr/accept-contract/${id}/tax`);
    },
    [id, navigate, setFields],
  );

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    if (autoValidate) {
      handleSubmit(onSubmit)().catch(() => {});
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  if (contract.error) return <h1>error</h1>;
  if (contract.isLoading) return <Loader />;
  return (
    <Form handleSubmit={handleSubmit} onSubmit={onSubmit} noFooter>
      <>
        <Section heading="Address">
          <>
            <div className="col-span-1 sm:col-span-3">
              <Input
                inputType="text"
                label="Address 1"
                inputProps={{
                  defaultValue: contract.data.workerProfile.address1 ?? '',
                  autoComplete: 'address-line1',
                }}
                reactHookForm={{
                  register,
                  fieldName: 'address1',
                  errors,
                }}
              />
            </div>
            <div className="col-span-1 sm:col-span-3">
              <Input
                inputType="text"
                label="Address 2"
                inputProps={{
                  defaultValue: contract.data.workerProfile.address2 ?? '',
                  autoComplete: 'address-line2',
                }}
                reactHookForm={{
                  register,
                  fieldName: 'address2',
                  errors,
                }}
              />
            </div>
            <div className="col-span-1 sm:col-span-2">
              <Input
                inputType="text"
                label="City"
                inputProps={{
                  defaultValue: contract.data.workerProfile.city ?? '',
                  autoComplete: 'city',
                }}
                reactHookForm={{
                  register,
                  fieldName: 'city',
                  errors,
                }}
              />
            </div>
            <div className="col-span-1 sm:col-span-2">
              <Input
                inputType="text"
                label="State"
                inputProps={{
                  defaultValue: contract.data.workerProfile.zoneCode ?? '',
                  autoComplete: 'state',
                }}
                reactHookForm={{
                  register,
                  fieldName: 'zoneCode',
                  errors,
                }}
              />
            </div>
            <div className="col-span-1 sm:col-span-2">
              <Input
                inputType="text"
                label="Postal Code"
                inputProps={{
                  defaultValue: contract.data.workerProfile.postalCode ?? '',
                  autoComplete: 'postalCode',
                }}
                reactHookForm={{
                  register,
                  fieldName: 'postalCode',
                  errors,
                }}
              />
            </div>
            <div className="col-span-1 sm:col-span-3">
              <SelectInput
                label="Tax Residence"
                loading={contract.isLoading}
                defaultValue={contract.data.workerProfile.isoCountryCode ?? ''}
                options={countries.map((country) => ({
                  label: country.name,
                  value: country.alpha2,
                }))}
                reactHookForm={{
                  register,
                  fieldName: 'isoCountryCode',
                  errors,
                }}
              />
            </div>
          </>
        </Section>
        <Footer
          primaryButtonText="Next"
          secondaryButtonText="Back"
          secondaryButtonOnClick={() =>
            navigate(`/wrkr/accept-contract/${id}/info`)
          }
        />
      </>
    </Form>
  );
}
