import { ClockIcon, TrashIcon } from '@heroicons/react/24/outline';
import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PageLoader } from 'ui/src/components/PageLoader';
import EmptyState from '../../components/EmptyState';
import Modal from '../../components/Modal';
import { trpc } from '../../lib/trpc';
import TimeSheetModal from '../TimeSheetModal';

export function TimeSheet() {
  const { data, refetch, isLoading } = trpc.w.timeCards.list.useQuery();
  const { mutateAsync: deleteTimeCard } = trpc.w.timeCards.delete.useMutation();

  const [searchParams] = useSearchParams();
  const [modalOpen, setModalOpen] = useState(
    !!searchParams.get('open') || false,
  );

  function loadTimeSheetData() {
    refetch().catch((err) => {
      // eslint-disable-next-line no-console
      console.error(err);
    });
  }

  function handleDeleteTimeCard(timeCardId: string) {
    deleteTimeCard({ timeCardId })
      .then(() => {
        loadTimeSheetData();
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
      });
  }

  if (isLoading) return <PageLoader />;
  if (data === undefined) return <PageLoader />;

  return (
    <div className="mt-8">
      <div className="max-w-6xl px-4 mx-auto sm:px-6 lg:px-8">
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="float-right">
              <button
                type="submit"
                className="flex justify-center w-full px-4 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                onClick={() => setModalOpen(true)}
              >
                <ClockIcon
                  className="flex-shrink-0 w-6 h-6 mr-4 text-indigo-200"
                  aria-hidden="true"
                />
                Log New Entry
              </button>
            </div>
            <h2 className="font-semibold text-gray-900">Time Cards</h2>
            <div className="flex flex-col mt-10">
              <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  {data.length > 0 ? (
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead>
                        <tr>
                          <th className="py-4 text-sm font-semibold text-left text-gray-600">
                            Contract
                          </th>
                          <th className="py-4 text-sm font-semibold text-left text-gray-600">
                            Date
                          </th>
                          <th className="py-4 text-sm font-semibold text-left text-gray-600">
                            Hours
                          </th>
                          <th className="py-4 text-sm font-semibold text-center text-gray-600">
                            Label
                          </th>
                          <th className="py-4 text-sm font-semibold text-center text-gray-600">
                            Work Description
                          </th>
                          <th className="py-4 text-sm font-semibold text-center text-gray-600">
                            &nbsp;
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200">
                        {data.map((timecard) => (
                          <tr key={timecard.id}>
                            <td className="py-6 pl-4 pr-3 text-sm font-medium text-gray-600 whitespace-nowrap sm:pl-6 md:pl-0">
                              {timecard.contract.name}
                            </td>
                            <td className="py-6 text-sm text-gray-600 whitespace-nowrap">
                              {timecard.date.toISOString().split('T')[0]}
                            </td>
                            <td
                              className="py-6 text-sm text-center text-gray-600 whitespace-nowrap"
                              data-testid="hours"
                            >
                              {timecard.hours}
                            </td>
                            <td className="max-w-xs px-10 py-6 text-sm text-center text-gray-600 sm:text-ellipsis">
                              <span className="px-2 py-1 bg-gray-200 rounded-md">
                                {timecard.projectLabel ? (
                                  <span className="px-2 py-1 bg-gray-200 rounded-md">
                                    {timecard.projectLabel}
                                  </span>
                                ) : (
                                  'No Label'
                                )}
                              </span>
                            </td>
                            <td className="max-w-xs px-10 py-6 text-sm text-center text-gray-600 sm:text-ellipsis">
                              {timecard.description}
                            </td>
                            <td className="py-6 text-sm text-center text-gray-600 whitespace-nowrap sm:text-ellipsis">
                              <TrashIcon
                                data-testid="delete-timecard"
                                className="p-1 m-auto text-gray-400 rounded-full cursor-pointer w-7 h-7 hover:bg-gray-200"
                                aria-hidden="true"
                                onClick={() =>
                                  handleDeleteTimeCard(timecard.id)
                                }
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <EmptyState entity="Time log" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modalOpen} setIsOpen={setModalOpen}>
        <TimeSheetModal
          setModalOpen={setModalOpen}
          // eslint-disable-next-line react/jsx-no-bind
          loadTimeSheetData={loadTimeSheetData}
        />
      </Modal>
    </div>
  );
}
