import React, { useCallback, useEffect, useState } from 'react';
import { DateTime, Interval } from 'luxon';
import { useNavigate } from 'react-router-dom';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid';
import { classNames } from 'listo/src/utils/strings';
import { Loading } from 'ui/src/components/Table';

import Button from '../../components/Button';
import { RouterOutput, trpc } from '../../lib/trpc';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

function daysInInterval(interval: Interval) {
  const days = [];
  let cursor = interval.start.startOf('day');
  while (cursor <= interval.end) {
    days.push(cursor);
    cursor = cursor.plus({ days: 1 });
  }
  return days;
}

function withContext(days: DateTime[], selectedDate: DateTime) {
  const firstDay = days[0];
  const weekDayofFirstDayOfTheMonth = firstDay?.startOf('month').weekday;

  const pad =
    [1, 2, 3, 4, 5, 6, 7].findIndex((i) => i === weekDayofFirstDayOfTheMonth) +
    1;
  if (firstDay) {
    const beginningPad = daysInInterval(
      Interval.fromDateTimes(
        firstDay.minus({ days: pad }),
        firstDay.minus({ days: 1 }),
      ),
    );

    return [
      ...beginningPad.map((day) => ({
        date: day,
        isCurrentMonth: day.hasSame(selectedDate, 'day'),
        isSelected: day.hasSame(selectedDate, 'day'),
        isToday: DateTime.local().hasSame(day, 'day'),
      })),
      ...days.map((day) => ({
        date: day,
        isCurrentMonth: true,
        isToday: DateTime.local().hasSame(day, 'day'),
        isSelected: selectedDate.hasSame(day, 'day'),
      })),
    ];
  }
  return [];
}

interface Day {
  date: DateTime;
  isCurrentMonth: boolean;
  isToday: boolean;
  isSelected: boolean;
}
type Days = Day[];

export function TimeSheetModal({
  setModalOpen,
  currentData,
}: {
  setModalOpen: (value: boolean) => void;
  currentData: RouterOutput['u']['timeCards']['getByWorkerProfile']['timecards'][0];
}) {
  //   const claims = useAuth((state) => state.claims);

  //   const timeCardsData = trpc.w.timeCards.list.useQuery();
  //   const { data: contractsData, isLoading } = trpc.w.contracts.list.useQuery();
  const { mutateAsync: updateTimeCard } = trpc.u.timeCards.update.useMutation();

  const [hours, setHours] = useState(1);

  const [isLoading, setLoading] = useState(true);

  const [selectedDate, setSelectedDate] = useState<DateTime>(DateTime.now());

  const [textBox, setTextBox] = useState('');

  React.useEffect(() => {
    setTextBox(currentData.description ?? '');
    setSelectedDate(DateTime.fromJSDate(currentData.date));
    setHours(currentData.hours);
    setLoading(false);
  }, [currentData]);

  const [month, setMonth] = useState(new Date().getMonth() + 1);
  const [year, setYear] = useState(new Date().getFullYear());
  const [days, setDays] = useState<Days>([]);
  const [calendarDay, setCalendarDay] = useState(
    DateTime.now().startOf('month'),
  );
  const [hoursError, setHoursError] = useState<string | null>();
  const navigate = useNavigate();

  const loadCalendar = useCallback(
    (date: DateTime) => {
      setMonth(date.month);
      setYear(date.year);
      const interval = Interval.fromDateTimes(
        date.startOf('month'),
        date.endOf('month'),
      );
      const newDays = daysInInterval(interval);
      const daysWithContext = withContext(newDays, selectedDate);

      setDays(daysWithContext);
    },
    [selectedDate],
  );

  function submitNewTimeCardEntry() {
    setHoursError(null);
    if (hours < 0) {
      setHoursError('Please set valid hours.');
      return;
    }

    updateTimeCard({
      date: selectedDate.toISODate(),
      hours: hours.toString(),
      description: textBox,
      id: currentData.id,
    })
      .then(() => {
        setModalOpen(false);
        navigate('/time-tracking');
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.error('e ', e);
      });
  }

  useEffect(() => {
    loadCalendar(calendarDay);
  }, [calendarDay, loadCalendar]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="p-4 sm:p-6">
      <h2 className="font-semibold text-gray-900">Create New Time Log</h2>
      <div className="grid grid-cols-5 gap-12 mt-12">
        <div className="col-span-3 mb-10 text-center">
          <div className="flex items-center text-gray-900">
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Previous month</span>
              <ChevronLeftIcon
                className="w-5 h-5"
                aria-hidden="true"
                onClick={() => setCalendarDay(calendarDay.minus({ month: 1 }))}
              />
            </button>
            <div className="flex-auto font-semibold">
              {year} {monthNames[month - 1]}
            </div>
            <button
              type="button"
              className="-m-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
            >
              <span className="sr-only">Next month</span>
              <ChevronRightIcon
                className="w-5 h-5"
                aria-hidden="true"
                onClick={() => setCalendarDay(calendarDay.plus({ month: 1 }))}
              />
            </button>
          </div>
          <div className="grid grid-cols-7 mt-6 text-xs leading-6 text-gray-500">
            <div>S</div>
            <div>M</div>
            <div>T</div>
            <div>W</div>
            <div>T</div>
            <div>F</div>
            <div>S</div>
          </div>
          <div className="grid grid-cols-7 gap-px mt-2 text-sm bg-gray-200 shadow isolate ring-1 ring-gray-200">
            {days.map((day) => (
              <button
                key={day.date.toISODate()}
                type="button"
                className={classNames(
                  'py-1.5 hover:bg-indigo-400 focus:z-10',
                  day.isCurrentMonth
                    ? 'bg-white text-gray-900'
                    : 'bg-gray-50 text-gray-400',
                  day.isSelected || day.isToday ? 'font-semibold' : '',
                  day.isSelected ? 'bg-indigo-600' : '',
                  !day.isSelected && day.isCurrentMonth && !day.isToday
                    ? 'text-gray-900'
                    : '',
                  day.isToday && !day.isSelected ? 'bg-indigo-100' : '',
                )}
                onClick={() => setSelectedDate(day.date)}
              >
                <time
                  dateTime={day.date.toISODate()}
                  className={classNames(
                    'mx-auto flex h-7 w-7 items-center justify-center',
                    day.isSelected ? 'text-white' : '',
                  )}
                >
                  {day.date.day}
                  {/* {timeCardsData.data
                    ?.map((tc) => tc.date.toISOString().split('T')[0])
                    .includes(day.date.toISODate().split('T')[0]) && (
                    <div className="w-1.5 h-1.5 rounded-full bg-indigo-600 absolute mt-6" />
                  )} */}
                </time>
              </button>
            ))}
          </div>
        </div>

        <div className="col-span-2">
          <div className="flex flex-col gap-4">
            <div>
              <div className="block mb-2 text-base font-medium text-gray-700">
                Date
              </div>

              <div className="w-full p-2 text-sm text-center border border-gray-300 rounded-md cursor-not-allowed">
                {selectedDate.toISODate()}
              </div>
            </div>

            <div>
              <label
                htmlFor="hours"
                className="block mb-2 text-base font-medium text-gray-700"
              >
                Hours
              </label>

              <input
                id="hours"
                type="number"
                className="w-full p-2 text-sm text-center border border-gray-300 rounded-md"
                name="hours"
                defaultValue={hours}
                onChange={(e) => setHours(parseFloat(e.target.value))}
                onKeyUp={(e) =>
                  setHours(parseFloat((e.target as HTMLInputElement).value))
                }
              />

              <p className="m-1 text-xs text-red-500">{hoursError}</p>
            </div>
          </div>
        </div>
      </div>

      <div>
        <label
          htmlFor="comment"
          className="block text-sm font-medium text-gray-700"
        >
          Add a Description of Your Work Day
        </label>
        <div className="mt-1 mb-4">
          <textarea
            rows={4}
            name="comment"
            id="comment"
            className="block w-full border-gray-300 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            value={textBox}
            onChange={(e) => setTextBox(e.target.value)}
          >
            {textBox}
          </textarea>
        </div>
      </div>
      <div>
        <Button
          text="Submit"
          className="flex justify-center w-full px-4 py-3 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => submitNewTimeCardEntry()}
        />
      </div>
    </div>
  );
}
