import { useCallback, useEffect } from 'react';
import isArray from 'lodash/isArray';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/20/solid';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { useAuth } from '../../hooks/useAuth';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';
import { IPayload } from './types';

export function ManageLabels() {
  const { claims } = useAuth();
  const navigate = useNavigate();
  const setNotification = useNotification((state) => state.setNotification);
  const clientId = claims?.activeClientId ?? '';
  const {
    data: labels,
    isLoading,
    refetch,
  } = trpc.u.labels.list.useQuery({
    clientId,
  });

  const defaultValues: IPayload = {
    name: labels?.name ?? '',
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    values: (labels?.values as string[]) ?? [''],
    required: labels?.required ?? false,
    clientId,
    id: labels?.id ?? '',
  };

  const { mutate: createLabel } = trpc.u.labels.create.useMutation({
    onSuccess: async () => {
      await refetch();

      return setNotification({
        type: 'success',
        title: 'Success',
        message: 'Label Created ',
      });
    },

    onError: (error) =>
      setNotification({
        type: 'error',
        title: 'Error',
        message: error.message || 'Something went wrong.',
      }),
  });

  const { mutate: updateLabel } = trpc.u.labels.update.useMutation({
    onSuccess: async () => {
      await refetch();

      return setNotification({
        type: 'success',
        title: 'Success',
        message: 'Label Updated',
      });
    },

    onError: (error) =>
      setNotification({
        type: 'error',
        title: 'Error',
        message: error.message || 'Something went wrong.',
      }),
  });

  const {
    handleSubmit,
    register,
    reset,
    watch,
    setValue,
    formState: { isSubmitting },
  } = useForm({
    defaultValues,
  });

  const loadForm = useCallback(() => {
    reset(defaultValues);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  useEffect(() => {
    if (labels) {
      loadForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [labels]);

  const values = watch('values', ['']);
  const name = watch('name');

  const onSubmit = (payload: IPayload) => {
    if (labels) {
      return updateLabel({
        params: {
          name: payload.name,
          values: payload.values,
          clientId: payload.clientId,
          required: payload.required,
        },
        id: payload.id,
      });
    }
    return createLabel({
      name: payload.name,
      values: payload.values,
      clientId: payload.clientId,
      required: payload.required,
    });
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <main className="flex-1 min-h-screen pb-8 bg-gray-100">
      <TopBar title="Manage Labels" />

      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="w-full max-w-md p-3 mt-4">
          <form
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={handleSubmit(onSubmit)}
            className="px-8 py-8 space-y-4 bg-white shadow sm:rounded-lg"
          >
            <div className="grid grid-cols-1 gap-y-8">
              <div className="sm:col-span-1">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Name
                </label>

                <input
                  type="text"
                  id="name"
                  className="block w-full mt-2 border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register('name', {
                    required: 'This is required',
                  })}
                />
              </div>

              <div className="sm:col-span-1">
                <label
                  htmlFor="name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Values
                </label>

                {isArray(values) &&
                  values.map((_, index: number) => (
                    <div
                      // eslint-disable-next-line react/no-array-index-key
                      key={`values-${index}`}
                      className="flex items-start gap-2 mt-2 mb-3"
                    >
                      <input
                        type="text"
                        id="name"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        {...register(`values.${index}`, {
                          required: 'This is required',
                        })}
                      />

                      <button
                        type="button"
                        data-testid="delete-timecard-value"
                        onClick={() =>
                          setValue(
                            'values',
                            // eslint-disable-next-line @typescript-eslint/no-shadow
                            values.filter((_, i) => i !== index),
                          )
                        }
                        disabled={values.length === 1}
                        className="mt-2"
                      >
                        <TrashIcon
                          className="w-5 h-6 m-auto text-gray-400 cursor-pointer"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  ))}

                <Button
                  type="button"
                  variant="secondary"
                  text="Add"
                  onClick={() => {
                    const temp = [...values];
                    temp.push('');
                    return setValue('values', temp);
                  }}
                />
              </div>

              <div className="flex items-center gap-4">
                <input
                  id="required"
                  {...register('required')}
                  type="checkbox"
                  className="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                />

                <label
                  htmlFor="required"
                  className="block text-sm font-medium text-gray-700"
                >
                  Required
                </label>
              </div>
            </div>

            <div className="pt-4">
              <div className="flex justify-end gap-2">
                <Button
                  type="button"
                  variant="secondary"
                  text="Cancel"
                  onClick={() => navigate('/time-tracking')}
                />

                <Button
                  type="submit"
                  loading={isSubmitting}
                  disabled={!name}
                  className="inline-flex justify-center px-4 py-3 font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:ring-2 focus:outline-none focus:ring-offset-2"
                  text="Save"
                />
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
}
