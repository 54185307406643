import { PaperClipIcon } from '@heroicons/react/24/outline';

export function Attachments({ uploadUrl }: { uploadUrl: string }) {
  return (
    <div className="py-5 px-5">
      <dt className="text-sm font-medium text-gray-500">Attachments</dt>
      <dd className="mt-1 text-sm text-gray-900">
        <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
          <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            <div className="w-0 flex-1 flex items-center">
              <PaperClipIcon
                className="flex-shrink-0 h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
              <span className="ml-2 flex-1 w-0 truncate">
                SignedContract.pdf
              </span>
            </div>
            <div className="ml-4 flex-shrink-0">
              <a
                href={uploadUrl}
                className="font-medium text-blue-600 hover:text-blue-500"
                target="_blank"
                rel="noreferrer"
              >
                Download
              </a>
            </div>
          </li>
        </ul>
      </dd>
    </div>
  );
}
