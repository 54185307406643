import { formatDate } from 'listo/src/utils/dates';
import { classNames } from 'listo/src/utils/strings';
import { RouterOutput } from '../../lib/trpc';

const steps = {
  GATHERING: 0,
  GENERATED: 0,
  APPROVAL: 1,
  INVOICED: 2,
  COLLECTING: 3,
  DISTRIBUTING: 4,
  COMPLETE: 5,
};

export function InvoiceDetails({
  invoice,
}: {
  invoice: RouterOutput['u']['invoices']['invoice'];
}) {
  const shortId = invoice.id.split('-')[0];
  const step = steps.INVOICED;

  return (
    <section aria-labelledby="applicant-information-title">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="applicant-information-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Invoice Information
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding important dates and status expectations.
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Invoice Number
              </dt>
              <dd className="mt-1 text-sm text-gray-900">{shortId}</dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Invoice Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatDate(invoice.createdAt)}
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-indigo-600"
              style={{
                width: `calc((${step} * 2 + 1) / 10 * 100%)`,
              }}
            />
          </div>
          <div className="mt-6 hidden grid-cols-5 font-medium text-gray-600 sm:grid">
            <div className="text-indigo-600">Gather</div>
            <div
              className={classNames(
                step > 0 ? 'text-indigo-600' : '',
                'text-center',
              )}
            >
              Approve
            </div>
            <div
              className={classNames(
                step > 1 ? 'text-indigo-600' : '',
                'text-center',
              )}
            >
              Open
            </div>
            <div
              className={classNames(
                step > 2 ? 'text-indigo-600' : '',
                'text-center',
              )}
            >
              Collect
            </div>
            <div
              className={classNames(
                step > 3 ? 'text-indigo-600' : '',
                'text-right',
              )}
            >
              Distribute
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
