import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { CloudArrowDownIcon, EyeIcon } from '@heroicons/react/24/outline';
import { Button } from 'ui/src/components/Button';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';
import TopBar from '../../components/TopBar';
import Modal from '../../components/Modal';
import { Spinner } from '../../components/Loader';
import { Failed } from '../../components/Badge/Badge';

interface Actions {
  fileName?: string;
  url?: string;
}

function DocumentActions({ fileName = '', url = '' }: Actions) {
  const [viewDocument, setViewDocument] = useState(false);
  const [loading, setLoading] = useState('');
  const [documentFile, setDocumentFile] = useState({
    name: '',
    type: '',
    objectUrl: '',
    downloadUrl: '',
  });

  const initiateDocumentDownload = () => {
    if (documentFile.downloadUrl) {
      const el = document.createElement('a');
      el.href = documentFile.downloadUrl;
      el.download = documentFile.name;
      el.style.display = 'none';
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
      URL.revokeObjectURL(documentFile.downloadUrl);
      setDocumentFile({ ...documentFile, downloadUrl: '' });
    }
  };

  useEffect(() => {
    if (documentFile.downloadUrl) {
      initiateDocumentDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentFile.downloadUrl]);

  const handleDocumentDownload = async (
    documentName?: string,
    documentUploadUrl?: string,
  ) => {
    try {
      setLoading('download');
      const response = await fetch(documentUploadUrl ?? '');
      const blob = await response.blob();

      const name = documentName ?? '';
      const downloadUrl = URL.createObjectURL(blob);

      setDocumentFile({ ...documentFile, name, downloadUrl });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setLoading('');
    }
  };

  const handleDocumentView = async (documentUploadUrl?: string) => {
    try {
      setViewDocument(true);
      setLoading('view');

      const response = await fetch(documentUploadUrl ?? '');
      const blob = await response.blob();

      const type = blob.type.includes('image') ? 'image' : 'pdf';
      const objectUrl = URL.createObjectURL(blob);

      setDocumentFile({ ...documentFile, type, objectUrl });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setLoading('');
    }
  };

  return (
    <>
      <div className="flex items-center gap-4">
        <button
          type="button"
          className="p-0 outline-none border-none text-indigo-600 hover:underline cursor-pointer"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => handleDocumentView(url)}
        >
          <span className="flex gap-1">
            <EyeIcon className="h-5" />
            View
          </span>
        </button>

        <button
          type="button"
          className="p-0 outline-none border-none text-indigo-600 hover:underline cursor-pointer"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={() => handleDocumentDownload(fileName, url)}
        >
          {loading === 'download' ? (
            <Spinner height={4} width={4} />
          ) : (
            <span className="flex gap-1">
              <CloudArrowDownIcon className="h-5" />
              Download
            </span>
          )}
        </button>
      </div>

      <Modal isOpen={viewDocument} setIsOpen={() => setViewDocument(false)}>
        <div className="x-4 pt-5 pb-4 sm:my-12 sm:max-w-4xl sm:p-6 sm:w-[60vw] h-[80vh] w-[90vw]">
          {loading === 'view' ? (
            <div className="flex justify-center items-center h-full">
              <Spinner />
            </div>
          ) : (
            <div className="h-full w-full mb-4">
              {documentFile.type === 'image' ? (
                <img
                  style={{ height: '100%', width: '100%' }}
                  title="image viewer"
                  alt="img"
                  src={documentFile.objectUrl}
                />
              ) : (
                <iframe
                  style={{ height: '100%', width: '100%' }}
                  title="pdf viewer"
                  src={`${documentFile.objectUrl}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0`}
                />
              )}
            </div>
          )}

          <div className="flex gap-4 justify-end">
            <Button
              type="button"
              variant="secondary"
              text="Cancel"
              onClick={() => setViewDocument(false)}
            />

            <Button
              type="button"
              text="Download"
              icon={<CloudArrowDownIcon className="h-4 w-4 text-gray-50" />}
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onClick={() => handleDocumentDownload(fileName, url)}
              loading={loading === 'download'}
            />
          </div>
        </div>
      </Modal>
    </>
  );
}

function Empty() {
  return (
    <div className="text-center w-full py-6 bg-white">
      <h3 className="text-sm font-medium text-gray-900">- Empty -</h3>
    </div>
  );
}

export function Documents() {
  const { claims } = useAuth();
  const workerId = claims?.workers[0]?.id ?? '';

  const { data: documents, isLoading } = trpc.w.documents.list.useQuery({
    workerId,
  });

  if (isLoading) {
    return <Spinner />;
  }

  let isDocumentEmpty = false;

  if (isEmpty(documents) || documents.updatedDocument.length === 0) {
    isDocumentEmpty = true;
  }

  return (
    <div className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="My Documents" />

      <div className="px-4 sm:px-6 lg:px-8">
        <div className="mt-8 flex flex-col">
          <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                {isDocumentEmpty ? (
                  <Empty />
                ) : (
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                        >
                          Contract
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Document type
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Updated at
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Uploaded by
                        </th>

                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 "
                        >
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {documents?.updatedDocument.map((document) => (
                        <tr key={document.id}>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                            <Link
                              to={`/wrkr/my-contracts/${document.contractId}`}
                              className="text-indigo-600 hover:underline"
                            >
                              {`${document.contract.name} - ${document.contract.isoCountryCode}`}
                            </Link>
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {document.documentType}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {document.updatedAt.toLocaleDateString()}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-900">
                            {document.uploadedBy ? (
                              <span className="text-green-600 capitalize">
                                {document.uploadedBy.toLocaleLowerCase()}
                              </span>
                            ) : (
                              '-'
                            )}
                          </td>

                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {document.uploadId ? (
                              <DocumentActions
                                fileName={document.upload?.fileName}
                                url={document.upload?.url}
                              />
                            ) : (
                              <div
                                className="cursor-pointer"
                                data-tooltip-target="tooltipee"
                              >
                                <div className="inline-block group cursor-help relative">
                                  <Failed text="Requested" />
                                  <div className="opacity-0 bg-gray-900 text-white text-xs rounded-lg p-2 absolute z-10 group-hover:opacity-100 bottom-[120%] -left-[110%] pointer-events-none">
                                    You are requested to upload your document.
                                    <svg
                                      className="absolute text-gray-900 h-2 w-full left-0 top-full"
                                      x="0px"
                                      y="0px"
                                      viewBox="0 0 255 255"
                                      xmlSpace="preserve"
                                    >
                                      <polygon
                                        className="fill-current"
                                        points="0,0 127.5,127.5 255,0"
                                      />
                                    </svg>
                                  </div>
                                </div>
                              </div>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
