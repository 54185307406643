import { useState, Fragment, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { SubmitHandler } from 'react-hook-form';
import {
  CalendarIcon,
  EllipsisHorizontalIcon,
} from '@heroicons/react/20/solid';
import { Menu, Transition } from '@headlessui/react';
import {
  ExpenseReimbursementStatusEnum,
  ExpenseReimbursementStatus,
} from 'listo/src/zodObjects/expenseReimbursement';
import { formatDate } from 'listo/src/utils/dates';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { AlertModal } from 'ui/src/components/AlertModal/AlertModal';
import Avatar from '../Avatar';
import { RouterOutput, trpc } from '../../lib/trpc';
import { ApproveModal, IApprovalModalForm } from '../ApproveModal';
import { statusChipConfig } from './StatusChip';

enum EApprovalModal {
  APPROVE = 'approve',
  DECLINE = 'decline',
}

interface IReimbursementListItem {
  data: RouterOutput['u']['expenseReimbursement']['listAll'][0];
  status: ExpenseReimbursementStatus;
  isWorker: boolean;
  refetch: () => Promise<unknown>;
}

interface IMenuOptions {
  id: string;
  title: string;
  onClick: () => void;
  disabled: boolean;
}

export function ReimbursementListItem({
  data,
  status,
  isWorker,
  refetch,
}: IReimbursementListItem) {
  const navigate = useNavigate();
  const [approveModalType, setApproveModalType] = useState('');
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [menuOptions, setMenuOptions] = useState<IMenuOptions[]>([]);
  const reimbursementId = data.id;
  const setNotification = useNotification((state) => state.setNotification);

  useEffect(() => {
    if (isWorker) {
      const isApprovedOrDeclined =
        data.status === ExpenseReimbursementStatusEnum.Values.APPROVED ||
        data.status === ExpenseReimbursementStatusEnum.Values.DECLINED;

      setMenuOptions([
        {
          id: 'delete',
          title: 'Delete',
          onClick: () => setOpenDeleteModal(true),
          disabled: isApprovedOrDeclined,
        },
      ]);
    } else {
      setMenuOptions([
        {
          id: 'approve',
          title: 'Approve',
          onClick: () => setApproveModalType(EApprovalModal.APPROVE),
          disabled:
            data.status === ExpenseReimbursementStatusEnum.Values.APPROVED,
        },
        {
          id: 'decline',
          title: 'Decline',
          onClick: () => setApproveModalType(EApprovalModal.DECLINE),
          disabled:
            data.status === ExpenseReimbursementStatusEnum.Values.DECLINED,
        },
        {
          id: 'view',
          title: 'View Details',
          onClick: () => navigate(`/request-reimbursement/${reimbursementId}`),
          disabled: false,
        },
      ]);
    }
  }, [data.status, data.expenseDate, isWorker, navigate, reimbursementId]);

  const { mutateAsync: updateStatus } =
    trpc.u.expenseReimbursement.updateStatus.useMutation({
      onSuccess: () =>
        setNotification({
          type: 'success',
          title: 'Success',
          message:
            approveModalType === EApprovalModal.APPROVE
              ? 'Approved successfully.'
              : 'Declined successfully.',
        }),
      onError: () =>
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Your request has failed. Please contact us.',
        }),
    });

  const { mutateAsync: deleteReimbursementRequest, isLoading: loadingDelete } =
    trpc.w.expenseReimbursement.delete.useMutation({
      onSuccess: () =>
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Deleted successfully.',
        }),
      onError: () =>
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Your request has failed. Please contact us.',
        }),
    });

  const onSubmit: SubmitHandler<IApprovalModalForm> = async (values) => {
    try {
      await updateStatus({
        id: reimbursementId,
        status:
          approveModalType === EApprovalModal.APPROVE
            ? ExpenseReimbursementStatusEnum.Values.APPROVED
            : ExpenseReimbursementStatusEnum.Values.DECLINED,
        clientMessage: values.message,
      });
      refetch().catch(() => {});
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setApproveModalType('');
    }
  };

  const onDelete = async () => {
    try {
      await deleteReimbursementRequest({
        id: reimbursementId,
      });
      refetch().catch(() => {});
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setApproveModalType('');
    }
  };

  return (
    <li className="relative flex space-x-6 px-8 md:px-10 py-6 md:py-8 xl:static">
      <Avatar
        className="shrink-0 h-12 w-12 rounded-full text-sm"
        name={data.workerProfile.fullName ?? ''}
        image={data.workerProfile.workerProfileUpload?.url ?? ''}
      />

      <div className="flex-auto">
        <h3 className="pr-6 font-semibold text-gray-600 xl:pr-0 capitalize">
          {data.workerProfile.fullName}
        </h3>

        <dl className="flex xl:flex-row items-center text-gray-500 mb-4">
          <div className="flex items-start flex-wrap gap-2">
            <dt className="mt-0.5">
              <span className="sr-only">Expense Date:</span>

              <CalendarIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </dt>

            <dd className=" shrink-0 w-24">
              <time dateTime={data.expenseDate.toISOString()}>
                {formatDate(data.expenseDate)}
              </time>
            </dd>

            <div className="flex items-start text-xs">
              <dd
                className={`flex items-center gap-1 px-2 py-1 rounded-xl text-white capitalize tracking-wide ${statusChipConfig[status].color} ${statusChipConfig[status].bgColor}`}
              >
                {statusChipConfig[status].icon} {status.toLowerCase()}
              </dd>
            </div>
          </div>
        </dl>

        <div className="mt-1 text-gray-500">{data.description}</div>
      </div>

      {menuOptions.length > 0 && (
        <Menu
          as="div"
          className="absolute top-6 right-0 xl:relative xl:top-auto xl:right-auto xl:self-start"
        >
          <div>
            <Menu.Button className="-mt-1 flex items-center rounded-full p-1 text-gray-500 hover:bg-gray-100">
              <EllipsisHorizontalIcon className="h-6 w-6" aria-hidden="true" />
            </Menu.Button>
          </div>

          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                {menuOptions.map((menuOption) => (
                  <Menu.Item
                    key={`menu-option-${reimbursementId}-${menuOption.id}`}
                  >
                    <button
                      type="button"
                      className="text-left text-gray-700 block w-full px-4 py-2 text-sm disabled:opacity-25 hover:bg-gray-100"
                      onClick={menuOption.onClick}
                      disabled={menuOption.disabled}
                    >
                      {menuOption.title}
                    </button>
                  </Menu.Item>
                ))}
              </div>
            </Menu.Items>
          </Transition>
        </Menu>
      )}

      <ApproveModal
        open={!!approveModalType}
        onClose={() => setApproveModalType('')}
        title={
          approveModalType === EApprovalModal.APPROVE ? 'Approve' : 'Decline'
        }
        onSubmit={onSubmit}
      />

      <AlertModal
        title="Are you sure want to delete this request ?"
        description=""
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirmButtonText="Confirm"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onConfirm={onDelete}
        loadingConfirm={loadingDelete}
      />
    </li>
  );
}
