import { countryNameByIsoCode } from 'listo/src/countries';
import { formatCentsToDollars } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import RadixAvatar from '../../components/RadixAvatar';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';

function formatInfo(info: string) {
  return info.toLowerCase().charAt(0).toUpperCase() + info.slice(1);
}

export function Recruit() {
  const { id } = useParams();

  if (!id) throw new Error('id is required');

  const { data, isLoading, error } = trpc.u.recruits.recruit.useQuery({
    recruitId: id,
  });

  if (isLoading) return <Loader />;
  if (error) return <h1>error</h1>;

  if (!data) return null;

  if (!id) return null;

  return (
    <div className="min-h-screen bg-gray-100">
      <TopBar title="Recruit" />
      <main className="py-10">
        {/* Page header */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <RadixAvatar fallbackString={data.jobTitle} />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {data.jobTitle}
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Request created on{' '}
                <time dateTime="2020-08-25">{formatDate(data.createdAt)}</time>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          {/* <div className="lg:col-start-3 lg:col-span-1">
            <section aria-labelledby="timeline-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="sm:px-6 px-4 py-5">
                  <h2
                    id="timeline-title"
                    className="text-lg font-medium text-gray-900 leading-6"
                  >
                    Send Recruit Information
                  </h2>
                  <div className="mt-1 sm:flex sm:items-center">
                    <div className="w-full sm:max-w-xs">
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                        placeholder="email@example.com"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>

                    <Button
                      className="ml-3"
                      loading={isLoading}
                      text="Send"
                      onClick={() => {
                        sendRecruitInfo(email);
                      }}
                    />
                  </div>
                </div>
              </div>
            </section>
          </div> */}
          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="applicant-information-title">
              <div className="bg-white shadow sm:rounded-lg">
                <div className="px-4 py-5 sm:px-6">
                  <h2
                    id="applicant-information-title"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Recruitment Request Information
                  </h2>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    Recruitment details and overview.
                  </p>
                </div>
                <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                  <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Country of Origin
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {countryNameByIsoCode(data.countryOfOrigin)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Scope of Work
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatInfo(data.scopeOfWork)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Field of Expertise
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatInfo(data.fieldOfExpertise)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Seniority Level
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatInfo(data.seniorityLevel)}
                      </dd>
                    </div>
                    <div className="sm:col-span-2">
                      <dt className="text-sm font-medium text-gray-500">
                        Payment Currency
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {data.paymentCurrency}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Work Experience
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatInfo(data.experience)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Education Level
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatInfo(data.educationLevel)}
                      </dd>
                    </div>
                    <div className="sm:col-span-1">
                      <dt className="text-sm font-medium text-gray-500">
                        Salary
                      </dt>
                      <dd className="mt-1 text-sm text-gray-900">
                        {formatCentsToDollars(
                          data.minSalary * 100,
                          data.paymentCurrency,
                        )}{' '}
                        -{' '}
                        {formatCentsToDollars(
                          data.maxSalary * 100,
                          data.paymentCurrency,
                        )}
                      </dd>
                    </div>
                  </dl>
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}
