import { XCircleIcon } from '@heroicons/react/20/solid';
import { BuildingOffice2Icon } from '@heroicons/react/24/outline';
import * as Popover from '@radix-ui/react-popover';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'ui/src/components/Button';
import { getJwt, useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';

export interface Account {
  workerOrUserId: string;
  clientId: string;
  clientName: string | null;
}

export type Accounts = Account[];

export function AccountSelect({ accounts }: { accounts: Accounts }) {
  const claims = useAuth((state) => state.claims);
  const setJwt = useAuth((state) => state.setJwt);
  const queryClient = useQueryClient();

  const { mutate: refreshJwt, isLoading } =
    trpc.p.auth.refreshToken.useMutation({
      onSuccess: (jwt) => {
        queryClient.clear();
        setJwt(jwt);
      },
    });

  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <button
          type="button"
          className="text-left text-indigo-100 hover:text-white hover:bg-indigo-600 group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md w-full"
        >
          <BuildingOffice2Icon
            className="mr-4 h-6 w-6 text-indigo-200"
            aria-hidden="true"
          />
          {claims?.clientName ?? claims?.activeClientId}
        </button>
      </Popover.Trigger>
      <Popover.Content className="PopoverContent" sideOffset={5}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
          {accounts
            .filter((a) => a.clientName !== claims?.clientName)
            .map((account) => (
              <Button
                className="w-full"
                variant="secondary"
                loading={isLoading}
                key={account.clientId}
                text={account.clientName ?? ''}
                onClick={() => {
                  const jwt = getJwt();
                  if (jwt)
                    refreshJwt({
                      workerOrUserId: account.workerOrUserId,
                      token: jwt,
                    });
                }}
              />
            ))}
        </div>
        <Popover.Close className="PopoverClose" aria-label="Close">
          <XCircleIcon />
        </Popover.Close>
        <Popover.Arrow className="PopoverArrow" />
      </Popover.Content>
    </Popover.Root>
  );
}
