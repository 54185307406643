import {
  BriefcaseIcon,
  CalendarIcon,
  CurrencyDollarIcon,
  MapIcon,
} from '@heroicons/react/20/solid';
import { Bars3Icon, BuildingOfficeIcon } from '@heroicons/react/24/outline';
import { countryNameByIsoCode } from 'listo/src/countries';
import { formatPaymentAmount } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { titleCase } from 'listo/src/utils/strings';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { PageLoader } from 'ui/src/components/PageLoader';
import { Spinner } from 'ui/src/components/Spinner';
import ErrorState from '../../components/ErrorState';
import { useAuth } from '../../hooks/useAuth';
import useSidebar from '../../hooks/useSidebar';
import MyPayments from './MyPayments';
import { RouterOutput, trpc } from '../../lib/trpc';
import { StepLink } from './StepLink';
import { PayPeriod } from './PayPeriod';
import Avatar from '../../components/Avatar';
import { getSignContractLink } from '../../lib/utilities';

function Header() {
  const { claims } = useAuth();
  const { data } = trpc.w.workers.profile.useQuery();
  const { sidebarIsOpen, setSidebarIsOpen } = useSidebar();

  const profileFullName = data?.fullName ?? '';
  const profilePictureUrl = data?.workerProfileUpload?.url ?? '';

  return (
    <div className="bg-white shadow">
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            {/* Profile */}
            <div className="flex items-center">
              <Avatar
                name={profileFullName}
                image={profilePictureUrl}
                className="h-16 w-16 lg:flex bg-gray-100 text-center text-[28px] uppercase hidden"
              />
              <div>
                <div className="flex items-center">
                  <button
                    type="button"
                    className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                    onClick={() => {
                      setSidebarIsOpen(!sidebarIsOpen);
                    }}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {`Hello, ${titleCase(claims?.fullName ?? '')}`}
                  </h1>
                </div>
                <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap lg:flex">
                  <dt className="sr-only">Company</dt>
                  <dd className="flex items-center text-sm text-gray-500 font-medium capitalize sm:mr-6">
                    <BuildingOfficeIcon
                      className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    Contract Portal
                  </dd>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CardHeader({
  contract,
}: {
  contract: RouterOutput['w']['contracts']['list']['results'][0];
}) {
  return (
    <>
      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
        {titleCase(contract.client.name)}
      </h2>
      <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
        <div className="mt-2 flex items-center text-sm text-gray-500">
          <BriefcaseIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          {titleCase(contract.engagementType)}
        </div>
        <div className="mt-2 flex items-center text-sm text-gray-500">
          <MapIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          {countryNameByIsoCode(contract.isoCountryCode)}
        </div>
        <div className="mt-2 flex items-center text-sm text-gray-500">
          <CurrencyDollarIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          {formatPaymentAmount(
            contract.billingAmountInCents,
            contract.billingFrequency,
            contract.billingCurrency,
          )}
        </div>
        <div className="mt-2 flex items-center text-sm text-gray-500">
          <CalendarIcon
            className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
            aria-hidden="true"
          />
          {`Starting on ${formatDate(contract.startDate)}`}
        </div>
      </div>
    </>
  );
}

function OnboardingProgress({
  contract,
}: {
  contract: RouterOutput['w']['contracts']['contract'];
}) {
  return (
    <nav aria-label="Progress">
      <ol className="overflow-hidden">
        {contract.engagementType !== 'EOR' ? (
          <li className="pb-8 relative">
            <StepLink
              to={getSignContractLink(contract.id, contract.workerProfile)}
              heading="Sign Contract"
              subheading={`Review and sign your compliant agreement with ${contract.client.name}`}
              complete={
                !!contract.workerSignature || contract.status === 'EXECUTED'
              }
            />
          </li>
        ) : null}

        <li className="pb-8 relative">
          <StepLink
            to="/wrkr/profile/bank-account"
            heading="Add Bank Info"
            subheading="Add your bank account to receive payments."
            complete={
              !!contract.workerProfile.bankAccounts.filter((f) => f.number)
                .length
            }
          />
        </li>
        <li>
          <StepLink
            to="/wrkr/profile/tax"
            heading="Tax Identification"
            subheading="Make sure your tax information is up to date."
            complete={!!contract.worker.workerProfile.taxIdNumber}
          />
        </li>
      </ol>
    </nav>
  );
}

function CardBody({ contractId }: { contractId: string }) {
  const navigate = useNavigate();

  const {
    data: fullContract,
    isLoading,
    error,
  } = trpc.w.contracts.contract.useQuery(contractId);

  if (isLoading) return <Spinner />;
  if (error)
    return <ErrorState trpcError={error} parentComponentName="ContractCard" />;

  return (
    <div className="grid grid-cols-1 sm:grid-cols-2">
      <OnboardingProgress contract={fullContract} />
      <div className="flex flex-1 items-end justify-end">
        <Button
          text="Log Time"
          onClick={() => navigate('/wrkr/timecards?open=true')}
        />
      </div>
    </div>
  );
}

function ContractCard({
  contract,
}: {
  contract: RouterOutput['w']['contracts']['list']['results'][0];
}) {
  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow sm:col-span-2">
      <div className="px-4 py-5 sm:px-6">
        <CardHeader contract={contract} />
      </div>
      <div className="px-4 py-5 sm:p-6">
        <CardBody contractId={contract.id} />
      </div>
    </div>
  );
}

export function WorkerDashboard() {
  const {
    data: contracts,
    isLoading,
    error,
  } = trpc.w.contracts.list.useQuery();

  if (isLoading) return <PageLoader />;
  if (error)
    return (
      <ErrorState trpcError={error} parentComponentName="WorkerDashboard" />
    );

  return (
    <div className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <Header />
      {contracts.results.length === 0 ? (
        <div className="text-center mt-8">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No contracts
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            When you are added to a contract it will appear here.
          </p>
          <div className="mt-6">
            <Link
              to="/wrkr/my-contracts"
              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Contracts
            </Link>
          </div>
        </div>
      ) : null}
      <div className="sm:mx-8">
        <div className="my-8 grid grid-cols-1 sm:grid-cols-4 gap-4">
          {contracts.results.map((contract) => (
            <ContractCard contract={contract} key={contract.id} />
          ))}

          {contracts.results.map((contract) => (
            <PayPeriod contractId={contract.id} key={contract.id} />
          ))}
        </div>
        <MyPayments />
      </div>
    </div>
  );
}
