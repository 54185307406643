import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { countryNameByIsoCode } from 'listo/src/countries';
import { DateTime } from 'listo/src/luxonUtc';
import { centsToDollars, dollarCurrencyFormat } from 'listo/src/utils/currency';
import { monthNamesBetweenDates } from 'listo/src/utils/dates';
import { groupBy, reduce } from 'lodash';
import { Bar } from 'react-chartjs-2';
import { RouterOutput } from '../../lib/trpc';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

type LineItems = RouterOutput['u']['reporting']['lineItems']['lineItems'];

export const options = {
  plugins: {
    title: {
      display: true,
      text: 'Monthly Line Items Grouped by Country',
    },
  },
  responsive: true,
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      ticks: {
        callback: (value: number) => dollarCurrencyFormat(value),
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any,
    },
  },
};

function getAColor(i?: number) {
  if (i === 0) return '#4D46E3';
  if (i === 1) return '#76E7F0';
  if (i === 2) return '#7872EA';
  if (i === 3) return '#468DE3';
  if (i === 4) return '#9C46E3';
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

function groupDatasets(lineItems: LineItems, groupType: 'isoCountryCode') {
  if (!lineItems.length)
    return {
      labels: [],
      datasets: [],
    };

  const [first, ...rest] = lineItems;
  if (!first) throw new Error('No line items');
  const last = rest[rest.length - 1];
  if (!last) throw new Error('No line items');

  const monthLabels = monthNamesBetweenDates(
    last.invoiceDate,
    first.invoiceDate,
  );

  const groups = groupBy(lineItems, groupType);
  const sorted = Object.keys(groups).sort();

  const datasets = reduce(
    groups,
    (acc, group, key) => {
      const groupedData = monthLabels.map((lbl) => {
        const filtered = group.filter((lineItem) => {
          const d = DateTime.fromJSDate(lineItem.invoiceDate);
          return d.monthShort === lbl;
        });

        const d = filtered.reduce(
          (ac, lineItem: LineItems[0]) => ac + lineItem.amountInCents,
          0,
        );

        return centsToDollars(d);
      });

      const label = countryNameByIsoCode(key);
      const index = sorted.findIndex((c) => c === key);
      const backgroundColor = getAColor(index);

      acc.push({ data: groupedData, label, backgroundColor });
      return acc;
    },
    [] as { data: number[]; label: string; backgroundColor: string }[],
  );

  return { labels: monthLabels, datasets };
}

export function ReactChart2({ lineItems }: { lineItems: LineItems }) {
  const d = groupDatasets(lineItems, 'isoCountryCode');

  return <Bar options={options} data={d} />;
}
