import { formatCentsToDollars } from 'listo/src/utils/currency';
import { Button } from 'ui/src/components/Button';
import { PageLoader } from 'ui/src/components/PageLoader';
import { downloadFromURL } from '../../../utility/download';
import ErrorState from '../../components/ErrorState';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';
import { LineItemsTable } from './LineItemsTable';
import { ReactChart2 } from './ReactChart2';

export function LineItemsBarChart() {
  const { data, isLoading, error } = trpc.u.reporting.lineItems.useQuery();
  const { mutate: exportData, isLoading: exportLoading } =
    trpc.u.reporting.export.useMutation({
      onSuccess: (d) => {
        downloadFromURL(d.url);
      },
    });

  if (isLoading) {
    return <PageLoader />;
  }

  if (error) {
    return (
      <ErrorState parentComponentName="LineItemsbarChart" trpcError={error} />
    );
  }
  const { lineItems, averagePerMonth } = data;

  return (
    <div className="min-h-screen bg-gray-100">
      <TopBar title="Monthly Financial Report" />

      <div className="grid grid-cols-4 gap-4 px-4 mt-8 mb-4 sm:px-6 lg:px-8">
        <div className="col-span-3 w-full mx-auto max-h-[40rem] bg-white shadow px-6 py-4">
          <ReactChart2 lineItems={lineItems} />
        </div>
        <div>
          <div className="px-4 py-5 overflow-hidden bg-white rounded-lg shadow sm:p-6">
            <dt className="text-sm font-medium text-gray-500 truncate">
              Average Monthly Spend
            </dt>
            <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
              {formatCentsToDollars(averagePerMonth, 'USD')}
            </dd>
          </div>
        </div>
      </div>

      <div className="flex justify-end px-4 mb-2 sm:px-6 lg:px-8">
        <Button
          onClick={() => exportData()}
          variant="secondary"
          text="Export"
          loading={exportLoading}
        />
      </div>
      <div className="px-4 sm:px-6 lg:px-8 lg:pb-10">
        <LineItemsTable lineItems={lineItems} />
      </div>
    </div>
  );
}
