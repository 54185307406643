import { unionBy } from 'lodash';
import create from 'zustand';
import { SelectedFilter } from './TeamFilters';

interface TeamFiltersState {
  page: number;
  pagesSize: number;

  nextPage: number | undefined;
  previousPage: number | undefined;
  filterProps: {
    filterName: string;
    optionLabel: string;
    optionValue: string;
  }[];
  currentFilters: SelectedFilter[];
  searchTerm: string | undefined;
  sortValue: string;
  setPageNumber: (page: number) => void;
  setTheCurrentFilters: (currentFilters: SelectedFilter) => void;
  setSearchTerm: (searchTerm: string | undefined) => void;
  setSortValue: (sortValue: string | undefined) => void;
}

export const useTeamFiltersStore = create<TeamFiltersState>()((set) => ({
  page: 0,
  pagesSize: 25,
  nextPage: 0,
  previousPage: 0,
  filterProps: [],
  currentFilters: [],
  searchTerm: undefined,
  sortValue: 'createdAt.desc',
  setPageNumber: (page) =>
    set((state) => ({
      ...state,
      page,
    })),
  setTheCurrentFilters: (currentFilters: SelectedFilter) =>
    set((state) => ({
      ...state,
      currentFilters: unionBy(
        [currentFilters, ...state.currentFilters],
        'optionLabel',
      ),
      page: 0,
    })),
  setSearchTerm: (searchTerm: string | undefined) =>
    set((state) => ({ ...state, searchTerm, page: 0 })),
  setSortValue: (sortValue: string | undefined) =>
    set((state) => ({ ...state, sortValue, page: 0 })),
}));
