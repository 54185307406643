import { useState } from 'react';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { Input } from 'ui/src/components/Input';
import { TimeOffRequestList } from '../../components/RequestTimeOffList';
import {
  RequestTimeOffFilters,
  defaultFilter,
  dateRangeFilterValues,
  normalizeFiltersForMutation,
} from '../../components/RequestTimeOffFilter';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';

export function TimeOffRequest() {
  const { claims } = useAuth();
  const [filterBy, setFilterBy] = useState([defaultFilter.value] as string[]);

  const [dateRange, setDateRange] = useState<DateTime[]>([
    DateTime.now().startOf('day'),
  ]);

  const clientId = claims?.activeClientId ?? '';
  const startDate = dateRange[0]?.toISO() ?? '';
  const endDate = dateRange[1]?.toISO() ?? '';

  const resetFilter = () =>
    setFilterBy((prev: string[]) => {
      const newValue = prev.filter(
        (v: string) => !dateRangeFilterValues.includes(v),
      );
      return newValue;
    });

  const handleSetFilterBy = (values: string[]) => {
    const hasDateFilters = values.some((v: string) =>
      dateRangeFilterValues.includes(v),
    );
    const hasNoDateFilters = values.every(
      (v: string) => !dateRangeFilterValues.includes(v),
    );
    const shouldAddDefaultDateRange = hasNoDateFilters && dateRange.length < 2;

    if (hasDateFilters) {
      setDateRange([]);
    }

    if (shouldAddDefaultDateRange) {
      setDateRange([
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ]);
    }

    return setFilterBy(values);
  };

  const { data, isLoading, refetch } = trpc.u.timeOffRequest.listAll.useQuery(
    normalizeFiltersForMutation({
      clientId,
      startDate,
      endDate,
      filterBy,
    }),
  );

  return (
    <div className="mt-8 max-w-prose lg:mx-auto m-auto">
      <div className="flex items-center gap-8 mb-6">
        <div className="flex items-center gap-2">
          <Input
            inputProps={{
              value: dateRange[0]?.toISODate() ?? '',
              onChange: ({ target: { value } }) => {
                setDateRange([DateTime.fromISO(value).startOf('day')]);
                resetFilter();
              },
            }}
            inputType="date"
          />
          -
          <Input
            inputProps={{
              min: dateRange[0]?.toISODate() ?? '',
              value: dateRange[1]?.toISODate() ?? '',
              onChange: ({ target: { value } }) => {
                if (dateRange[0]) {
                  setDateRange((prev) => {
                    if (prev[0]) {
                      return [prev[0], DateTime.fromISO(value).endOf('day')];
                    }

                    return prev;
                  });
                }

                resetFilter();
              },
            }}
            inputType="date"
          />
        </div>

        <RequestTimeOffFilters
          defaultValue={filterBy}
          onSubmit={handleSetFilterBy}
          popupDirection="left"
        />
      </div>

      <div className="bg-white shadow sm:rounded-lg">
        <div className="flex justify-center items-start flex-col-reverse lg:flex-row gap-4">
          <div className="w-full md:w-3/4 xl:w-full mx-auto">
            <TimeOffRequestList
              data={orderBy(data, 'createdAt', 'desc')}
              refetch={refetch}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
