import { useParams } from 'react-router-dom';
import { ContractStatusEnum } from 'listo/src/zodObjects/contracts';
import { Button } from 'ui/src/components/Button';
import { useSignedModal } from '../../components/SignedContract/useSignedContract';
import SignedContract from '../../components/SignedContract';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

function WorkerContractStatus() {
  const { id } = useParams();
  const setSignedModalOpen = useSignedModal((state) => state.setIsOpen);
  const { data: contract, isLoading } = trpc.w.contracts.contract.useQuery(
    id ?? '',
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!contract) {
    return null;
  }

  if (contract.status !== ContractStatusEnum.enum.EXECUTED) {
    return (
      <section aria-labelledby="contract-status">
        <div className="mb-5 bg-white shadow sm:rounded-lg">
          <div className="flex flex-col items-start justify-start gap-2 px-4 py-5 sm:px-6">
            <h2
              id="timeline-title"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              Status: {contract.status}
            </h2>

            <p className="max-w-2xl mt-1 text-sm text-gray-500">
              We are preparing your employment contract. Once it is complete,
              you can view the contract.
            </p>
          </div>
        </div>
      </section>
    );
  }

  return (
    <section aria-labelledby="contract-status">
      <SignedContract contractId={contract.id} />

      <div className="mb-5 bg-white shadow sm:rounded-lg">
        <div className="flex flex-col items-start justify-start gap-2 px-4 py-5 sm:px-6">
          <h2
            id="timeline-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Status: {contract.status}
          </h2>

          <p className="max-w-2xl text-sm text-gray-500">
            We&apos;ve issued your compliant employment contract. Click below to
            view the contract.
          </p>

          <Button
            id="ContractStatus-Action-openSignedModal"
            text="View Contract"
            onClick={() => setSignedModalOpen(true)}
            className="w-full"
          />
        </div>
      </div>
    </section>
  );
}

export default WorkerContractStatus;
