import { zodResolver } from '@hookform/resolvers/zod';
import {
  InviteGuestClientSignType,
  inviteGuestClientSignZod,
} from 'listo/src/zodObjects/legal';
import { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { trpc } from '../../lib/trpc';
import Form from '../Form';
import { Footer } from '../Form/Footer';
import { TextInput } from '../Form/TextInput';
import { SetStep } from './types';

export function GatherSigner({
  contractId,
  onCancel,
  setStep,
}: {
  contractId: string;
  onCancel: (isOpen: boolean) => void;
  setStep: SetStep;
}) {
  const {
    handleSubmit,
    register,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<InviteGuestClientSignType>({
    resolver: zodResolver(inviteGuestClientSignZod),
  });
  const { id } = useParams();
  const utils = trpc.useContext();

  const inviteSigner = trpc.l.legal.inviteGuestClientSign.useMutation({
    onSuccess: () => {
      utils.u.workers.worker.invalidate(id).catch(() => {});
      setStep('emailSent');
    },
  });

  const onSubmit: SubmitHandler<InviteGuestClientSignType> = (data) => {
    inviteSigner.mutate({
      email: getValues('email'),
      contractId: data.contractId,
    });
  };

  useEffect(() => {
    setValue('contractId', contractId);
  }, [contractId, setValue]);

  return (
    <div className="flex items-center justify-center h-full bg-gray-100">
      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden w-full sm:p-6 sm:w-96">
        <h1 className="text-base font-medium text-gray-900 mb-4">
          Invite Signer
        </h1>
        <Form handleSubmit={handleSubmit} onSubmit={onSubmit} noFooter>
          <>
            <TextInput
              inputProps={{ autoFocus: true }}
              cols={12}
              register={register}
              fieldName="email"
              label="Email"
              error={errors.email}
            />
            <Footer
              primaryButtonText="Send"
              secondaryButtonText="Cancel"
              secondaryButtonOnClick={() => onCancel(false)}
              loading={inviteSigner.isLoading}
            />
          </>
        </Form>
      </div>
    </div>
  );
}
