import { Outlet } from 'react-router-dom';
import Stepper from '../../components/Stepper';

const steps = [
  {
    name: 'Contractor',
    to: 'contractor',
  },
  {
    name: 'Job',
    to: 'job',
  },
  {
    name: 'Payment',
    to: 'payment',
  },
];

export function ContractForm() {
  return (
    <div className="px-4 sm:px-6 max-w-prose lg:mx-auto lg:px-8 my-20 self-center">
      <Stepper steps={steps} />
      <Outlet />
    </div>
  );
}
