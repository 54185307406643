import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { trpc } from '../../lib/trpc';

export function LeFakeStripeInvoice() {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  if (!id) throw new Error('no id');

  const { data: invoice, isLoading: invoiceLoading } =
    trpc.u.invoices.invoice.useQuery(id);

  const { mutate: fakePay, isLoading } = trpc.u.invoices.fakePay.useMutation({
    onSuccess: () => {
      navigate('/');
    },
  });

  if (!id) {
    return <div>no id</div>;
  }
  if (invoiceLoading) return <div>loading</div>;
  if (!invoice) return <div>no invoice</div>;

  if (invoice.stripeInvoiceStatus === 'paid') {
    return (
      <div className="flex flex-col items-center justify-center min-h-screen py-2">
        <h1>{id}</h1>
        <p>invoice is already paid</p>
        <Button className="mt-4" text="Go Home" onClick={() => navigate('/')} />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center min-h-screen py-2">
      <h1>{id}</h1>
      <p>
        this is a fake stripe invoice. in production stripe will handle this and
        we receive a webhook when the invoice is paid.
      </p>
      <Button
        loading={isLoading}
        className="mt-4"
        text="Fake Pay"
        onClick={() =>
          fakePay({
            invoiceId: id,
          })
        }
      />
    </div>
  );
}
