import { WorkerAcceptContract } from 'listo/src/zodObjects/worker';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface AcceptContractForm {
  setFields: (fields: Partial<WorkerAcceptContract>) => void;
  fields: undefined | Partial<WorkerAcceptContract>;
  resetForm: () => void;
}

export const useAcceptContractForm = create<AcceptContractForm>()(
  persist(
    (set) => ({
      fields: undefined,
      setFields: (fields: Partial<WorkerAcceptContract>) =>
        set((state) => ({ fields: { ...state.fields, ...fields } })),
      resetForm: () => set(() => ({ fields: undefined })),
    }),
    {
      name: 'accept-contract-form',
    },
  ),
);
