import { UseFormRegister } from 'react-hook-form';
import { crazyColspanFix } from './TextInput';

function Loading() {
  return (
    <div className="h-[36px]">
      <div className="w-full">
        <div className="animate-pulse flex space-x-4">
          <div className="flex-1 space-y-6 py-1">
            <div className="h-2 bg-slate-200 rounded" />
          </div>
        </div>
      </div>
    </div>
  );
}

interface Option {
  label: string;
  value: string;
}

function selectDefault({
  defaultOption,
  defaultValue,
}: {
  defaultOption?: Option;
  defaultValue?: string;
}) {
  if (defaultValue) return defaultValue;
  if (defaultOption) return defaultOption.value;
  return undefined;
}

function Data({
  fieldName,
  register,
  options,
  defaultOption,
  defaultValue,
  disabled,
}: {
  defaultOption?: Option;
  fieldName: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  options: Option[];
  defaultValue?: string;
  disabled: boolean;
}) {
  return (
    <select
      disabled={disabled}
      id={fieldName}
      defaultValue={selectDefault({ defaultValue, defaultOption })}
      {...register(fieldName)}
      className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
    >
      {defaultOption && (
        <option disabled hidden value={defaultOption.value}>
          {defaultOption.label}
        </option>
      )}
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
}

function Error({ field, message }: { field: string; message: string }) {
  return (
    <p className="mt-2 text-sm text-red-600" id={`${field}-error`}>
      {message}
    </p>
  );
}

export function SelectInput({
  cols,
  register,
  fieldName,
  label,
  prompt,
  error,
  loading = false,
  options,
  defaultValue,
  defaultOption,
  disabled = false,
}: {
  cols: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  fieldName: string;
  label: string;
  prompt?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
  loading?: boolean;
  options: { label: string; value: string }[];
  defaultValue?: string;
  defaultOption?: { label: string; value: string } | undefined;
  disabled?: boolean;
}) {
  return (
    <div className={crazyColspanFix(cols)}>
      <label
        htmlFor="country"
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      {prompt ? <p className="mt-1 text-sm text-gray-500">{prompt}</p> : null}
      <div className="mt-1">
        {!loading ? (
          <Data
            defaultValue={defaultValue}
            defaultOption={defaultOption}
            fieldName={fieldName}
            register={register}
            options={options}
            disabled={disabled}
          />
        ) : (
          <Loading />
        )}

        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/prefer-optional-chain */}
        {error && error.message ? (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
          <Error field={fieldName} message={error.message} />
        ) : null}
      </div>
    </div>
  );
}
