import WorkerMonthOverMonthReport from '../../components/HoursTracking/WorkerMonthOverMonthReport';
import TopBar from '../../components/TopBar';
import TimeSheet from '../TimeSheet';

export function Timecards() {
  return (
    <div className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Time cards" />
      <WorkerMonthOverMonthReport />
      <TimeSheet />
    </div>
  );
}
