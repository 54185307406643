import { ReactElement } from 'react';
import { SubmitHandler } from 'react-hook-form';
import { Footer, FooterProps } from './Footer';

export function Form({
  children,
  handleSubmit,
  onSubmit,
  primaryButtonText,
  primaryButtonOnClick,
  secondaryButtonText,
  secondaryButtonOnClick,
  noFooter,
}: {
  children: ReactElement;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSubmit: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSubmit: SubmitHandler<any>;
  noFooter?: boolean;
} & Partial<FooterProps>) {
  return (
    <form
      className="space-y-8 divide-y divide-gray-200"
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
      onSubmit={handleSubmit(onSubmit)}
    >
      {children}
      {/* the footer should be removed from this component and explicitly included */}
      {noFooter ? null : (
        <Footer
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          primaryButtonText={primaryButtonText!}
          primaryButtonOnClick={primaryButtonOnClick}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          secondaryButtonText={secondaryButtonText!}
          secondaryButtonOnClick={secondaryButtonOnClick as () => void}
        />
      )}
    </form>
  );
}
