import { zodResolver } from '@hookform/resolvers/zod';
import { countries } from 'listo/src/countries';
import {
  UpdatePersonalInfoType,
  updatePersonalInfoZod,
  UpdateWorkerProfileType,
} from 'listo/src/zodObjects/workerProfile';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { SelectInput } from '../../components/Form/SelectInput';
import Loader from '../../components/Loader';
import { ProfilePictureUploader } from '../../components/ProfilePictureUploader';
import { trpc } from '../../lib/trpc';
import { useAuth } from '../../hooks/useAuth';

function ProfilePicture() {
  const { claims } = useAuth();
  const { data, refetch } = trpc.w.workers.profile.useQuery();
  const setNotification = useNotification((state) => state.setNotification);

  const workerProfileId = claims?.workerProfileId ?? '';
  const workerProfileFullName = data?.fullName ?? '';
  const pictureUrl = data?.workerProfileUpload?.url ?? '';

  const { mutate: uploadProfilePicture } =
    trpc.w.workers.addWorkerProfilePicture.useMutation({
      onSuccess: async () => {
        setNotification({
          type: 'success',
          title: 'Profile update',
          message: 'Profile picture updated',
        });

        await refetch();
      },

      onError: () => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Something went wrong',
        });
      },
    });

  const uploadPicture = (id: string) =>
    uploadProfilePicture({ workerProfileId, workerProfileUploadId: id });

  return (
    <ProfilePictureUploader
      fullName={workerProfileFullName}
      pictureUrl={pictureUrl}
      uploadPicture={uploadPicture}
    />
  );
}

export function WorkerProfile() {
  const { data: profile, isLoading, error } = trpc.w.workers.profile.useQuery();
  const setNotification = useNotification((state) => state.setNotification);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UpdatePersonalInfoType>({
    resolver: zodResolver(updatePersonalInfoZod),
  });

  const utils = trpc.useContext();

  const { mutate: updateWorkerProfile, isLoading: updateLoading } =
    trpc.w.workers.updateProfile.useMutation({
      onSuccess: () => {
        utils.w.workers.profile.invalidate().catch(() => {});
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Profile information updated',
        });
      },
    });

  const onSubmit: SubmitHandler<UpdateWorkerProfileType> = (data) => {
    updateWorkerProfile(data);
  };

  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <div className="pt-6">
      <div>
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Profile Information
        </h3>

        <p className="mt-1 text-sm text-gray-500">
          Update your personal information. This information will be used to
          populate contract agreements.
        </p>
      </div>

      <div className="mt-8">
        <ProfilePicture />
      </div>

      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <Input
              inputType="text"
              label="Full Name"
              inputProps={{
                defaultValue: profile.fullName ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'fullName',
                errors,
              }}
            />
          </div>
          <div className="sm:col-span-3">
            <Input
              inputType="text"
              label="Phone Number"
              inputProps={{
                defaultValue: profile.phone ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'phone',
                errors,
              }}
            />
          </div>

          <div className="sm:col-span-4">
            <Input
              inputType="text"
              label="Address 1"
              inputProps={{
                defaultValue: profile.address1 ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'address1',
                errors,
              }}
            />
          </div>

          <div className="sm:col-span-2">
            <Input
              inputType="text"
              label="Address 2"
              inputProps={{
                defaultValue: profile.address2 ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'address2',
                errors,
              }}
            />
          </div>

          <div className="sm:col-span-2">
            <Input
              inputType="text"
              label="City"
              inputProps={{
                defaultValue: profile.city ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'city',
                errors,
              }}
            />
          </div>

          <div className="sm:col-span-2">
            <Input
              inputType="text"
              label="State / Providence"
              inputProps={{
                defaultValue: profile.zoneCode ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'zoneCode',
                errors,
              }}
            />
          </div>

          <div className="sm:col-span-2">
            <Input
              inputType="text"
              label="Postal Code"
              inputProps={{
                defaultValue: profile.postalCode ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'postalCode',
                errors,
              }}
            />
          </div>

          <SelectInput
            cols={2}
            register={register}
            fieldName="isoCountryCode"
            label="Country"
            error={errors.isoCountryCode}
            defaultValue={profile.isoCountryCode ?? ''}
            defaultOption={{ label: 'Select', value: '' } as const}
            options={countries.map((country) => ({
              label: country.name,
              value: country.alpha2,
            }))}
          />
        </div>

        <div className="flex justify-end mt-4">
          <Button type="submit" text="Update" loading={updateLoading} />
        </div>
      </form>
    </div>
  );
}
