import { createColumnHelper } from '@tanstack/react-table';
import { formatCentsToDollars } from 'listo/src/utils/currency';
import EmptyState from '../../components/EmptyState';
import RadixAvatar from '../../components/RadixAvatar';
import TableLoader from '../../components/TableLoader';
import { RouterOutput, trpc } from '../../lib/trpc';
import { StackedList } from '../Team/StackedList';

type DistributionType = RouterOutput['u']['distributions']['listInfo'][0];

const columnHelper = createColumnHelper<DistributionType>();

const columns = [
  columnHelper.display({
    id: 'fullName',
    cell: (info) => (
      <div className="flex items-center">
        <RadixAvatar
          fallbackString={info.row.original.workerProfile.fullName ?? ''}
        />
        <span className="ml-2">{info.row.original.workerProfile.fullName}</span>
      </div>
    ),
  }),

  columnHelper.accessor(
    ({ amountInCents, currency }) => [amountInCents, currency],
    {
      id: 'amountInCents',
      cell: (info) => {
        const [amountInCents, currency] = info.getValue();
        return (
          <div className="flex justify-end">
            <span className="ml-2">
              {formatCentsToDollars(
                amountInCents as number,
                currency as string,
              )}
            </span>
          </div>
        );
      },

      header: () => null,
      footer: (info) => info.column.id,
    },
  ),

  //   columnHelper.accessor((row) => row.formattedAmount, {
  //     id: 'amountInDollars',
  //     cell: (info) => (
  //       // <ChevronCell value={info.getValue()} />
  //       <div className="flex justify-end">
  //         <span className="text-gray-500">{info.getValue()}</span>
  //         <ChevronRightIcon
  //           className="h-5 w-5 text-gray-400 ml-5"
  //           aria-hidden="true"
  //         />
  //       </div>
  //     ),
  //     header: () => null,
  //     footer: (info) => info.column.id,
  //   }),
];

function PaymentRow({
  distribution,
}: {
  distribution: RouterOutput['u']['distributions']['listInfo'][0];
}) {
  return (
    <StackedList
      columns={columns}
      isLoading={false}
      error={null}
      data={[distribution]}
    />
  );
}

export default function PaymentTable() {
  const {
    data: distributions,
    isLoading,
    error,
  } = trpc.u.distributions.listInfo.useQuery();

  return (
    <>
      {distributions !== undefined && distributions.length === 0 ? (
        <EmptyState entity="Contract" message="No Invoices" />
      ) : null}

      <div className="bg-white shadow overflow-hidden  mt-8">
        <ul className="divide-y divide-gray-200">
          {error ? <div> error </div> : null}
          {!distributions && isLoading ? <TableLoader /> : null}

          {distributions
            ? distributions.map((distribution) => (
                <PaymentRow key={distribution.id} distribution={distribution} />
              ))
            : null}
        </ul>
      </div>
    </>
  );
}
