import { z } from 'zod';

export const recruitInfoStepZod = z.object({
  jobTitle: z.string(),
  seniorityLevel: z.string(),
  scopeOfWork: z.string(),
  fieldOfExpertise: z.string(),
  countryOfOrigin: z.string(),
  experience: z.string(),
  educationLevel: z.string(),
});

export const paymentStepZod = z.object({
  paymentCurrency: z
    .string()
    .min(1, 'Select the currency the contract will be paid in'),
  minSalary: z.string(),
  maxSalary: z.string(),
});

export const recruitForm = recruitInfoStepZod.merge(paymentStepZod);

export type RecruitFormType = z.infer<typeof recruitForm>;
export type PaymentStepType = z.infer<typeof paymentStepZod>;
export type RecruitInfoStepType = z.infer<typeof recruitInfoStepZod>;
