import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { loadStripe } from '@stripe/stripe-js';
import { classNames } from 'listo/src/utils/strings';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import RadixAvatar from '../../components/RadixAvatar';
import { useAuth } from '../../hooks/useAuth';
import { STRIPE_PUBLISHABLE_KEY } from '../../lib/constants';
import { trpc } from '../../lib/trpc';

interface ExpandedUsBankAccount {
  last4: string;
  financial_connections_account: string;
  bank_name: string;
  routing_number: string;
}

// https://stripe.com/docs/payments/ach-debit/set-up-payment?platform=web
function StripeConnectFlow({
  setStripeConnectOpen,
  stripeCustomerId,
}: {
  setStripeConnectOpen: (closed: boolean) => void;
  stripeCustomerId: string;
}) {
  const claims = useAuth((state) => state.claims);
  const [clientSecret, setClientSecret] = useState<string | null>(null);
  const utils = trpc.useContext();

  trpc.u.paymentMethods.connectBankAccountIntent.useQuery(
    { stripeCustomerId },
    {
      onSuccess: (data) => {
        setClientSecret(data.client_secret);
      },
      trpc: {},
    },
  );

  const { mutate } = trpc.u.paymentMethods.savePaymentMethod.useMutation({
    onSuccess: () => {
      utils.u.paymentMethods.list.invalidate().catch(() => {});
      setClientSecret(null);
      setStripeConnectOpen(false);
    },
  });

  useEffect(() => {
    if (!clientSecret) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    loadStripe(STRIPE_PUBLISHABLE_KEY)
      .then((stripe) => {
        if (!stripe) return;

        stripe
          .collectBankAccountForSetup({
            clientSecret,
            params: {
              payment_method_type: 'us_bank_account',
              payment_method_data: {
                billing_details: {
                  name: claims?.clientName ?? undefined,
                  email: claims?.email ?? undefined,
                },
              },
            },
            expand: ['payment_method'],
          })
          .then(({ setupIntent, error }) => {
            if (error) {
              // eslint-disable-next-line no-console
              console.error(error.message);
              // PaymentMethod collection failed for some reason.
            } else if (setupIntent.status === 'requires_payment_method') {
              // Customer canceled the hosted verification modal. Present them with other
              // payment method type options.
              setStripeConnectOpen(false);
            } else if (setupIntent.status === 'requires_confirmation') {
              // We collected an account - possibly instantly verified, but possibly
              // manually-entered. Display payment method details and mandate text
              // to the customer and confirm the intent once they accept
              // the mandate.

              const { id: paymentMethodId, us_bank_account: bankAccount } =
                setupIntent.payment_method as unknown as {
                  id: string;
                  us_bank_account: ExpandedUsBankAccount;
                };

              stripe
                .confirmUsBankAccountSetup(clientSecret)
                // eslint-disable-next-line @typescript-eslint/no-shadow
                .then(({ setupIntent, error }) => {
                  if (error) {
                    // eslint-disable-next-line no-console
                    console.error(error.message);
                    // The payment failed for some reason.
                  } else if (setupIntent.status === 'requires_payment_method') {
                    // Confirmation failed. Attempt again with a different payment method.
                  } else if (setupIntent.status === 'succeeded') {
                    // Confirmation succeeded! The account is now saved.
                    // Display a message to customer.
                    mutate({
                      bankName: bankAccount.bank_name,
                      stripeFinancialConnectionsAccountId:
                        bankAccount.financial_connections_account,
                      lastFour: bankAccount.last4,
                      routingNumber: bankAccount.routing_number,
                      stripePaymentMethodId: paymentMethodId,
                      verificationStatus: 'VERIFIED',
                    });
                  } else if (
                    setupIntent.next_action?.type ===
                    'verify_with_microdeposits'
                  ) {
                    mutate({
                      bankName: bankAccount.bank_name,
                      stripeFinancialConnectionsAccountId:
                        bankAccount.financial_connections_account,
                      lastFour: bankAccount.last4,
                      routingNumber: bankAccount.routing_number,
                      stripePaymentMethodId: paymentMethodId,
                      verificationStatus: 'PENDING',
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
                      verificationUrl: (setupIntent.next_action as any)
                        ?.verify_with_microdeposits?.hosted_verification_url,
                    });
                  }
                })
                .catch(() => {});
            }
          })
          .catch(() => {});
      })
      .catch(() => {});
  }, [
    claims?.clientName,
    claims?.email,
    clientSecret,
    mutate,
    setStripeConnectOpen,
  ]);

  // stripe's SDK renders everything
  return null;
}

export function PaymentMethods() {
  const { claims } = useAuth();
  const [stripeConnectOpen, setStripeConnectOpen] = useState<boolean>(false);
  const setNotification = useNotification((state) => state.setNotification);
  const utils = trpc.useContext();

  const {
    data: paymentMethods,
    isLoading,
    error,
  } = trpc.u.paymentMethods.list.useQuery();

  const { mutate } = trpc.u.paymentMethods.setDefaultBankAccount.useMutation({
    onSuccess: () => {
      utils.u.paymentMethods.list.invalidate().catch(() => {});
      setNotification({
        type: 'success',
        title: 'Success',
        message: 'Default bank account set.',
      });
    },
  });

  if (error) return <h1>error</h1>;
  if (isLoading) return <Loader />;

  return (
    <div>
      {/* <StripeConnectFlow needs to be unmounted because of the stripe.js JavaScript we don't fully control */}
      {stripeConnectOpen && claims?.stripeCustomerId ? (
        <StripeConnectFlow
          setStripeConnectOpen={setStripeConnectOpen}
          stripeCustomerId={claims.stripeCustomerId}
        />
      ) : null}
      <div className="flow-root mt-6">
        <ul className="-my-5 divide-y divide-gray-200">
          {paymentMethods.map((paymentMethod) => (
            <li key={paymentMethod.id} className="py-4">
              <div className="flex items-center space-x-4">
                <RadixAvatar fallbackString={paymentMethod.bankName} />

                <div className="flex-1 min-w-0">
                  <p className="text-sm font-medium text-gray-900 truncate">
                    {paymentMethod.bankName}
                  </p>
                  <p className="text-sm text-gray-500 truncate">
                    {paymentMethod.lastFour}
                  </p>
                </div>
                <div className="flex flex-shrink-0">
                  {paymentMethod.verificationStatus === 'PENDING' &&
                  paymentMethod.verificationUrl ? (
                    <p className="text-sm text-gray-500 mr-4">
                      <a href={paymentMethod.verificationUrl}>
                        {paymentMethod.verificationUrl}
                      </a>
                    </p>
                  ) : null}
                  <p
                    className={classNames(
                      'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
                      paymentMethod.verificationStatus === 'VERIFIED'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800',
                    )}
                  >
                    {capitalize(paymentMethod.verificationStatus)}
                  </p>
                  {paymentMethod.default ? (
                    <CheckBadgeIcon
                      className="ml-1 mr-1.5 h-5 w-5 flex-shrink-0 text-green-600 cursor-pointer"
                      aria-hidden="true"
                      aria-label="Make Default"
                      title="Make Default"
                      onClick={() => {
                        mutate({
                          paymentMethodId: paymentMethod.id,
                        });
                      }}
                    />
                  ) : (
                    <CheckBadgeIcon
                      className="ml-1 mr-1.5 h-5 w-5 flex-shrink-0 text-grey-400 cursor-pointer"
                      aria-hidden="true"
                      aria-label="Make Default"
                      title="Make Default"
                      onClick={() => {
                        mutate({
                          paymentMethodId: paymentMethod.id,
                        });
                      }}
                    />
                  )}
                </div>
                {/* <div>
                  <a
                    href="#"
                    className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Delete
                  </a>
                </div> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
      <div className="mt-6 flex justify-end">
        <Button
          text="Add Payment Method"
          onClick={() => setStripeConnectOpen(true)}
        />
      </div>
    </div>
  );
}
