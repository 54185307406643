import { z } from 'zod';

export const ExpenseReimbursementStatusEnum = z.enum([
  'PENDING',
  'APPROVED',
  'DECLINED',
  'DELETED',
]);

export const createExpenseReimbursementZod = z.object({
  clientId: z.string().min(1, 'Client is required.').uuid(),
  workerProfileId: z.string().uuid(),
  amountInCents: z.preprocess(
    (val) => (typeof val === 'string' ? parseFloat(val) : val),
    z.number().min(1),
  ),
  expenseDate: z.string().min(1, 'Expense Date is required.'),
  status: ExpenseReimbursementStatusEnum.optional(),
  description: z.string().nullish(),
  clientMessage: z.string().nullish(),
});

export type ExpenseReimbursementStatus = z.infer<
  typeof ExpenseReimbursementStatusEnum
>;
export type CreateExpenseReimbursementType = z.infer<
  typeof createExpenseReimbursementZod
>;
