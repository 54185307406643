import { CheckBadgeIcon, PencilIcon } from '@heroicons/react/24/outline';
import { formatDate } from 'listo/src/utils/dates';
import { classNames } from 'listo/src/utils/strings';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ActionPanel } from 'ui/src/components/ActionPanel';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';

import Loader from '../../components/Loader';
import SignContract from '../../components/SignContract';
import { useSigningModal } from '../../components/SignContract/hooks/useSigningModal';
import TopBar from '../../components/TopBar';
import Avatar from '../../components/Avatar';
import { RouterOutput, trpc } from '../../lib/trpc';
import Payments from './Payments';
import Paystubs from './Paystubs';
import ContractInformation from './ContractInformation';
import DocumentElem from './DocumentElem';
import WorkerContractStatus from './WorkerContractStatus';

const tabs = [
  { name: 'Contract Information', href: '#', current: true },
  { name: 'Documents', href: '#', current: false },
  { name: 'Payments', href: '#', current: false },
  // { name: 'Paystubs', href: '#', current: false },
];

function SignatureCard({
  data,
  setModal,
}: {
  data: RouterOutput['u']['contracts']['contract'];
  setModal: (value: boolean) => void;
}) {
  const navigate = useNavigate();
  const setNotification = useNotification((state) => state.setNotification);

  function handleSignContract() {
    if (data.workerProfile.taxIdNumber) {
      setModal(true);
    } else {
      navigate(`/wrkr/accept-contract/${data.id}/info`);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'You need to accept the contract first',
      });
    }
  }

  return (
    <div className="border-t border-gray-200">
      <div className="px-4 py-5 sm:px-6">
        {data.clientSignature || data.status === 'EXECUTED' ? (
          <div className="mb-4">
            <p className="flex text-sm font-medium text-gray-500">
              Issuer signature
              <CheckBadgeIcon className="w-5 h-5 text-green-500" />
            </p>
            <div className="flex ">
              <p
                style={{
                  fontFamily: "'Dancing Script', cursive",
                  fontSize: 30,
                }}
                className="flex items-center justify-center text-gray-900"
              >
                {data.clientSignature}
              </p>
            </div>
          </div>
        ) : null}
        {!data.workerSignature && data.status !== 'EXECUTED' ? (
          <div>
            <p className="text-sm font-medium text-gray-500">
              Contractor signature
            </p>
            <button
              type="button"
              className="relative block w-full p-6 mt-1 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={handleSignContract}
            >
              <div className="mx-auto h-12 w-12">
                <PencilIcon color="#9ca3af" />
              </div>

              <span className="block mt-2 text-sm font-medium text-gray-900">
                Sign Contract
              </span>
            </button>
          </div>
        ) : null}

        {data.workerSignature || data.status === 'EXECUTED' ? (
          <div className="mt-4">
            <p className="flex text-sm font-medium text-gray-500">
              Contractor signature
              <CheckBadgeIcon className="w-5 h-5 text-green-500" />
            </p>
            <div className="flex">
              <p
                style={{
                  fontFamily: "'Dancing Script', cursive",
                  fontSize: 30,
                }}
                className="flex items-center justify-center text-gray-900"
              >
                {data.workerSignature}
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}

function Tabs({
  contract,
}: {
  contract: RouterOutput['w']['contracts']['contract'];
}) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="space-y-6 lg:col-start-1 lg:col-span-2">
      <section aria-labelledby="applicant-information-title">
        <div className="bg-white shadow sm:rounded-lg">
          <div>
            <div className="sm:block ">
              <div className="border-b border-gray-200">
                <nav className="px-3 -mb-px space-x-8 sm:p-6" aria-label="Tabs">
                  {tabs.map((tab, index) => (
                    <button
                      type="button"
                      key={tab.name}
                      onClick={() => setActiveTab(index)}
                      className={classNames(
                        activeTab === index
                          ? 'border-indigo-500 text-indigo-600'
                          : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                        'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                      )}
                      aria-current={activeTab === index ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
            {activeTab === 0 && <ContractInformation contract={contract} />}
            {activeTab === 1 && (
              <DocumentElem
                document={contract.documents}
                clientId={contract.clientId}
              />
            )}
            {activeTab === 2 && <Payments contractId={contract.id} />}
            {activeTab === 3 && <Paystubs contractId={contract.id} />}
          </div>
        </div>
      </section>
    </div>
  );
}

export function MyContract() {
  const navigate = useNavigate();

  const { id } = useParams();
  const setSigningModalOpen = useSigningModal((state) => state.setIsOpen);
  const utils = trpc.useContext();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const contractQuery = trpc.w.contracts.contract.useQuery(id!);

  if (contractQuery.error) return <h1>error</h1>;
  if (contractQuery.isLoading) return <Loader />;

  const { data: contract } = contractQuery;

  const profileFullName = contract.workerProfile.fullName ?? '';
  const profilePictureUrl =
    contract.workerProfile.workerProfileUpload?.url ?? '';

  return (
    <div className="min-h-screen bg-gray-100">
      <SignContract
        signer="worker"
        contractId={contract.id}
        initialStep="document"
        onSuccess={() => {
          setSigningModalOpen(false);
          utils.w.contracts.contract.invalidate(id).catch(() => {});
        }}
      />
      <TopBar title="Contract" />
      <main className="py-10">
        {/* Page header */}
        <div className="max-w-3xl px-4 mx-auto sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <Avatar
                  name={profileFullName}
                  image={profilePictureUrl}
                  className="w-16 h-16 text-2xl rounded-full"
                />
                <span
                  className="absolute inset-0 rounded-full shadow-inner"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900">
                {contract.name}
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Contract created on{' '}
                <time dateTime="2020-08-25">
                  {formatDate(contractQuery.data.createdAt)}
                </time>
              </p>
            </div>
          </div>
        </div>
        <div>
          <div className="grid max-w-3xl grid-cols-1 gap-6 mx-auto mt-8 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="lg:col-start-3 lg:col-span-1">
              {contract.engagementType === 'CONTRACTOR' ? (
                <section aria-labelledby="timeline-title">
                  <div className="mb-5 bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="timeline-title"
                        className="text-lg font-medium leading-6 text-gray-900"
                      >
                        Signatures
                      </h2>
                      <p className="max-w-2xl mt-1 text-sm text-gray-500">
                        Sign and complete the contract
                      </p>
                    </div>

                    <SignatureCard
                      data={contract}
                      setModal={setSigningModalOpen}
                    />
                  </div>
                </section>
              ) : null}

              {contract.engagementType === 'EOR' ? (
                <WorkerContractStatus />
              ) : null}

              <ActionPanel title="Track Time" content="Track your time here">
                <Button
                  text="Log Time"
                  onClick={() => navigate('/wrkr/timecards?open=true')}
                />
              </ActionPanel>
            </div>

            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <Tabs contract={contract} />
                </div>
              </section>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
