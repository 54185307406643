/* eslint-disable jsx-a11y/no-redundant-roles */
import { InboxIcon } from '@heroicons/react/20/solid';
import { PlusIcon, UserIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

function Empty() {
  const navigate = useNavigate();

  return (
    <div className="text-center">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">No users</h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by onboarding a new user.
      </p>
      <div className="mt-6">
        <button
          onClick={() => navigate('/settings/users/new')}
          type="button"
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New User
        </button>
      </div>
    </div>
  );
}

export function Users() {
  const navigate = useNavigate();
  const { data, isLoading } = trpc.u.users.list.useQuery();

  if (isLoading) return <Loader />;
  if (!data) return <Empty />;

  return (
    <div className="mt-12 bg-white shadow overflow-hidden sm:rounded-md">
      <ul role="list" className="divide-y divide-gray-200">
        {data.map((user) => (
          <li key={user.id}>
            <a href="#" className="block hover:bg-gray-50">
              <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    <UserIcon />
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-indigo-600 truncate">
                        {user.fullName}
                      </p>
                      <p className="mt-2 flex items-center text-sm text-gray-500">
                        <InboxIcon
                          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                        <span className="truncate">
                          {user.credential.email}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </li>
        ))}
      </ul>
      <main className="flex-1">
        <div className="border-b border-gray-200 px-4 py-4 sm:flex sm:items-center sm:justify-between sm:px-6 lg:px-8">
          <div className="flex-1 min-w-0" />
          <div className="mt-4 flex sm:mt-0 sm:ml-4">
            <Button
              onClick={() => navigate('/add-user')}
              text="Add User"
              className="ml-3"
            />
          </div>
        </div>
      </main>
    </div>
  );
}
