import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { formatCentsToDollars } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { classNames, titleCase } from 'listo/src/utils/strings';
import { RouterOutput } from '../../lib/trpc';

type LineItem = RouterOutput['u']['reporting']['lineItems']['lineItems'][0];
type LineItems = LineItem[];

const columnHelper = createColumnHelper<LineItem>();

const columns = [
  columnHelper.accessor((row) => row.worker.workerProfile.fullName, {
    id: 'Name',
    cell: (info) => titleCase(info.getValue()),
  }),
  columnHelper.accessor((row) => row.worker.workerProfile.credential.email, {
    id: 'Email',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor((row) => row.description, {
    id: 'Description',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor((row) => row.isoCountryCode, {
    id: 'Country',
    cell: (info) => info.getValue(),
  }),
  columnHelper.accessor(
    (row) => [row.amountInCents, row.billAmountInCents, row.billingCurrency],
    {
      id: 'Amount',
      cell: (info) => {
        const [amountInCents, billAmountInCents, billingCurrency] =
          info.getValue();
        const usAmount = formatCentsToDollars(amountInCents as number, 'USD');
        if (billingCurrency === 'USD') return usAmount;
        const billAmt = formatCentsToDollars(
          billAmountInCents as number,
          billingCurrency as string,
        );
        return `${billAmt} → ${usAmount}`;
      },
    },
  ),
  columnHelper.accessor('invoiceDate', {
    header: () => 'Invoice Date',
    cell: (info) => formatDate(info.getValue()),
  }),
];

export function LineItemsTable({ lineItems }: { lineItems: LineItems }) {
  const table = useReactTable({
    data: lineItems,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div>
      <table className="min-w-full divide-y divide-gray-300">
        <thead className="bg-gray-50">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className={classNames(
                    'px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500',
                  )}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id}>
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 max-w-xs overflow-hidden overflow-ellipsis"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
        <tfoot>
          {table.getFooterGroups().map((footerGroup) => (
            <tr key={footerGroup.id}>
              {footerGroup.headers.map((header) => (
                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.footer,
                        header.getContext(),
                      )}
                </th>
              ))}
            </tr>
          ))}
        </tfoot>
      </table>
    </div>
  );
}
