import { HomeIcon } from '@heroicons/react/20/solid';
import { Bars3Icon } from '@heroicons/react/24/outline';
import { classNames } from 'listo/src/utils/strings';
import { Link, useNavigate } from 'react-router-dom';
import useSidebar from '../../hooks/useSidebar';

interface BreadcrumbProps {
  breadcrumbs: { name: string; href: string; current: boolean }[];
}
interface TopBarProps extends Partial<BreadcrumbProps> {
  title: string;
  buttonText?: string;
  buttonRoute?: string;
}

function Breadcrumbs({ breadcrumbs }: BreadcrumbProps) {
  return (
    <div className="mx-auto max-w-7xl px-4 sm:px-6">
      <div className="border-t border-gray-200 py-3">
        <nav className="flex" aria-label="Breadcrumb">
          {/* <div className="flex sm:hidden">
        <a
          href="#"
          className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700"
        >
          <ArrowSmLeftIcon
            className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-600"
            aria-hidden="true"
          />
          <span>Back to Applicants</span>
        </a>
      </div> */}
          {/* hidden sm:block */}
          <div className="block">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <Link to="/" className="text-gray-400 hover:text-gray-500">
                    <HomeIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              {breadcrumbs.map((item) => (
                <li key={item.name}>
                  <div className="flex items-center">
                    <svg
                      className="h-5 w-5 flex-shrink-0 text-gray-300"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      aria-hidden="true"
                    >
                      <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z" />
                    </svg>
                    <Link
                      to={item.href}
                      className={classNames(
                        'ml-4 text-sm font-medium',
                        item.current
                          ? 'text-indigo-500 hover:text-indigo-700 '
                          : 'text-gray-500 hover:text-gray-700',
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Link>
                  </div>
                </li>
              ))}
            </ol>
          </div>
        </nav>
      </div>
    </div>
  );
}
export function TopBar({
  title,
  buttonText,
  buttonRoute,
  breadcrumbs,
}: TopBarProps) {
  const { sidebarIsOpen, setSidebarIsOpen } = useSidebar();
  const navigate = useNavigate();

  return (
    <div className={`${title ? 'bg-white shadow' : 'bg-gray-50'}`}>
      <div className="px-4 sm:px-6 lg:max-w-6xl lg:mx-auto lg:px-8">
        <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
          <div className="flex-1 min-w-0">
            <div className="flex items-center">
              <div>
                <div className="flex items-center">
                  <button
                    id="open-sidebar"
                    type="button"
                    className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 lg:hidden"
                    onClick={() => setSidebarIsOpen(!sidebarIsOpen)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                    {title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
          {buttonText && buttonRoute && (
            <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4">
              <button
                onClick={() => {
                  navigate(buttonRoute);
                }}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {buttonText}
              </button>
            </div>
          )}
        </div>
      </div>
      {breadcrumbs ? <Breadcrumbs breadcrumbs={breadcrumbs} /> : null}
    </div>
  );
}
