interface AvatarProps {
  image?: string;
  name: string;
  className?: string;
}

export function getLetters(name: string | undefined) {
  if (!name) return '';

  const splited = name
    .split(' ')
    .map((item) => item.charAt(0).toLocaleUpperCase());

  return splited.slice(0, 2).join('').replace(/-/g, '');
}

export function Avatar({ image, name, className }: AvatarProps) {
  const hasImage = !!(image && !image.includes('gravatar'));

  const fallback = getLetters(name);

  if (hasImage) {
    return (
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      <img src={image} className={`${className} rounded-full`} alt={name} />
    );
  }

  return (
    <div
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      className={`rounded-full text-indigo-600 bg-gray-100 border border-gray-200 flex items-center justify-center ${className}`}
    >
      <span>{fallback}</span>
    </div>
  );
}
