import { ReactElement } from 'react';

export function Section({
  children,
  heading,
  subheading,
}: {
  children: ReactElement;
  heading?: string;
  subheading?: string;
}) {
  return (
    <div className="pt-8">
      {heading ? (
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {heading}
          </h3>
          {subheading ? (
            <p className="mt-1 text-sm text-gray-500">{subheading}</p>
          ) : null}
        </div>
      ) : null}
      <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 col-start-1">
        {children}
      </div>
    </div>
  );
}
