import Logo from '../../public/images/circle-logo.svg';
import { DENIED_LOCAL_STORAGE_ERROR_MESSAGE } from '../../lib/constants';

interface FallbackProps {
  title?: string;
  message?: string;
  subMessage?: string;
  localStorageIsDenied?: boolean;
}

export function Fallback({
  title = 'Error',
  message = 'Something went wrong.',
  subMessage = 'Sorry, something went wrong on our end. Contact us using the chat widget if the issue persists.',
  localStorageIsDenied,
}: FallbackProps) {
  const localStorageDeniedSubMessage =
    'Local Storage or Cookies are blocked or not supported by your browser. You must enable it to use this app.';
  return (
    <div className="min-h-screen pt-16 pb-12 flex flex-col bg-white">
      <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8 mb-24">
        <div className="flex-shrink-0 flex justify-center">
          <a href="/" className="inline-flex">
            <span className="sr-only">Workflow</span>
            <img className="h-12 w-auto" src={Logo} alt="" />
          </a>
        </div>
        <div className="py-16">
          <div className="text-center">
            <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide">
              {title}
            </p>
            {localStorageIsDenied ? (
              <h1 className="mt-16 font-light text-gray-900 tracking-tight text-3xl max-w-xl leading-relaxed mx-auto">
                {localStorageDeniedSubMessage}
              </h1>
            ) : (
              <>
                <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                  {message}
                </h1>
                <p className="mt-2 text-base text-gray-500">{subMessage}</p>

                <div className="mt-6">
                  <button
                    type="button"
                    onClick={() => {
                      window.location.href = '/';
                    }}
                    className="text-base font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    Refresh<span aria-hidden="true"> &rarr;</span>
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </main>
      <footer className="flex-shrink-0 max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
        <nav className="flex justify-center space-x-4">
          <a
            href="mailto:info@listoglobal.com"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Contact Support
          </a>
          {/* <span
            className="inline-block border-l border-gray-300"
            aria-hidden="true"
          />
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Status
          </a>
          <span
            className="inline-block border-l border-gray-300"
            aria-hidden="true"
          />
          <a
            href="#"
            className="text-sm font-medium text-gray-500 hover:text-gray-600"
          >
            Twitter
          </a> */}
        </nav>
      </footer>
    </div>
  );
}

interface IRenderFallBack {
  error: Error;
}

export const renderFallBack = ({ error }: IRenderFallBack) => (
  <Fallback
    localStorageIsDenied={error.message === DENIED_LOCAL_STORAGE_ERROR_MESSAGE}
  />
);
