import { zodResolver } from '@hookform/resolvers/zod';
import { currencySelectOptions } from 'listo/src/countries';
import { PaymentStepType, paymentStepZod } from 'listo/src/zodObjects/recruits';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Form from '../../components/Form';
import { Header } from '../../components/Form/Header';
import { NumberInput } from '../../components/Form/NumberInput';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { trpc } from '../../lib/trpc';
import { useCreateForm } from './hooks/useCreateForm';

export function Payment() {
  const navigate = useNavigate();
  const fields = useCreateForm((s) => s.fields);
  const resetForm = useCreateForm((s) => s.resetForm);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<PaymentStepType>({ resolver: zodResolver(paymentStepZod) });

  const utils = trpc.useContext();

  const { mutate } = trpc.u.recruits.create.useMutation({
    onSuccess: () => {
      utils.u.recruits.list.invalidate().catch(() => {});
      resetForm();
      navigate(`/recruiting`);
    },
  });

  const onSubmit: SubmitHandler<PaymentStepType> = (data) => {
    mutate({
      jobTitle: fields?.jobTitle as string,
      seniorityLevel: fields?.seniorityLevel as string,
      scopeOfWork: fields?.scopeOfWork as string,
      fieldOfExpertise: fields?.fieldOfExpertise as string,
      countryOfOrigin: fields?.countryOfOrigin as string,
      experience: fields?.experience as string,
      educationLevel: fields?.educationLevel as string,
      ...data,
    });
  };

  return (
    <Form
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-explicit-any
      handleSubmit={handleSubmit as any}
      onSubmit={onSubmit}
      primaryButtonText="Submit"
      secondaryButtonText="Back"
      secondaryButtonOnClick={() => navigate(`/create-recruit/info`)}
    >
      <>
        <Header
          heading="Payment Details"
          subheading="Provide information regarding the payment terms so we can help pay your contract on time."
        />
        <Section>
          <>
            <SelectInput
              cols={6}
              register={register}
              fieldName="paymentCurrency"
              label="Payment Currency"
              error={errors.paymentCurrency}
              defaultValue={(fields?.paymentCurrency as string) || 'USD'}
              options={currencySelectOptions}
            />
            <NumberInput
              cols={3}
              register={register}
              fieldName="minSalary"
              label="Minimum Salary"
              error={errors.minSalary}
              defaultValue={(fields?.minSalary as number) || 0}
            />
            <NumberInput
              cols={3}
              register={register}
              fieldName="maxSalary"
              label="Maximum Salary"
              error={errors.maxSalary}
              defaultValue={(fields?.maxSalary as number) || 0}
            />
          </>
        </Section>
      </>
    </Form>
  );
}
