import { trpc } from '../../lib/trpc';
import { PayPeriodCard } from './PayPeriodCard';

export function PayData() {
  const {
    data: payPeriod,
    error,
    isLoading,
  } = trpc.u.payPeriods.dashboard.useQuery();

  if (isLoading) {
    return null;
  }
  if (error) return <div>{error.message}</div>;
  if (payPeriod === null) return null;

  return <PayPeriodCard payPeriod={payPeriod} />;
}
