import { zodResolver } from '@hookform/resolvers/zod';
import {
  WorkerAcceptContract,
  workerAddressFields,
  workerIdentityFields,
  workerTaxFields,
  WorkerTaxFields,
} from 'listo/src/zodObjects/worker';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import Form from '../../components/Form';
import { Footer } from '../../components/Form/Footer';
import { Section } from '../../components/Form/Section';
import { TextInput } from '../../components/Form/TextInput';
import { trpc } from '../../lib/trpc';
import { useAcceptContractForm } from './hooks/useAcceptContractForm';

export function Tax() {
  const navigate = useNavigate();
  const { id } = useParams();
  const fields = useAcceptContractForm((s) => s.fields);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<WorkerTaxFields>({
    resolver: zodResolver(workerTaxFields),
  });

  if (!id) throw new Error('Contract ID is required');

  const acceptContract = trpc.w.contracts.accept.useMutation({
    onSuccess: (data) => {
      navigate(
        `/wrkr/my-contracts/${id}?${
          data.contract.engagementType === 'EOR' ? '' : 'initialOpen=true'
        } `,
      );
    },
  });

  const onSubmit: SubmitHandler<WorkerTaxFields> = (data) => {
    const personalInfoStep = workerIdentityFields.safeParse(fields);

    if (!personalInfoStep.success) {
      navigate(`/wrkr/accept-contract/${id}/info?autoValidate=true`);
    }

    const addressStep = workerAddressFields.safeParse(fields);

    if (!addressStep.success) {
      navigate(`/wrkr/accept-contract/${id}/address?autoValidate=true`);
    }

    acceptContract.mutate({
      ...fields,
      ...data,
      contractId: id,
    } as WorkerAcceptContract);
  };

  return (
    <Form handleSubmit={handleSubmit} onSubmit={onSubmit} noFooter>
      <>
        <Section heading="Tax Information">
          <TextInput
            cols={6}
            register={register}
            defaultValue={fields?.taxIdNumber}
            fieldName="taxIdNumber"
            label="Tax ID Number"
            error={errors.taxIdNumber}
          />
        </Section>
        <Footer
          primaryButtonText="Next"
          secondaryButtonText="Back"
          secondaryButtonOnClick={() =>
            navigate(`/wrkr/accept-contract/${id}/address`)
          }
        />
      </>
    </Form>
  );
}
