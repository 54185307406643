import {
  CalendarIcon,
  ChartBarSquareIcon,
  CogIcon,
  CalendarDaysIcon,
  DocumentMagnifyingGlassIcon,
  HomeIcon,
  UserGroupIcon,
  UserPlusIcon,
  BanknotesIcon,
  CreditCardIcon,
} from '@heroicons/react/24/outline';
import { ErrorBoundary } from '@sentry/react';
import { WORKER_PORTAL_MAGIC_STRING_SRY } from 'listo/src/constants';
import { useQueryClient } from '@tanstack/react-query';
import { titleCase } from 'listo/src/utils/strings';
import posthog from 'posthog-js';
import { useCallback, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { NotificationRegion } from 'ui/src/components/Notification';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { useAuth } from '../../hooks/useAuth';
import {
  flagsArray,
  FlagsKeys,
  SetFlags,
  useFeatureFlags,
} from '../../hooks/useFeatureFlags';
import { SidebarProvider } from '../../hooks/useSidebar';
import { ADMIN_URL } from '../../lib/constants';
import Fallback from '../Fallback';
import Sidebar from '../Sidebar';
import { Accounts } from '../Sidebar/AccountSelect';
import { RequireAuth } from '../Utility/RequireAuth';

const navigation = [
  { name: 'Dashboard', href: '/', icon: HomeIcon },
  { name: 'New Contract', href: '/create-contract', icon: UserPlusIcon },
  {
    name: 'Team',
    href: '/team',
    icon: UserGroupIcon,
  },
  {
    name: 'Invoices',
    href: '/invoices',
    icon: BanknotesIcon,
  },
  // {
  //   name: 'PayPeriod',
  //   href: '/payPeriods',
  //   icon: CurrencyDollarIcon,
  // },
  {
    name: 'Time Tracking',
    href: '/time-tracking',
    icon: CalendarIcon,
  },
  {
    name: 'Time Off',
    href: '/time-off',
    icon: CalendarDaysIcon,
  },
  {
    name: 'Reports',
    href: '/reports/line-items/bar-chart',
    icon: ChartBarSquareIcon,
  },
  {
    name: 'Recruiting',
    href: '/recruiting',
    icon: DocumentMagnifyingGlassIcon,
  },
  {
    name: 'Reimbursements',
    href: '/reimbursements',
    icon: CreditCardIcon,
  },
  {
    name: 'Payments',
    href: '/payments',
    icon: CreditCardIcon,
  },
];

const secondaryNavigation = [
  { name: 'Settings', href: '/settings/general', icon: CogIcon },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getNavigation(flags: SetFlags | undefined) {
  const baseFlags = navigation;

  return [
    ...baseFlags,
    // ...(flags && flags.recruiting
    //   ? [
    //       {
    //         name: 'Recruiting',
    //         href: '/recruiting',
    //         icon: DocumentMagnifyingGlassCircleIcon,
    //       },
    //     ]
    //   : []),
  ];
}

function Shell() {
  const claims = useAuth((state) => state.claims);
  const clear = useAuth((state) => state.clear);
  const [accounts, setAccounts] = useState<Accounts>([]);
  const location = useLocation();
  const setFlags = useFeatureFlags((state) => state.setFlags);
  const setFlagsLoaded = useFeatureFlags((state) => state.setFlagsLoaded);
  const flags = useFeatureFlags((state) => state.flags);
  const queryClient = useQueryClient();
  const { update, shutdown } = useIntercom();

  useNotification();

  const logout = useCallback(() => {
    posthog.reset();
    shutdown();
    queryClient.clear();
    clear();
  }, [shutdown, queryClient, clear]);

  useEffect(() => {
    if (!claims) return;

    posthog.onFeatureFlags(() => {
      flagsArray.forEach((flag) => {
        if (posthog.isFeatureEnabled(flag)) {
          setFlags({ [flag]: true } as Record<Partial<FlagsKeys>, boolean>);
        } else {
          setFlags({ [flag]: false } as Record<Partial<FlagsKeys>, boolean>);
        }
      });
      setFlagsLoaded(true);
    });
  }, [claims, setFlags, setFlagsLoaded]);

  useEffect(() => {
    if (!claims) return;

    update({
      name: titleCase(claims.fullName),
      email: claims.email,
      userId: claims.credentialId,
    });
    update();
  }, [claims, update]);

  useEffect(() => {
    if (!claims) return;

    const hasContractorPortal = claims.workers.length > 0;

    const usrs = claims.users.map((user) => ({
      clientName: user.clientName,
      clientId: user.clientId,
      workerOrUserId: user.id,
    }));

    setAccounts([
      ...usrs,
      ...(hasContractorPortal
        ? [
            {
              clientName: 'Worker Portal',
              clientId: 'worker',
              workerOrUserId: WORKER_PORTAL_MAGIC_STRING_SRY,
            },
          ]
        : []),
    ]);
  }, [claims]);

  return (
    <div className="min-h-full">
      {claims?.impersonated && (
        <div className="relative bg-listo-400">
          <div className="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
            <div className="pr-16 sm:px-16 sm:text-center">
              <p className="font-medium text-gray-600">
                <span className="hidden md:inline">
                  You are currently impersonating{' '}
                </span>
                <span className="block sm:ml-2 sm:inline-block">
                  <a
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                    href={ADMIN_URL}
                    onClick={(e) => {
                      e.preventDefault();
                      logout();
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      window.location.href = ADMIN_URL;
                    }}
                    className="font-bold text-gray-600 underline"
                  >
                    Stop Impersonating
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                  &nbsp;{claims.users.length > 0 ? 'Client' : 'Contracts'}
                </span>
              </p>
            </div>
          </div>
        </div>
      )}
      <SidebarProvider>
        <Sidebar
          navigation={getNavigation(flags)}
          secondaryNavigation={secondaryNavigation}
          accounts={accounts}
        />
        <div className="min-h-full lg:pl-64 flex flex-col flex-1">
          <ErrorBoundary key={location.pathname} fallback={<Fallback />}>
            <Outlet />
          </ErrorBoundary>
        </div>
      </SidebarProvider>
      <NotificationRegion />
    </div>
  );
}

export function UserAppShell() {
  return (
    <RequireAuth requiredAuthType="user">
      <Shell />
    </RequireAuth>
  );
}
