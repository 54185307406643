import { BrowserRouter as Router } from 'react-router-dom';
import useThrowLocalStorageDenied from '../hooks/useThrowLocalStorageDenied';
import { ListoRoutes } from './ListoRoutes';

export function AppRouter() {
  useThrowLocalStorageDenied();
  return (
    <Router>
      <ListoRoutes />
    </Router>
  );
}
