import { FieldError, Merge, UseFormRegister } from 'react-hook-form';
import { classNames } from 'listo/src/utils/strings';

// no clue: https://stackoverflow.com/questions/68953892/tailwind-grid-cols-not-working-in-production-with-next
export function crazyColspanFix(cols: number) {
  return classNames(
    cols === 1 ? 'col-span-1' : '',
    cols === 2 ? 'col-span-2' : '',
    cols === 3 ? 'col-span-3' : '',
    cols === 4 ? 'col-span-4' : '',
    cols === 5 ? 'col-span-5' : '',
    cols === 6 ? 'col-span-6' : '',
    cols === 7 ? 'col-span-7' : '',
    cols === 8 ? 'col-span-8' : '',
    cols === 9 ? 'col-span-9' : '',
    cols === 10 ? 'col-span-10' : '',
    cols === 11 ? 'col-span-11' : '',
    cols === 12 ? 'col-span-12' : '',
  );
}

export function TextInput({
  cols,
  register,
  fieldName,
  label,
  error,
  defaultValue,
  autoComplete,
  placeholder,
  inputProps,
}: {
  cols: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  fieldName: string;
  label: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: Merge<FieldError, any>;
  defaultValue?: string;
  autoComplete?: string;
  placeholder?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  inputProps?: any;
}) {
  return (
    <div className={crazyColspanFix(cols)}>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <input
          autoComplete={autoComplete}
          id={fieldName}
          defaultValue={defaultValue}
          placeholder={placeholder}
          type="text"
          {...register(fieldName)}
          {...inputProps}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
        {error ? (
          <p className="mt-2 text-sm text-red-600" id={`${fieldName}-error`}>
            {error.message}
          </p>
        ) : null}
      </div>
    </div>
  );
}
