import TopBar from '../../components/TopBar';
import { TimeOffRequest } from './RequestTimeOff';

export function TimeOffRequestInfo() {
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Request Time Off" />

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-6 mt-8">
        <TimeOffRequest />
      </div>
    </main>
  );
}
