import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { formatDateShort } from 'listo/src/utils/dates';
import { Link } from 'react-router-dom';
import { RouterOutput, trpc } from '../../lib/trpc';
import { payPeriodStatusLabel } from '../PayPeriod/payPeriod.utils';

export function PayPeriodCard({
  payPeriod,
}: {
  payPeriod: NonNullable<RouterOutput['u']['payPeriods']['dashboard']>;
}) {
  const { data: outstandingHours, isLoading } =
    trpc.u.payPeriods.outstandingHours.useQuery({
      payPeriodId: payPeriod.id,
    });

  return (
    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 pb-12 shadow sm:px-6 sm:pt-6 ">
      <dt>
        <div className="absolute rounded-md bg-indigo-500 p-3">
          <PaperAirplaneIcon
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          Next Pay Period
        </p>
      </dt>
      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
        <div>
          <p className="text-xl font-semibold text-gray-900">
            {`${formatDateShort(payPeriod.periodStart)} - ${formatDateShort(
              payPeriod.periodEnd,
            )}`}
          </p>
          {!isLoading && !['SCHEDULED'].includes(payPeriod.status) ? (
            <p className="text-sm font-medium text-gray-500">
              {outstandingHours?.totalHours} hours
            </p>
          ) : null}
        </div>
        <div className="ml-2 inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 text-center">
          {payPeriodStatusLabel(payPeriod)}
        </div>
        <div className="absolute inset-x-0 bottom-0 bg-gray-50 px-4 py-4 sm:px-6">
          <div className="text-sm">
            <Link
              to={`/pay-period/${payPeriod.id}`}
              className="font-medium text-indigo-600 hover:text-indigo-500"
            >
              View <span className="sr-only">view payPeriod</span>
            </Link>
          </div>
        </div>
      </dd>
    </div>
  );
}
