import { DateTime } from 'listo/src/luxonUtc';
import create from 'zustand';

interface TimeTrackingDatesState {
  startDate: DateTime;
  endDate: DateTime;
  setStartDate: (startDate: DateTime) => void;
  setEndDate: (endDate: DateTime) => void;
}

export const useTimeTrackingDates = create<TimeTrackingDatesState>()((set) => ({
  startDate: DateTime.now().startOf('month'),
  endDate: DateTime.now().endOf('month'),
  setStartDate: (startDate) => set((state) => ({ ...state, startDate })),
  setEndDate: (endDate) => set((state) => ({ ...state, endDate })),
}));
