import { countryNameByIsoCode } from 'listo/src/countries';
import { formatPaymentAmount } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { PaperClipIcon } from '@heroicons/react/24/outline';
import { RouterOutput } from '../../lib/trpc';

function ContractInformation({
  contract,
}: {
  contract: RouterOutput['w']['contracts']['contract'];
}) {
  return (
    <>
      <div className="px-4 py-5 sm:px-6">
        <h2
          id="applicant-information-title"
          className="text-lg leading-6 font-medium text-gray-900"
        >
          Contract Information
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Contract details and overview.
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
        <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Tax Residence</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {countryNameByIsoCode(contract.isoCountryCode)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Start Date</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatDate(contract.startDate)}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Job Title</dt>
            <dd className="mt-1 text-sm text-gray-900">{contract.jobTitle}</dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">
              Seniority Level
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {contract.seniorityLevel}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Scope of work</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {contract.scopeOfWork}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Currency</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {contract.distributionCurrency}
            </dd>
          </div>
          <div className="sm:col-span-1">
            <dt className="text-sm font-medium text-gray-500">Amount</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {formatPaymentAmount(
                contract.billingAmountInCents,
                contract.billingFrequency,
                contract.billingCurrency,
              )}
            </dd>
          </div>

          {contract.upload?.url ? (
            <div className="sm:col-span-2">
              <dt className="text-sm font-medium text-gray-500">Attachments</dt>
              <dd className="mt-1 text-sm text-gray-900">
                <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
                  <li
                    key={contract.name}
                    className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                  >
                    <div className="w-0 flex-1 flex items-center">
                      <PaperClipIcon
                        className="flex-shrink-0 h-5 w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-2 flex-1 w-0 truncate">
                        SignedContract.pdf
                      </span>
                    </div>
                    <div className="ml-4 flex-shrink-0">
                      <a
                        href={contract.upload.url}
                        className="font-medium text-blue-600 hover:text-blue-500"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          ) : null}
        </dl>
      </div>
    </>
  );
}

export default ContractInformation;
