import { ChevronRightIcon } from '@heroicons/react/24/outline';

export function TableLoader() {
  return (
    <li className="list-none">
      <div className="block hover:bg-gray-50 animate-pulse">
        <div className="px-4 py-4 flex items-center justify-between sm:px-6">
          <div className="rounded-full bg-slate-200 h-5 w-5" />
          <div className="h-2 bg-slate-200 rounded w-20" />
          <div className="h-2 bg-slate-200 rounded w-20" />
          <div className="h-2 bg-slate-200 rounded w-60" />

          <div className="ml-5 flex-shrink-0">
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className="block hover:bg-gray-50 animate-pulse">
        <div className="px-4 py-4 flex items-center justify-between sm:px-6">
          <div className="rounded-full bg-slate-200 h-5 w-5" />
          <div className="h-2 bg-slate-200 rounded w-60" />
          <div className="h-2 bg-slate-200 rounded w-20" />
          <div className="h-2 bg-slate-200 rounded w-20" />

          <div className="ml-5 flex-shrink-0">
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
      <div className="block hover:bg-gray-50 animate-pulse">
        <div className="px-4 py-4 flex items-center justify-between sm:px-6">
          <div className="rounded-full bg-slate-200 h-5 w-5" />
          <div className="h-2 bg-slate-200 rounded w-20" />
          <div className="h-2 bg-slate-200 rounded w-60" />
          <div className="h-2 bg-slate-200 rounded w-20" />

          <div className="ml-5 flex-shrink-0">
            <ChevronRightIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
        </div>
      </div>
    </li>
  );
}
