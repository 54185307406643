import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { Link } from 'react-router-dom';
import TopBar from '../../components/TopBar';

export function Reports() {
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Reports" />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-">
          <div className="relative bg-white pt-5 px-4 pb-12 sm:pt-6 sm:px-6 shadow rounded-lg overflow-hidden">
            <dt>
              <div className="absolute bg-indigo-500 rounded-md p-3">
                <CurrencyDollarIcon
                  className="h-6 w-6 text-white"
                  aria-hidden="true"
                />
              </div>
              <p className="ml-16 text-sm font-medium text-gray-500 truncate">
                Financial Report
              </p>
            </dt>
            <dd className="ml-16 pb-6 flex items-baseline sm:pb-7">
              <p className="text-2xl font-semibold text-gray-900">
                Cost Insights
              </p>
              <Link to="/reports/cost-breakdown">
                <div className="absolute bottom-0 inset-x-0 bg-gray-50 px-4 py-4 sm:px-6">
                  <div className="text-sm">
                    <p className="font-medium text-indigo-600 hover:text-indigo-500">
                      View full report
                      {/* <span className="sr-only"> {item.name} stats</span> */}
                    </p>
                  </div>
                </div>
              </Link>
            </dd>
          </div>

          {/* <div className="bg-white rounded-lg">
            <Line options={options} data={data} />
          </div> */}
        </div>
      </div>
    </main>
  );
}
