import { useEffect } from 'react';
import { DENIED_LOCAL_STORAGE_ERROR_MESSAGE } from '../lib/constants';

const checkAndThrow = () => {
  try {
    localStorage.setItem('check_localstorage', 'yes');
    if (localStorage.getItem('check_localstorage') === 'yes') {
      localStorage.removeItem('check_localstorage');
    } else {
      throw new Error(DENIED_LOCAL_STORAGE_ERROR_MESSAGE);
    }
  } catch {
    throw new Error(DENIED_LOCAL_STORAGE_ERROR_MESSAGE);
  }
};

function useThrowLocalStorageDenied() {
  useEffect(() => {
    checkAndThrow();
  }, []);

  return null;
}

export default useThrowLocalStorageDenied;
