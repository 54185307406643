import create from 'zustand';

interface SigningModal {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useSigningModal = create<SigningModal>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
}));
