import { FieldError, UseFormRegister } from 'react-hook-form';
import { crazyColspanFix } from './TextInput';

function Error({ field, message }: { field: string; message: string }) {
  return (
    <p className="mt-2 text-sm text-red-600" id={`${field}-error`}>
      {message}
    </p>
  );
}

export function RadioInput({
  defaultValue,
  fieldName,
  register,
  cols = 3,
  options,
  error,
}: {
  defaultValue?: string;
  fieldName: string;
  cols?: number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  register: UseFormRegister<any>;
  options: { label: string; value: string }[];
  error?: FieldError;
}) {
  return (
    <div className={crazyColspanFix(cols)}>
      <fieldset className="mt-4">
        <legend className="sr-only">{fieldName}</legend>
        <div className="space-y-4">
          {options.map(({ label, value }) => (
            <div className="flex items-center" key={value}>
              <input
                defaultChecked={defaultValue === value}
                {...register(fieldName)}
                value={value}
                id={value}
                name={fieldName}
                type="radio"
                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
              />
              <label
                htmlFor={value}
                className="ml-3 block text-sm font-medium text-gray-700"
              >
                {label}
              </label>
            </div>
          ))}
        </div>
      </fieldset>
      {/* eslint-disable-next-line @typescript-eslint/prefer-optional-chain */}
      {error && error.message ? (
        <Error field={fieldName} message={error.message} />
      ) : null}
    </div>
  );
}
