import { countryNameByIsoCode } from 'listo/src/countries';
import { useEffect, useState } from 'react';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';
import { Filters } from './TeamFilters';
import Stats from './TeamStats';
import Table from './TeamTable';

export function Team() {
  const workerStats = trpc.u.workers.stats.useQuery();
  const [filters, setFilters] = useState<Filters>([]);

  useEffect(() => {
    if (!workerStats.data) return;

    const { countries } = workerStats.data;
    const countryKeys = Object.keys(countries);

    if (Object.keys(countries).length < 1) return;

    const options = countryKeys.map((country) => ({
      label: countryNameByIsoCode(country),
      value: country,
    }));

    setFilters([
      {
        name: 'Country',
        options,
      },
      {
        name: 'Filters',
        options: [
          { label: 'Invited', value: 'hasNotAcceptedInvite' },
          { label: 'Pending Signature', value: 'pendingSignature' },
          { label: 'Needs Bank Info', value: 'noBankAccount' },
          { label: 'Include Offboarded', value: 'offboarded' },
        ],
      },
      {
        name: 'Engagement Type',
        options: [
          {
            label: 'eor',
            value: 'EOR',
          },
          {
            label: 'payroll',
            value: 'PAYROLL',
          },
          {
            label: 'contractors',
            value: 'CONTRACTOR',
          },
        ],
      },
    ]);
  }, [workerStats.data]);

  if (workerStats.isLoading) return <Loader />;
  if (workerStats.error) return <div>{workerStats.error.message}</div>;

  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar
        title="Team"
        buttonRoute="/create-contract"
        buttonText="New Contract"
      />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <Stats
          isLoading={workerStats.isLoading}
          totalWorkers={workerStats.data.totalWorkers}
          totalCountries={workerStats.data.totalCountries}
        />

        <Table filters={filters} />
      </div>
    </main>
  );
}
