import { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Sidebar from '../../components/Sidebar';
import SignContract from '../../components/SignContract';
import { trpc } from '../../lib/trpc';
import { useAuth } from '../../hooks/useAuth';

export function GuestSign() {
  const { contractId } = useParams();
  const navigate = useNavigate();
  const setJwt = useAuth((state) => state.setJwt);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [verifying, setVerifying] = useState(true);

  const { mutateAsync } = trpc.p.auth.verifyJwt.useMutation({
    onSuccess: (result) => {
      if (result.verified && token) {
        setJwt(token);
      } else {
        navigate('/login');
      }

      setVerifying(false);
    },
    onError: () => {
      navigate('/login');
      setVerifying(false);
    },
  });

  useEffect(() => {
    if (!token) return;

    mutateAsync({
      token,
    }).catch(() => {});
  }, [token, mutateAsync]);

  if (verifying) {
    return null;
  }

  if (!contractId) {
    return null;
  }

  return (
    <div className="min-h-full">
      <Sidebar navigation={[]} secondaryNavigation={[]} />

      <SignContract
        contractId={contractId}
        initialStep="document"
        signer="client"
        onSuccess={() => navigate('/login')}
      />
    </div>
  );
}
