/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Merge, UseFormRegister } from 'react-hook-form';
import { crazyColspanFix } from './TextInput';

export function TextAreaInput({
  cols,
  register,
  fieldName,
  label,
  error,
  description,
  defaultValue,
}: {
  cols: number;
  register: UseFormRegister<any>;
  fieldName: string;
  label: string;
  error?: Merge<FieldError, any>;
  description?: string;
  defaultValue?: string;
}) {
  return (
    <div className={crazyColspanFix(cols)}>
      <label
        htmlFor={`${fieldName}`}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      <div className="mt-1">
        <textarea
          id={fieldName}
          defaultValue={defaultValue}
          rows={3}
          {...register(fieldName)}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border border-gray-300 rounded-md"
        />
        {error ? (
          <p className="mt-2 text-sm text-red-600" id={`${fieldName}-error`}>
            {error.message}
          </p>
        ) : null}
        {description ? (
          <p className="mt-2 text-sm text-gray-500">
            Write a few sentences explaining the role and responsibilities.
          </p>
        ) : null}
      </div>
    </div>
  );
}
