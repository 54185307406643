import { formatPaymentAmount } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { Outlet, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Stepper from '../../components/Stepper';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';

const steps = [
  {
    name: 'Info',
    to: 'info',
  },
  {
    name: 'Address',
    to: 'address',
  },
  {
    name: 'Tax',
    to: 'tax',
  },
];

export function AcceptContract() {
  const { id } = useParams();

  if (!id) throw new Error('Contract ID is required');
  const { data, isLoading, error } = trpc.w.contracts.contract.useQuery(id);

  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;
  return (
    <div className="min-h-screen bg-gray-50">
      <TopBar title="" />
      <div className="px-4 sm:px-6 max-w-prose mx-auto lg:px-8 my-12">
        <Stepper steps={steps} />

        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
          <section aria-labelledby="applicant-information-title">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2
                  id="applicant-information-title"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  Contract Information
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">
                  {`Please review and confirm the contract issued by ${data.client.name}. Input the relevant information in the three steps below. On the last
                  step you can accept or reject the contract. For questions
                  contact: ${data.client.name}.`}
                </p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">Email</dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.worker.credential.email}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Start Date
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatDate(data.startDate)}
                    </dd>
                  </div>
                  <div className="sm:col-span-1">
                    <dt className="text-sm font-medium text-gray-500">
                      Payment
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {formatPaymentAmount(
                        data.billingAmountInCents,
                        data.billingFrequency,
                        data.billingCurrency,
                      )}
                    </dd>
                  </div>
                  <div className="sm:col-span-2">
                    <dt className="text-sm font-medium text-gray-500">
                      Statement of Work
                    </dt>
                    <dd className="mt-1 text-sm text-gray-900">
                      {data.scopeOfWork}
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </section>
        </div>

        <div className="px-6 mt-4">
          <Outlet />
        </div>
      </div>
    </div>
  );
}
