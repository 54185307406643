export function Header({
  heading,
  subheading,
}: {
  heading: string;
  subheading: string;
}) {
  return (
    <div className="space-y-8 divide-y divide-gray-200">
      <div>
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {heading}
          </h3>
          <p className="mt-1 text-sm text-gray-500">{subheading}</p>
        </div>
      </div>
    </div>
  );
}
