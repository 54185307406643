import { zodResolver } from '@hookform/resolvers/zod';
import {
  UpdateTaxInfoType,
  updateTaxInfoZod,
} from 'listo/src/zodObjects/workerProfile';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { Input } from 'ui/src/components/Input';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';

export function TaxInfo() {
  const { data: profile, isLoading, error } = trpc.w.workers.profile.useQuery();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<UpdateTaxInfoType>({
    resolver: zodResolver(updateTaxInfoZod),
  });

  const setNotification = useNotification((state) => state.setNotification);

  const utils = trpc.useContext();

  const { mutate: updateWorkerProfile, isLoading: updateLoading } =
    trpc.w.workers.updateProfile.useMutation({
      onSuccess: () => {
        utils.w.workers.profile.invalidate().catch(() => {});
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Tax information updated',
        });
      },
    });

  const onSubmit: SubmitHandler<UpdateTaxInfoType> = (data) => {
    updateWorkerProfile(data);
  };

  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <div className="pt-6">
      <div>
        <h3 className="text-lg font-medium leading-6 text-gray-900">
          Tax Information
        </h3>

        <p className="mt-1 text-sm text-gray-500">
          Make sure your tax information is up to date. This is required for a
          work engagment. This can be your personal id number or the tax id of
          an entity.
        </p>
      </div>

      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 mt-6 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <Input
              inputType="text"
              label="Tax Id Number"
              inputProps={{
                defaultValue: profile.taxIdNumber ?? '',
              }}
              reactHookForm={{
                register,
                fieldName: 'taxIdNumber',
                errors,
              }}
            />
          </div>
        </div>

        <div className="flex justify-end mt-4">
          <Button type="submit" text="Update" loading={updateLoading} />
        </div>
      </form>
    </div>
  );
}
