import { CheckIcon } from '@heroicons/react/20/solid';
import { Link } from 'react-router-dom';

function Step({
  complete,
  heading,
  subheading,
}: {
  complete: boolean;
  heading: string;
  subheading: string;
}) {
  return (
    <>
      <span className="flex h-9 items-center">
        {complete ? (
          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full bg-indigo-600 group-hover:bg-indigo-800">
            <CheckIcon className="h-5 w-5 text-white" aria-hidden="true" />
          </span>
        ) : (
          <span className="relative z-10 flex h-8 w-8 items-center justify-center rounded-full border-2 border-gray-300 bg-white group-hover:border-gray-400">
            <span className="h-2.5 w-2.5 rounded-full bg-transparent group-hover:bg-gray-300" />
          </span>
        )}
      </span>
      <span className="ml-4 flex min-w-0 flex-col">
        <span className="text-sm font-medium text-gray-600">{heading}</span>
        <span className="text-sm text-gray-500">{subheading}</span>
      </span>
    </>
  );
}

export function StepLink({
  complete,
  heading,
  subheading,
  to,
}: {
  complete: boolean;
  heading: string;
  subheading: string;
  to?: string;
}) {
  if (to) {
    return (
      <Link to={to} className="group relative flex items-start">
        <Step heading={heading} complete={complete} subheading={subheading} />
      </Link>
    );
  }
  return (
    <div className="group relative flex items-start">
      <Step heading={heading} complete={complete} subheading={subheading} />
    </div>
  );
}
