import { getShortId, titleCase } from 'listo/src/utils/strings';
import { Outlet, useParams } from 'react-router-dom';
import ContractTile from '../../components/ContractTile';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import SignedContract from '../../components/SignedContract';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';
import { Header } from './Header';
import { OnboardingStatus } from './OnboardingStatus';
import Payments from './Payments';
import ClientContractStatus from './ClientContractStatus';

export function Contract() {
  const { contractId } = useParams();
  if (!contractId) throw new Error('No contractId');
  const {
    data: contract,
    isLoading,
    error,
  } = trpc.u.contracts.contract.useQuery(contractId);
  if (isLoading) return <Loader />;
  if (error)
    return <ErrorState trpcError={error} parentComponentName="Contract.tsx" />;

  return (
    <div className="flex-1 min-h-screen pb-8 bg-gray-100">
      <TopBar
        breadcrumbs={[
          {
            name: titleCase(contract.workerProfile.fullName ?? 'profile'),
            href: `/team/${contract.worker.id}`,
            current: false,
          },
          {
            name: 'Contracts',
            href: `/team/${contract.worker.id}/contracts`,
            current: false,
          },
          {
            name: getShortId(contract.id) ?? '',
            href: `/contracts/${contract.id}`,
            current: true,
          },
        ]}
        buttonText="Edit"
        buttonRoute={`/contracts/${contract.id}/edit`}
        title="Contract"
      />
      <div className="px-4 mx-auto mt-8 max-w-7xl sm:px-6 lg:px-8">
        {/* {contract.engagementType === 'EOR' && contract.quote === null ? (
          <PendingQuote />
        ) : null} */}

        {/* quote accepter */}
        {/* {contract.engagementType === 'EOR' &&
        contract.quote &&
        contract.workerId &&
        !contract.quote.accepted ? (
          <AcceptQuote quoteId={contract.quote.id} />
        ) : null} */}

        <div className="grid gap-6 gird-cols-2 lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="overflow-hidden bg-white shadow sm:rounded-md lg:col-start-1 lg:col-span-2">
            <Header contract={contract} />
            {/* {contract.engagementType === 'EOR' ? (
              <Steps contractId={contractId} stepStatuses={contract.steps} />
            ) : null} */}
          </div>
          <div className="lg:col-start-3 lg:col-span-1">
            <SignedContract contractId={contract.id} />
            {contract.engagementType === 'EOR' ? (
              <ClientContractStatus />
            ) : null}
            {contract.engagementType === 'CONTRACTOR' ? (
              <ContractTile contract={contract} worker={contract.worker} />
            ) : null}
          </div>
        </div>

        <div className="grid gap-6 mt-5 gird-cols-2 lg:grid-flow-col-dense lg:grid-cols-3">
          <Payments contractId={contractId} />
          <OnboardingStatus contract={contract} />
        </div>

        <Outlet />
      </div>
    </div>
  );
}
