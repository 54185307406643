import { ChangeEventHandler, useState, useEffect, useRef } from 'react';
import { isArray } from 'lodash';
import axios from 'axios';
import XMarkIcon from '@heroicons/react/24/outline/XMarkIcon';
import PhotoIcon from '@heroicons/react/24/outline/PhotoIcon';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  CreateExpenseReimbursementType,
  createExpenseReimbursementZod,
  ExpenseReimbursementStatusEnum,
} from 'listo/src/zodObjects/expenseReimbursement';

import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { Input } from 'ui/src/components/Input';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';

export function ReimbursementForm({
  setOpenForm,
  afterSubmit,
}: {
  setOpenForm: (val: boolean) => void;
  afterSubmit?: () => void;
}) {
  const { claims } = useAuth();
  const setNotification = useNotification((state) => state.setNotification);
  const documentFileInputRef = useRef<HTMLInputElement>(null);
  const [documentFile, setDocumentFile] = useState<File>();
  const [documentErrorMessage, setDocumentErrorMessage] = useState('');
  const [activeClientId, setActiveClientId] = useState(
    claims?.activeClientId ?? '',
  );

  // setting fallback client id for to get signed URL
  useEffect(() => {
    if (!activeClientId || activeClientId === 'n/a') {
      if (claims && isArray(claims.users)) {
        setActiveClientId(claims.users[0]?.clientId ?? '');
      }
    }
  }, [claims, activeClientId]);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateExpenseReimbursementType>({
    defaultValues: {
      workerProfileId: claims?.workerProfileId,
      status: ExpenseReimbursementStatusEnum.Values.PENDING,
      amountInCents: 0,
    },
    resolver: zodResolver(createExpenseReimbursementZod),
  });

  const { data: contracts, isLoading: loadingContracts } =
    trpc.w.contracts.list.useQuery();

  const { mutateAsync: createReimbursement } =
    trpc.w.expenseReimbursement.create.useMutation({
      onSuccess: () => {
        reset();

        if (afterSubmit) {
          afterSubmit();
        }

        return setNotification({
          type: 'success',
          title: 'Success',
          message:
            'Your reimbursement request has been submitted successfully.',
        });
      },
      onError: () =>
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Your reimbursement request has failed. Please contact us.',
        }),
    });

  const { mutateAsync: addReimbursementDocument } =
    trpc.w.expenseReimbursement.addReimbursementDocument.useMutation();

  const { mutateAsync: getSignedUrlForUpload } =
    trpc.a.assets.getSignedUrl.useMutation();

  const { mutateAsync: uploadFileSuccess } =
    trpc.a.assets.uploadSuccess.useMutation();

  const removeUpload = () => {
    setDocumentFile(undefined);

    if (documentFileInputRef.current) {
      documentFileInputRef.current.value = '';
    }
  };

  // eslint-disable-next-line consistent-return
  const uploadDocument = async (selectedFile: File) => {
    try {
      const fileName = selectedFile.name;
      const mimeType = selectedFile.type;

      if (mimeType && fileName) {
        const { signedUrl, cloudStorageKey } = await getSignedUrlForUpload({
          listoAssetType: 'documents',
          mimeType,
          clientId: activeClientId,
        });

        await axios.put(signedUrl, selectedFile, {
          headers: {
            'Content-Type': selectedFile.type,
          },
        });

        const { id } = await uploadFileSuccess({
          clientId: activeClientId,
          fileName,
          mimeType,
          cloudStorageKey,
        });

        return id;
      }

      return null;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Something went wrong',
      });
    } finally {
      removeUpload();
    }
  };

  const onSubmit: SubmitHandler<CreateExpenseReimbursementType> = async (
    data,
  ) => {
    try {
      if (documentFile) {
        // eslint-disable-next-line @typescript-eslint/non-nullable-type-assertion-style
        const documentUploadId = (await uploadDocument(documentFile)) as string;
        const { id: reimbursementId } = await createReimbursement(data);

        await addReimbursementDocument({
          reimbursementId,
          documentUploadId,
        });
      } else {
        setDocumentErrorMessage('Document is required.');
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const fileList = event.target.files;

    if (fileList) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const selectedFile = fileList[0]!;
      setDocumentFile(selectedFile);
      setDocumentErrorMessage('');
    }
  };

  return (
    <div className="w-full md:min-w-[500px] p-4 sm:p-6">
      <h2 className="font-semibold text-xl text-gray-900 mb-6">
        Request Reimbursement
      </h2>

      {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-6">
        <div className="grid grid-cols-1 gap-y-8">
          <div className="sm:col-span-1">
            {loadingContracts ? null : (
              <>
                <label
                  htmlFor="clientId"
                  className="block text-sm text-gray-700 font-medium"
                >
                  Client *
                </label>

                <select
                  {...register('clientId')}
                  id="clientId"
                  className="block w-full mt-2 p-2 shadow-sm border border-gray-300 rounded-md resize-none focus:outline-none sm:text-sm"
                >
                  <option value="">None</option>
                  {contracts?.results.map((contract) => (
                    <option value={contract.client.id} key={contract.id}>
                      {contract.client.name} ({contract.engagementType})
                    </option>
                  ))}
                </select>

                {errors.clientId?.message ? (
                  <p className="mt-2 text-sm text-red-600">
                    {errors.clientId.message}
                  </p>
                ) : null}
              </>
            )}
          </div>

          <div className="sm:col-span-1">
            <Input
              inputType="number"
              label="Amount in USD*"
              reactHookForm={{
                register,
                fieldName: 'amountInCents',
                errors,
              }}
              inputProps={{
                min: 0,
                step: '0.01',
                id: 'amount *',
              }}
            />
          </div>

          <div className="sm:col-span-1">
            <Input
              inputType="date"
              label="Expense Date *"
              reactHookForm={{
                register,
                fieldName: 'expenseDate',
                errors,
              }}
              inputProps={{
                min: 1,
                id: 'expense date *',
              }}
            />
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="document"
              className="block text-sm text-gray-700 font-medium"
            >
              Upload *
            </label>

            <div className="grid grid-cols-1 gap-x-4 mt-2">
              {documentFile ? (
                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                  <div className="space-y-4 text-center">
                    <PhotoIcon className="mx-auto h-12 w-12 text-gray-400" />

                    <div className="flex gap-2">
                      <h4 className="text-gray-500 text-xs font-light">
                        {documentFile.name}
                      </h4>

                      <button
                        type="button"
                        onClick={removeUpload}
                        title="Remove"
                        className="text-red-700 hover:text-red-500 transition focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-0"
                      >
                        <XMarkIcon className="h-4" />
                      </button>
                    </div>
                  </div>
                </div>
              ) : (
                <label
                  htmlFor="document"
                  className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-0 cursor-pointer"
                >
                  <div className="space-y-1 text-center">
                    <svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <div className="flex text-sm text-gray-600">
                      <input
                        id="document"
                        type="file"
                        ref={documentFileInputRef}
                        accept="image/*,application/pdf"
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        onChange={handleChange}
                        className="sr-only"
                      />
                      <h4 className="text-indigo-600 font-bold">
                        Upload your document
                      </h4>
                    </div>

                    <p className="text-xs text-gray-500">
                      PDF, PNG, JPG or any images
                    </p>
                  </div>
                </label>
              )}

              {documentErrorMessage ? (
                <p className="mt-2 text-sm text-red-600">
                  {documentErrorMessage}
                </p>
              ) : null}
            </div>
          </div>

          <div className="sm:col-span-1">
            <label
              htmlFor="description"
              className="block text-sm text-gray-700 font-medium"
            >
              Description *
            </label>

            <div className="grid grid-cols-1 gap-x-4 mt-2">
              <textarea
                rows={6}
                id="description"
                {...register('description')}
                className="block w-full p-2 shadow-sm border border-gray-300 rounded-md resize-none focus:outline-none sm:text-sm"
              />
            </div>

            {errors.description?.message ? (
              <p className="mt-2 text-sm text-red-600">
                {errors.description.message}
              </p>
            ) : null}
          </div>
        </div>

        <div className="pt-2">
          <div className="flex justify-end gap-2">
            <Button
              type="button"
              variant="secondary"
              onClick={() => setOpenForm(false)}
              text="Cancel"
            />
            <Button type="submit" loading={isSubmitting} text="Submit" />
          </div>
        </div>
      </form>
    </div>
  );
}
