import {
  TimeOffRequestStatusEnum,
  TimeOffRequestStatus,
} from 'listo/src/zodObjects/requestTimeOff';

import { Spinner } from 'ui/src/components/Spinner';
import { RouterOutput } from '../../lib/trpc';
import { TimeOffRequestListItem } from './RequestTimeOffListItem';

function Empty() {
  return (
    <div className="text-center w-full py-6">
      <h3 className="text-sm font-medium text-gray-900">- Empty -</h3>
    </div>
  );
}

export function TimeOffRequestList({
  isWorker,
  data,
  isLoading,
  refetch,
}: {
  isWorker?: boolean;
  data: RouterOutput['u']['timeOffRequest']['listAll'] | undefined;
  isLoading: boolean;
  refetch: () => Promise<unknown>;
}) {
  if (isLoading) {
    return <Spinner />;
  }

  if (!data || data.length === 0) {
    return <Empty />;
  }

  return (
    <ol className="divide-y divide-gray-100 w-full text-sm leading-6">
      {data.map((timeOffRequestItem) => {
        const status = timeOffRequestItem.status as TimeOffRequestStatus;

        if (status === TimeOffRequestStatusEnum.Values.CANCELLED) {
          return null;
        }

        return (
          <TimeOffRequestListItem
            key={timeOffRequestItem.id}
            data={timeOffRequestItem}
            status={status}
            refetch={refetch}
            isWorker={!!isWorker}
          />
        );
      })}
    </ol>
  );
}
