import { ChevronRightIcon } from '@heroicons/react/24/outline';
import { createColumnHelper } from '@tanstack/react-table';
import { formatDate } from 'listo/src/utils/dates';
import { useState } from 'react';
import { QuickBooksInvoiceResponse } from 'api/src/invoicing/providers/quickbookTypes';
import EmptyState from '../../components/EmptyState';
import RadixAvatar from '../../components/RadixAvatar';
import TableLoader from '../../components/TableLoader';
import { API_URL, QUICKBOOKS_HOSTED_PAYMENT_PAGE } from '../../lib/constants';
import { RouterOutput, trpc } from '../../lib/trpc';
import PdfModal from '../PdfModal';
import { StackedList } from '../Team/StackedList';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
// function statusColor(_status: RouterOutput['u']['invoices']['list'][0]) {
//   if (status === 'GENERATED' || status === 'GATHERING')
//     return 'bg-gray-100 text-gray-800';
//   if (status === 'APPROVAL' || status === 'INVOICED')
//     return 'bg-yellow-100 text-yellow-800';

//   return 'bg-green-100 text-green-800';
// }

// function formatLabel(invoice: RouterOutput['u']['invoices']['list'][0]) {
//   if (
//     invoice.status === 'GENERATED' ||
//     invoice.status === 'GATHERING' ||
//     invoice.status === 'APPROVAL'
//   )
//     return '';

//   return invoice.formattedAmount;
// }

type InvoiceType = RouterOutput['u']['invoices']['list'][0];

const columnHelper = createColumnHelper<InvoiceType>();

const columns = [
  columnHelper.accessor((row) => formatDate(row.createdAt), {
    id: 'createdAt',
    cell: (info) => (
      <div className="flex items-center">
        <RadixAvatar fallbackString={info.getValue()[0] ?? ''} />

        <span className="ml-2">{info.getValue()}</span>
      </div>
    ),

    header: () => null,
    footer: (info) => info.column.id,
  }),
  columnHelper.accessor((row) => row.formattedAmount, {
    id: 'amountInDollars',
    cell: (info) => (
      // <ChevronCell value={info.getValue()} />
      <div className="flex justify-end">
        <span className="text-gray-500">{info.getValue()}</span>
        <ChevronRightIcon
          className="h-5 w-5 text-gray-400 ml-5"
          aria-hidden="true"
        />
      </div>
    ),
    header: () => null,
    footer: (info) => info.column.id,
  }),
];

function pdfFooter(paymentLink: string, hasInvoiceUrl: boolean) {
  return (
    <>
      {hasInvoiceUrl ? (
        <div className="flex justify-end mt-2">
          <button
            onClick={() => {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              window.open(`${QUICKBOOKS_HOSTED_PAYMENT_PAGE}/${paymentLink}`);
            }}
            type="button"
            className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Pay
          </button>
        </div>
      ) : null}
    </>
  );
}

function InvoiceRow({
  invoice,
  setInvoiceUrl,
}: {
  invoice: RouterOutput['u']['invoices']['list'][0];
  setInvoiceUrl: (
    invoicePdfUrl: string,
    externalInvoice: QuickBooksInvoiceResponse,
  ) => void;
}) {
  if (invoice.externalProvider === 'QUICKBOOKS' && invoice.externalInvoiceId) {
    return (
      // eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events
      <div
        onClick={() => {
          const externalInvoice =
            invoice.externalJson as unknown as QuickBooksInvoiceResponse;
          setInvoiceUrl(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            `${API_URL}/quickbooks/${invoice.externalInvoiceId}/pdf`,
            externalInvoice,
          );
        }}
        className="block hover:bg-gray-50 cursor-pointer"
      >
        <StackedList
          columns={columns}
          isLoading={false}
          error={null}
          data={[invoice]}
        />
      </div>
    );
  }

  if (invoice.externalProvider === 'STRIPE' && invoice.stripeHostedUrl)
    return (
      <a href={invoice.stripeHostedUrl} className="block hover:bg-blue-100">
        <StackedList
          columns={columns}
          isLoading={false}
          error={null}
          data={[invoice]}
        />
      </a>
    );
  return null;
}

export default function InvoicesTable() {
  const {
    data: invoices,
    isLoading,
    error,
  } = trpc.u.invoices.list.useQuery({});
  const [pdfOpen, setPdfOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const [pdfUrl, _setPdfUrl] = useState('');
  const [paymentUrl, setPaymentUrl] = useState('');
  const [hasInvoiceUrl, setHasInvoiceUrl] = useState(true);

  const setInvoiceLink = (status: boolean, urlLink: string) => {
    setHasInvoiceUrl(status);
    setPaymentUrl(urlLink);
  };

  const setInvoiceUrl = (
    invoicePdfUrl: string,
    externalInvoice: QuickBooksInvoiceResponse,
  ) => {
    _setPdfUrl(invoicePdfUrl);
    setPdfOpen(true);

    // Get PaymentLink
    const invoiceLink: string | undefined = externalInvoice.Invoice.InvoiceLink;
    if (!invoiceLink) {
      setInvoiceLink(false, '');
      return;
    }
    const lastSlashIndex = invoiceLink.lastIndexOf('/');
    setInvoiceLink(true, invoiceLink.substring(lastSlashIndex + 1));
  };

  return (
    <>
      {invoices !== undefined && invoices.length === 0 ? (
        <EmptyState entity="Contract" message="No Invoices" />
      ) : null}

      <div className="bg-white shadow overflow-hidden  mt-8">
        <ul className="divide-y divide-gray-200">
          {error ? <div> error </div> : null}
          {!invoices && isLoading ? <TableLoader /> : null}

          <PdfModal
            isOpen={pdfOpen}
            setIsOpen={setPdfOpen}
            title="invoice"
            pdfUrl={pdfUrl}
            footer={{
              children: pdfFooter(paymentUrl, hasInvoiceUrl),
              offsetPx: hasInvoiceUrl ? 20 : 0,
            }}
          />
          {invoices
            ? invoices.map((invoice) => (
              <InvoiceRow
                key={invoice.id}
                invoice={invoice}
                setInvoiceUrl={setInvoiceUrl}
              />
            ))
            : null}
        </ul>
      </div>
    </>
  );
}
