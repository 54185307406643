import { z } from 'zod';

export const workerIdentityFields = z.object({
  firstName: z.string().min(1, "What's the worker's first name?"),
  lastName: z.string().min(1, "What's the worker's last name?"),
  phone: z.string().min(1, "What is the worker's phone number?"),
});

export type WorkerIdentityFields = z.infer<typeof workerIdentityFields>;

export const workerAddressFields = z.object({
  address1: z.string().min(1, "What is the worker's address?"),
  address2: z.string(),
  city: z.string().min(1, "What is the worker's city?"),
  zoneCode: z.string().min(1, "What is the worker's state?"),
  postalCode: z.string().min(1, "What is the worker's zip?"),
  isoCountryCode: z.string(),
});

export type WorkerAddressFields = z.infer<typeof workerAddressFields>;

export const workerTaxFields = z.object({
  taxIdNumber: z.string().min(1, "What is the worker's tax id?"),
});

export const contractFields = z.object({
  contractId: z.string().uuid().min(1, 'What is the contract id?'),
});

export type WorkerTaxFields = z.infer<typeof workerTaxFields>;

export const workerAcceptContract = workerIdentityFields
  .merge(workerAddressFields)
  .merge(workerTaxFields)
  .merge(contractFields);

export type WorkerAcceptContract = z.infer<typeof workerAcceptContract>;
