import { NavLink, useMatch, useResolvedPath } from 'react-router-dom';
import { classNames } from 'listo/src/utils/strings';

export function CustomNavLink({
  to,
  title,
  Icon,
  onClick,
  activeClasses = 'bg-indigo-800 text-white',
  inactiveClasses = 'text-indigo-100 hover:text-white hover:bg-indigo-600',
  baseClasses = 'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
}: {
  to: string;
  title: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Icon: any;
  onClick: () => void;
  activeClasses?: string;
  inactiveClasses?: string;
  baseClasses?: string;
}) {
  const resolved = useResolvedPath(to);
  const customMatch = useMatch({ path: resolved.pathname, end: true });

  return (
    <NavLink
      onClick={onClick}
      to={to}
      className={({ isActive }) => {
        // /wrkr/ route prefix throws off the default {isActive} prop.
        // use a custom matcher inside the /wrkr/ portal to fix this.
        const active =
          resolved.pathname.includes('/wrkr') || resolved.pathname === '/'
            ? customMatch
            : isActive;

        return classNames(
          active ? activeClasses : inactiveClasses,
          baseClasses,
        );
      }}
    >
      <Icon
        className="mr-4 flex-shrink-0 h-6 w-6 text-indigo-200"
        aria-hidden="true"
      />
      {title}
    </NavLink>
  );
}
