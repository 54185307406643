import { zodResolver } from '@hookform/resolvers/zod';
import {
  ContractorJobFields,
  contractorJobFields,
} from 'listo/src/zodObjects/contracts';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Form from '../../components/Form';
import { DateInput } from '../../components/Form/DateInput';
import { Header } from '../../components/Form/Header';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { TextAreaInput } from '../../components/Form/TextAreaInput';
import { TextInput } from '../../components/Form/TextInput';
import { trpc } from '../../lib/trpc';
import { useCreateContractForm } from './hooks/useCreateContractForm';

const seniorityLevels = [
  { label: 'Not Applicable', value: 'not-applicable' },
  { label: 'Junior', value: 'junior' },
  { label: 'Mid', value: 'mid' },
  { label: 'Senior', value: 'senior' },
  { label: 'Lead', value: 'lead' },
  { label: 'Principal', value: 'principal' },
  { label: 'Director', value: 'director' },
  { label: 'Department Head', value: 'department-head' },
  { label: 'Vice President', value: 'vice-president' },
  { label: 'Senior Vice President', value: 'senior-vice-president' },
  { label: 'C-Level Executive', value: 'c-level-executive' },
];

export function ContractorJob() {
  const { type } = useParams();
  if (!type) throw new Error('type is required');
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const setFields = useCreateContractForm((state) => state.setFields);
  const fields = useCreateContractForm((state) => state.fields);
  const entites = trpc.u.entities.list.useQuery();

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ContractorJobFields>({
    resolver: zodResolver(contractorJobFields),
  });

  const onSubmit: SubmitHandler<ContractorJobFields> = useCallback(
    (data) => {
      setFields(data);
      navigate(`/create-contract/${type}/payment`);
    },
    [navigate, setFields, type],
  );

  useEffect(() => {
    const entityId = entites.data?.[0]?.id;
    if (entityId) {
      setFields({ entityId });
    }
  }, [entites.data, setFields]);

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    if (autoValidate) {
      handleSubmit(onSubmit)().catch(() => {});
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Next"
      secondaryButtonText="Back"
      secondaryButtonOnClick={() =>
        navigate(`/create-contract/${type}/contractor`)
      }
    >
      <>
        <Header
          heading="Job Information"
          subheading="Please provide information regarding the work the contractor will be performing."
        />
        <Section>
          <>
            <TextInput
              cols={3}
              register={register}
              fieldName="jobTitle"
              label="Job Title"
              defaultValue={fields?.jobTitle}
              error={errors.jobTitle}
            />
            <SelectInput
              cols={3}
              register={register}
              fieldName="seniorityLevel"
              label="Seniority Level"
              error={errors.seniorityLevel}
              defaultValue={fields?.seniorityLevel}
              defaultOption={{ label: 'Select', value: '' }}
              options={seniorityLevels.map((level) => ({
                label: level.label,
                value: level.value,
              }))}
            />
            <TextAreaInput
              cols={6}
              register={register}
              fieldName="scopeOfWork"
              label="Scope of Work"
              description="Write a few sentences explaining the role and responsibilities."
              defaultValue={fields?.scopeOfWork}
              error={errors.scopeOfWork}
            />
            <DateInput
              cols={3}
              register={register}
              fieldName="startDate"
              label="Start Date"
              defaultValue={fields?.startDate}
              error={errors.startDate}
            />
          </>
        </Section>
      </>
    </Form>
  );
}
