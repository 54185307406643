import { useParams } from 'react-router-dom';
import { CostBreakdown } from './CostBreakdown';

export function Report() {
  const { type: reportType } = useParams();

  if (reportType === 'cost-breakdown') return <CostBreakdown />;

  return <div>Report</div>;
}
