import { zodResolver } from '@hookform/resolvers/zod';
import {
  workerIdentityFields,
  WorkerIdentityFields,
} from 'listo/src/zodObjects/worker';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Form from '../../components/Form';
import { Section } from '../../components/Form/Section';
import { TextInput } from '../../components/Form/TextInput';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import { useAcceptContractForm } from './hooks/useAcceptContractForm';
import { Footer } from '../../components/Form/Footer';

export function PersonalInfo() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  if (!id) throw new Error('Contract ID is required');
  const contract = trpc.w.contracts.contract.useQuery(id);

  const setFields = useAcceptContractForm((s) => s.setFields);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<WorkerIdentityFields>({
    resolver: zodResolver(workerIdentityFields),
  });

  const onSubmit: SubmitHandler<WorkerIdentityFields> = useCallback(
    (data) => {
      setFields(data);
      navigate(`/wrkr/accept-contract/${id}/address`);
    },
    [id, navigate, setFields],
  );

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    if (autoValidate) {
      handleSubmit(onSubmit)().catch(() => {});
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  if (contract.error) return <h1>error</h1>;
  if (contract.isLoading) return <Loader />;
  return (
    <Form handleSubmit={handleSubmit} onSubmit={onSubmit} noFooter>
      <>
        <Section heading="Personal Information">
          <>
            <TextInput
              cols={3}
              register={register}
              autoComplete="given-name"
              fieldName="firstName"
              label="First Name"
              error={errors.firstName}
              defaultValue={contract.data.workerProfile.firstName ?? undefined}
            />
            <TextInput
              cols={3}
              register={register}
              defaultValue={contract.data.workerProfile.lastName ?? undefined}
              fieldName="lastName"
              autoComplete="family-name"
              label="Last Name"
              error={errors.lastName}
            />
            <TextInput
              cols={3}
              register={register}
              autoComplete="tel"
              fieldName="phone"
              label="Phone Number"
              error={errors.phone}
              defaultValue={contract.data.workerProfile.phone ?? undefined}
            />
          </>
        </Section>
        <Footer
          primaryButtonText="Next"
          secondaryButtonText="Cancel"
          secondaryButtonOnClick={() => navigate('/wrkr')}
        />
      </>
    </Form>
  );
}
