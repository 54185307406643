import { zodResolver } from '@hookform/resolvers/zod';
import {
  eorEmployeeFields,
  EorEmployeeFields,
} from 'listo/src/zodObjects/contracts';
import { useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { countries } from 'listo/src/countries';
import shallow from 'zustand/shallow';
import { InformationCircleIcon } from '@heroicons/react/24/outline';
import Form from '../../components/Form';
import { Header } from '../../components/Form/Header';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { TextInput } from '../../components/Form/TextInput';
import { useEorForm } from './hooks/useEorForm';

export function EmployeeInfo() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [showBanner, setShowBanner] = useState(false);

  const { fullName, email, nationality, country } = useEorForm(
    (state) => ({
      fullName: state.fields?.fullName,
      email: state.fields?.email,
      nationality: state.fields?.nationality,
      country: state.fields?.country,
      requiresVisa: state.fields?.requiresVisa,
    }),
    shallow,
  );
  const { setFields } = useEorForm((state) => state);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<EorEmployeeFields>({ resolver: zodResolver(eorEmployeeFields) });

  const onSubmit: SubmitHandler<EorEmployeeFields> = useCallback(
    (data) => {
      setFields(data);
      navigate('/create-contract/eor/job');
    },
    [navigate, setFields],
  );

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    if (autoValidate) {
      handleSubmit(onSubmit)().catch(() => {});
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  useEffect(() => {
    if (watch('nationality') === watch('country')) {
      setValue('requiresVisa', 'no');
      if (watch('country') !== '' && watch('nationality') !== '')
        setShowBanner(false);
    } else {
      setValue('requiresVisa', 'yes');
      if (watch('country') !== '' && watch('nationality') !== '')
        setShowBanner(true);
    }
  }, [setValue, watch]);

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Next"
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => navigate('/create-contract')}
    >
      <>
        <Header
          heading="Employee Information"
          subheading="Let's gather some basic information about the employee so we
          can create a compliant contract."
        />
        <Section>
          <>
            <TextInput
              cols={3}
              register={register}
              fieldName="fullName"
              label="Full Name"
              defaultValue={fullName ?? ''}
              error={errors.fullName}
            />
            <TextInput
              cols={3}
              register={register}
              fieldName="email"
              label="Email"
              defaultValue={email ?? ''}
              error={errors.email}
            />
            <SelectInput
              cols={3}
              register={register}
              fieldName="nationality"
              label="Employee Nationality"
              defaultValue={nationality ?? ''}
              defaultOption={{ label: 'Select', value: '' }}
              options={countries.map((c) => ({
                label: c.name,
                value: c.alpha2,
              }))}
              error={errors.nationality}
            />
            <SelectInput
              cols={3}
              register={register}
              fieldName="country"
              label="Country Employee will Work in"
              defaultValue={country ?? ''}
              defaultOption={{ label: 'Select', value: '' }}
              options={countries.map((c) => ({
                label: c.name,
                value: c.alpha2,
              }))}
              error={errors.country}
            />
          </>
        </Section>
        {showBanner && (
          <div className="rounded-md bg-blue-50 p-4">
            <div className="flex ">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-blue-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-blue-700">
                  This employee will need a work visa
                </p>
                {/* <p className="mt-3 text-sm md:mt-0 md:ml-6">
                  <a
                    href="#"
                    className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600"
                  >
                    Details
                    <span aria-hidden="true"> &rarr;</span>
                  </a>
                </p> */}
              </div>
            </div>
          </div>
        )}

        {/* {needVisa && (
          <div className="pt-8">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Work eligibility document
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Your employee is of a different nationality than the country they
              will be working from. We would need a work eligibility document
              (this can be a work permit or visa). Optional, if you can&apos;t
              provide this we will ask your employee.
            </p>
            <UploadBox
              value={workEligibilityDocument}
              clientId={claims?.activeClientId}
              allowedTypes={['.pdf']}
              listoAssetType="documents"
              text="Upload document"
              onFileUploaded={(file: UploadedFile) =>
                setValue('workEligibilityDocument', file)
              }
            />
          </div>
        )} */}
      </>
    </Form>
  );
}
