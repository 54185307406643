/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { PaginationRes } from '../../types/common';

interface PaginationProps {
  paging?: PaginationRes;
  onPageChange?: (page: number) => void;
}

export default function Pagination({ paging, onPageChange }: PaginationProps) {
  const { pageNumber = 0, pageSize = 0, pages = 0, count = 0 } = paging ?? {};
  const previous = pageNumber > 1;
  const showingEnd =
    pageNumber === pages || pageSize * pageNumber > count
      ? count
      : pageSize * pageNumber;
  const showingBegin =
    pageSize * (pageNumber - 1) + 1 <= 0 ? 1 : pageSize * (pageNumber - 1) + 1;
  const hasMiddleSeparator = pages > 6;
  const next = pages > 0 && pageNumber < pages;
  const pagesToSplice = new Array(pages > 6 ? 3 : pages)
    .fill('PAGE')
    .map((_, index) => pageNumber - (index + 1))
    .reverse();
  const firtPortionArrayToDislay = pages > 3 ? pagesToSplice : [1, 2, 3];
  const firtPortionArray =
    firtPortionArrayToDislay.length > 1
      ? firtPortionArrayToDislay.splice(0, pages)
      : [1];
  const lastPortionArray = new Array(pages > 6 ? 3 : 0).map(
    (_, index) => pages - index + 1,
  );

  const handlePageChange = (page: number) => () => {
    if (onPageChange) {
      onPageChange(page);
    }
  };

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 shadow overflow-hidden sm:rounded-b-md">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className={`relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
            !previous && 'disabled'
          }`}
          onClick={handlePageChange(pageNumber - 1)}
        >
          Previous
        </a>
        <a
          href="#"
          className={`ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 ${
            !next && 'disabled'
          }`}
          onClick={handlePageChange(pageNumber + 1)}
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          {count ? (
            <p className="text-sm text-gray-700">
              Showing <span className="font-medium">{showingBegin}</span> to
              <span className="font-medium"> {showingEnd}</span> of
              <span className="font-medium"> {count}</span> results
            </p>
          ) : null}
        </div>

        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <a
              href="#"
              className={`relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                !previous && 'disabled'
              }`}
              onClick={handlePageChange(pageNumber - 1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>
            {/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
            {firtPortionArray.map((page: number) => (
              <a
                href="#"
                key={page}
                aria-current="page"
                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                  pageNumber === page &&
                  'border-indigo-500 text-indigo-600 z-30 active'
                }`}
                onClick={handlePageChange(page)}
              >
                {page}
              </a>
            ))}
            {hasMiddleSeparator && (
              <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                ...
              </span>
            )}
            {lastPortionArray.map((page) => (
              <a
                key={page}
                href="#"
                className="bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium"
              >
                {page}
              </a>
            ))}
            <a
              href="#"
              className={`relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 ${
                !next && 'disabled'
              }`}
              onClick={handlePageChange(pageNumber + 1)}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}
