import { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import PdfModal from '../PdfModal';

export function ContractStatus() {
  const [modalOpen, setModalOpen] = useState(false);

  const { contractId } = useParams();
  if (!contractId) throw new Error('contractId is required');
  const navigate = useNavigate();
  const {
    data: contract,
    isLoading: contractLoading,
    error,
  } = trpc.u.contracts.contract.useQuery(contractId);

  if (contractLoading) return <Loader />;
  if (error)
    return (
      <ErrorState trpcError={error} parentComponentName="ContractStatus.tsx" />
    );

  // if (contract.quote?.depsositAmountInCents && !contract.quote.depositPaid) {
  //   return (
  //     <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-b-none mt-6 p-6">
  //       <div className="mx-auto py-4 my-4">
  //         <p className="text-md text-gray-500">
  //           Your deposit needs to be paid.
  //         </p>
  //         <div className="mt-4">
  //           <Button
  //             text="Pay Deposit"
  //             onClick={() => navigate(`/contracts/${contractId}/deposit`)}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // }

  // TODO make sure the info is actually entered
  if (!contract.invitationLink) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-b-none mt-6 p-6">
        <div className="mx-auto py-4 my-4">
          <p className="text-md text-gray-500">
            The employee needs to enter additional information
          </p>
          <div className="mt-4">
            <Button
              text="Invite Employee"
              onClick={() => navigate(`/contracts/${contractId}/invite`)}
            />
          </div>
        </div>
      </div>
    );
  }

  if (!contract.customContractUploadId) {
    return (
      <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-b-none mt-6 p-6">
        <div className="mx-auto py-4 my-4">
          <p className="text-md text-gray-500">
            Your contract is being prepared. Check back soon or contact us for
            additional status updates.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-b-none mt-6 p-6">
      <Button text="View Contract" onClick={() => setModalOpen(true)} />
      {contract.upload?.url || contract.customContractUpload?.url ? (
        <PdfModal
          isOpen={modalOpen}
          setIsOpen={setModalOpen}
          title="Contract"
          pdfUrl={
            contract.upload?.url ?? contract.customContractUpload?.url ?? ''
          }
        />
      ) : null}
    </div>
  );
}
