import { useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { CreateUserType, createUserZod } from 'listo/src/zodObjects/users';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import Button from '../../components/Button';
import { ProfilePictureUploader } from '../../components/ProfilePictureUploader';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';

export function AddUser() {
  const navigate = useNavigate();
  const { handleSubmit, register } = useForm<CreateUserType>({
    resolver: zodResolver(createUserZod),
  });
  const utils = trpc.useContext();
  const setNotification = useNotification((state) => state.setNotification);
  const [profilePicture, setProfilePicture] = useState('');
  const [userUploadId, setUserUploadId] = useState('');

  const { mutateAsync: uploadProfilePicture } =
    trpc.u.users.addUserProfilePicture.useMutation();

  const { mutate: createUser, isLoading } = trpc.u.users.create.useMutation({
    onSuccess: async (data) => {
      try {
        await utils.u.users.invalidate();
        const { id } = data;

        if (userUploadId)
          await uploadProfilePicture({ userId: id, userUploadId });

        setNotification({
          type: 'success',
          title: 'User Created',
          message: 'User created successfully',
        });

        navigate('/settings/users');
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error('e ', e);
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Something went wrong',
        });
      }
    },
  });

  const uploadPicture = (id: string, url: string) => {
    setUserUploadId(id);
    setProfilePicture(url);
  };

  return (
    <div className="min-h-full">
      <TopBar title="Add User" />
      <div className="max-w-lg mx-auto pt-10 pb-12 px-4 lg:pb-16">
        <form
          /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
          onSubmit={handleSubmit(createUser as SubmitHandler<CreateUserType>)}
        >
          <div className="space-y-6">
            <div className="flex">
              <div>
                <h1 className="text-lg leading-6 font-medium text-gray-900">
                  Onboard User
                </h1>
                <p className="mt-1 text-sm text-gray-500">
                  Add a new user to Listo and send an invitation email.
                </p>
              </div>
            </div>

            <div className="mb-4">
              <ProfilePictureUploader
                fullName="+"
                pictureUrl={profilePicture}
                uploadPicture={uploadPicture}
              />
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="firstName"
                  {...register('firstName', {
                    required: true,
                  })}
                  name="firstName"
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="lastName"
                  {...register('lastName', {
                    required: true,
                  })}
                  name="lastName"
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue=""
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="user-email"
                className="block text-sm font-medium text-gray-700"
              >
                User Email
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  id="email"
                  {...register('email', {
                    required: true,
                  })}
                  className="block w-full shadow-sm focus:ring-sky-500 focus:border-sky-500 sm:text-sm border border-gray-300 rounded-md"
                  defaultValue=""
                />
              </div>
            </div>

            <div className="flex justify-end">
              <button
                onClick={() => {
                  navigate('/settings/users');
                }}
                type="button"
                className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500"
              >
                Cancel
              </button>
              <Button
                loading={isLoading}
                type="submit"
                text="Create new user"
                className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
