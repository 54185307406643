import { useParams } from 'react-router-dom';
import { FixedRatePayment } from './FixedRatePayment';
import { PayAsYouGoPayment } from './PayAsYouGoPayment';

export function Payment() {
  const { type } = useParams();

  if (!type) return <h1>type required</h1>;
  if (type === 'fixed-rate') return <FixedRatePayment />;
  if (type === 'pay-as-you-go') return <PayAsYouGoPayment />;

  return <h1>unsupported type</h1>;
}
