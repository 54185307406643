import { zodResolver } from '@hookform/resolvers/zod';
import { countries } from 'listo/src/countries';
import {
  UpdateBankInfoType,
  updateBankInfoZod,
} from 'listo/src/zodObjects/bankAccounts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { SelectInput } from 'ui/src/components/SelectInput';
import { useMemo } from 'react';
import { requiresIban } from 'listo/src/utils/currency';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';

export function CreateBankAccount() {
  const setNotification = useNotification((state) => state.setNotification);
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    formState: { errors },
    watch,
  } = useForm<UpdateBankInfoType>({
    resolver: zodResolver(updateBankInfoZod),
  });

  const utils = trpc.useContext();
  const country = watch('country');

  const { mutate: createBankAccount, isLoading: updateLoading } =
    trpc.w.bankAccounts.createBankAccount.useMutation({
      onSuccess: () => {
        utils.w.bankAccounts.invalidate().catch(() => {});
        navigate('/wrkr/profile/bank-account');
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Bank account created',
        });
      },
    });

  const onSubmit: SubmitHandler<UpdateBankInfoType> = (data) => {
    createBankAccount(data);
  };

  const isIbanCountry = useMemo(() => requiresIban(country), [country]);

  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Add Bank Account" />
      <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 md:px-0">
            <div className="p-4 sm:p-6 bg-white lg:rounded">
              <div className="pt-6">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Bank Account Information
                  </h3>

                  <p className="mt-1 text-sm text-gray-500">
                    Add new bank details. Payments from Listo platform will be
                    issued to this bank account.
                  </p>
                </div>

                {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-2">
                      <SelectInput
                        label="Country"
                        defaultOption={{ label: 'Select', value: '' }}
                        options={countries.map((c) => ({
                          label: c.name,
                          value: c.alpha2,
                        }))}
                        reactHookForm={{
                          register,
                          fieldName: 'country',
                          errors,
                        }}
                        infoTooltipId="country"
                        infoTooltipContent="Country where your bank is located"
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <SelectInput
                        label="Business or Personal"
                        defaultOption={{ label: 'Select', value: '' }}
                        options={[
                          {
                            label: 'Business',
                            value: 'COMPANY',
                          },
                          {
                            label: 'Personal',
                            value: 'PERSONAL',
                          },
                        ]}
                        reactHookForm={{
                          register,
                          fieldName: 'entityType',
                          errors,
                        }}
                        infoTooltipId="businessOrPersonal"
                        infoTooltipContent="Type of Account."
                      />
                    </div>

                    <div className="sm:col-span-2">
                      <SelectInput
                        label="Checking or Savings"
                        defaultOption={{ label: 'Select', value: '' }}
                        options={[
                          {
                            label: 'Checking',
                            value: 'CHECKING',
                          },
                          {
                            label: 'Savings',
                            value: 'SAVINGS',
                          },
                        ]}
                        reactHookForm={{
                          register,
                          fieldName: 'type',
                          errors,
                        }}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Input
                        inputType="text"
                        label="Bank Name"
                        inputProps={{}}
                        reactHookForm={{
                          register,
                          fieldName: 'bankName',
                          errors,
                        }}
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Input
                        inputType="text"
                        label="Name on bank account"
                        inputProps={{}}
                        reactHookForm={{
                          register,
                          fieldName: 'ownerName',
                          errors,
                        }}
                        infoTooltipId="beneficiaryName"
                        infoTooltipContent="Name exactly as it appears on the bank account."
                      />
                    </div>

                    <div className="sm:col-span-3">
                      <Input
                        inputType="text"
                        label={isIbanCountry ? 'IBAN' : 'Account Number'}
                        inputProps={{}}
                        reactHookForm={{
                          register,
                          fieldName: 'number',
                          errors,
                        }}
                      />
                    </div>
                    <div className="sm:col-span-3">
                      <Input
                        inputType="text"
                        label="Routing/Swift Number"
                        inputProps={{}}
                        reactHookForm={{
                          register,
                          fieldName: 'routingNumber',
                          errors,
                        }}
                        infoTooltipId="routingNumber"
                        infoTooltipContent="If your bank is located in the USA enter the ACH routing number.<br/>
                         If you bank is located outside of the USA enter the SWIFT number."
                      />
                    </div>
                  </div>

                  <div className="flex justify-end mt-4">
                    <Button
                      type="button"
                      text="Cancel"
                      variant="secondary"
                      className="mx-2"
                      onClick={() => navigate('/wrkr/profile/bank-account')}
                    />
                    <Button
                      type="submit"
                      text="Create"
                      loading={updateLoading}
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
