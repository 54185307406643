import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

export function RequireAuth({
  children,
  requiredAuthType,
}: {
  children: JSX.Element;
  requiredAuthType: 'user' | 'worker';
}) {
  const jwt = useAuth((state) => state.jwt);
  const claims = useAuth((state) => state.claims);
  const location = useLocation();

  if (jwt === undefined || claims === undefined) {
    return null;
  }

  if (jwt === null || claims === null) {
    return (
      <Navigate
        to="/login"
        replace
        state={{ path: location.pathname, search: location.search }}
      />
    );
  }

  if (jwt && claims.accessType === requiredAuthType) {
    return children;
  }

  if (
    jwt &&
    claims.accessType !== requiredAuthType &&
    claims.accessType === 'worker'
  ) {
    return <Navigate to="/wrkr" />;
  }

  if (
    jwt &&
    claims.accessType !== requiredAuthType &&
    claims.accessType === 'user'
  ) {
    return <Navigate to="/" />;
  }

  return (
    <Navigate
      to="/login"
      replace
      state={{ path: location.pathname, search: location.search }}
    />
  );
}
