import { useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';
import InvoicePaid from '../Invoices/InvoicePaid';
import PdfModal from '../PdfModal';
import { InvoiceActionPanel } from './InvoiceActionPanel';
import { InvoiceData } from './InvoiceData';
import { InvoiceDetails } from './InvoiceDetails';
import { InvoiceEvents } from './InvoiceEvents';

function Panels({ id }: { id: string }) {
  const { data, isLoading, error } = trpc.u.invoices.invoice.useQuery(id);

  if (isLoading) return <Loader />;
  if (error) return <div>Error</div>;

  return (
    <div className="mx-auto grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
      <div className="space-y-6 lg:col-span-2 lg:col-start-1">
        <InvoiceDetails invoice={data} />
      </div>
      <InvoiceActionPanel invoice={data} />
    </div>
  );
}

function PayInvoice({
  invoiceId,
  setInvoiceModalClosed,
}: {
  invoiceId: string;
  setInvoiceModalClosed: () => void;
}) {
  const { mutate, isLoading } = trpc.u.invoices.initiatePayment.useMutation({
    onSuccess: (data) => {
      if (data.url) window.location.href = data.url;
    },
  });

  return (
    <div className="w-full px-4 pt-5 pb-4">
      <div className="flex justify-end">
        <Button
          variant="secondary"
          text="Cancel"
          onClick={setInvoiceModalClosed}
        />
        <Button
          className="ml-2"
          text="Pay"
          loading={isLoading}
          onClick={() => {
            mutate({
              invoiceId,
            });
          }}
        />
      </div>
    </div>
  );
}

export function Invoice() {
  const { id } = useParams();
  const [invoiceModalOpen, setInvoiceModalOpen] = useState(false);
  if (!id) throw new Error('no id');

  const {
    data: invoice,
    isLoading,
    error,
  } = trpc.u.invoices.invoice.useQuery(id);

  const shortId = id.split('-')[0];
  if (isLoading) return <Loader />;
  if (error)
    return <ErrorState trpcError={error} parentComponentName="Invoice.tsx" />;

  return (
    <main className="min-h-screen bg-gray-100 ">
      <TopBar
        breadcrumbs={[
          { name: 'Invoices', href: '/invoices', current: false },
          { name: shortId ?? '', href: `/invoices/${id}`, current: true },
        ]}
        title="Invoice"
      />
      {invoice.upload?.url ? (
        <PdfModal
          isOpen={invoiceModalOpen}
          setIsOpen={() => setInvoiceModalOpen(false)}
          title={`invoice-${invoice.id}`}
          pdfUrl={invoice.upload.url}
          footer={{
            children: (
              <PayInvoice
                invoiceId={invoice.id}
                setInvoiceModalClosed={() => setInvoiceModalOpen(false)}
              />
            ),
            offsetPx: 30,
          }}
        />
      ) : null}
      <div className="py-10">
        <Panels id={id} />
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
          {['GENERATED', 'GATHERING', 'APPROVAL'].includes('fakenews') ? (
            <InvoiceData
              id={id}
              reminders={['GENERATED', 'GATHERING'].includes('fakenews')}
            />
          ) : null}
        </div>
        <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 ">
          <InvoiceEvents id={id} />
        </div>
      </div>
      <InvoicePaid />
    </main>
  );
}
