import { countryNameByIsoCode } from 'listo/src/countries';
import { useNotification } from 'ui/src/components/Notification/useNotification';

import Loader from '../../components/Loader';
import { ProfilePictureUploader } from '../../components/ProfilePictureUploader';
import { trpc } from '../../lib/trpc';
import { useAuth } from '../../hooks/useAuth';

function ProfilePicture() {
  const { claims } = useAuth();
  const setNotification = useNotification((state) => state.setNotification);
  const userId = claims?.userId ?? '';
  const { data, refetch } = trpc.u.users.listById.useQuery(userId, {
    enabled: !!userId,
  });
  const pictureUrl = data?.userUpload?.url ?? '';
  const userFullName = data?.fullName ?? '';

  const { mutate: uploadProfilePicture } =
    trpc.u.users.addUserProfilePicture.useMutation({
      onSuccess: async () => {
        setNotification({
          type: 'success',
          title: 'Profile updated',
          message: 'Profile picture updated',
        });

        await refetch();
      },

      onError: () => {
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Something went wrong',
        });
      },
    });

  const uploadPicture = (id: string) =>
    uploadProfilePicture({ userId, userUploadId: id });

  return (
    <div className="my-4">
      <ProfilePictureUploader
        fullName={userFullName}
        pictureUrl={pictureUrl}
        uploadPicture={uploadPicture}
      />
    </div>
  );
}

export function General() {
  const { data, isLoading, error } = trpc.u.dashboard.settings.useQuery();

  if (isLoading) return <Loader />;
  if (error) return <div>error</div>;

  return (
    <div className="mt-10 divide-y divide-gray-200">
      <div className="space-y-1">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Overview
        </h3>
        <p className="max-w-2xl text-sm text-gray-500">
          General information about your account.
        </p>
      </div>

      <div className="mt-6">
        <dl className="divide-y divide-gray-200">
          <div className="flex items-end gap-8 flex-wrap">
            <div className="divide-y divide-gray-200 flex-1 flex-wrap">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500">Full Name</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{data.fullName}</span>
                </dd>
              </div>

              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <span className="flex-grow">{data.email}</span>
                </dd>
              </div>
            </div>
            <ProfilePicture />
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:pt-5">
            <dt className="text-sm font-medium text-gray-500">Company Name</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.companyName}</span>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Entity Type</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {data.entities[0]?.corporationType}
              </span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Address 1</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.client.address1}</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Address 2</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.client.address2}</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-sm font-medium text-gray-500">City</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.client.city}</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Postal Code</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.client.postalCode}</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-sm font-medium text-gray-500">
              State / Providence
            </dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">{data.client.zoneCode}</span>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:border-b sm:border-gray-200">
            <dt className="text-sm font-medium text-gray-500">Country</dt>
            <dd className="mt-1 flex text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <span className="flex-grow">
                {data.client.isoCountryCode &&
                  countryNameByIsoCode(data.client.isoCountryCode)}
              </span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  );
}
