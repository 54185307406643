import { classNames } from 'listo/src/utils/strings';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import TopBar from '../../components/TopBar';

const tabs = [
  { name: 'Personal Info', href: '/wrkr/profile/info' },
  {
    name: 'Bank Account',
    href: '/wrkr/profile/bank-account',
  },
  {
    name: 'Tax Info',
    href: '/wrkr/profile/tax',
  },
  {
    name: 'Change Password',
    href: '/wrkr/profile/password-update',
  },
];

function Tabs() {
  const { pathname } = useLocation();

  const navigate = useNavigate();

  return (
    <>
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.href === pathname)?.name}
          onChange={(event) => {
            const newTab = tabs.find((tab) => tab.name === event.target.value);
            if (newTab) navigate(newTab.href);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="flex items-center justify-between border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.href === pathname
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                )}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}

export function WorkerSettings() {
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Profile" />
      <div className="relative max-w-4xl mx-auto md:px-8 xl:px-0">
        <div className="pt-10 pb-16">
          <div className="px-4 sm:px-6 md:px-0">
            <div className="p-4 sm:p-6 bg-white lg:rounded">
              <Tabs />
              <Outlet />
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}
