import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { trpc } from '../../lib/trpc';
import Loader from '../Loader';
import Modal from '../Modal';
import { Document } from './Document';
import { EmailSent } from './EmailSent';
import { GatherSigner } from './GatherSigner';
import { useSigningModal } from './hooks/useSigningModal';
import { Steps } from './types';
import { WhoSignsClient } from './WhoSignsClient';

export function SignContract({
  contractId,
  signer,
  initialStep,
  onSuccess,
}: {
  contractId: string;
  signer: 'client' | 'worker';
  initialStep: Steps;
  onSuccess: () => void;
}) {
  const contract = trpc.l.legal.contract.useQuery(contractId);

  const signingModalOpen = useSigningModal((state) => state.isOpen);
  const setSigningModalOpen = useSigningModal((state) => state.setIsOpen);
  const [searchParams] = useSearchParams();
  const initialOpen = searchParams.get('initialOpen');
  const [step, setStep] = useState<Steps>(initialStep);

  useEffect(() => {
    setStep(initialStep);
  }, [initialStep]);

  useEffect(() => {
    // reset modal when it closes
    if (!signingModalOpen) {
      // delay to not change the step before the modal is closed
      setTimeout(() => {
        setStep(initialStep);
      }, 150);
    }
  }, [initialStep, signingModalOpen]);

  useEffect(() => {
    if (initialOpen) setSigningModalOpen(true);
  }, [initialOpen, setSigningModalOpen]);

  if (contract.isError) return <div>Error</div>;
  if (contract.isLoading) return <Loader />;
  return (
    <Modal isOpen={signingModalOpen} setIsOpen={setSigningModalOpen}>
      <div className="relative h-full">
        {step === 'whoSigns' ? (
          <WhoSignsClient
            setStep={setStep}
            onCancel={() => setSigningModalOpen(false)}
          />
        ) : null}
        {step === 'gatherSigner' ? (
          <GatherSigner
            contractId={contractId}
            setStep={setStep}
            onCancel={() => setSigningModalOpen(false)}
          />
        ) : null}
        {step === 'emailSent' ? (
          <EmailSent onCancel={() => setSigningModalOpen(false)} />
        ) : null}
        {step === 'document' ? (
          <Document
            customContractUrl={contract.data.customContractUpload?.url}
            contractId={contractId}
            onCancel={() => setSigningModalOpen(false)}
            signer={signer}
            onSuccess={onSuccess}
          />
        ) : null}
      </div>
    </Modal>
  );
}
