import { zodResolver } from '@hookform/resolvers/zod';
import { currencySelectOptions } from 'listo/src/countries';
import {
  eorCompensationFields,
  EorCompensationFields,
} from 'listo/src/zodObjects/contracts';
import { useCallback, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import shallow from 'zustand/shallow';
import Form from '../../components/Form';
import { Header } from '../../components/Form/Header';
import { NumberInput } from '../../components/Form/NumberInput';
import { RadioInput } from '../../components/Form/RadioInput';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { useEorForm } from './hooks/useEorForm';

export function Compensation() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const {
    distributionCurrency,
    workType,
    variableCompensation,
    employmentType,
    contractTerm,
    billingFrequency,
    billingAmountInCents,
  } = useEorForm(
    (state) => ({
      distributionCurrency: state.fields?.distributionCurrency,
      annualBaseSalary: state.fields?.annualBaseSalary,
      variableCompensation: state.fields?.variableCompensation,
      employmentType: state.fields?.employmentType,
      contractTerm: state.fields?.contractTerm,
      workType: state.fields?.workType,
      billingFrequency: state.fields?.billingFrequency,
      billingAmountInCents: state.fields?.billingAmountInCents,
    }),
    shallow,
  );
  const { setFields, fields } = useEorForm((state) => state);

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<EorCompensationFields>({
    resolver: zodResolver(eorCompensationFields),
  });

  const onSubmit: SubmitHandler<EorCompensationFields> = useCallback(
    (data) => {
      setFields(data);
      navigate('/create-contract/eor/benefits');
    },
    [navigate, setFields],
  );

  const autoValidate = searchParams.get('autoValidate');

  useEffect(() => {
    setValue('workType', 'salary');
  }, [setValue]);

  useEffect(() => {
    if (getValues('workType') === 'salary')
      setValue('billingFrequency', 'MONTHLY');
  }, [getValues, setValue]);

  useEffect(() => {
    if (autoValidate) {
      // eslint-disable-next-line no-console
      handleSubmit(onSubmit)().catch((e) => console.log(e));
    }
  }, [autoValidate, handleSubmit, onSubmit]);

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Next"
      secondaryButtonText="Back"
      secondaryButtonOnClick={() => navigate('/create-contract/eor/job')}
    >
      <>
        <Header
          heading="Compensation"
          subheading="How much should the employee be paid? Select between hourly and salary compensation."
        />
        <Section>
          <>
            <RadioInput
              defaultValue={workType}
              fieldName="workType"
              register={register}
              cols={6}
              error={errors.workType}
              options={[
                { label: 'Hourly', value: 'hourly' },
                { label: 'Salary', value: 'salary' },
              ]}
            />

            {watch('workType', fields?.workType) === 'hourly' && (
              <>
                <NumberInput
                  cols={3}
                  register={register}
                  fieldName="billingAmountInCents"
                  label="Payment Amount (USD)"
                  inputProps={{
                    step: 0.01,
                  }}
                  defaultValue={billingAmountInCents ?? 0}
                  error={errors.billingAmountInCents}
                />
                <SelectInput
                  cols={3}
                  register={register}
                  fieldName="billingFrequency"
                  label="Payment Rate"
                  defaultValue={billingFrequency ?? 'HOURLY'}
                  error={errors.billingFrequency}
                  options={[{ label: 'Hourly', value: 'HOURLY' }].map(
                    (frequency) => ({
                      label: frequency.label,
                      value: frequency.value,
                    }),
                  )}
                />
              </>
            )}

            {watch('workType', fields?.workType) === 'salary' && (
              <NumberInput
                cols={3}
                register={register}
                fieldName="billingAmountInCents"
                label="Gross Monthly Salary"
                inputProps={{
                  step: 0.01,
                }}
                defaultValue={billingAmountInCents ?? 0}
                error={errors.billingAmountInCents}
              />
            )}

            <SelectInput
              cols={6}
              register={register}
              prompt="The currency in which the employee will be paid"
              fieldName="distributionCurrency"
              label="Distribution Currency"
              error={errors.distributionCurrency}
              defaultValue={distributionCurrency}
              defaultOption={{ label: 'Select', value: '' }}
              options={currencySelectOptions}
            />
          </>
        </Section>

        <div className="space-y-8 divide-y divide-gray-200 pt-8">
          <div>
            <div>
              <Header
                heading="Employment Type"
                subheading="Note that definitions for “full time” and “part time” hours may vary by country."
              />
            </div>
            <RadioInput
              defaultValue={employmentType}
              fieldName="employmentType"
              register={register}
              cols={6}
              error={errors.employmentType}
              options={[
                { label: 'Full-time', value: 'full-time' },
                { label: 'Part-time', value: 'part-time' },
              ]}
            />
          </div>
        </div>

        <div className="space-y-8 divide-y divide-gray-200 pt-8">
          <div>
            <div>
              <Header
                heading="Variable Compensation"
                subheading="Eligible for variable Compensation"
              />
            </div>
            <RadioInput
              defaultValue={variableCompensation}
              fieldName="variableCompensation"
              register={register}
              cols={6}
              error={errors.variableCompensation}
              options={[
                { label: 'Yes', value: 'true' },
                { label: 'No', value: 'false' },
              ]}
            />
          </div>
        </div>

        <div className="space-y-8 divide-y divide-gray-200 pt-8">
          <div>
            <div>
              <Header
                heading="Contract Term"
                subheading="An “indefinite” contract term means the contract remains in place until terminated. A “definite” contract term terminates on a pre-defined date."
              />
            </div>

            <RadioInput
              defaultValue={contractTerm}
              fieldName="contractTerm"
              register={register}
              cols={6}
              error={errors.contractTerm}
              options={[
                { label: 'Indefinite', value: 'indefinite' },
                { label: 'Definite', value: 'definite' },
              ]}
            />
          </div>
        </div>
      </>
    </Form>
  );
}
