import { Dialog, Transition } from '@headlessui/react';
import {
  ArrowRightOnRectangleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { useQueryClient } from '@tanstack/react-query';
import { classNames } from 'listo/src/utils/strings';
import posthog from 'posthog-js';
import { Fragment, useCallback } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import useSidebar from '../../hooks/useSidebar';
import Logo from '../../public/images/logo-w-wordmark-turq-white.svg';
import CustomNavLink from '../CustomNavLink';
import { Accounts, AccountSelect } from './AccountSelect';

export interface SidebarNavElement {
  name: string;
  href: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  icon: any;
}
type SidebarNavElements = SidebarNavElement[];

export function Sidebar({
  navigation,
  secondaryNavigation,
  accounts,
}: {
  navigation: SidebarNavElements;
  secondaryNavigation: SidebarNavElements;
  accounts?: Accounts;
}) {
  const clear = useAuth((state) => state.clear);
  const queryClient = useQueryClient();
  const { sidebarIsOpen, setSidebarIsOpen } = useSidebar();
  const { pathname } = useLocation();

  const logout = useCallback(() => {
    posthog.reset();
    queryClient.clear();
    clear();
  }, [clear, queryClient]);

  return (
    <>
      <Transition.Root show={sidebarIsOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarIsOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-indigo-700">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarIsOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XCircleIcon
                      className="h-6 w-6 text-white"
                      aria-hidden="true"
                    />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center justify-center px-4 py-6">
                <img className="h-8 w-auto" src={Logo} alt="listo logo" />
              </div>
              <nav
                className="mt-5 flex-shrink-0 h-full divide-y divide-indigo-800 overflow-y-auto"
                aria-label="Sidebar"
                id="mobile-sidebar-nav"
              >
                <div className="px-2 space-y-1">
                  {navigation.map((item) => (
                    <CustomNavLink
                      key={item.name}
                      onClick={() => setSidebarIsOpen(false)}
                      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                      Icon={item.icon}
                      to={item.href}
                      title={item.name}
                      baseClasses="group flex items-center px-2 py-2 text-base font-medium rounded-md"
                    />
                  ))}
                </div>
                <div className="mt-6 pt-6">
                  <div className="px-2 space-y-1">
                    {secondaryNavigation.map((item) => (
                      <NavLink
                        onClick={() => setSidebarIsOpen(false)}
                        key={item.name}
                        to={item.href}
                        className="group flex items-center px-2 py-2 text-base font-medium rounded-md text-indigo-100 hover:text-white hover:bg-indigo-600"
                      >
                        <item.icon
                          className="mr-4 h-6 w-6 text-indigo-200"
                          aria-hidden="true"
                        />
                        {item.name}
                      </NavLink>
                    ))}
                    {/* TODO: add for mobile nav something isn't working */}
                    {accounts && accounts.length > 1 ? (
                      <div className="z-50">
                        <AccountSelect accounts={accounts} />
                      </div>
                    ) : null}
                    <NavLink
                      onClick={() => {
                        logout();
                      }}
                      to="/login"
                      className="group flex items-center px-2 py-2  text-base font-medium rounded-md text-indigo-100 hover:text-white hover:bg-indigo-600"
                    >
                      <ArrowRightOnRectangleIcon
                        className="mr-4 h-6 w-6 text-indigo-200"
                        aria-hidden="true"
                      />
                      Logout
                    </NavLink>
                  </div>
                </div>
              </nav>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>

      <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
        {/* Sidebar component, swap this element with another sidebar if you like */}
        <div className="flex flex-col flex-grow bg-indigo-700 pt-5 pb-4 overflow-y-auto">
          <div className="flex items-center justify-center flex-shrink-0 px-4 py-6">
            <img className="h-8 w-auto" src={Logo} alt="listo logo" />
          </div>
          <nav
            className="mt-5 flex-1 flex flex-col divide-y divide-indigo-800 overflow-y-auto"
            aria-label="Sidebar"
          >
            <div className="px-2 space-y-1">
              {navigation.map((item) => (
                <CustomNavLink
                  key={item.name}
                  onClick={() => setSidebarIsOpen(false)}
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                  Icon={item.icon}
                  to={item.href}
                  title={item.name}
                />
              ))}
            </div>
            <div className="mt-6 pt-6">
              <div className="px-2 space-y-1">
                {secondaryNavigation.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className={({ isActive }: { isActive: boolean }) =>
                      classNames(
                        (
                          isActive || item.name === 'Settings'
                            ? pathname.includes('settings')
                            : false
                        )
                          ? 'bg-indigo-800 text-white'
                          : 'text-indigo-100 hover:text-white hover:bg-indigo-600',
                        'group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md',
                      )
                    }
                  >
                    <item.icon
                      className="mr-4 h-6 w-6 text-indigo-200"
                      aria-hidden="true"
                    />
                    {item.name}
                  </NavLink>
                ))}
                {accounts && accounts.length > 1 ? (
                  <AccountSelect accounts={accounts} />
                ) : null}
                <Link
                  onClick={() => {
                    logout();
                  }}
                  to="/login"
                  className="group flex items-center px-2 py-2 text-sm leading-6 font-medium rounded-md text-indigo-100 hover:text-white hover:bg-indigo-600"
                >
                  <ArrowRightOnRectangleIcon
                    className="mr-4 h-6 w-6 text-indigo-200"
                    aria-hidden="true"
                  />
                  Logout
                </Link>
              </div>
            </div>
          </nav>
        </div>
      </div>
    </>
  );
}
