/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import jwt_decode from 'jwt-decode';
import { useEffect } from 'react';
import { ADMIN_URL, APP_URL } from '../lib/constants';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function listenForImpersonationRequest(event: any) {
  if (event.origin !== ADMIN_URL && event.name !== 'impersonate') return;

  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { jwt, redirectionURL = APP_URL } = event.data.data;
    jwt_decode(jwt);

    window.localStorage.setItem('jwt', jwt);

    if (window.parent) {
      window.parent.postMessage(
        {
          event: 'app.redirect',
          data: {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            redirectionURL,
          },
        },
        ADMIN_URL,
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error with listenForImpersonationRequest ', error);
  }
}

export default function useImpersonate() {
  useEffect(() => {
    window.addEventListener('message', listenForImpersonationRequest);

    return () => {
      window.removeEventListener('message', listenForImpersonationRequest);
    };
  }, []);
}
