import { formatDate } from 'listo/src/utils/dates';
import { titleCase } from 'listo/src/utils/strings';
import { RouterOutput } from '../../lib/trpc';

export function payPeriodStatusLabel(
  payPeriod: NonNullable<RouterOutput['u']['payPeriods']['dashboard']>,
) {
  if (payPeriod.status === 'SCHEDULED') return 'Scheduled';

  if (payPeriod.status === 'PENDING_APPROVAL' && !payPeriod.approvedAt)
    return 'Pending Approval';
  if (payPeriod.status === 'APPROVED' && payPeriod.approvedAt)
    return 'Approved';

  if (payPeriod.status === 'INVOICED' && !payPeriod.invoicedAt)
    return 'Pending Invoice';
  if (payPeriod.status === 'INVOICED' && payPeriod.invoicedAt)
    return 'Invoiced';

  if (payPeriod.status === 'DISTRIBUTING' && !payPeriod.distributedAt)
    return 'Pending Distribution';
  if (payPeriod.status === 'DISTRIBUTING' && payPeriod.distributedAt)
    return 'Distributing';

  if (payPeriod.status === 'COMPLETE') {
    return 'Complete';
  }

  return titleCase(payPeriod.status);
}

export function payPeriodDescription(
  payPeriod: RouterOutput['u']['payPeriods']['payPeriod'],
) {
  if (payPeriod.status === 'SCHEDULED')
    return `Your pay period is scheduled. The pay period will be ready to approve on ${formatDate(
      payPeriod.approvalDate,
    )}`;
  if (payPeriod.status === 'PENDING_APPROVAL' && !payPeriod.approvedAt) {
    return `Your pay period is pending approval. The pay period period must be approved on or before ${formatDate(
      payPeriod.approvalDate,
    )} in order for Listo to collect and distribute payments on-time.`;
  }
  if (payPeriod.status === 'APPROVED' && payPeriod.approvedAt) {
    return `Your pay period is approved. Your invoice will be issued on ${formatDate(
      payPeriod.invoiceDate,
    )}.`;
  }

  if (payPeriod.status === 'INVOICED' && !payPeriod.invoiceId) {
    return `Your invoice is pending. Listo will invoice you soon.`;
  }
  if (payPeriod.status === 'INVOICED' && payPeriod.invoiceId) {
    return `Your invoice is ready. Please view and pay your invoice so that Listo can distribute payments to your team.`;
  }

  if (payPeriod.status === 'DISTRIBUTING' && !payPeriod.distributedAt) {
    return `Your pay period is pending distribution. Listo will distribute payments to your team on ${formatDate(
      payPeriod.distributeDate,
    )}.`;
  }
  if (payPeriod.status === 'DISTRIBUTING' && payPeriod.distributedAt) {
    return `Your payments are being distributed. Payments have been sent to your team.`;
  }

  if (payPeriod.status === 'COMPLETE') {
    return `Your pay period is complete. Distribution payments have been sent to your team.`;
  }

  return '';
}
