import create from 'zustand';

interface MultiStepForm {
  setFields: (fields: Record<string, unknown>) => void;
  fields: undefined | Record<string, unknown | undefined>;
  resetForm: () => void;
}

export const useCreateForm = create<MultiStepForm>((set) => ({
  fields: undefined,
  setFields: (fields: Record<string, unknown>) =>
    set((state) => ({ fields: { ...state.fields, ...fields } })),
  resetForm: () => set(() => ({ fields: undefined })),
}));
