import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import PdfModal from '../PdfModal';

function QuoteFooter({ quoteId }: { quoteId: string }) {
  const navigate = useNavigate();
  const utils = trpc.useContext();

  const { mutate: acceptQuote, isLoading } = trpc.u.quotes.accept.useMutation({
    onSuccess: () => {
      utils.u.quotes.quote.invalidate({ quoteId }).catch(() => {});
      navigate('/team');
    },
  });

  return (
    <div className="w-full px-4 pt-5 pb-4">
      <div className="flex justify-end">
        <Button
          variant="secondary"
          text="Cancel"
          onClick={() => navigate('/team')}
        />
        <Button
          className="ml-2"
          text="Accept Quote"
          loading={isLoading}
          onClick={() => {
            acceptQuote(quoteId);
          }}
        />
      </div>
    </div>
  );
}

export function QuoteModal({ quoteId }: { quoteId: string }) {
  const {
    data: quote,
    isLoading,
    error,
  } = trpc.u.quotes.quote.useQuery({ quoteId });

  if (isLoading) return <Loader />;
  if (error)
    return (
      <ErrorState trpcError={error} parentComponentName="AcceptQuote.tsx" />
    );

  if (!quote?.upload?.url) return <div>no upload</div>;

  return (
    <PdfModal
      pdfUrl={quote.upload.url}
      isOpen
      setIsOpen={() => null}
      title="quote"
      footer={{
        children: <QuoteFooter quoteId={quoteId} />,
        offsetPx: 30,
      }}
    />
  );
}
