import { zodResolver } from '@hookform/resolvers/zod';
import { countries } from 'listo/src/countries';
import {
  RecruitFormType,
  recruitInfoStepZod,
} from 'listo/src/zodObjects/recruits';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Form from '../../components/Form';
import { Section } from '../../components/Form/Section';
import { SelectInput } from '../../components/Form/SelectInput';
import { TextAreaInput } from '../../components/Form/TextAreaInput';
import { TextInput } from '../../components/Form/TextInput';
import { useCreateForm } from './hooks/useCreateForm';

const seniorityLevels = [
  { label: 'Not Applicable', value: 'not-applicable' },
  { label: 'Junior', value: 'junior' },
  { label: 'Mid', value: 'mid' },
  { label: 'Senior', value: 'senior' },
  { label: 'Lead', value: 'lead' },
  { label: 'Principal', value: 'principal' },
  { label: 'Director', value: 'director' },
  { label: 'Department Head', value: 'department-head' },
  { label: 'Vice President', value: 'vice-president' },
  { label: 'Senior Vice President', value: 'senior-vice-president' },
  { label: 'C-Level Executive', value: 'c-level-executive' },
];

const educationLevels = [
  { label: 'Not Applicable', value: 'not-applicable' },
  { label: 'High School Degree', value: 'high-school-degree' },
  { label: 'Some College', value: 'some-college' },
  { label: 'Associate Degree', value: 'associate-degree' },
  { label: 'Bachelor Degree', value: 'bachelor-degree' },
  { label: 'Master Degree', value: 'master-degree' },
  { label: 'PhD', value: 'phd' },
];

const experience = [
  { label: 'Not Applicable', value: 'not-applicable' },
  { label: 'Less than 1 year', value: 'Less-than-1-year' },
  { label: '1-2 years', value: '1-2-years' },
  { label: '3-5 years', value: '3-5-years' },
  { label: '6-10 years', value: '6-10-years' },
  { label: '10+ years', value: '10+-years' },
];

export function Info() {
  const navigate = useNavigate();
  const setFields = useCreateForm((s) => s.setFields);
  const fields = useCreateForm((s) => s.fields);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RecruitFormType>({ resolver: zodResolver(recruitInfoStepZod) });

  const onSubmit: SubmitHandler<RecruitFormType> = (data) => {
    setFields(data);
    navigate(`/create-recruit/payment`);
  };

  return (
    <Form
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      primaryButtonText="Next"
      secondaryButtonText="Cancel"
      secondaryButtonOnClick={() => navigate('/recruiting')}
    >
      <Section
        heading="Job Info"
        subheading="Please provide information regarding the work the contractor will be performing."
      >
        <>
          <TextInput
            cols={3}
            register={register}
            fieldName="jobTitle"
            label="Job Title"
            defaultValue={fields?.jobTitle as string}
            error={errors.jobTitle}
          />
          <SelectInput
            cols={3}
            register={register}
            fieldName="seniorityLevel"
            label="Seniority Level"
            error={errors.seniorityLevel}
            defaultValue={fields?.seniorityLevel as string}
            defaultOption={{ label: 'Select', value: '' }}
            options={seniorityLevels.map((level) => ({
              label: level.label,
              value: level.value,
            }))}
          />
          <TextAreaInput
            cols={6}
            register={register}
            fieldName="scopeOfWork"
            label="Scope of Work"
            description="Write a few sentences explaining the role and responsibilities."
            defaultValue={fields?.scopeOfWork as string}
            error={errors.scopeOfWork}
          />
          <TextInput
            cols={3}
            register={register}
            fieldName="fieldOfExpertise"
            label="Field of Expertise"
            defaultValue={fields?.fieldOfExpertise as string}
            error={errors.jobTitle}
          />
          <SelectInput
            cols={3}
            register={register}
            fieldName="countryOfOrigin"
            label="Country of Origin"
            error={errors.countryOfOrigin}
            defaultValue={fields?.countryOfOrigin as string}
            defaultOption={{ label: 'Select', value: '' } as const}
            options={countries.map((ctr) => ({
              label: ctr.name,
              value: ctr.alpha2,
            }))}
          />
          <SelectInput
            cols={3}
            register={register}
            fieldName="experience"
            label="Experience"
            error={errors.experience}
            defaultValue={fields?.experience as string}
            defaultOption={{ label: 'Select', value: '' } as const}
            options={experience.map((exp) => ({
              label: exp.label,
              value: exp.value,
            }))}
          />
          <SelectInput
            cols={3}
            register={register}
            fieldName="educationLevel"
            label="Education Level"
            error={errors.educationLevel}
            defaultValue={fields?.educationLevel as string}
            defaultOption={{ label: 'Select', value: '' }}
            options={educationLevels.map((level) => ({
              label: level.label,
              value: level.value,
            }))}
          />
        </>
      </Section>
    </Form>
  );
}
