/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
// eslint-disable-next-line no-restricted-imports
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartData,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { formatCentsToDollars } from 'listo/src/utils/currency';
import { formatDate } from 'listo/src/utils/dates';
import { useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

// from a year ago till today
const today = new Date();
const sDate = new Date(today.setFullYear(today.getFullYear() - 1));
const eDate = new Date();

const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
};

export function CostBreakdown() {
  const [startDate, setStartDate] = useState(sDate);
  const [endDate, setEndDate] = useState(eDate);
  const [totalAmount, setTotalAmount] = useState(0);
  const [dataSet, setDataSet] = useState<Record<string, number>>({});
  const [parsedLabels, setParsedLabels] = useState<string[]>([]);
  const [parsedDataSet, setParsedDataSet] = useState<any[]>([]);
  const [invoices, setInvoices] = useState<Record<string, any>>({});
  const [barData, setBarData] = useState<
    ChartData<'bar', number[]> | undefined
  >();

  function getMonthsArray(start: null | Date, end: null | Date): string[] {
    const months: string[] = [];
    const startCopy = start === null ? startDate : start;
    const endCopy = end === null ? endDate : end;
    for (
      let dt = new Date(startCopy);
      dt <= new Date(endCopy);
      dt.setDate(dt.getDate() + 1)
    ) {
      const month = dt.getMonth() + 1;
      let monthStr = month.toString();
      if (month < 10) {
        monthStr = `0${monthStr}`;
      }
      const yearMonthKey = `${dt.getFullYear()}${monthStr}`;
      if (!months.includes(yearMonthKey)) {
        months.push(yearMonthKey.toString());
      }
    }
    return months;
  }

  const [labels, setLabels] = useState(getMonthsArray(sDate, eDate));
  trpc.u.reporting.payPeriodReport.useQuery(
    {
      startDate: startDate.toISOString(),
      endDate: endDate.toISOString(),
    },
    {
      onSuccess(d) {
        const set: Record<string, number> = {};
        labels.forEach((label) => {
          set[label] = 0;
        });
        let total = 0;
        setInvoices(d);
        d.forEach((item) => {
          const dt = new Date(item.invoiceDate);
          const month = dt.getMonth() + 1;
          let monthStr = month.toString();
          if (month < 10) {
            monthStr = `0${month.toString()}`;
          }
          const key = `${dt.getFullYear()}${monthStr}`;
          const dollarAmount = parseFloat(item.amountInCents.toString()) / 100;
          set[key] += dollarAmount;
          total += dollarAmount;
        });
        setTotalAmount(Math.round(total * 100) / 100);
        setDataSet(set);
      },
    },
  );

  useEffect(() => {
    const parsed = [...labels].map(
      (label: string) => `${label.slice(0, 4)}-${label.slice(4)}`,
    );
    setParsedLabels(parsed);
  }, [labels]);

  useEffect(() => {
    const parsed = Object.keys(dataSet).map((key) => dataSet[key]);

    setParsedDataSet(parsed);
  }, [dataSet]);

  useEffect(() => {
    setBarData({
      labels: parsedLabels,
      datasets: [
        {
          label: '',
          data: parsedDataSet,
          backgroundColor: 'hsl(252, 82.9%, 67.8%)',
          borderColor: 'hsl(252, 82.9%, 67.8%)',
        },
      ],
    });
  }, [parsedLabels, parsedDataSet]);

  const onChangeStartDate = (e: { target: { value: string } }) => {
    setStartDate(new Date(e.target.value));
    setLabels(getMonthsArray(new Date(e.target.value), null));
  };

  const onChangeEndDate = (e: { target: { value: string } }) => {
    setEndDate(new Date(e.target.value));
    setLabels(getMonthsArray(null, new Date(e.target.value)));
  };

  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Cost Breakdown" />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between">
            <div className="flex">
              <label
                className="block text-sm font-medium text-gray-700 mt-3 mr-3"
                htmlFor="start-date"
              >
                Start Date
              </label>
              <div className="mt-1 mr-20">
                <input
                  id="start-date"
                  type="date"
                  defaultValue={sDate.toISOString().substring(0, 10)}
                  onChange={onChangeStartDate}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
              <label
                className="block text-sm font-medium text-gray-700 mt-3 mr-3"
                htmlFor="end-date"
              >
                End Date
              </label>
              <div className="mt-1">
                <input
                  id="end-date"
                  type="date"
                  defaultValue={eDate.toISOString().substring(0, 10)}
                  onChange={onChangeEndDate}
                  className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                />
              </div>
            </div>
            <div className="text-3xl font-semibold mt-1">
              Total: ${totalAmount}
            </div>
          </div>
          <div className="mt-4">
            {barData ? <Bar options={options} data={barData as any} /> : null}
          </div>
        </div>
      </div>
      <div className="mt-8">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8 bg-white shadow">
          <div className="px-4 py-5 sm:p-6">
            <h2 className="font-semibold text-2xl mt-4 text-gray-900">
              Invoices
            </h2>
            {invoices.length > 0 ? (
              <table className="table-auto mt-4 min-w-full divide-y divide-gray-300">
                <thead>
                  <tr>
                    <th className="py-2 px-2 text-left"> Contract </th>
                    <th className="py-2 px-2 text-left"> Amount </th>
                    <th className="py-2 px-2 text-left"> Date </th>
                    <th className="py-2 px-2 text-left"> Country </th>
                    <th className="py-2 px-2 text-left"> Type </th>
                  </tr>
                </thead>
                <tbody>
                  {invoices.map((invoice: any, idx: number) => (
                    <tr
                      key={invoice.id}
                      className={idx % 2 === 0 ? undefined : 'bg-gray-50'}
                    >
                      <td className="py-6 px-2"> {invoice.description} </td>
                      <td className="py-6 px-2">
                        {' '}
                        {formatCentsToDollars(
                          invoice.amountInCents,
                          'USD',
                        )}{' '}
                      </td>
                      <td className="py-6 px-2">
                        {' '}
                        {formatDate(invoice.invoiceDate)}{' '}
                      </td>
                      <td className="py-6 px-2"> {invoice.isoCountryCode} </td>
                      <td className="py-6 px-2">
                        {' '}
                        {invoice.type?.replace('_', ' ')}{' '}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="mt-4 mb-2 text-center text-gray-500">
                There are no invoices for this time period.
              </div>
            )}
          </div>
        </div>
      </div>
    </main>
  );
}
