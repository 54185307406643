import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/20/solid';
import {
  ChevronRightIcon,
  MagnifyingGlassCircleIcon,
  PlusIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { formatCentsToDollars } from 'listo/src/utils/currency';
import { classNames } from 'listo/src/utils/strings';
import { debounce } from 'lodash';
import {
  ChangeEventHandler,
  Fragment,
  MouseEventHandler,
  useRef,
  useState,
} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Pagination from '../../components/Pagination/Pagination';
import TopBar from '../../components/TopBar';
import { RouterOutput, trpc } from '../../lib/trpc';

interface SearchItem {
  key?: string;
  value?: string;
}

interface FilterParams {
  search?: SearchItem[];
  filter?: Record<string, string[]>;
  sort?: SortItem[];
  pagination?: {
    pageSize: number;
    pageNumber: number;
  };
}

interface SortItem {
  key: string;
  direction: 'asc' | 'desc';
}

// const stats = [
//   {
//     name: 'Countries',
//     stat: '2',
//   },
// ];

const sortOptions = [
  { name: 'Most Popular', href: '#' },
  { name: 'Best Rating', href: '#' },
  { name: 'Newest', href: '#' },
];
const filters = [
  {
    id: 'country',
    name: 'Country',
    options: [
      // { value: 'slovenia', label: 'Slovenia' },
      { value: 'us', label: 'United States' },
      // { value: 'greece', label: 'Greece' },
    ],
  },
  {
    id: 'status',
    name: 'Status',
    options: [
      { value: 'unsent', label: 'Unsent' },
      { value: 'sent', label: 'Sent' },
      { value: 'paid', label: 'Paid' },
      { value: 'overdue', label: 'Overdue' },
      { value: 'viewed', label: 'Viewed' },
      { value: 'partial', label: 'Partial' },
    ],
  },
];

const initialFilterParams = {
  pagination: {
    pageNumber: 1,
    pageSize: 10,
  },
};
interface FiltersProps {
  filterParamsChange: (value: FilterParams) => void;
}

export function Filters({ filterParamsChange }: FiltersProps) {
  const [open, setOpen] = useState(false);
  const filterParams = useRef<FilterParams>(initialFilterParams);

  const handleSearchTermChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    const { value } = e.target;
    filterParams.current = {
      ...filterParams.current,
      search: [{ key: 'name', value }],
    };
    filterParamsChange(filterParams.current);
  };

  const handleFilterChange = (key: string, value: string) =>
    ((e) => {
      const { checked } = e.target;
      const existingFilters = filterParams.current.filter ?? {};
      const usedKey = key.toLowerCase();
      let usedValues = existingFilters[usedKey] ?? [];
      if (checked) {
        usedValues.push(value);
        usedValues = [...new Set(usedValues)];
      } else {
        usedValues = usedValues.filter((item) => value !== item);
      }

      const updatedFilters = {
        ...existingFilters,
        [usedKey]: usedValues,
      };

      filterParams.current = {
        ...filterParams.current,
        filter: updatedFilters,
      };

      filterParamsChange(filterParams.current);
    }) as ChangeEventHandler<HTMLInputElement>;

  const handleSortChange = (key: string) =>
    (() => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const keys: any = {
        Newest: 'createdAt',
      };
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
      const usedKey = keys[key];

      if (!usedKey) {
        return;
      }

      const existingSort = filterParams.current.sort ?? [];
      const direction = 'desc';
      const updatedSort = [
        ...existingSort,
        {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          key: usedKey,
          direction,
        } as SortItem,
      ];

      filterParams.current = {
        ...filterParams.current,
        sort: updatedSort,
      };

      filterParamsChange(filterParams.current);
    }) as MouseEventHandler<HTMLAnchorElement>;

  return (
    <div>
      {/* Mobile filter dialog */}
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 flex z-40">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <Dialog.Panel className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
                <div className="px-4 flex items-center justify-between">
                  <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                  <button
                    type="button"
                    className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                    onClick={() => setOpen(false)}
                  >
                    <span className="sr-only">Close menu</span>
                    <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>

                {/* Filters */}
                <form className="mt-4">
                  {filters.map((section) => (
                    <Disclosure
                      as="div"
                      key={section.name}
                      className="border-t border-gray-200 px-4 py-6"
                    >
                      {({ open: isOpen }) => (
                        <>
                          <h3 className="-mx-2 -my-3 flow-root">
                            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
                              <span className="font-medium text-gray-900">
                                {section.name}
                              </span>
                              <span className="ml-6 flex items-center">
                                <ChevronDownIcon
                                  className={classNames(
                                    isOpen ? '-rotate-180' : 'rotate-0',
                                    'h-5 w-5 transform',
                                  )}
                                  aria-hidden="true"
                                />
                              </span>
                            </Disclosure.Button>
                          </h3>
                          <Disclosure.Panel className="pt-6">
                            <div className="space-y-6">
                              {section.options.map((option, optionIdx) => (
                                <div
                                  key={option.value}
                                  className="flex items-center"
                                >
                                  <input
                                    id={`filter-mobile-${section.id}-${optionIdx}`}
                                    name={`${section.id}[]`}
                                    onChange={handleFilterChange(
                                      section.name,
                                      option.value,
                                    )}
                                    defaultValue={option.value}
                                    type="checkbox"
                                    className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                  />
                                  <label
                                    htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                    className="ml-3 text-sm text-gray-500"
                                  >
                                    {option.label}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))}
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className="max-w-6xl mx-auto text-center lg:max-w-6xl">
        <section aria-labelledby="filter-heading" className="py-6">
          <h2 id="filter-heading" className="sr-only">
            Recruit filters
          </h2>

          <div className="flex items-center justify-between">
            <div>
              <label htmlFor="mobile-search-candidate" className="sr-only">
                Search
              </label>
              <label htmlFor="desktop-search-candidate" className="sr-only">
                Search
              </label>
              <div className="relative flex-grow focus-within:z-10 pr-4">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MagnifyingGlassCircleIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  onInput={handleSearchTermChange}
                  type="text"
                  name="mobile-search-candidate"
                  id="mobile-search-candidate"
                  className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded rounded-l-md pl-10 sm:hidden border-gray-300"
                  placeholder="Search"
                />
                <input
                  onInput={handleSearchTermChange}
                  type="text"
                  name="desktop-search-candidate"
                  id="desktop-search-candidate"
                  className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
                  placeholder="Search recruits"
                />
              </div>
            </div>

            <Menu as="div" className="relative z-10 inline-block text-left">
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sortOptions.map((option) => (
                      <Menu.Item key={option.name}>
                        {({ active }) => (
                          <a
                            href={option.href}
                            onClick={handleSortChange(option.name)}
                            className={classNames(
                              active ? 'bg-gray-100' : '',
                              'block px-4 py-2 text-sm font-medium text-gray-900',
                            )}
                          >
                            {option.name}
                          </a>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
              onClick={() => setOpen(true)}
            >
              Filters
            </button>

            <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8">
              {filters.map((section, sectionIdx) => (
                <Popover
                  as="div"
                  key={section.name}
                  id="desktop-menu"
                  className="relative z-10 inline-block text-left"
                >
                  <div>
                    <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                      <span>{section.name}</span>
                      {sectionIdx === 0 ? (
                        <span className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums">
                          1
                        </span>
                      ) : null}
                      <ChevronDownIcon
                        className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                        aria-hidden="true"
                      />
                    </Popover.Button>
                  </div>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                      <form className="space-y-4">
                        {section.options.map((option, optionIdx) => (
                          <div key={option.value} className="flex items-center">
                            <input
                              id={`filter-${section.id}-${optionIdx}`}
                              name={`${section.id}[]`}
                              defaultValue={option.value}
                              type="checkbox"
                              className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                              onChange={handleFilterChange(
                                section.name,
                                option.value,
                              )}
                            />
                            <label
                              htmlFor={`filter-${section.id}-${optionIdx}`}
                              className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                            >
                              {option.label}
                            </label>
                          </div>
                        ))}
                      </form>
                    </Popover.Panel>
                  </Transition>
                </Popover>
              ))}
            </Popover.Group>
          </div>
        </section>
      </div>
    </div>
  );
}

// export function Stats() {
//   return (
//     <div>
//       <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-3 md:divide-y-0 md:divide-x">
//         {stats.map((item) => (
//           <div key={item.name} className="px-4 py-5 sm:p-6">
//             <dt className="text-base font-normal text-gray-900">{item.name}</dt>
//             <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
//               <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
//                 {item.stat}
//                 <span className="ml-2 text-sm font-medium text-gray-500">
//                   {/* {item.previousStat} */}
//                 </span>
//               </div>
//             </dd>
//           </div>
//         ))}
//       </dl>
//     </div>
//   );
// }

/* This example requires Tailwind CSS v2.0+ */
export function PaymentDue() {
  return (
    <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="sm:flex sm:items-start sm:justify-between">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Make Payment
              </h3>
              <div className="mt-2 max-w-xl text-sm text-gray-500">
                <p>One of your payments is due.</p>
              </div>
            </div>
            <div className="mt-5 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-center">
              <button
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
              >
                Pay
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function Empty() {
  const navigate = useNavigate();
  return (
    <div className="text-center mt-6">
      <svg
        className="mx-auto h-12 w-12 text-gray-400"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        aria-hidden="true"
      >
        <path
          vectorEffect="non-scaling-stroke"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
        />
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">
        No recruitment listing
      </h3>
      <p className="mt-1 text-sm text-gray-500">
        Get started by creating a new recruitment listing.
      </p>
      <div className="mt-6">
        <button
          type="button"
          onClick={() => {
            navigate('/create-recruit');
          }}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon className="-ml-1 mr-2 h-5 w-5" aria-hidden="true" />
          New Recruit
        </button>
      </div>
    </div>
  );
}

export function Table({
  data,
  isLoading,
  onPageChange,
}: {
  data?: RouterOutput['u']['recruits']['list'];
  isLoading: boolean;
  onPageChange?: (page: number) => void;
}) {
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (data && data.results.recruits.length === 0) return <Empty />;
  if (data === undefined) return <div>No data</div>;

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-md">
      <ul className="divide-y divide-gray-200">
        {data.results.recruits.map((recruit) => (
          <li key={recruit.id}>
            <Link
              to={`/recruits/${recruit.id}`}
              className="block hover:bg-gray-50"
            >
              <div className="px-4 py-4 flex items-center sm:px-6">
                <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                  <div className="truncate">
                    <div className="flex text-sm items-center">
                      <p className="font-medium text-indigo-600 truncate">
                        {recruit.jobTitle}
                      </p>
                    </div>
                    <div className="mt-2 flex">
                      <div className="flex items-center text-sm text-gray-500">
                        <p className="">{recruit.countryOfOrigin}</p>
                      </div>
                    </div>
                  </div>
                  <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                    <div className="flex items-center">
                      <div className="ml-2 lg:ml-6">
                        <h1>
                          {formatCentsToDollars(
                            recruit.minSalary * 100,
                            recruit.paymentCurrency,
                          )}{' '}
                          -{' '}
                          {formatCentsToDollars(
                            recruit.maxSalary * 100,
                            recruit.paymentCurrency,
                          )}
                        </h1>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-5 flex-shrink-0">
                  <ChevronRightIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </Link>
          </li>
        ))}
      </ul>
      <Pagination paging={data.paging} onPageChange={onPageChange} />
    </div>
  );
}

export function Recruiting() {
  const [filterParams, setFilterParams] =
    useState<FilterParams>(initialFilterParams);
  const filterParamsChange = debounce(
    (value: FilterParams) => setFilterParams(value),
    400,
  );

  const onPageChange = (page: number) => {
    const updatedFilterParams: FilterParams = {
      ...filterParams,
      pagination: {
        ...filterParams.pagination,
        pageNumber: page,
        pageSize: 10,
      },
    };
    filterParamsChange(updatedFilterParams);
  };

  const { data, isLoading, error } = trpc.u.recruits.list.useQuery();

  const hasData = !!data?.results.recruits.length;

  if (error) return <div>Error!</div>;
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar
        title="Recruiting"
        buttonRoute="/create-recruit/info"
        buttonText="Create Requisition"
      />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <div>
          <dl className="mt-5 mb-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-2 md:divide-y-0 md:divide-x">
            <div className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                Total Recruits
              </dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                  {data ? data.results.totalRecruits : '-'}
                  <span className="ml-2 text-sm font-medium text-gray-500">
                    {/* {item.previousStat} */}
                  </span>
                </div>
              </dd>
            </div>

            <div className="px-4 py-5 sm:p-6">
              <dt className="text-base font-normal text-gray-900">
                Total Countries
              </dt>
              <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
                <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                  {data ? data.results.totalCountries : '-'}
                  <span className="ml-2 text-sm font-medium text-gray-500">
                    {/* {item.previousStat} */}
                  </span>
                </div>
              </dd>
            </div>
          </dl>
        </div>
        {/* <PaymentDue /> */}
        {/* {data?.results.le ? (
          // <Filters filterParamsChange={filterParamsChange} />
        ) : (
          <EmptyState ctaRoute="/create-recruit" entity="Recruit" />
        )} */}
        {hasData && (
          <Table
            data={data}
            isLoading={isLoading}
            onPageChange={onPageChange}
          />
        )}
      </div>
    </main>
  );
}
