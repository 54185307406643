import { DateTime } from 'luxon';
import { useState } from 'react';

import { trpc } from '../../lib/trpc';
import Loader from '../Loader';
import MonthOverMonth from './MonthOverMonth';

export default function WorkerMonthOverMonthReport() {
  const [lastMonth, setLastMonth] = useState(
    DateTime.local().startOf('month').minus({ day: 1 }),
  );
  const [currentMonth, setCurrentMonth] = useState(DateTime.now());

  const { isLoading, error, data, refetch } =
    trpc.w.timeCards.monthOverMonthReport.useQuery({
      lastMonth: lastMonth.toFormat('yyyy-MM'),
      currentMonth: currentMonth.toFormat('yyyy-MM'),
    });

  if (isLoading) return <Loader />;
  if (error) return <>Error</>;
  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 my-4">
      <div className="grid grid-cols-1 sm:grid-cols-2">
        <div className="col-span-1">
          <MonthOverMonth
            data={data}
            refetch={refetch}
            lastMonth={lastMonth}
            currentMonth={currentMonth}
            setLastMonth={setLastMonth}
            setCurrentMonth={setCurrentMonth}
          />
        </div>
      </div>
    </div>
  );
}
