import Button from '../Button';

export interface FooterProps {
  primaryButtonText: string;
  loading?: boolean;
  secondaryButtonText: string;
  secondaryButtonOnClick: () => void;
  primaryButtonOnClick?: () => void;
}

export function Footer({
  primaryButtonText,
  loading,
  secondaryButtonText,
  secondaryButtonOnClick,
  primaryButtonOnClick,
}: FooterProps) {
  return (
    <div className="pt-5">
      <div className="flex justify-end">
        {secondaryButtonText ? (
          <button
            onClick={(e) => {
              e.preventDefault();
              secondaryButtonOnClick();
            }}
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {secondaryButtonText || 'Cancel'}
          </button>
        ) : null}
        {primaryButtonText ? (
          <Button
            type={!primaryButtonOnClick ? 'submit' : 'button'}
            onClick={primaryButtonOnClick}
            loading={loading}
            text={primaryButtonText}
            className="ml-3"
          />
        ) : null}
      </div>
    </div>
  );
}
