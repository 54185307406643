import { ErrorBoundary } from '@sentry/react';
import { httpBatchLink } from '@trpc/client';
import posthog from 'posthog-js';
import { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { IntercomProvider } from 'react-use-intercom';
import superjson from 'superjson';
import { IntlProvider } from 'react-intl';
import { renderFallBack } from './components/Fallback';
import useImpersonate from './hooks/useImpersonate';
import { API_URL, IS_PROD } from './lib/constants';
import { trpc } from './lib/trpc';
import { AppRouter } from './router';
import { customLink } from './lib/customLink';

posthog.init('phc_nenPTwRbTafIJwRUkAaEK1p25XwBQhx8KB2pCRk6ObV', {
  api_host: 'https://app.posthog.com',
  autocapture: IS_PROD,
  disable_session_recording: !IS_PROD,
});

function getJwt() {
  return window.localStorage.getItem('jwt');
}

const INTERCOM_APP_ID = 'wclf1j7m';

function App() {
  useImpersonate();

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
      },
    },
  });
  const [trpcQueryClient] = useState(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            retry: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
  );

  const [trpcClient] = useState(() =>
    trpc.createClient({
      transformer: superjson,
      links: [
        customLink,
        httpBatchLink({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          url: `${API_URL}/trpc`,
          headers() {
            return {
              // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition, @typescript-eslint/restrict-template-expressions
              authorization: `Bearer ${getJwt()}` ?? '',
            };
          },
        }),
      ],
    }),
  );

  return (
    <ErrorBoundary fallback={({ error }) => renderFallBack({ error })}>
      <IntlProvider locale="en">
        <QueryClientProvider client={queryClient}>
          <trpc.Provider client={trpcClient} queryClient={trpcQueryClient}>
            <QueryClientProvider client={trpcQueryClient}>
              <IntercomProvider
                appId={INTERCOM_APP_ID}
                shouldInitialize={!!IS_PROD}
              >
                <AppRouter />
              </IntercomProvider>
            </QueryClientProvider>
          </trpc.Provider>
        </QueryClientProvider>
      </IntlProvider>
    </ErrorBoundary>
  );
}

export default App;
