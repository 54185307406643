// eslint-disable-next-line no-restricted-imports
import { PayPeriodStatus } from 'api/src/generated/prismaClient';
import { formatDate } from 'listo/src/utils/dates';
import { classNames, getShortId } from 'listo/src/utils/strings';
import { useParams } from 'react-router-dom';
import { Button } from 'ui/src/components/Button';
import Loader from '../../components/Loader';
import Modal from '../../components/Modal';
import TopBar from '../../components/TopBar';
import { RouterOutput, trpc } from '../../lib/trpc';
import { Approval } from './Approval';
import { payPeriodDescription, payPeriodStatusLabel } from './payPeriod.utils';
import { useApprovalModal } from './useApprovalModal';

function getStep(status: PayPeriodStatus) {
  if (status === 'SCHEDULED') return 0;
  if (status === 'PENDING_APPROVAL') return 0;
  if (status === 'APPROVED') return 1;
  if (status === 'INVOICED') return 1;
  if (status === 'DISTRIBUTING') return 2;

  return 3;
}

function PayPeriodStatusCard({
  payPeriod,
}: {
  payPeriod: RouterOutput['u']['payPeriods']['payPeriod'];
}) {
  const step = getStep(payPeriod.status);

  return (
    <section aria-labelledby="pay-period-status">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="payPeriod-status"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Pay Period
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Details regarding important dates and status expectations.
          </p>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Pay Period Id
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {getShortId(payPeriod.id)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Target Distribution Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatDate(payPeriod.distributeDate)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Required Approval Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatDate(payPeriod.approvalDate)}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="text-sm font-medium text-gray-500">
                Anticipated Invoice Date
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {formatDate(payPeriod.invoiceDate)}
              </dd>
            </div>
          </dl>
        </div>
        <div className="px-4 py-5 sm:px-6">
          <div className="overflow-hidden rounded-full bg-gray-200">
            <div
              className="h-2 rounded-full bg-indigo-600"
              style={{
                width: `calc((${step} * 2 + 1) / 6 * 100%)`,
              }}
            />
          </div>
          <div className="mt-6 hidden grid-cols-3 font-medium text-gray-600 sm:grid">
            <div className="text-indigo-600"> Approve</div>
            <div
              className={classNames(
                step > 1 ? 'text-indigo-600' : '',
                'text-center',
              )}
            >
              Invoice
            </div>
            <div
              className={classNames(
                step > 2 ? 'text-indigo-600' : '',
                'text-right',
              )}
            >
              Distribute
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

function InvoiceButton({ invoiceId }: { invoiceId: string }) {
  const { data: invoice, isLoading } =
    trpc.u.invoices.invoice.useQuery(invoiceId);

  if (isLoading) return null;
  if (!invoice) return null;

  return (
    <div className="justify-stretch mt-6 flex flex-col">
      <Button
        onClick={() => {
          if (invoice.stripeHostedUrl)
            window.location.href = invoice.stripeHostedUrl;
        }}
        text="View Invoice"
      />
    </div>
  );
}

export function PayPeriod() {
  const approvalModalOpen = useApprovalModal((state) => state.isOpen);
  const setApprovalModalOpen = useApprovalModal((state) => state.setIsOpen);
  const { payPeriodId } = useParams<{ payPeriodId: string }>();
  if (!payPeriodId) throw new Error('payPeriodId is required');

  const {
    data: payPeriod,
    isLoading,
    error,
  } = trpc.u.payPeriods.payPeriod.useQuery({
    payPeriodId,
  });

  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <main className="min-h-screen bg-gray-100 ">
      <TopBar title="Pay Period" />
      <div className="mx-auto mt-8 grid max-w-3xl grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <Modal isOpen={approvalModalOpen} setIsOpen={setApprovalModalOpen}>
          <Approval payPeriod={payPeriod} />
        </Modal>
        <div className="space-y-6 lg:col-span-2 lg:col-start-1">
          <PayPeriodStatusCard payPeriod={payPeriod} />
        </div>
        <section
          aria-labelledby="timeline-title"
          className="lg:col-span-1 lg:col-start-3"
        >
          <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
            <h2
              id="timeline-title"
              className="text-lg font-medium text-gray-900"
            >
              {payPeriodStatusLabel(payPeriod)}
            </h2>
            <p className="mt-2 text-sm text-gray-700 max-w-lg">
              {payPeriodDescription(payPeriod)}
            </p>
            {payPeriod.status === 'INVOICED' && payPeriod.invoiceId ? (
              <InvoiceButton invoiceId={payPeriod.invoiceId} />
            ) : null}
            {payPeriod.status === 'PENDING_APPROVAL' &&
            !payPeriod.approvedAt ? (
              <div className="justify-stretch mt-6 flex flex-col">
                <Button
                  onClick={() => {
                    setApprovalModalOpen(true);
                  }}
                  text="View"
                />
              </div>
            ) : null}
          </div>
        </section>
      </div>
    </main>
  );
}
