import { classNames } from 'listo/src/utils/strings';
import { capitalize } from 'lodash';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { BillingCycle } from 'ui/src/components/Currency/Currency';
import Avatar from '../../components/Avatar';
import { RouterOutput, trpc } from '../../lib/trpc';

const tabPadding = 'px-4 py-5 sm:px-6';

export default function Contracts() {
  const [selectedContract, setSelectedContract] =
    useState<RouterOutput['u']['workers']['worker']['contracts'][0]>();
  const { id, contractId } = useParams();
  if (!id) throw new Error('id is required');
  const { data } = trpc.u.workers.worker.useQuery(id);

  useEffect(() => {
    if (data) {
      const { contracts } = data;

      const c =
        contracts.find((co) => co.id === contractId) ??
        contracts.sort(
          (a, b) =>
            +new Date(b.createdAt as unknown as string) -
            +new Date(a.createdAt as unknown as string),
        )[0];

      setSelectedContract(c);
    }
  }, [contractId, data]);

  const contracts = data?.contracts;
  const navigate = useNavigate();

  return (
    <div className={tabPadding}>
      <div className="flow-root">
        <ul className="-my-5 divide-y divide-gray-200">
          {contracts?.map((contract) => (
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions
            <li
              key={contract.id}
              className="py-4"
              onClick={() => {
                // if (contract.engagementType === 'EOR') {
                //   navigate(
                //     `/contracts/${
                //       contract.id
                //     }/${contract.currentStep.toLowerCase()}`,
                //   );
                // } else {
                navigate(`/contracts/${contract.id}`);
                // }
              }}
            >
              <div className="flex items-center space-x-4 cursor-pointer">
                <Avatar
                  className="h-10 w-10 rounded-full text-xl"
                  name={contract.workerProfile.fullName ?? ''}
                  image={contract.workerProfile.workerProfileUpload?.url ?? ''}
                />
                <div className="flex-1 min-w-0">
                  <div
                    className={classNames(
                      'text-sm font-medium truncate',
                      selectedContract?.id === contract.id
                        ? 'text-indigo-900'
                        : 'text-gray-900',
                    )}
                  >
                    <BillingCycle
                      currency={contract.billingCurrency}
                      amount={contract.billingAmountInCents}
                      frequency={contract.billingFrequency}
                      isCents
                    />
                  </div>
                  <p
                    className={classNames(
                      'text-sm truncate',
                      selectedContract?.id === contract.id
                        ? 'text-indigo-500'
                        : 'text-gray-500',
                    )}
                  >
                    {`${capitalize(contract.engagementType)} - ${capitalize(
                      contract.contractType.replace('_', ' '),
                    )}`}
                  </p>
                </div>

                <div className="flex flex-shrink-0">
                  {/* {contract.verificationStatus === 'PENDING' &&
                    contract.verificationUrl ? (
                      <p className="text-sm text-gray-500 mr-4">
                        <a href={contract.verificationUrl}>
                          {contract.verificationUrl}
                        </a>
                      </p>
                    ) : null} */}
                  <p
                    className={classNames(
                      'inline-flex rounded-full px-2 text-xs font-semibold leading-5',
                      contract.status === 'EXECUTED'
                        ? 'bg-green-100 text-green-800'
                        : 'bg-yellow-100 text-yellow-800',
                    )}
                  >
                    {capitalize(contract.status)}
                  </p>
                </div>
                {/* <div>
                    <a
                      href="#"
                      className="inline-flex items-center shadow-sm px-2.5 py-0.5 border border-gray-300 text-sm leading-5 font-medium rounded-full text-gray-700 bg-white hover:bg-gray-50"
                    >
                      Delete
                    </a>
                  </div> */}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
