import {
  CheckBadgeIcon,
  PencilIcon,
  PlusCircleIcon,
} from '@heroicons/react/24/outline';
import { ContractStatusEnum } from 'listo/src/zodObjects/contracts';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RouterOutput, trpc } from '../../lib/trpc';
import { ContratorSignature } from '../../pages/Profile/ContractorSignature';
import Button from '../Button';
import SignContract from '../SignContract';
import { useSigningModal } from '../SignContract/hooks/useSigningModal';
import { Steps } from '../SignContract/types';
import { useSignedModal } from '../SignedContract/useSignedContract';
import { PendingContractRelease } from './PendingContractRelease';

function NoContract({
  worker,
}: {
  worker: RouterOutput['u']['contracts']['contract']['worker'];
}) {
  const navigate = useNavigate();
  return (
    <div>
      <p className="text-lg font-medium text-gray-500">Contract</p>
      <button
        type="button"
        className="relative block w-full p-6 mt-1 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        onClick={() =>
          navigate(`/create-contract?workerEmail=${worker.credential.email}`)
        }
      >
        <div className="w-12 h-12 mx-auto">
          <PlusCircleIcon color="#9ca3af" />
        </div>

        <span className="block mt-2 text-sm font-medium text-gray-900">
          New Contract
        </span>
      </button>
    </div>
  );
}

function ClientSignatureTile({
  clientInvitationLink,
  clientSignature,
  status,
  setModalIsOpen,
}: {
  clientSignature: string | null;
  status?: string;
  clientInvitationLink: string | null;
  setModalIsOpen: (isOpen: boolean) => void;
}) {
  const [linkCopied, setLinkCopied] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [email, setEmail] = useState('');

  useEffect(() => {
    if (!clientInvitationLink) return;
    const urlParams = new URLSearchParams(clientInvitationLink);
    const e = urlParams.get('email');
    if (e) setEmail(e);
  }, [clientInvitationLink]);

  // consider moving this inside notification component
  useEffect(() => {
    if (!linkCopied) return;

    const timer = setTimeout(() => setLinkCopied(false), 2000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [linkCopied]);

  if (clientSignature || status === 'EXECUTED')
    return (
      <div>
        <p className="flex text-sm font-medium text-gray-500">
          Issuer signature
          <CheckBadgeIcon className="w-5 h-5 text-green-500" />
        </p>
        <div className="flex">
          <p
            style={{
              fontFamily: "'Dancing Script', cursive",
              fontSize: 30,
            }}
            className="flex items-center justify-center text-gray-900"
          >
            {clientSignature}
          </p>
        </div>
      </div>
    );

  // if (!clientSignature && clientInvitationLink)
  //   return (
  //     <>
  //       <Notification
  //         title="Link copied"
  //         message="Link copied to clipboard"
  //         show={linkCopied}
  //         onDismiss={() => setLinkCopied(false)}
  //       />

  //       <div
  //         role="button"
  //         className="flex py-4 cursor-pointer"
  //         onClick={() => {
  //           navigator.clipboard.writeText(clientInvitationLink).catch(() => {});
  //           setLinkCopied(true);
  //         }}
  //         tabIndex={0}
  //         onKeyPress={() => {
  //           navigator.clipboard.writeText(clientInvitationLink).catch(() => {});
  //           setLinkCopied(true);
  //         }}
  //       >
  //         <RadixAvatar fallbackString={email} />
  //         <div className="ml-3 overflow-hidden">
  //           <p className="text-sm font-medium text-gray-900">{email}</p>

  //           <p className="text-sm text-gray-500 truncate">
  //             {clientInvitationLink}
  //           </p>
  //         </div>
  //       </div>
  //     </>
  //   );

  if (!clientSignature)
    return (
      <>
        <div>
          <p className="text-sm font-medium text-gray-500">Issuer signature</p>
          <button
            type="button"
            className="relative block w-full p-6 mt-1 text-center border-2 border-gray-300 border-dashed rounded-lg hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => setModalIsOpen(true)}
          >
            <div className="w-12 h-12 mx-auto">
              <PencilIcon color="#9ca3af" />
            </div>

            <span className="block mt-2 text-sm font-medium text-gray-900">
              Sign Contract
            </span>
          </button>
        </div>

        {/* {clientInvitationLink ? (
          <>
            <Notification
              title="Guest sign link copied"
              message="Link copied to clipboard"
              show={linkCopied}
              onDismiss={() => setLinkCopied(false)}
            />

            <div
              role="button"
              className="flex py-4 cursor-pointer"
              onClick={() => {
                navigator.clipboard
                  .writeText(clientInvitationLink)
                  .catch(() => {});
                setLinkCopied(true);
              }}
              tabIndex={0}
              onKeyPress={() => {
                navigator.clipboard
                  .writeText(clientInvitationLink)
                  .catch(() => {});
                setLinkCopied(true);
              }}
            >
              <RadixAvatar fallbackString={email} />
              <div className="ml-3 overflow-hidden">
                <p className="text-sm font-medium text-gray-900">{email}</p>

                <p className="text-sm text-gray-500 truncate">
                  {clientInvitationLink}
                </p>
              </div>
            </div>
          </>
        ) : null} */}
      </>
    );

  return null;
}

export function ContractTile({
  contract,
  worker,
}: {
  contract: RouterOutput['u']['workers']['worker']['contracts'][0] | null;
  worker: RouterOutput['u']['contracts']['contract']['worker'];
}) {
  const setSigningModalOpen = useSigningModal((state) => state.setIsOpen);
  const setSignedModalOpen = useSignedModal((state) => state.setIsOpen);
  const [initialStep] = useState<Steps>('whoSigns');
  const { id } = useParams();
  const utils = trpc.useContext();

  if (!contract) return <NoContract worker={worker} />;

  if (contract.engagementType === 'EOR') {
    return contract.status === ContractStatusEnum.enum.PENDING_RELEASE &&
      !contract.customContractUploadId ? (
      <PendingContractRelease contract={contract} />
    ) : null;
  }

  return (
    <section aria-labelledby="timeline-title">
      <SignContract
        signer="client"
        contractId={contract.id}
        initialStep={initialStep}
        onSuccess={() => {
          utils.u.workers.worker.invalidate(id).catch(() => {});
          utils.u.contracts.contract.invalidate(contract.id).catch(() => {});
          setSigningModalOpen(false);
        }}
      />

      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h2
            id="timeline-title"
            className="text-lg font-medium leading-6 text-gray-900"
          >
            Signatures
          </h2>
          <p className="max-w-2xl mt-1 text-sm text-gray-500">
            Sign and send the contract
          </p>
        </div>

        <div className="border-t border-gray-200">
          <div className="px-4 py-5 sm:px-6">
            <ClientSignatureTile
              clientInvitationLink={contract.clientInvitationLink}
              clientSignature={contract.clientSignature}
              setModalIsOpen={setSigningModalOpen}
              status={contract.status}
            />

            <ContratorSignature
              invitationLink={contract.invitationLink}
              workerSignature={contract.workerSignature}
              workerId={contract.workerId}
              workerEmail={worker.credential.email}
              contractId={contract.id}
              status={contract.status}
            />

            {(!!contract.clientSignature && !!contract.workerSignature) ||
            contract.status === 'EXECUTED' ? (
              <div className="flex justify-end pt-2">
                <Button
                  text="View Contract"
                  onClick={() => {
                    setSignedModalOpen(true);
                  }}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
}
