import { useState, useEffect } from 'react';
import { isArray } from 'lodash';
import Select from 'react-select';
import { ArrowDownTrayIcon } from '@heroicons/react/20/solid';
import { DateTime } from 'listo/src/luxonUtc';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { downloadFromURL } from '../../../utility/download';
import TopBar from '../../components/TopBar';
import { Spinner } from '../../components/Loader';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';
import { TimeTrackingStats } from './TimeTrackingStats';
import { Table } from './TimeTrackingTable';
import { useTimeTrackingDates } from './useTimeTrackingDates';
import { IlabelOptions } from './types';

export function TimeTracking() {
  const { claims } = useAuth();
  const setNotification = useNotification((state) => state.setNotification);
  const { startDate, endDate, setStartDate, setEndDate } =
    useTimeTrackingDates();

  const [totalHours, setTotalHours] = useState('');
  const [selectedOptions, setSelectedOptions] = useState<string[]>();

  const startString = startDate.toISODate();
  const endString = endDate.toISODate();
  const clientId = claims?.activeClientId ?? '';

  const { data, isLoading, refetch, status } = trpc.u.timeCards.list.useQuery({
    startDate: startString,
    endDate: endString,
    labels: selectedOptions,
  });

  const { data: labels } = trpc.u.labels.list.useQuery({
    clientId,
  });

  const { mutate: exportTimeCardsCSV, isLoading: exportCSVLoading } =
    trpc.u.timeCards.exportTimeCardsToCSV.useMutation({
      onSuccess: ({ url }: { url: string }) => {
        downloadFromURL(url);

        return setNotification({
          type: 'success',
          title: 'Success',
          message: 'Download completed',
        });
      },
      onError(error: unknown) {
        // eslint-disable-next-line no-console
        console.error(error);

        return setNotification({
          type: 'error',
          title: 'Error',
          message: 'Download failed',
        });
      },
    });

  const handleExportCSV = () =>
    exportTimeCardsCSV({ startDate: startString, endDate: endString });

  const handleFilterChange = (options: IlabelOptions) => {
    if (options.length) {
      setSelectedOptions(options.map((option) => option.value));
    } else {
      setSelectedOptions(undefined);
    }

    return refetch();
  };

  useEffect(() => {
    if (status === 'success') {
      const total = data.reduce((acc, curr) => acc + (curr.hours ?? 0), 0);

      setTotalHours(total.toString());
    }
  }, [status, data]);

  return (
    <main className="flex-1 min-h-screen pb-8 bg-gray-100">
      <TopBar
        title="Time Tracking"
        buttonText="Manage Labels"
        buttonRoute="/time-tracking/labels"
      />
      <div className="max-w-6xl px-4 mx-auto mt-8 sm:px-6 lg:px-8">
        <TimeTrackingStats totalHours={totalHours} refetch={refetch} />

        <div className="flex flex-wrap items-center justify-between gap-4 my-6">
          <div className="flex items-center gap-5">
            <div className="flex items-center gap-2">
              <Input
                inputProps={{
                  value: startDate.toISODate(),
                  onChange: ({ target: { value } }) => {
                    setStartDate(DateTime.fromISO(value).startOf('day'));
                  },
                }}
                inputType="date"
              />
              -
              <Input
                inputProps={{
                  value: endDate.toISODate(),
                  onChange: ({ target: { value } }) => {
                    setEndDate(DateTime.fromISO(value).startOf('day'));
                  },
                }}
                inputType="date"
              />
            </div>

            <Select
              isMulti
              name="filter"
              placeholder="Filter..."
              options={
                labels?.values && isArray(labels.values)
                  ? labels.values.map((option) => ({
                      label: option as string,
                      value: option as string,
                    }))
                  : []
              }
              // eslint-disable-next-line @typescript-eslint/no-misused-promises
              onChange={(options) =>
                handleFilterChange(options as IlabelOptions)
              }
              className="mt-1 text-sm basic-multi-select"
              classNamePrefix="select"
              styles={{
                input: (baseStyles) => ({
                  ...baseStyles,

                  'input[type = Text]': {
                    ':focus': {
                      outline: 'none !important',
                      border: 'none !important',
                      boxShadow: 'none !important',
                    },
                  },
                }),
                multiValue: (baseStyles) => ({
                  ...baseStyles,
                  backgroundColor: '#f2f2f2',
                  display: 'flex',
                  borderRadius: '4px',
                }),
                multiValueLabel: (baseStyles) => ({
                  ...baseStyles,
                  color: '#737373',
                  fontSize: '13px',
                }),
                multiValueRemove: (baseStyles) => ({
                  ...baseStyles,
                  margin: '3px',
                  padding: '3px',
                  borderRadius: '4px',
                  color: '#737373',
                  ':hover': {
                    backgroundColor: '#d9d9d9',
                    color: 'white',
                  },
                }),
              }}
            />
          </div>

          <button
            type="button"
            className="inline-flex items-start justify-center gap-2 ml-auto text-base text-gray-700 sm:mx-0 hover:text-gray-900 disabled:opacity-75"
            onClick={handleExportCSV}
            disabled={exportCSVLoading}
          >
            <span>Export</span>
            {exportCSVLoading ? (
              <Spinner height={5} width={5} />
            ) : (
              <ArrowDownTrayIcon className="w-5 h-5" aria-hidden="true" />
            )}
          </button>
        </div>
        {/* <ClientMonthOverMonthReport
            lastMonth={lastMonth}
            setLastMonth={setLastMonth}
            currentMonth={currentMonth}
            setCurrentMonth={setCurrentMonth}
          /> */}

        <Table
          startDate={startDate}
          endDate={endDate}
          isLoading={isLoading}
          data={data ?? undefined}
          refetch={refetch}
        />
      </div>
    </main>
  );
}
