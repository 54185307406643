import { useNavigate, useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { getShortId, titleCase } from 'listo/src/utils/strings';
import { formatDate } from 'listo/src/utils/dates';
import {
  CreateDistributionType,
  createDistributionZod,
} from 'listo/src/zodObjects/distributions';
import { Button } from 'ui/src/components/Button';
import { RouterOutput, trpc } from '../../lib/trpc';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';
import { Header } from '../../components/Form/Header';
import { SelectInput } from '../../components/Form/SelectInput';
import { NumberInput } from '../../components/Form/NumberInput';

export function OneOffForm({
  contract,
}: {
  contract: RouterOutput['u']['contracts']['contract'];
}) {
  const navigate = useNavigate();
  const { data: payPeriods, isLoading: isPayPeriodsLoading } =
    trpc.u.payPeriods.list.useQuery();

  const { mutate: createDistribution, isLoading: isCreateDistributionLoading } =
    trpc.u.distributions.create.useMutation({
      onSuccess: () => navigate(`/contracts/${contract.id}`),
    });

  const defaultValues = {
    clientId: contract.clientId,
    payPeriodId: '',
    workerProfileId: contract.workerProfileId,
    amountInCents: 0,
  };

  const {
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm<CreateDistributionType>({
    resolver: zodResolver(createDistributionZod),
    defaultValues,
  });

  const onSubmit: SubmitHandler<CreateDistributionType> = (data) =>
    createDistribution(data);

  return (
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    <form onSubmit={handleSubmit(onSubmit)}>
      <Header
        heading="Employee Information"
        subheading="Let's gather some basic information about the employee so we
          can create one off payment."
      />

      <div className="grid gap-6 pt-4 mt-4 border-t border-gray-200">
        <NumberInput
          cols={12}
          register={register}
          fieldName="amountInCents"
          label={`Amount (${contract.billingCurrency})`}
          error={errors.amountInCents}
        />

        <SelectInput
          cols={12}
          register={register}
          fieldName="payPeriodId"
          label="Pay period"
          error={errors.payPeriodId}
          loading={isPayPeriodsLoading}
          defaultOption={{ label: 'Select PayPeriod', value: '' } as const}
          options={
            payPeriods?.map((payPeriod) => ({
              label: `Distribute Date: ${formatDate(payPeriod.distributeDate)}`,
              value: payPeriod.id,
            })) ?? []
          }
        />

        <div className="flex justify-end col-span-12">
          <Button
            id="one-off-payments-action-handle-create-distribution"
            loading={isCreateDistributionLoading}
            disabled={!isValid || isCreateDistributionLoading}
            text="Create"
            type="submit"
          />
        </div>
      </div>
    </form>
  );
}

function OneOffPayments() {
  const { contractId } = useParams<{ contractId: string }>();
  if (!contractId) throw new Error('No contractId');

  const { data: contract, isLoading } =
    trpc.u.contracts.contract.useQuery(contractId);

  const { data: payPeriods } = trpc.u.payPeriods.list.useQuery();

  if (isLoading || contract === undefined) return <Loader />;

  return (
    <div className="min-h-screen bg-gray-100">
      <TopBar
        breadcrumbs={[
          {
            name: titleCase(contract.workerProfile.fullName ?? 'profile'),
            href: `/team/${contract.worker.id}`,
            current: false,
          },
          {
            name: 'Contracts',
            href: `/team/${contract.worker.id}/contracts`,
            current: false,
          },
          {
            name: getShortId(contract.id) ?? '',
            href: `/contracts/${contract.id}`,
            current: false,
          },
          {
            name: 'One Off Payments',
            href: `/contracts/${contract.id}/payments`,
            current: true,
          },
        ]}
        title="Contract"
      />

      <div className="grid mt-6 gird-cols-6">
        {payPeriods ? (
          <div className="p-8 mx-auto bg-white rounded-lg max-w-7xl">
            <OneOffForm contract={contract} />
          </div>
        ) : (
          <div className="p-8 mx-auto rounded-md bg-yellow-50">
            <div className="flex">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="w-5 h-5 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <h3 className="text-sm font-medium text-yellow-800">
                  Pay periods missing
                </h3>

                <div className="mt-2 text-sm text-yellow-700">
                  <p>
                    You need to set up pay period. please contact your customer
                    support representative.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default OneOffPayments;
