import {
  MapPinIcon,
  BanknotesIcon,
  HashtagIcon,
  CheckBadgeIcon,
  PencilSquareIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Tooltip } from 'react-tooltip';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from 'ui/src/components/AlertModal/AlertModal';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import Loader from '../../components/Loader';
import { RouterOutput, trpc } from '../../lib/trpc';

function BankAccount({
  bankAccount,
}: {
  bankAccount: RouterOutput['w']['bankAccounts']['list'][0];
}) {
  const navigate = useNavigate();
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const setNotification = useNotification((state) => state.setNotification);
  const utils = trpc.useContext();
  const { mutate: makeBankAccountDefault } =
    trpc.w.bankAccounts.makeDefault.useMutation({
      onSuccess: () => {
        utils.w.workers.profile.invalidate().catch(() => {});
        utils.w.bankAccounts.invalidate().catch(() => {});
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Default Bank account updated',
        });
      },
    });

  const { mutate: deleteBankAccount, isLoading: loadingDelete } =
    trpc.w.bankAccounts.deleteBankAccount.useMutation({
      onSuccess: () => {
        utils.w.workers.profile.invalidate().catch(() => {});
        utils.w.bankAccounts.invalidate().catch(() => {});
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Bank account deleted',
        });
      },
    });

  return (
    <li key={bankAccount.id} className="block hover:bg-gray-50">
      <div className="px-4 py-4 sm:px-6">
        <div className="flex items-center justify-between">
          <p className="truncate text-sm font-medium text-indigo-600">
            {bankAccount.bankName}
          </p>
          <div className="ml-2 flex flex-shrink-0">
            {bankAccount.isDefault && (
              <p className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                Default
              </p>
            )}
          </div>
        </div>
        <div className="mt-2 sm:flex sm:justify-between">
          <div className="sm:flex">
            <p className="flex items-center text-sm text-gray-500">
              <HashtagIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {bankAccount.routingNumber}
            </p>
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
              <MapPinIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {bankAccount.country}
            </p>
            <p className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0 sm:ml-6">
              <BanknotesIcon
                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                aria-hidden="true"
              />
              {bankAccount.type} {bankAccount.entityType}
            </p>
          </div>
          <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
            <div>
              <button
                type="submit"
                className="flex justify-center w-full px-2 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                data-tooltip-id="delete-bank-account"
                data-tooltip-html="Delete Bank account"
                onClick={() => {
                  setOpenDeleteModal(true);
                }}
              >
                <TrashIcon
                  className="flex-shrink-0 w-4 h-6 mr-1 text-indigo-200"
                  aria-hidden="true"
                  aria-label="Delete Bank Account"
                />
                Delete
              </button>
              <Tooltip id="delete-bank-account" place="bottom" />
            </div>
            &nbsp;
            <button
              type="submit"
              className="flex justify-center px-2 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              data-tooltip-id="edit-bank-account"
              data-tooltip-html="Edit Bank account"
              onClick={() =>
                navigate(`/wrkr/bank-account/${bankAccount.id}/edit`)
              }
            >
              <PencilSquareIcon
                className="flex-shrink-0 w-4 h-6 mr-2 text-indigo-200"
                aria-hidden="true"
                aria-label="Edit Bank Account"
              />
              Edit
            </button>
            <Tooltip id="edit-bank-account" place="bottom" />
            &nbsp;
            {!bankAccount.isDefault && (
              <>
                <button
                  type="submit"
                  className="flex justify-center px-2 py-2 text-base font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  data-tooltip-id="make-default"
                  data-tooltip-html="Make Default"
                  onClick={() => makeBankAccountDefault(bankAccount.id)}
                >
                  <CheckBadgeIcon
                    className="flex-shrink-0 w-4 h-6 mr-1 text-indigo-200"
                    aria-hidden="true"
                    aria-label="Make Default"
                  />
                  Make Default
                </button>
                <Tooltip id="make-default" place="bottom" />
              </>
            )}
          </div>
        </div>
      </div>

      <AlertModal
        title="Are you sure want to delete this bank account?"
        description=""
        open={openDeleteModal}
        setOpen={setOpenDeleteModal}
        confirmButtonText="Confirm"
        onConfirm={() => deleteBankAccount(bankAccount.id)}
        loadingConfirm={loadingDelete}
      />
    </li>
  );
}

export default function BankAccountsList() {
  const navigate = useNavigate();

  const {
    data: bankAccounts,
    isLoading,
    error,
  } = trpc.w.bankAccounts.list.useQuery();

  if (isLoading) return <Loader />;
  if (error) return <div>{error.message}</div>;

  return (
    <div className="overflow-hidden bg-white sm:rounded-md">
      <div className="p-5">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Bank Accounts
        </h3>

        <p className="mt-1 text-sm text-gray-500">
          Enter or update your one or more bank details. You can set one as
          default, Payments from Listo platform will be issued to this default
          bank account.
        </p>
      </div>
      <ul className="divide-y divide-gray-200">
        {bankAccounts.map((bankAccount) => (
          <BankAccount key={bankAccount.id} bankAccount={bankAccount} />
        ))}
      </ul>
      <div className="mt-6 flex justify-end">
        <Button
          text="Add a Bank Account"
          onClick={() => navigate('/wrkr/bank-account/create')}
        />
      </div>
    </div>
  );
}
