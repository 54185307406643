import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import axios, { AxiosRequestConfig } from 'axios';
import { AssetTypes } from 'listo/src/zodObjects/assets';
import { Maybe, Nullable } from '../../types/common';
import { trpc, RouterOutput } from '../../lib/trpc';

interface ProgressData {
  total: number;
  loaded: number;
}

interface UploaderProps {
  children: React.ReactNode;
  listoAssetType: AssetTypes;
  allowedTypes?: string[];
  setLoading: (loading: boolean) => void;
  setProgress: (loading: number) => void;
  setUploadedFile: (file: RouterOutput['a']['assets']['uploadSuccess']) => void;
  onPreviewLoad?: (preview: Maybe<string>) => void;
  onError?: (error: unknown) => void;
  clientId: string;
}

export function Uploader({
  children,
  allowedTypes,
  onPreviewLoad,
  onError,
  listoAssetType = 'invoices',
  setLoading,
  setProgress,
  setUploadedFile,
  clientId,
}: UploaderProps) {
  const { mutateAsync: getSignedUrlForUpload } =
    trpc.a.assets.getSignedUrl.useMutation();

  const { mutateAsync: uploadFileSuccess } =
    trpc.a.assets.uploadSuccess.useMutation();

  const uploaderInputRef = useRef<Nullable<HTMLInputElement>>(null);
  const [file, setFile] = useState<File>();
  const [preview, setPreview] = useState<string>();

  const handleClick = () => {
    uploaderInputRef.current?.click();
  };

  const handleKeyPress = () => {};
  const accept = !allowedTypes ? '*' : allowedTypes.join(',');

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  const handleSubmit: ChangeEventHandler<HTMLInputElement> = async (event) => {
    try {
      setLoading(true);

      const fileList = event.target.files;

      if (!fileList) return;

      const [selectedFile] = fileList;

      setFile(selectedFile);

      const fileName = selectedFile?.name;
      const mimeType = selectedFile?.type;

      if (mimeType && fileName) {
        const { signedUrl, cloudStorageKey } = await getSignedUrlForUpload({
          listoAssetType,
          mimeType,
          clientId,
        });

        setProgress(25);

        const config: AxiosRequestConfig = {
          onUploadProgress: (progressEvent: ProgressEvent) => {
            const { total, loaded }: ProgressData = progressEvent;
            setProgress((loaded * 75) / total);
          },
        };

        await axios
          .put(signedUrl, selectedFile, {
            headers: {
              'Content-Type': selectedFile.type,
            },
            ...config,
          })
          .then(() => setProgress(100));

        const upload = await uploadFileSuccess({
          clientId,
          fileName,
          mimeType,
          cloudStorageKey,
        });

        setUploadedFile(upload);
      }
    } catch (error) {
      if (onError) {
        onError(error);
      }
    } finally {
      setProgress(0);
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (onPreviewLoad && file) {
      const previewUrl = URL.createObjectURL(file);
      setPreview(previewUrl);
      onPreviewLoad(preview);
    }
  }, [file, onPreviewLoad, preview]);

  return (
    <>
      <input
        hidden
        ref={uploaderInputRef}
        type="file"
        name="uploader"
        accept={accept}
        onChange={handleSubmit}
      />
      <div
        role="button"
        tabIndex={0}
        className="uploader"
        onKeyPress={handleKeyPress}
        onClick={handleClick}
      >
        {children}
      </div>
    </>
  );
}
