import { date, z } from 'zod';
import { DateTime } from 'luxon';

export const TimeOffRequestStatusEnum = z.enum([
  'PENDING',
  'APPROVED',
  'DECLINED',
  'CANCELLED',
]);

export const createTimeOffRequestZod = z
  .object({
    clientId: z.string().min(1, 'Client is required.').uuid(),
    workerProfileId: z.string().uuid(),
    startDate: z.string().min(1, 'Start Date is required.'),
    endDate: z.string().min(1, 'End Date is required.'),
    reason: z.string().min(1, 'Reason is required.'),
    status: TimeOffRequestStatusEnum.optional(),
    clientMessage: z.string().nullish(),
  })
  .superRefine((data, ctx) => {
    const startDate = data.startDate;
    const endDate = data.endDate;

    if (startDate && endDate) {
      if (DateTime.fromISO(startDate) > DateTime.fromISO(endDate)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['endDate'],
          message: 'End Date should be greater than Start Date.',
        });
      }
    }
  });

export type TimeOffRequestStatus = z.infer<typeof TimeOffRequestStatusEnum>;
export type CreateTimeOffRequestType = z.infer<typeof createTimeOffRequestZod>;
