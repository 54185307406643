/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { DateTime } from './luxonUtc';

export const API_URL = import.meta.env.VITE_API_URL;
export const ADMIN_URL = import.meta.env.VITE_ADMIN_URL;
export const APP_URL = import.meta.env.VITE_APP_URL;
export const STRIPE_PUBLISHABLE_KEY = import.meta.env
  .VITE_STRIPE_PUBLISHABLE_KEY;
export const IS_PROD = import.meta.env.PROD;
export const MKT_URL = import.meta.env.VITE_MKT_URL;
export const DENIED_LOCAL_STORAGE_ERROR_MESSAGE = 'local-storage-is-denied';
export const BUNDLE_VERSION = DateTime.now().toISO();
export const QUICKBOOKS_HOSTED_PAYMENT_PAGE = import.meta.env
  .VITE_QUICKBOOKS_HOSTED_PAYMENT_PAGE;
