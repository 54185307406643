import { useSearchParams } from 'react-router-dom';
import { trpc } from '../../lib/trpc';
import Loader from '../Loader';
import Modal from '../Modal';
import { useAuth } from '../../hooks/useAuth';
import Button from '../Button';
import { useSignedModal } from './useSignedContract';

export function SignedContract({ contractId }: { contractId: string }) {
  const isOpen = useSignedModal((state) => state.isOpen);
  const setIsOpen = useSignedModal((state) => state.setIsOpen);
  const contract = trpc.l.legal.contract.useQuery(contractId);
  const { jwt: jwtFromContext } = useAuth();
  const [searchParams] = useSearchParams();

  if (contract.isError) return <div>Error</div>;
  if (contract.isLoading) return <Loader />;

  const pdfSource = contract.data.upload?.url ?? '';
  const source = `${pdfSource}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0`;

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="relative h-full">
        <div>
          <div className="px-4 pt-5 pb-4 sm:my-8 sm:max-w-4xl sm:p-6 sm:w-[60vw] h-[80vh]">
            <div
              className="overflow-auto"
              style={{ height: 'calc(100% - 30px)', borderRadius: 4 }}
            >
              {jwtFromContext || searchParams.get('token') ? (
                <iframe
                  style={{ height: '100%', width: '100%' }}
                  title="contract"
                  src={source}
                />
              ) : null}
            </div>
            <div className="flex justify-end pt-6">
              <div className="mr-2">
                <a href={pdfSource} target="_blank" download rel="noreferrer">
                  <Button text="Download" variant="primary" />
                </a>
              </div>
              <Button
                text="Close"
                variant="secondary"
                onClick={() => setIsOpen(false)}
              />
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}
