import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert } from 'ui/src/components/Alert';
import { NotificationRegion } from 'ui/src/components/Notification/NotificationRegion';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { useAuth } from '../../hooks/useAuth';
import { MKT_URL } from '../../lib/constants';
import { trpc } from '../../lib/trpc';
import { handleLoginRedirect } from '../../lib/utilities';
import Logo from '../../public/images/circle-logo.svg';

interface LoginForm {
  email: string;
  password: string;
}

export function Login() {
  const navigate = useNavigate();
  const setJwt = useAuth((state) => state.setJwt);
  const jwt = useAuth((state) => state.jwt);
  const claims = useAuth((state) => state.claims);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { state } = useLocation();
  const [failedLogin, setFailedLogin] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const [searchParams] = useSearchParams();
  // const [isLoading, setIsLoading] = useState(false);
  const [emailDisabled, setEmailDisabled] = useState(false);
  const redirect = searchParams.get('redirect');
  const accountExists = searchParams.get('accountExists');
  const unauthenticated = searchParams.get('unauthenticated');

  const setNotification = useNotification((s) => s.setNotification);

  const { mutate, isLoading } = trpc.p.auth.login.useMutation({
    onSuccess: ({ jwt: responseJwt }) => {
      setJwt(responseJwt);
      setLoginSuccess(true);
    },
    onError: () => {
      setFailedLogin(true);
    },
  });

  // this handles the route change for login success
  useEffect(() => {
    if (loginSuccess && jwt && claims) {
      handleLoginRedirect(navigate, claims, redirect, state);
    }
  }, [loginSuccess, jwt, claims, navigate, redirect, state]);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<LoginForm>();

  const onSubmit: SubmitHandler<LoginForm> = (data) => {
    mutate(data);
  };

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const stateSearch = state?.search;

  useEffect(() => {
    if (!stateSearch) return;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    const match = state.search.match(/\?email=(?<email>.*)/);
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (match?.groups?.email) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      setValue('email', match.groups.email as string);
      setEmailDisabled(true);
    }
  }, [stateSearch, setValue, state]);

  useEffect(() => {
    if (unauthenticated) {
      setNotification({
        type: 'error',
        title: 'Signed Out',
        message: 'You have been signed out. Please sign back in.',
      });
    }
  }, [setNotification, unauthenticated]);

  return (
    <div className="flex h-screen">
      <main className="container mx-auto my-auto">
        <div className="min-h-full flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
            <div className="mx-auto w-full max-w-sm lg:w-96">
              <div>
                <img className="h-12 w-auto" src={Logo} alt="logo" />
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">
                  Sign in to your account
                </h2>
                <p className="mt-2 text-sm text-gray-600">
                  Or{' '}
                  <a
                    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
                    href={`${MKT_URL}/sign-up`}
                    className="font-medium text-indigo-600 hover:text-indigo-500"
                  >
                    create an account
                  </a>
                </p>
              </div>
              {accountExists ? (
                <div className="mt-4">
                  <Alert
                    heading="This account already exists"
                    items={['Please sign in or reset your password']}
                  />
                </div>
              ) : null}

              {failedLogin && (
                <div className="bg-red-50 border-l-4 border-red-800 p-2 mt-8">
                  <div className="flex">
                    <div className="ml-3">
                      <h3 className="text-sm font-medium text-red-800">
                        Incorrect Username or Password
                      </h3>
                    </div>
                  </div>
                </div>
              )}

              <div className="mt-4">
                {/* <SocialSignIns /> */}

                <div className="mt-6">
                  <form
                    // eslint-disable-next-line @typescript-eslint/no-misused-promises
                    onSubmit={handleSubmit(onSubmit)}
                    noValidate
                    className="space-y-6"
                  >
                    <div>
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Email address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          type="email"
                          autoComplete="email"
                          disabled={emailDisabled}
                          // eslint-disable-next-line jsx-a11y/no-autofocus
                          autoFocus
                          {...register('email', {
                            required: true,
                            pattern: /(.+)@(.+){2,}\.(.+){2,}/,
                          })}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {errors.email && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="email-error"
                          >
                            Your email is invalid.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="space-y-1">
                      <label
                        htmlFor="password"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          type="password"
                          autoComplete="current-password"
                          {...register('password', { required: true, min: 8 })}
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                        {errors.password && (
                          <p
                            className="mt-2 text-sm text-red-600"
                            id="password-error"
                          >
                            Your password must be more than 8 characters.
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          id="remember-me"
                          name="remember-me"
                          type="checkbox"
                          defaultChecked
                          className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                        />
                        <label
                          htmlFor="remember-me"
                          className="ml-2 block text-sm text-gray-900"
                        >
                          Remember me
                        </label>
                      </div>

                      <div className="text-sm">
                        <a
                          href="/forgot-password"
                          className="font-medium text-indigo-600 hover:text-indigo-500"
                        >
                          Forgot your password?
                        </a>
                      </div>
                    </div>

                    <div>
                      {!isLoading ? (
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Sign in
                        </button>
                      ) : (
                        <button
                          type="button"
                          disabled
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          <svg
                            className="animate-spin h-5 w-5 mr-3 ..."
                            viewBox="0 0 24 24"
                          >
                            <circle
                              className="opacity-25"
                              cx="12"
                              cy="12"
                              r="10"
                              stroke="currentColor"
                              strokeWidth="4"
                            />
                            <path
                              className="opacity-75"
                              fill="currentColor"
                              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                            />
                          </svg>
                        </button>
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-0 flex-1">
            <img
              className="absolute inset-0 h-full w-full object-cover"
              src="https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
              alt=""
            />
          </div>
        </div>
      </main>
      <NotificationRegion />
    </div>
  );
}
