import { useState } from 'react';
import { DateTime } from 'listo/src/luxonUtc';
import { formatDate } from 'listo/src/utils/dates';
import { Link } from 'react-router-dom';
import { TrashIcon } from '@heroicons/react/24/outline';
import AlertModal from 'ui/src/components/AlertModal';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import Avatar from '../../components/Avatar';
import { trpc } from '../../lib/trpc';
import EmptyState from '../../components/EmptyState';
import TableLoader from '../../components/TableLoader';

interface DataObject {
  fullName: string;
  hours: string | number | null;
  workerId?: string;
  workerProfileId?: string;
  workerProfilePicture?: string;
}

interface ITableRow {
  timeCard: DataObject;
  startString: string;
  endString: string;
  refetch: () => Promise<unknown>;
}

function TableRow({ timeCard, startString, endString, refetch }: ITableRow) {
  const [open, setOpen] = useState<boolean>(false);
  const setNotification = useNotification((state) => state.setNotification);

  const {
    mutate: deleteAllWorkerTimeCards,
    isLoading: isLoadingDeleteAllTimeCards,
  } = trpc.u.timeCards.deleteAllWorkerTimeCards.useMutation({
    onSuccess: async () => {
      await refetch();
      setOpen(false);
    },
    onError: () => {
      setNotification({
        type: 'error',
        title: 'Error',
        message: 'Your request has failed. Please contact us.',
      });
    },
  });

  const handleConfirmDelete = () =>
    deleteAllWorkerTimeCards({
      workerProfileId: timeCard.workerProfileId ?? '',
    });

  const handleDelete = () => setOpen(true);

  return (
    <>
      <li>
        <div className="block">
          <Link to={`/time-tracking/${timeCard.workerProfileId ?? ''}`}>
            <div className="px-4 py-4 flex items-center sm:px-6">
              <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                <div className="truncate">
                  <div className="flex text-sm items-center">
                    <div className="mt-4 flex-shrink-0 sm:mt-0">
                      <div className="flex overflow-hidden">
                        <Avatar
                          name={timeCard.fullName}
                          image={timeCard.workerProfilePicture ?? ''}
                          className="w-10 h-10 font-medium"
                        />
                      </div>
                    </div>

                    <p className="font-medium text-indigo-600 truncate ml-2 capitalize">
                      {timeCard.fullName}
                    </p>
                  </div>
                </div>

                <div className="sm:flex-col sm:justify-between">
                  <div className="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                    <p className="ml-2">
                      {`${formatDate(startString)} - ${formatDate(endString)}`}
                    </p>
                  </div>
                </div>
              </div>
              <div className="ml-5 flex-shrink-0">
                <p className="font-medium text-indigo-600 truncate ml-2 capitalize">
                  {`${timeCard.hours ?? ''} hours`}
                </p>
              </div>
              <div className="ml-1 flex-shrink-0">
                <button
                  type="button"
                  data-testid="time-tracking-all-cards-action-delete"
                  className="flex items-center font-medium text-rose-800 truncate ml-2 capitalize hover:text-rose-900"
                  disabled={isLoadingDeleteAllTimeCards}
                  /* eslint-disable-next-line @typescript-eslint/no-misused-promises */
                  onClick={(e) => {
                    e.preventDefault();
                    handleDelete();
                  }}
                >
                  <TrashIcon
                    className="h-6 text-rose-800 w-5 m-1"
                    aria-hidden="true"
                  />
                  Delete
                </button>
              </div>
            </div>
          </Link>
        </div>
      </li>

      <AlertModal
        open={open}
        setOpen={() => setOpen(false)}
        title="Delete?"
        loadingConfirm={isLoadingDeleteAllTimeCards}
        description={`Are you sure you want to delete all timecards of ${timeCard.fullName}? This action cannot be undone.`}
        onConfirm={handleConfirmDelete}
      />
    </>
  );
}

export function Table({
  startDate,
  endDate,
  isLoading,
  data = [],
  refetch,
}: {
  startDate: DateTime;
  endDate: DateTime;
  isLoading: boolean;
  data?: DataObject[];
  refetch: () => Promise<unknown>;
}) {
  const startString = startDate.toISODate();
  const endString = endDate.toISODate();

  return (
    <>
      {data.length === 0 ? (
        <EmptyState
          message={`No Time Cards logged between ${startString} and ${endString}`}
          entity="Time Card"
        />
      ) : null}

      <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-b-none mt-8">
        <ul className="divide-y divide-gray-200">
          {!isLoading ? (
            data.map((timeCard) => (
              <TableRow
                key={timeCard.workerId}
                timeCard={timeCard}
                startString={startString}
                endString={endString}
                refetch={refetch}
              />
            ))
          ) : (
            <TableLoader />
          )}
        </ul>
      </div>
    </>
  );
}
