import {
  floatInDollarsToCents,
  formatCentsToDollars,
} from 'listo/src/utils/currency';
import {
  eorBenefits,
  eorCompensationFields,
  EorContractCreate,
  eorEmployeeFields,
  eorJobFields,
} from 'listo/src/zodObjects/contracts';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import { trpc } from '../../lib/trpc';
import { useEorForm } from './hooks/useEorForm';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function FeesTables({
  setDepositRequested,
}: {
  setDepositRequested: (depositRequested: number) => void;
}) {
  const fields = useEorForm((state) => state.fields);
  const {
    data: quote,
    isLoading,
    error,
  } = trpc.u.contracts.quote.useQuery(
    {
      country: fields?.country ?? 'XX',
      billingAmountInCents: (fields?.billingAmountInCents ?? 0) * 100,
      billingFrequency: fields?.billingFrequency ?? 'ANNUALLY',
    },
    {
      onSuccess: (data) => {
        setDepositRequested(data.deposit);
      },
    },
  );

  if (isLoading) return <Loader />;
  if (error)
    return <ErrorState trpcError={error} parentComponentName="Quote.tsx" />;

  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-5">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto mt-4">
          <h1 className="text-xl font-semibold text-gray-900">Quote</h1>
        </div>
      </div>
      <div className="mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                colSpan={2}
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
              >
                One-Time Fees
              </th>
              <th
                scope="col"
                colSpan={2}
                className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td
                className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0"
                colSpan={2}
              >
                <div className="font-medium text-gray-500">
                  Refundable Deposit
                </div>
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCentsToDollars(quote.deposit, 'USD')}
              </td>
            </tr>

            <tr className="border-b border-gray-200">
              <td
                className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0"
                colSpan={2}
              >
                <div className="font-medium text-gray-500">
                  Setup and Onboarding
                </div>
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCentsToDollars(quote.setup, 'USD')}
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <td
                className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0"
                colSpan={2}
              >
                <div className="font-medium text-gray-500">
                  Advanced First Month PayPeriod
                </div>
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCentsToDollars(quote.advancedFirstMonth, 'USD')}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="-mx-4 mt-8 flex flex-col sm:-mx-6 md:mx-0">
        <table className="min-w-full divide-y divide-gray-300">
          <thead>
            <tr>
              <th
                scope="col"
                colSpan={2}
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6 md:pl-0"
              >
                Ongoing Monthly Fees
              </th>
              <th
                scope="col"
                colSpan={2}
                className="py-3.5 pl-3 pr-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0"
              >
                Price
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="border-b border-gray-200">
              <td
                className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0"
                colSpan={2}
              >
                <div className="font-medium text-gray-500">Monthly Salary</div>
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCentsToDollars(quote.monthly, 'USD')}
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <td
                className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0"
                colSpan={2}
              >
                <div className="font-medium text-gray-500">
                  Taxes and Social (Estimated)
                </div>
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCentsToDollars(quote.taxesAndSocial, 'USD')}
              </td>
            </tr>
            <tr className="border-b border-gray-200">
              <td
                className="py-4 pl-4 pr-3 text-sm sm:pl-6 md:pl-0"
                colSpan={2}
              >
                <div className="font-medium text-gray-500">
                  Monthly Services Fee
                </div>
              </td>
              <td className="py-4 pl-3 pr-4 text-right text-sm text-gray-500 sm:pr-6 md:pr-0">
                {formatCentsToDollars(quote.monthlyServiceFee, 'USD')}
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <th
                scope="row"
                colSpan={2}
                className="hidden pl-6 pr-3 pt-4 text-right text-sm font-semibold text-gray-900 sm:table-cell md:pl-0"
              >
                Monthly Total
              </th>
              <th
                scope="row"
                className="pl-4 pr-3 pt-4 text-left text-sm font-semibold text-gray-900 sm:hidden"
              >
                Montly Total
              </th>
              <td className="pl-3 pr-4 pt-4 text-right text-sm font-semibold text-gray-900 sm:pr-6 md:pr-0">
                {formatCentsToDollars(quote.monthlyTotal, 'USD')}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function BenefitsTable() {
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <p className="mt-2 text-sm text-gray-700 text-center">
            Estimated Monthly Private Benefits by Age
          </p>
        </div>
      </div>
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 mb-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Age
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      Standard
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-3.5 text-center text-sm font-semibold text-gray-900"
                    >
                      Premium
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-gray-900 sm:pr-6"
                    >
                      Premium + Maternity
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      0-17
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $75
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $120
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $165
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      18-39
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $120
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $190
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $235
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      40-49
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $180
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $285
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $385
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      50-59
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $285
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $450
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $595
                    </td>
                  </tr>
                  <tr className="divide-x divide-gray-200">
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      60-74
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $525
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $825
                    </td>
                    <td className="whitespace-nowrap text-center p-4 text-sm text-gray-500">
                      $1,110
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function InfoText() {
  return (
    <div className="relative overflow-hidden bg-white py-4">
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="prose prose-lg prose-indigo mx-auto mt-3 text-center text-xs text-gray-500">
          <p>
            Subject to applicable country laws, regulations, and customs,
            exclusive of any agreed-upon signing bonus, or annual
            <a href="https://www.listoglobal.com/blog">
              {' '}
              13th month payment
            </a>{' '}
            if applicable, and the Listo Global
            <a href="https://www.listoglobal.com/terms-of-use">
              {' '}
              Terms and Conditions.
            </a>{' '}
            Estimated fees are not binding. See the
            <a href="https://www.listoglobal.com/global-hiring-guide">
              {' '}
              Listo Global Hiring Guide
            </a>{' '}
            for more information on local taxes and social contributions. All
            amounts in USD.
          </p>
          <p>
            For more information about private benefits plans and coverage, see
            our{' '}
            <a href="https://www.listoglobal.com/#">
              Private Benefits Information.
            </a>{' '}
            Estimated amounts are not binding. All amounts in USD.
          </p>
        </div>
      </div>
    </div>
  );
}

export function Quote() {
  const navigate = useNavigate();
  const fields = useEorForm((state) => state.fields);
  const resetForm = useEorForm((state) => state.resetForm);
  const utils = trpc.useContext();

  const createContract = trpc.u.contracts.eor.useMutation({
    onSuccess: ({ workerId, id }) => {
      resetForm();
      utils.u.workers.worker.invalidate(workerId).catch(() => {});
      navigate(`/contracts/${id}/invite`);
    },
  });

  const clickHandler = () => {
    const employeeDetailsStep = eorEmployeeFields.safeParse(fields);
    if (!employeeDetailsStep.success) {
      navigate(`/create-contract/eor/employee-info?autoValidate=true`);
      return;
    }

    const jobStep = eorJobFields.safeParse(fields);
    if (!jobStep.success) {
      navigate(`/create-contract/eor/job?autoValidate=true`);
      return;
    }

    const compensationStep = eorCompensationFields.safeParse(fields);
    if (!compensationStep.success) {
      navigate(`/create-contract/eor/compensation?autoValidate=true`);
      return;
    }

    const benefitsStep = eorBenefits.safeParse(fields);
    if (!benefitsStep.success) {
      navigate(`/create-contract/eor/benefits?autoValidate=true`);
      return;
    }

    createContract.mutate({
      eorContractCreate: {
        ...(fields as EorContractCreate),
        billingAmountInCents: floatInDollarsToCents(
          fields?.billingAmountInCents,
        ),
      },
    });
  };

  return (
    <div className="pt-5">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Request a Quote
          </h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            After submission we will prepare a quote and cost breakdown. You
            will be able to review and approve the quote before proceeding. If
            you have any questions, please contact us.
          </p>
        </div>
        {/* <FeesTables setDepositRequested={setDepositRequested} /> */}
        {/* {fields?.benefits === 'true' ? <BenefitsTable /> : null} */}
        {/* <InfoText /> */}
        <div className="px-4 py-5 sm:p-6">
          <div className="mt-2 sm:flex sm:items-start sm:justify-end">
            <div className="mt-2 sm:mt-0 sm:ml-6 sm:flex-shrink-0 sm:flex sm:items-right">
              <Button
                text="Submit"
                loading={createContract.isLoading}
                onClick={clickHandler}
                type="button"
                className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm ml-3"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
