/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { ADMIN_URL } from './constants';
import { RouterOutput } from './trpc';

export interface StandarErrorResponse {
  error: string[];
}

export function handleLoginRedirect(
  navigate: any,
  claims: any,
  redirect: string | null,
  state: any,
) {
  if (claims.accessType === 'admin') {
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    window.location.href = `${ADMIN_URL}/login`;
  }

  if (claims.accessType === 'user' && claims.activeClientId) {
    navigate(redirect ?? state?.path ?? '/');
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (claims.accessType === 'worker') {
    navigate(redirect ?? state?.path ?? '/wrkr');
  }
}

export type WorkerProfile =
  RouterOutput['w']['contracts']['contract']['workerProfile'];

export function getSignContractLink(
  contractId: string,
  workerProfile: WorkerProfile,
) {
  if (workerProfile.taxIdNumber) {
    return `/wrkr/my-contracts/${contractId}?initialOpen=true`;
  }

  return `/wrkr/accept-contract/${contractId}/info`;
}
