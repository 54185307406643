import { zodResolver } from '@hookform/resolvers/zod';
import { centsToDollars } from 'listo/src/utils/currency';
import { stringFromDateOrString } from 'listo/src/utils/dates';
import { UpdatePayType, updatePayZod } from 'listo/src/zodObjects/contracts';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Input } from 'ui/src/components/Input';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { CurrencyInput } from 'ui/src/components/Currency/CurrencyInput';
import { RouterOutput, trpc } from '../../lib/trpc';

export function UpdatePaymentAmount({
  contract,
}: {
  contract: RouterOutput['u']['contracts']['contract'];
}) {
  const setNotification = useNotification((state) => state.setNotification);
  const utils = trpc.useContext();
  const { mutate: updatePay } = trpc.u.contracts.updatePay.useMutation({
    onSuccess: () => {
      utils.u.contracts.contract.invalidate(contract.id).catch(() => {});
      setNotification({
        type: 'success',
        title: 'Success',
        message: 'Pay updated',
      });
    },
  });

  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<UpdatePayType>({
    resolver: zodResolver(updatePayZod),
  });

  const onSubmit: SubmitHandler<UpdatePayType> = (data) => {
    updatePay({ ...data, contractId: contract.id });
  };

  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Change Payment Amount
          </h3>
          <p className="mt-1 text-sm text-gray-600">
            Updating the payment amount requires an effective date indicating
            when the pay change will take effect.
          </p>
        </div>
      </div>
      <div className="mt-5 md:col-span-2 md:mt-0">
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
              <div className="grid grid-cols-3 gap-6">
                <CurrencyInput
                  currencyField="billingCurrency"
                  amountField="billingAmountInCents"
                  register={register}
                  errors={errors}
                  label="Contract Amount"
                  control={control}
                  defaultValues={{
                    currency: contract.billingCurrency,
                    amount: centsToDollars(contract.billingAmountInCents),
                  }}
                />

                <div className="col-span-3 sm:col-span-1">
                  <Input
                    label="Effective Date"
                    inputType="date"
                    inputProps={{
                      defaultValue: stringFromDateOrString(new Date()).split(
                        'T',
                      )[0],
                    }}
                    reactHookForm={{
                      register,
                      fieldName: 'effectiveDate',
                      errors,
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <button
                type="submit"
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Change Pay
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
