import { useState } from 'react';
import Modal from '../../components/Modal';
import { Spinner } from '../../components/Spinner/Spinner';

const iframeStyling =
  '#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0';

export function PdfModal({
  isOpen,
  setIsOpen,
  title,
  pdfUrl,
  footer,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  title?: string;
  pdfUrl: string;
  footer?: {
    children: React.ReactNode;
    offsetPx: number;
  };
}) {
  const source = `${pdfUrl}${iframeStyling}`;
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Modal isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className="px-4 pt-5 pb-4 sm:my-8 sm:max-w-4xl sm:p-6 sm:w-[60vw] h-[80vh] w-[90vw]">
        <div
          className="overflow-auto rounded"
          style={{
            height: footer?.offsetPx
              ? `calc(100% - ${footer.offsetPx}px)`
              : '100%',
          }}
        >
          {isLoading ? (
            <div className="flex justify-center items-center h-full">
              <Spinner />
            </div>
          ) : null}

          <iframe
            style={{ height: '100%', width: '100%' }}
            title={title ?? 'document'}
            src={source}
            onLoad={() => setIsLoading(false)}
            onError={() => setIsLoading(false)}
          />
        </div>

        {footer?.children ? footer.children : null}
      </div>
    </Modal>
  );
}
