import { DateTime } from 'listo/src/luxonUtc';
import posthog from 'posthog-js';
import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Banner } from 'ui/src/components/Banner';
import useLocalStorage from 'ui/src/hooks/useLocalStorage';
import UserAppShell from '../components/UserAppShell';
import WorkerAppShell from '../components/WorkerAppShell';
import { useAuth } from '../hooks/useAuth';
import { BUNDLE_VERSION, IS_PROD } from '../lib/constants';
import Page404 from '../pages/404';
import AddUser from '../pages/AddUser';
import Contract from '../pages/Contract';
import { ContractStatus } from '../pages/Contract/ContractStatus';
import { Deposit } from '../pages/Contract/Deposit';
import { Invite } from '../pages/Contract/Invite';
import CreateContract from '../pages/CreateContract';
import ContractForm from '../pages/CreateContractForm';
import { Contractor } from '../pages/CreateContractForm/Contractor';
import { ContractorJob } from '../pages/CreateContractForm/ContractorJob';
import { Payment as PaymentForm } from '../pages/CreateContractForm/Payment';
import RecruitForm from '../pages/CreateRecruitForm';
import { Info as RecruitInfo } from '../pages/CreateRecruitForm/Info';
import { Payment as RecruitPayment } from '../pages/CreateRecruitForm/Payment';
import Dashboard from '../pages/Dashboard';
import TimeOffRequestInfo from '../pages/RequestTimeOffInfo';
import EditContract from '../pages/EditContract/EditContract';
import EorContract from '../pages/EorContract';
import { Benefits } from '../pages/EorContract/Benefits';
import { Compensation } from '../pages/EorContract/Compensation';
import { EmployeeInfo } from '../pages/EorContract/EmployeeInfo';
import { Job } from '../pages/EorContract/Job';
import { Quote } from '../pages/EorContract/Quote';
import ForgotPassword from '../pages/ForgotPassword';
import GuestSign from '../pages/GuestSign';
import Invoice from '../pages/Invoice';
import Invoices from '../pages/Invoices';
import Login from '../pages/Login';
import { PayPeriod } from '../pages/PayPeriod';
import { PayPeriods } from '../pages/PayPeriods';
import Profile from '../pages/Profile';
import ProfileDocuments from '../pages/Profile/Documents';
import Contracts from '../pages/Profile/Contracts';
import Payments from '../pages/Profile/Payments';
import ProfileDetails from '../pages/Profile/ProfileDetails';
import Recruit from '../pages/Recruit';
import Recruiting from '../pages/Recruiting';
import Report from '../pages/Report';
import Reports from '../pages/Reports';
import SetPassword from '../pages/SetPassword';
import Settings from '../pages/Settings';
import { Entity } from '../pages/Settings/Entity';
import { General } from '../pages/Settings/General';
import { PaymentMethods } from '../pages/Settings/PaymentMethods';
import { Users } from '../pages/Settings/Users';
import Team from '../pages/Team';
import { AcceptQuote } from '../pages/AcceptQuote';
import OneOffPayments from '../pages/Contract/OneOffPayments';
import TimeTracking from '../pages/TimeTracking';
import WorkerTimeTracking from '../pages/TimeTracking/WorkerTimeTracking';
import { ManageLabels } from '../pages/TimeTracking/ManageLabels';
import { RequestReimbursementInfo } from '../pages/RequestReimbursementInfo';
import { ReimbursementRequests } from '../pages/ReimbursementRequests';
import { Payments as PaymentInformation } from '../pages/Payments/payments';
import WorkerContract from '../workerPages/AcceptContract';
import { Address } from '../workerPages/AcceptContract/Address';
import { PersonalInfo } from '../workerPages/AcceptContract/PersonalInfo';
import { Tax } from '../workerPages/AcceptContract/Tax';
import { BankAccount } from '../workerPages/BankAccount/BankAccount';
import MyContract from '../workerPages/MyContract';
import MyContracts from '../workerPages/MyContracts';
import { TaxInfo } from '../workerPages/TaxInfo/TaxInfo';
import { Timecards } from '../workerPages/Timecards';
import { WorkerDashboard } from '../workerPages/WorkerDashboard';
import { WorkerProfile } from '../workerPages/WorkerProfile/WorkerProfile';
import { WorkerSettings } from '../workerPages/WorkerSettings/WorkerSettings';
import { TimeOffRequest } from '../workerPages/RequestTimeOff';
import { WorkerChangePassword } from '../workerPages/WorkerProfile/WorkerChangePassword';
import { TimeOffRequests } from '../pages/TimeOffRequests';
import { LeFakeStripeInvoice } from '../pages/LeFakeStripeInvoice';
import BankAccountsList from '../workerPages/BankAccountsList/BankAccountsList';
import { CreateBankAccount } from '../workerPages/CreateBankAccount/CreateBankAccount';
import { LineItemsBarChart } from '../pages/Reports/LineItemsBarChart';
import { RequestReimbursement } from '../workerPages/RequestReimbursement';

import { Documents } from '../workerPages/Documents';

export function ListoRoutes() {
  const location = useLocation();
  const verifyJwt = useAuth((state) => state.verifyJwt);
  const jwt = useAuth((state) => state.jwt);
  const claims = useAuth((state) => state.claims);
  const { boot, update } = useIntercom();
  const [showUpdateAvailable, setShowUpdateAvailable] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [latestVersion, setLatestVersion] = useLocalStorage(
    'latestVersion',
    undefined,
  );
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const [lastTimeUpdateRequested, setLastTimeUpdateRequested] = useLocalStorage(
    'lastTimeUpdateRequested',
    undefined,
  );

  useEffect(() => {
    posthog.capture('$pageview');
  }, [location.pathname]);

  useEffect(() => {
    verifyJwt();
  }, [location.pathname, verifyJwt]);

  useEffect(() => {
    boot();
  }, [boot]);

  useEffect(() => {
    update({
      customAttributes: {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        latest_version: latestVersion,
        bundle_version: BUNDLE_VERSION,
      },
    });
  }, [latestVersion, location.pathname, update]);

  useEffect(() => {
    if (jwt === undefined || claims === undefined) verifyJwt();
  }, [jwt, claims, verifyJwt]);

  useEffect(() => {
    if (IS_PROD)
      fetch('https://app.listoglobal.com/latestVersion.txt')
        .then((res) => res.text())
        .then((version) => {
          const c = DateTime.fromISO(version.replace(/\n/g, ''));
          // eslint-disable-next-line @typescript-eslint/no-unsafe-call
          setLatestVersion(c.toISO());
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
  }, [setLatestVersion]);

  useEffect(() => {
    if (typeof latestVersion !== 'string') return;
    const bundleVersion = DateTime.fromISO(BUNDLE_VERSION);
    const c = DateTime.fromISO(latestVersion);

    if (bundleVersion < c) {
      const now = DateTime.now();

      if (
        lastTimeUpdateRequested === undefined ||
        (typeof lastTimeUpdateRequested === 'string' &&
          DateTime.fromISO(lastTimeUpdateRequested).diff(now, 'days').days > 1)
      ) {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        setLastTimeUpdateRequested(now.toISO());
        setShowUpdateAvailable(true);
      }
    }
  }, [
    latestVersion,
    lastTimeUpdateRequested,
    setLastTimeUpdateRequested,
    // check if there is a new bundle version set to localstorage on each pageload
    location.pathname,
  ]);

  return (
    <div className="min-h-full">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/set-password" element={<SetPassword />} />
        <Route path="/guest-sign/:contractId" element={<GuestSign />} />
        <Route path="/" element={<UserAppShell />}>
          <Route index element={<Dashboard />} />
          <Route
            path="/request-time-off/:timeOffRequestId"
            element={<TimeOffRequestInfo />}
          />
          <Route path="/time-off" element={<TimeOffRequests />} />
          <Route
            path="/request-reimbursement/:reimbursementRequestId"
            element={<RequestReimbursementInfo />}
          />
          <Route path="/reimbursements" element={<ReimbursementRequests />} />
          <Route path="payments" element={<PaymentInformation />} />
          <Route path="/quotes/:quoteId" element={<AcceptQuote />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/create-contract" element={<CreateContract />} />
          <Route path="/create-contract/eor" element={<EorContract />}>
            <Route index element={<EmployeeInfo />} />
            <Route path="employee-info" element={<EmployeeInfo />} />
            <Route path="job" element={<Job />} />
            <Route path="compensation" element={<Compensation />} />
            <Route path="benefits" element={<Benefits />} />
            <Route path="quote" element={<Quote />} />
          </Route>
          <Route path="/create-contract/:type" element={<ContractForm />}>
            <Route index element={<Contractor />} />
            <Route path="contractor" element={<Contractor />} />
            <Route path="job" element={<ContractorJob />} />
            <Route path="payment" element={<PaymentForm />} />
          </Route>
          <Route
            path="/time-tracking/:workerProfileId"
            element={<WorkerTimeTracking />}
          />
          <Route path="/time-tracking" element={<TimeTracking />} />
          <Route path="/time-tracking/labels" element={<ManageLabels />} />
          <Route path="/invoices/:id" element={<Invoice />} />
          <Route path="/invoices" element={<Invoices />} />
          <Route
            path="/le-fake-stripe-invoice/:id"
            element={<LeFakeStripeInvoice />}
          />
          <Route path="/pay-period/:payPeriodId" element={<PayPeriod />} />
          <Route path="/payPeriods" element={<PayPeriods />} />
          <Route
            path="/reports/line-items/bar-chart"
            element={<LineItemsBarChart />}
          />
          <Route path="/reports/:type" element={<Report />} />
          <Route path="/reports" element={<Reports />} />
          <Route path="/recruits/:id" element={<Recruit />} />
          <Route path="/recruiting" element={<Recruiting />} />
          <Route path="/contracts/:contractId" element={<Contract />}>
            <Route index element={null} />
            <Route path="deposit" element={<Deposit />} />
            <Route path="invite" element={<Invite />} />
            <Route path="sign" element={<ContractStatus />} />
          </Route>
          <Route
            path="/contracts/:contractId/payments"
            element={<OneOffPayments />}
          />
          <Route
            path="/contracts/:contractId/edit"
            element={<EditContract />}
          />

          <Route path="/team/:id" element={<Profile />}>
            {/* TODO: use outlet / router for tabs? */}
            <Route index element={<ProfileDetails />} />
            <Route path="profile-details" element={<ProfileDetails />} />
            <Route path="documents" element={<ProfileDocuments />} />
            <Route path="payments" element={<Payments />} />
            <Route path="contracts" element={<Contracts />} />
          </Route>
          <Route path="/team" element={<Team />} />
          <Route path="/create-recruit" element={<RecruitForm />}>
            <Route index element={<RecruitInfo />} />
            <Route path="info" element={<RecruitInfo />} />
            <Route path="payment" element={<RecruitPayment />} />
          </Route>
          <Route path="/settings" element={<Settings />}>
            <Route index element={<General />} />
            <Route path="general" element={<General />} />
            <Route path="payment-methods" element={<PaymentMethods />} />
            <Route path="users" element={<Users />} />
            <Route path="entity" element={<Entity />} />
          </Route>
        </Route>

        <Route path="/wrkr" element={<WorkerAppShell />}>
          <Route index element={<WorkerDashboard />} />
          <Route path="my-contracts/:id" element={<MyContract />} />
          <Route path="my-contracts" element={<MyContracts />} />
          <Route path="accept-contract/:id" element={<WorkerContract />}>
            <Route index element={<PersonalInfo />} />
            <Route path="info" element={<PersonalInfo />} />
            <Route path="address" element={<Address />} />
            <Route path="tax" element={<Tax />} />
          </Route>
          <Route path="timecards" element={<Timecards />} />
          <Route path="request-time-off" element={<TimeOffRequest />} />
          <Route
            path="request-reimbursement"
            element={<RequestReimbursement />}
          />
          <Route path="documents" element={<Documents />} />
          <Route path="profile" element={<WorkerSettings />}>
            <Route index element={<WorkerProfile />} />
            <Route path="info" element={<WorkerProfile />} />
            <Route path="bank-account" element={<BankAccountsList />} />
            <Route path="tax" element={<TaxInfo />} />
            <Route path="password-update" element={<WorkerChangePassword />} />
          </Route>
          <Route path="bank-account/create" element={<CreateBankAccount />} />
          <Route path="bank-account/:id/edit" element={<BankAccount />} />
        </Route>
        <Route path="*" element={<Page404 />} />
      </Routes>
      {showUpdateAvailable ? (
        <Banner
          bigText="We're always improving! There is a new app release available."
          shortText="Get our newest app version"
          buttonText="Update Now"
          onConfirm={() => {
            window.location.reload();
          }}
          onClose={() => {
            setShowUpdateAvailable(false);
          }}
        />
      ) : null}
    </div>
  );
}
