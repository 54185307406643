import create from 'zustand';

interface ApprovalModal {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export const useApprovalModal = create<ApprovalModal>((set) => ({
  isOpen: false,
  setIsOpen: (isOpen) => set(() => ({ isOpen })),
}));
