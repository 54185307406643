import { getShortId, titleCase } from 'listo/src/utils/strings';
import { useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';
import { trpc } from '../../lib/trpc';
import { OffboardContract } from './OffboardContract';
import { UpdatePaymentAmount } from './UpdatePaymentAmount';

function Boarder() {
  return (
    <div className="hidden sm:block" aria-hidden="true">
      <div className="py-5">
        <div className="border-t border-gray-200" />
      </div>
    </div>
  );
}

function EditContract() {
  const { contractId } = useParams<{ contractId: string }>();
  if (!contractId) throw new Error('contractId is required');
  const { data: contract, isLoading } =
    trpc.u.contracts.contract.useQuery(contractId);

  if (isLoading || contract === undefined) return <Loader />;

  return (
    <div className="bg-gray-100 min-h-screen">
      <TopBar
        breadcrumbs={[
          {
            name: titleCase(contract.workerProfile.fullName ?? 'profile'),
            href: `/team/${contract.worker.id}`,
            current: false,
          },
          {
            name: 'Contracts',
            href: `/team/${contract.worker.id}/contracts`,
            current: false,
          },
          {
            name: getShortId(contract.id) ?? '',
            href: `/contracts/${contract.id}`,
            current: false,
          },
          {
            name: 'Edit',
            href: `/contracts/${contract.id}/edit`,
            current: true,
          },
        ]}
        title="Contract"
      />

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 py-6 sm:py-8">
        <UpdatePaymentAmount contract={contract} />
        <Boarder />
        <OffboardContract contract={contract} />
      </div>
    </div>
  );
}

export default EditContract;
