import { PaperAirplaneIcon } from '@heroicons/react/20/solid';
import { formatDate } from 'listo/src/utils/dates';
import { Link } from 'react-router-dom';
import { RouterOutput } from '../../lib/trpc';

function statusLabel(payPeriod: RouterOutput['w']['payPeriods']['list']) {
  if (!payPeriod) return null;

  if (payPeriod.status === 'PENDING_APPROVAL' && !payPeriod.approvedAt)
    return 'Pending Approval';
  if (payPeriod.status === 'APPROVED' && payPeriod.approvedAt)
    return 'Approved';
  if (!payPeriod.invoicedAt) return 'Pending Invoice';
  if (!payPeriod.distributedAt) return 'Pending Distribution';

  if (payPeriod.status === 'DISTRIBUTING') return 'Distributing';
  if (payPeriod.status === 'COMPLETE') return 'Completed';

  return '';
}

export function PayPeriodCard({
  payPeriod,
  contractId,
}: {
  payPeriod: RouterOutput['w']['payPeriods']['list'];
  contractId: string;
}) {
  if (!payPeriod) return null;
  return (
    <div className="relative overflow-hidden rounded-lg bg-white px-4 pt-5 shadow sm:px-6 sm:pt-6">
      <div className="text-2xl font-semibold text-gray-900">
        <Link to={`/wrkr/my-contracts/${contractId}`}>
          {`Pay Period - ${payPeriod.client.name}`}
        </Link>
      </div>
      <dt className="mt-4">
        <div className="absolute rounded-md bg-indigo-500 p-3">
          <PaperAirplaneIcon
            className="h-6 w-6 text-white"
            aria-hidden="true"
          />
        </div>
        <p className="ml-16 truncate text-sm font-medium text-gray-500">
          {`${formatDate(payPeriod.periodStart)} - ${formatDate(
            payPeriod.periodEnd,
          )}`}
        </p>
      </dt>
      <dd className="ml-16 flex items-baseline pb-6 sm:pb-7">
        <p className="text-lgs font-semibold text-gray-900">
          {formatDate(payPeriod.distributeDate)}
        </p>
        <div className="ml-2 inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
          {statusLabel(payPeriod)}
        </div>
      </dd>
    </div>
  );
}
