import { DateTime } from 'luxon';
import { Input } from 'ui/src/components/Input';

import { RouterOutput } from '../../lib/trpc';
import LineChart from './LineChart';

export default function MonthOverMonth({
  data,
  refetch,
  lastMonth,
  currentMonth,
  setLastMonth,
  setCurrentMonth,
}: {
  data: RouterOutput['w']['timeCards']['monthOverMonthReport'];
  refetch: () => Promise<unknown>;
  lastMonth: DateTime;
  currentMonth: DateTime;
  setLastMonth: (date: DateTime) => void;
  setCurrentMonth: (date: DateTime) => void;
}) {
  const getMonthName = (date: string) =>
    DateTime.fromFormat(date, 'yyyy-MM').monthLong;

  const handleLastMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const lM = DateTime.fromFormat(e.target.value, 'yyyy-MM');
    setLastMonth(lM);
    refetch().catch(() => {});
  };

  const handleCurrentMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const cM = DateTime.fromFormat(e.target.value, 'yyyy-MM');
    setCurrentMonth(cM);
    refetch().catch(() => {});
  };
  return (
    <div className="overflow-hidden rounded-lg bg-white shadow sm:col-span-2 px-4 py-5">
      <div className="grid grid-cols-2 gap-4 my-2">
        <Input
          inputType="month"
          inputProps={{
            defaultValue: lastMonth.toFormat('yyyy-MM'),
            onChange: handleLastMonthChange,
          }}
        />
        <Input
          inputType="month"
          inputProps={{
            defaultValue: currentMonth.toFormat('yyyy-MM'),
            onChange: handleCurrentMonthChange,
          }}
        />
      </div>
      <LineChart
        lastMonth={data.lastMonth}
        lastMonthLabel={getMonthName(lastMonth.toFormat('yyyy-MM'))}
        currentMonth={data.currentMonth}
        currentMonthLabel={getMonthName(currentMonth.toFormat('yyyy-MM'))}
      />
    </div>
  );
}
