import { PaperClipIcon, ArrowDownIcon } from '@heroicons/react/24/outline';
import { classNames } from 'listo/src/utils/strings';
import { useParams } from 'react-router-dom';
import { Success, Failed } from '../../components/Badge/Badge';
import { trpc } from '../../lib/trpc';

// type ComplianceDocumentWithUpload = ComplianceDocument & {
//   upload: Partial<UploadWithPresignedUrl> | null;
// };

const tabPadding = 'px-4 py-5 sm:px-6';

export default function ProfileDocuments() {
  const { id } = useParams();

  if (!id) throw new Error('id is required');

  const { data } = trpc.u.workers.worker.useQuery(id);

  return (
    <div className={tabPadding}>
      <div className="flow-root mt-6">
        <div className="sm:flex sm:items-center mb-10">
          <div className="sm:flex-auto">
            <p className="text-sm text-gray-700">
              A list of documents required in this contract.
            </p>
          </div>
        </div>
        <ul className="my-5 mr-4 divide-y divide-gray-200 border-t border-gray-200">
          {data?.documents.length === 0 && (
            <li className="py-4">
              <div className="flex items-center space-x-4 ">
                <p className="text-sm font-medium text-gray-900 truncate">
                  No Documents Requested
                </p>
              </div>
            </li>
          )}
          {data?.documents.map((doc) => {
            const { url } = doc.upload;

            return (
              <li key={doc.id} className="py-4">
                <div className="flex items-center space-x-4 ">
                  <div>
                    <PaperClipIcon className="h-5 w-5 rounded-full" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {doc.documentType}
                    </p>
                  </div>
                  <div>
                    {doc.uploadId ? (
                      <Success text="Uploaded" />
                    ) : (
                      <Failed text="Requested" />
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      title="Download File"
                      disabled={!url}
                      className={classNames(
                        doc.uploadId ? '' : 'cursor-not-allowed',
                      )}
                      onClick={() => {
                        if (!url) return;

                        window.open(url, '_blank');
                      }}
                    >
                      <ArrowDownIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
