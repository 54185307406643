import {
  BriefcaseIcon,
  CheckBadgeIcon,
  DocumentTextIcon,
  IdentificationIcon,
  ReceiptPercentIcon,
  TagIcon,
  UserCircleIcon,
} from '@heroicons/react/24/outline';
// eslint-disable-next-line no-restricted-imports
import { ActionItem } from 'api/src/generated/prismaClient';
import { formatDate } from 'listo/src/utils/dates';
import { classNames } from 'listo/src/utils/strings';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import { RouterOutput, trpc } from '../../lib/trpc';
import { UnstructuredData } from '../../types/actionItem';

export function Icon({ referenceType }: { referenceType: string | null }) {
  const className = 'h-5 w-5 text-white';
  switch (referenceType) {
    case 'contract':
      return <DocumentTextIcon className={className} aria-hidden="true" />;
    case 'user':
      return <UserCircleIcon className={className} aria-hidden="true" />;
    case 'worker':
      return <IdentificationIcon className={className} aria-hidden="true" />;
    case 'client':
      return <BriefcaseIcon className={className} aria-hidden="true" />;
    case 'invoice':
      return <ReceiptPercentIcon className={className} aria-hidden="true" />;
    case 'empty':
      return <CheckBadgeIcon className={className} aria-hidden="true" />;
    default:
      return <TagIcon className={className} aria-hidden="true" />;
  }
}

export function RenderListItem({
  action,
  actionIdx,
  actionsLength,
}: {
  action: RouterOutput['u']['actionItems']['list'][0];
  actionIdx: number;
  actionsLength: number;
}) {
  const unstructuredData =
    action.unstructuredData as unknown as UnstructuredData;

  return (
    <div className="relative pb-8">
      {actionIdx !== actionsLength - 1 ? (
        <span
          className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
          aria-hidden="true"
        />
      ) : null}
      <div className="relative flex space-x-3">
        <div>
          <span
            className={classNames(
              'bg-blue-500',
              'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
            )}
          >
            <Icon referenceType={action.referenceType} />
          </span>
        </div>
        <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
          <div>
            <p className="text-sm text-gray-500">
              {unstructuredData.displayTitle ?? ''}{' '}
              {unstructuredData.href ? (
                <Link
                  to={unstructuredData.href}
                  className="font-medium text-gray-900"
                >
                  {unstructuredData.description}
                </Link>
              ) : null}
            </p>
          </div>
          <div className="text-right text-sm whitespace-nowrap text-gray-500">
            <time dateTime={formatDate(action.timestamp)}>
              {formatDate(action.timestamp)}
            </time>
          </div>
        </div>
      </div>
    </div>
  );
}

export function RenderEmptyListMsg() {
  return (
    <div>
      <div className="relative pb-8">
        <div className="relative flex space-x-3">
          <div>
            <span
              className={classNames(
                'bg-blue-500',
                'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
              )}
            >
              <Icon referenceType="empty" />
            </span>
          </div>
          <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
            <p className="text-md text-gray-500">All caught up</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export function OnboardingCard() {
  const actionItems = trpc.u.actionItems.list.useQuery();

  if (actionItems.isLoading) return <Loader />;
  if (actionItems.error) return <div>Error</div>;

  return (
    <div className="flow-root">
      <ul className="-mb-8 h-44 overflow-y-scroll overflow-x-hidden scrollbar-hide">
        {actionItems.data.length === 0 ? <RenderEmptyListMsg /> : null}
        {actionItems.data.map((item: ActionItem, index) => (
          <div key={item.id}>
            <RenderListItem
              action={item}
              actionIdx={index}
              actionsLength={actionItems.data.length}
            />
          </div>
        ))}
      </ul>
    </div>
  );
}
