import TopBar from '../../components/TopBar';
import PaymentsTable from './paymentsTable';

export function Payments() {
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen" data-testid="main">
      <TopBar title="Payments" />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-12">
        <PaymentsTable />
      </div>
    </main>
  );
}
