import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { SubmitHandler } from 'react-hook-form';
import {
  TimeOffRequestStatusEnum,
  TimeOffRequestStatus,
} from 'listo/src/zodObjects/requestTimeOff';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { ExclamationTriangleIcon } from '@heroicons/react/20/solid';
import { Button } from 'ui/src/components/Button';
import {
  ApproveModal,
  IApprovalModalForm,
} from '../../components/ApproveModal';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';
import Avatar from '../../components/Avatar';
import { statusChipConfig } from '../../components/RequestTimeOffList';

enum EApprovalModal {
  APPROVE = 'approve',
  DECLINE = 'decline',
}

export function TimeOffRequest() {
  const { claims } = useAuth();
  const navigate = useNavigate();

  const { timeOffRequestId } = useParams();
  const setNotification = useNotification((state) => state.setNotification);
  const [approveModalType, setApproveModalType] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const today = DateTime.now();

  if (!timeOffRequestId) throw new Error('Missing timeOffRequestId');
  const { data, refetch, isLoading } =
    trpc.u.timeOffRequest.list.useQuery(timeOffRequestId);

  useEffect(() => {
    if (!isLoading) {
      const hasPermissionToView =
        data && claims?.activeClientId === data.clientId;

      if (!hasPermissionToView) {
        navigate('/404');
      } else {
        const status = data.status as TimeOffRequestStatus;
        const startDate = DateTime.fromJSDate(data.startDate);

        if (startDate < today) {
          setWarningMessage('This request for time off is expired.');
        } else if (status === TimeOffRequestStatusEnum.Values.CANCELLED) {
          setWarningMessage('This request for time off is already cancelled.');
        } else if (status === TimeOffRequestStatusEnum.Values.APPROVED) {
          setWarningMessage('This request for time off is already approved.');
        } else if (status === TimeOffRequestStatusEnum.Values.DECLINED) {
          setWarningMessage('This request for time off is already rejected.');
        }
      }
    }
  }, [data, claims, navigate, today, isLoading]);

  const { mutateAsync: updateStatus } =
    trpc.u.timeOffRequest.updateStatus.useMutation({
      onSuccess: () =>
        setNotification({
          type: 'success',
          title: 'Success',
          message:
            approveModalType === EApprovalModal.APPROVE
              ? 'Approved successfully.'
              : 'Declined successfully.',
        }),
      onError: () =>
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Your request has failed. Please contact us.',
        }),
    });

  const hasPermissionToView = data && claims?.activeClientId === data.clientId;

  if (!hasPermissionToView) return null;

  const status = data.status as TimeOffRequestStatus;
  const startDate = DateTime.fromJSDate(data.startDate);
  const endDate = DateTime.fromJSDate(data.endDate);
  const totalDays = endDate.diff(startDate, ['days']);
  const canApproveOrDecline =
    status !== TimeOffRequestStatusEnum.Values.CANCELLED && startDate > today;

  const onSubmit: SubmitHandler<IApprovalModalForm> = async (values) => {
    try {
      await updateStatus({
        id: timeOffRequestId,
        status:
          approveModalType === EApprovalModal.APPROVE
            ? TimeOffRequestStatusEnum.Values.APPROVED
            : TimeOffRequestStatusEnum.Values.DECLINED,
        clientMessage: values.message,
      });
      await refetch();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setApproveModalType('');
    }
  };

  return (
    <div className="bg-gray-100 min-h-screen pb-8">
      <div className="space-y-8 lg:max-w-6xl lg:mx-auto px-5">
        {!!warningMessage && (
          <div className="border-l-4 border-yellow-400 bg-yellow-50 p-6">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-8 w-8 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-yellow-700">{warningMessage}</p>
              </div>
            </div>
          </div>
        )}

        <div className="overflow-hidden space-y-10 px-8 py-12 bg-white shadow sm:rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-6">
              <Avatar
                name={data.workerProfile.fullName ?? ''}
                image={data.workerProfile.workerProfileUpload?.url ?? ''}
                className="w-[55px] h-[55px] bg-gray-200 text-3xl"
              />

              <span className="text-2xl font-medium text-gray-800 capitalize">
                {data.workerProfile.fullName}
              </span>
            </div>

            <div className="">
              <span
                className={`flex items-center gap-2 px-4 py-2 ${statusChipConfig[status].color} ${statusChipConfig[status].bgColor} rounded-full text-white text-sm capitalize`}
              >
                {statusChipConfig[status].icon} {status.toLowerCase()}
              </span>
            </div>
          </div>

          <div className="pt-2">
            <span className="text-3xl tracking-wide font-medium text-gray-800">
              {DateTime.fromISO(data.startDate.toISOString()).toFormat(
                'EEE MMM dd',
              )}{' '}
              -{' '}
              {DateTime.fromISO(data.endDate.toISOString()).toFormat(
                'EEE MMM dd',
              )}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            <span className="font-medium text-gray-800">
              Reason for time off
            </span>

            <span className="font-light text-gray-500 break-word">
              {data.reason}
            </span>
          </div>
        </div>

        <div className="overflow-hidden px-8 py-12 bg-white shadow sm:rounded-lg">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="font-medium text-gray-800">Name</dt>

              <dd className="mt-1  text-gray-500 capitalize">
                {data.workerProfile.fullName}
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="font-medium text-gray-800">Start Date</dt>
              <dd className="mt-1  text-gray-500">
                {DateTime.fromISO(data.startDate.toString()).toFormat('DD')}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="font-medium text-gray-800">End Date</dt>
              <dd className="mt-1  text-gray-500">
                {' '}
                {DateTime.fromISO(data.endDate.toISOString()).toFormat('DD')}
              </dd>
            </div>
            <div className="sm:col-span-1">
              <dt className="font-medium text-gray-800">Total Days</dt>
              <dd className="mt-1  text-gray-500">
                {totalDays.days === 0 ? 1 : totalDays.days + 1}
              </dd>
            </div>
          </dl>

          <div className="mt-10 flex gap-4 justify-end">
            {canApproveOrDecline && (
              <>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={
                    data.status === TimeOffRequestStatusEnum.Values.DECLINED
                  }
                  onClick={() => setApproveModalType(EApprovalModal.DECLINE)}
                  text="Decline"
                />

                <Button
                  type="button"
                  disabled={
                    data.status === TimeOffRequestStatusEnum.Values.APPROVED
                  }
                  onClick={() => setApproveModalType(EApprovalModal.APPROVE)}
                  text="Approve"
                />
              </>
            )}
          </div>

          <ApproveModal
            open={!!approveModalType}
            onClose={() => setApproveModalType('')}
            title={
              approveModalType === EApprovalModal.APPROVE
                ? 'Approve'
                : 'Decline'
            }
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}
