import {
  Dialog,
  Disclosure,
  Menu,
  Popover,
  Transition,
} from '@headlessui/react';
import {
  ChevronDownIcon,
  MagnifyingGlassCircleIcon,
  XCircleIcon,
} from '@heroicons/react/24/outline';
import { classNames } from 'listo/src/utils/strings';
import { Fragment, useState } from 'react';
import TeamExportCSVExport from './TeamExportCSV';

export interface SelectedFilter {
  filterName: string;
  optionLabel: string;
  optionValue: string | null;
}

export function Search({
  searchTerm,
  setSearchTerm,
}: {
  searchTerm: string | undefined;
  setSearchTerm: (searchTerm: string) => void;
}) {
  return (
    <div>
      <label htmlFor="mobile-search-candidate" className="sr-only">
        Search
      </label>
      <label htmlFor="desktop-search-candidate" className="sr-only">
        Search
      </label>
      <div className="relative flex-grow focus-within:z-10 pr-4">
        <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
          <MagnifyingGlassCircleIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          onChange={({ target: { value } }) => setSearchTerm(value)}
          value={searchTerm === undefined ? '' : searchTerm}
          type="text"
          name="mobile-search-candidate"
          id="mobile-search-candidate"
          className="focus:ring-indigo-500 focus:border-indigo-500 block w-full rounded rounded-l-md pl-10 sm:hidden border-gray-300"
          placeholder="Search"
        />
        <input
          onChange={({ target: { value } }) => setSearchTerm(value)}
          value={searchTerm === undefined ? '' : searchTerm}
          type="text"
          name="desktop-search-candidate"
          id="desktop-search-candidate"
          className="hidden focus:ring-indigo-500 focus:border-indigo-500 w-full rounded rounded-l-md pl-10 sm:block sm:text-sm border-gray-300"
          placeholder="Search people"
        />
      </div>
    </div>
  );
}

interface FilterProps {
  name: string;
  options: { value: string; label: string }[];
}

export function Filter({
  name,
  options,
  currentFilters,
  setCurrentFilters,
}: {
  name: string;
  options: { value: string; label: string }[];
  currentFilters: SelectedFilter[];
  setCurrentFilters: (currentFilter: SelectedFilter) => void;
}) {
  return (
    <Disclosure
      as="div"
      key={name}
      className="border-t border-gray-200 px-4 py-6"
    >
      {({ open: isOpen }) => (
        <>
          <h3 className="-mx-2 -my-3 flow-root">
            <Disclosure.Button className="px-2 py-3 bg-white w-full flex items-center justify-between text-sm text-gray-400">
              <span className="font-medium text-gray-900">{name}</span>
              <span className="ml-6 flex items-center">
                <ChevronDownIcon
                  className={classNames(
                    isOpen ? '-rotate-180' : 'rotate-0',
                    'h-5 w-5 transform',
                  )}
                  aria-hidden="true"
                />
              </span>
            </Disclosure.Button>
          </h3>
          <Disclosure.Panel className="pt-6">
            <div className="space-y-6">
              {options.map((option, optionIdx) => (
                <div key={option.value} className="flex items-center">
                  <input
                    id={`filter-mobile-${name}-${optionIdx}`}
                    name={`${name}[]`}
                    onChange={({ target: { checked } }) => {
                      setCurrentFilters({
                        filterName: name,
                        optionLabel: option.label,
                        optionValue: checked ? option.value : null,
                      });
                    }}
                    defaultValue={option.value}
                    checked={
                      !!currentFilters.find(
                        (cf) =>
                          cf.optionValue === option.value &&
                          cf.optionLabel === option.label &&
                          cf.filterName === name,
                      )
                    }
                    type="checkbox"
                    className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                  />
                  <label
                    htmlFor={`filter-mobile-${name}-${optionIdx}`}
                    className="ml-3 text-sm text-gray-500"
                  >
                    {option.label}
                  </label>
                </div>
              ))}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}

export function MobileFilters({
  filters,
  mobileFiltersOpen,
  setMobileFiltersOpen,
  currentFilters,
  setCurrentFilters,
}: {
  filters: FilterProps[];
  mobileFiltersOpen: boolean;
  setMobileFiltersOpen: (open: boolean) => void;
  currentFilters: SelectedFilter[];
  setCurrentFilters: (currentFilter: SelectedFilter) => void;
}) {
  return (
    <Transition.Root show={mobileFiltersOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-40 sm:hidden"
        onClose={setMobileFiltersOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="transition-opacity ease-linear duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity ease-linear duration-300"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 flex z-40">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <Dialog.Panel className="ml-auto relative max-w-xs w-full h-full bg-white shadow-xl py-4 pb-6 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between">
                <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XCircleIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>

              {/* Filters */}
              <form className="mt-4">
                {filters.map((filter) => (
                  <div key={filter.name}>
                    <Filter
                      name={filter.name}
                      options={filter.options}
                      currentFilters={currentFilters}
                      setCurrentFilters={setCurrentFilters}
                    />
                  </div>
                ))}
              </form>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export type Filters = FilterProps[];

interface TableHeaderProps {
  searchTerm: string | undefined;
  setSearchTerm: (searchTerm: string) => void;
  filters: FilterProps[];
  currentFilters: SelectedFilter[];
  setCurrentFilters: (currentFilter: SelectedFilter) => void;
  sort: FilterProps;
  sortValue: string;
  setSortValue: (setSort: string) => void;
}

export default function TableHeader({
  filters,
  currentFilters,
  setCurrentFilters,
  setSearchTerm,
  setSortValue,
  searchTerm,
  sort,
  sortValue,
}: TableHeaderProps) {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false);

  return (
    <div>
      <MobileFilters
        currentFilters={currentFilters}
        mobileFiltersOpen={mobileFiltersOpen}
        setMobileFiltersOpen={setMobileFiltersOpen}
        setCurrentFilters={setCurrentFilters}
        filters={filters}
      />

      <div className="max-w-6xl mx-auto text-center lg:max-w-6xl">
        <section aria-labelledby="filter-heading" className="py-6">
          <h2 id="filter-heading" className="sr-only">
            Search
          </h2>

          <div className="flex items-center justify-between">
            <Search setSearchTerm={setSearchTerm} searchTerm={searchTerm} />

            <Menu
              as="div"
              className="relative z-10 inline-block text-left ml-auto mr-5"
            >
              <div>
                <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                  Sort
                  <ChevronDownIcon
                    className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </Menu.Button>
              </div>
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="origin-top-left absolute left-0 z-10 mt-2 w-40 rounded-md shadow-2xl bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="py-1">
                    {sort.options.map((option) => (
                      <Menu.Item key={option.value}>
                        <button
                          type="button"
                          onClick={() => setSortValue(option.value)}
                          className={classNames(
                            sortValue === option.value ? 'bg-gray-100' : '',
                            'block w-full text-left px-4 py-2 text-sm font-medium text-gray-900',
                          )}
                        >
                          {option.label}
                        </button>
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Transition>
            </Menu>

            <button
              type="button"
              className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 mr-5 sm:hidden"
              onClick={() => setMobileFiltersOpen(true)}
            >
              Filters
            </button>

            <Popover.Group className="hidden sm:flex sm:items-baseline sm:space-x-8 mr-5">
              {filters.map((filter) => {
                const numberOfSelectedOptions = currentFilters.reduce(
                  (acc, f) => {
                    if (f.filterName === filter.name && f.optionValue) {
                      return acc + 1;
                    }
                    return acc;
                  },
                  0,
                );
                return (
                  <Popover
                    as="div"
                    key={filter.name}
                    id="desktop-menu"
                    className="relative z-10 inline-block text-left ml-5"
                  >
                    <div>
                      <Popover.Button className="group inline-flex items-center justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        <span>{filter.name}</span>

                        {numberOfSelectedOptions > 0 ? (
                          <span
                            data-testid="filter"
                            className="ml-1.5 rounded py-0.5 px-1.5 bg-gray-200 text-xs font-semibold text-gray-700 tabular-nums"
                          >
                            {numberOfSelectedOptions}
                          </span>
                        ) : null}

                        <ChevronDownIcon
                          className="flex-shrink-0 -mr-1 ml-1 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Popover.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Popover.Panel className="origin-top-right absolute right-0 mt-2 bg-white rounded-md shadow-2xl p-4 ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <form className="space-y-4">
                          {filter.options.map((option) => (
                            <div
                              key={option.value}
                              className="flex items-center"
                            >
                              <input
                                id={`filter-${filter.name}-${option.value}`}
                                name={`${filter.name}[]`}
                                defaultValue={option.value}
                                checked={
                                  !!currentFilters.find(
                                    (cf) =>
                                      cf.optionValue === option.value &&
                                      cf.optionLabel === option.label &&
                                      cf.filterName === filter.name,
                                  )
                                }
                                type="checkbox"
                                className="h-4 w-4 border-gray-300 rounded text-indigo-600 focus:ring-indigo-500"
                                onChange={({ target: { checked } }) => {
                                  setCurrentFilters({
                                    filterName: filter.name,
                                    optionLabel: option.label,
                                    optionValue: checked ? option.value : null,
                                  });
                                }}
                              />
                              <label
                                htmlFor={`filter-${filter.name}-${option.value}`}
                                className="ml-3 pr-6 text-sm font-medium text-gray-900 whitespace-nowrap"
                              >
                                {option.label}
                              </label>
                            </div>
                          ))}
                        </form>
                      </Popover.Panel>
                    </Transition>
                  </Popover>
                );
              })}
            </Popover.Group>

            <TeamExportCSVExport
              sort={sortValue}
              searchTerm={searchTerm}
              selectedFilters={currentFilters}
            />
          </div>
        </section>
      </div>
    </div>
  );
}
