/* eslint-disable @typescript-eslint/no-explicit-any */
import { FieldError, Merge, UseFormRegister } from 'react-hook-form';
import { crazyColspanFix } from './TextInput';

export function NumberInput({
  cols,
  register,
  fieldName,
  label,
  prompt,
  error,
  defaultValue,
  inputProps,
}: {
  cols: number;
  register: UseFormRegister<any>;
  fieldName: string;
  label: string;
  prompt?: string;
  error?: Merge<FieldError, any>;
  defaultValue?: number;
  inputProps?: any;
}) {
  return (
    <div className={crazyColspanFix(cols)}>
      <label
        htmlFor={fieldName}
        className="block text-sm font-medium text-gray-700"
      >
        {label}
      </label>
      {prompt ? <p className="mt-1 text-sm text-gray-500">{prompt}</p> : null}
      <div className="mt-1">
        <input
          id={fieldName}
          type="number"
          defaultValue={defaultValue}
          {...register(fieldName)}
          {...inputProps}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
        />
        {error ? (
          <p className="mt-2 text-sm text-red-600" id={`${fieldName}-error`}>
            {error.message}
          </p>
        ) : null}
      </div>
    </div>
  );
}
