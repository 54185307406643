/* eslint-disable no-nested-ternary */
import { Link, useLocation } from 'react-router-dom';

interface Step {
  name: string;
  to: string;
}

interface StepsProps {
  steps: Step[];
}

export function Stepper({ steps }: StepsProps) {
  const { pathname } = useLocation();
  const currentStep = steps.find((step) => pathname.includes(step.to));
  if (!currentStep) {
    // eslint-disable-next-line no-console
    console.error('no current step');
    return null;
  }

  const currentStepIndex =
    steps.findIndex((step) => step.to === currentStep.to) || 0;
  return (
    <nav
      className="flex items-center justify-center pb-2"
      aria-label="Progress"
    >
      <p className="text-sm font-medium">{currentStep.name}</p>
      <ol className="py-8 mx-8 flex items-center space-x-5">
        {steps.map((step) => (
          <li key={step.name}>
            {steps.findIndex((s) => step.to === s.to) < currentStepIndex ? (
              <Link
                to={step.to}
                className="block w-2.5 h-2.5 bg-indigo-600 rounded-full hover:bg-indigo-900"
              >
                <span className="sr-only">{step.name}</span>
              </Link>
            ) : currentStep.to === step.to ? (
              <Link
                to={step.to}
                className="relative flex items-center justify-center"
                aria-current="step"
              >
                <span className="absolute w-5 h-5 p-px flex" aria-hidden="true">
                  <span className="w-full h-full rounded-full bg-indigo-200" />
                </span>
                <span
                  className="relative block w-2.5 h-2.5 bg-indigo-600 rounded-full"
                  aria-hidden="true"
                />
                <span className="sr-only">{step.name}</span>
              </Link>
            ) : (
              <div className="block w-2.5 h-2.5 bg-gray-200 rounded-full hover:bg-gray-400">
                <span className="sr-only">{step.name}</span>
              </div>
            )}
          </li>
        ))}
      </ol>
      <p className="text-sm font-medium">
        Step {steps.findIndex((step) => currentStep.to === step.to) + 1} of{' '}
        {steps.length}
      </p>
    </nav>
  );
}
