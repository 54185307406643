export const downloadFromURL = (url: string) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = url;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);

  return URL.revokeObjectURL(url);
};
