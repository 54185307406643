import { Outlet } from 'react-router-dom';
import Stepper from '../../components/Stepper';

const steps = [
  {
    name: 'Info',
    to: 'info',
  },
  {
    name: 'Payment',
    to: 'payment',
  },
];

export function RecruitForm() {
  return (
    <div className="px-4 sm:px-6 max-w-prose lg:mx-auto lg:px-8 my-20">
      <Stepper steps={steps} />
      <Outlet />
    </div>
  );
}
