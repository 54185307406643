import { InformationCircleIcon } from '@heroicons/react/20/solid';
import { classNames } from 'listo/src/utils/strings';
import TableLoader from '../../components/TableLoader';
import { trpc } from '../../lib/trpc';

function InvoiceEventIcon() {
  return (
    <div>
      <span
        className={classNames(
          // 'bg-gray-400'
          'bg-indigo-500',
          'h-8 w-8 rounded-full flex items-center justify-center ring-8 ring-white',
        )}
      >
        <InformationCircleIcon
          className="h-5 w-5 text-white"
          aria-hidden="true"
        />
      </span>
    </div>
  );
}

export function InvoiceEvents({ id }: { id: string }) {
  const { data, isLoading, error } = trpc.u.invoices.events.useQuery({
    invoiceId: id,
  });

  if (isLoading) return <TableLoader />;
  if (error) return <div>Error</div>;

  if (data.length === 0) return null;
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3">
      <div className="lg:col-start-1 lg:col-span-2 px-4 sm:px-6 lg:px-8 mt-6">
        <div className="bg-white shadow sm:rounded-lg px-4 sm:px-6 lg:px-8  py-8">
          <div className="flow-root">
            <ul className="-mb-8">
              {data.map((event, eventIdx) => (
                <li key={event.id}>
                  <div className="relative pb-8">
                    {eventIdx !== data.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <InvoiceEventIcon />
                      <div className="flex min-w-0 flex-1 justify-between space-x-4 pt-1.5">
                        <div>
                          <p className="text-sm text-gray-500">
                            {
                              (event.unstructuredData as Record<string, string>)
                                .displayTitle
                            }{' '}
                            {/* <Link
                              to={event.href}
                              className="font-medium text-gray-900"
                            >
                              {event.target}
                            </Link> */}
                          </p>
                        </div>
                        <div className="whitespace-nowrap text-right text-sm text-gray-500">
                          <time dateTime={event.timestamp.toString()}>
                            {event.timestamp.toString()}
                          </time>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
