import { Button } from 'ui/src/components/Button/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import { RouterOutput, trpc } from '../../lib/trpc';
import { StepLink } from '../../workerPages/WorkerDashboard/StepLink';

export function OnboardingStatus({
  contract,
}: {
  contract: RouterOutput['u']['contracts']['contract'];
}) {
  const setNotification = useNotification((state) => state.setNotification);

  const { mutate: sendEmail } =
    trpc.u.bankAccounts.emailBankAccountReminder.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Email reminder sent',
        });
      },
    });

  const hasBankAccountWithNumber = !!contract.workerProfile.bankAccounts.filter(
    (f) => !!f.number,
  ).length;

  return (
    <div className="bg-white shadow sm:rounded-lg ">
      <div className="bg-white shadow sm:rounded-lg">
        <div className="sm:px-6 px-4 py-5">
          <h2
            id="timeline-title"
            className="text-lg font-medium text-gray-900 leading-6"
          >
            Onboarding Status
          </h2>
        </div>

        <div className="border-t border-gray-200">
          <div className="sm:px-6 px-4 py-5">
            <div className="mt-1 max-w-2xl text-sm text-gray-50 inline-block align-middle justify-center">
              <div className="group relative flex items-start">
                <StepLink
                  heading="Bank Details"
                  subheading={
                    hasBankAccountWithNumber
                      ? 'Added bank details'
                      : 'Missing bank info'
                  }
                  complete={!!hasBankAccountWithNumber}
                />
              </div>
            </div>
            {!hasBankAccountWithNumber ? (
              <div className="mt-2 pt-2 flex justify-end">
                <Button
                  text="Send Reminder"
                  variant="secondary"
                  onClick={() =>
                    sendEmail({
                      email: contract.worker.credential.email,
                    })
                  }
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
