import {
  ExpenseReimbursementStatusEnum,
  ExpenseReimbursementStatus,
} from 'listo/src/zodObjects/expenseReimbursement';

import { Spinner } from 'ui/src/components/Spinner';
import { RouterOutput } from '../../lib/trpc';
import { ReimbursementListItem } from './ReimbursementListItem';

function Empty() {
  return (
    <div className="text-center w-full py-6">
      <h3 className="text-sm font-medium text-gray-900">- Empty -</h3>
    </div>
  );
}

export function ReimbursementList({
  isWorker,
  data,
  isLoading,
  refetch,
}: {
  isWorker?: boolean;
  data: RouterOutput['u']['expenseReimbursement']['listAll'] | undefined;
  isLoading: boolean;
  refetch: () => Promise<unknown>;
}) {
  if (isLoading) {
    return <Spinner />;
  }

  if (!data || data.length === 0) {
    return <Empty />;
  }

  return (
    <ol className="divide-y divide-gray-100 w-full text-sm leading-6">
      {data.map((reimbursementListItem) => {
        const status =
          reimbursementListItem.status as ExpenseReimbursementStatus;

        if (status === ExpenseReimbursementStatusEnum.Values.DELETED) {
          return null;
        }

        return (
          <ReimbursementListItem
            key={reimbursementListItem.id}
            data={reimbursementListItem}
            status={status}
            refetch={refetch}
            isWorker={!!isWorker}
          />
        );
      })}
    </ol>
  );
}
