import { RouterOutput } from '../../lib/trpc';

function ContractRequired() {
  return <h1>Contract is required</h1>;
}
export function PendingContractRelease({
  contract,
}: {
  contract: RouterOutput['u']['workers']['worker']['contracts'][0] | undefined;
}) {
  if (!contract) return <ContractRequired />;

  return (
    <section>
      <div className="bg-white shadow sm:rounded-lg">
        <div className="sm:px-6 px-4 py-5">
          <h2
            id="timeline-title"
            className="text-lg font-medium text-gray-900 leading-6"
          >
            Pending Contract Release
          </h2>
          <p className="mt-1 max-w-2xl text-sm text-gray-500">
            Listo is preparing a compliant contract. You will be notified when
            the contract is released for signature.
          </p>
        </div>
      </div>
    </section>
  );
}
