import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { SubmitHandler } from 'react-hook-form';
import {
  ExpenseReimbursementStatusEnum,
  ExpenseReimbursementStatus,
} from 'listo/src/zodObjects/expenseReimbursement';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import {
  ExclamationTriangleIcon,
  EyeIcon,
  CloudArrowDownIcon,
} from '@heroicons/react/20/solid';
import { Button } from 'ui/src/components/Button';
import { centsToDollars } from 'listo/src/utils/currency';
import {
  ApproveModal,
  IApprovalModalForm,
} from '../../components/ApproveModal';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';
import Avatar from '../../components/Avatar';
import { statusChipConfig } from '../../components/ReimbursementList';
import Modal from '../../components/Modal';
import { Spinner } from '../../components/Spinner/Spinner';

enum EApprovalModal {
  APPROVE = 'approve',
  DECLINE = 'decline',
}

export function RequestReimbursement() {
  const { claims } = useAuth();
  const navigate = useNavigate();

  const { reimbursementRequestId } = useParams();
  const setNotification = useNotification((state) => state.setNotification);
  const [approveModalType, setApproveModalType] = useState('');
  const [warningMessage, setWarningMessage] = useState('');

  const [viewDocument, setViewDocument] = useState(false);
  const [loading, setLoading] = useState('');
  const [documentFile, setDocumentFile] = useState({
    name: '',
    type: '',
    objectUrl: '',
    downloadUrl: '',
  });

  const today = DateTime.now();

  if (!reimbursementRequestId)
    throw new Error('Missing reimbursementRequestId');
  const {
    data,
    refetch,
    isLoading: isDataLoading,
  } = trpc.u.expenseReimbursement.list.useQuery(reimbursementRequestId);

  useEffect(() => {
    if (documentFile.downloadUrl) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      initiateDocumentDownload();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [documentFile.downloadUrl]);

  useEffect(() => {
    if (!isDataLoading) {
      const hasPermissionToView =
        data && claims?.activeClientId === data.clientId;

      if (!hasPermissionToView) {
        navigate('/404');
      } else {
        const status = data.status as ExpenseReimbursementStatus;

        if (status === ExpenseReimbursementStatusEnum.Values.DELETED) {
          setWarningMessage(
            'This request for reimbursement is already deleted.',
          );
        } else if (status === ExpenseReimbursementStatusEnum.Values.APPROVED) {
          setWarningMessage(
            'This request for reimbursement is already approved.',
          );
        } else if (status === ExpenseReimbursementStatusEnum.Values.DECLINED) {
          setWarningMessage(
            'This request for reimbursement is already rejected.',
          );
        }
      }
    }
  }, [data, claims, navigate, today, isDataLoading]);

  const { mutateAsync: updateStatus } =
    trpc.u.expenseReimbursement.updateStatus.useMutation({
      onSuccess: () => {
        setNotification({
          type: 'success',
          title: 'Success',
          message:
            approveModalType === EApprovalModal.APPROVE
              ? 'Approved successfully.'
              : 'Declined successfully.',
        });
        navigate('/reimbursements');
      },
      onError: () =>
        setNotification({
          type: 'error',
          title: 'Error',
          message: 'Your request has failed. Please contact us.',
        }),
    });

  const hasPermissionToView = data && claims?.activeClientId === data.clientId;

  if (!hasPermissionToView) return null;

  const requestedDate = DateTime.fromJSDate(data.createdAt);
  const expenseDate = DateTime.fromJSDate(data.expenseDate);
  const status = data.status as ExpenseReimbursementStatus;
  const canApproveOrDecline =
    status !== ExpenseReimbursementStatusEnum.Values.DELETED;
  const documentUploadUrl = data.documentUpload?.url ?? '';
  const amountInDollars = centsToDollars(data.amountInCents).toLocaleString(
    'en-US',
    {
      style: 'currency',
      currency: 'USD',
    },
  );

  const onSubmit: SubmitHandler<IApprovalModalForm> = async (values) => {
    try {
      await updateStatus({
        id: reimbursementRequestId,
        status:
          approveModalType === EApprovalModal.APPROVE
            ? ExpenseReimbursementStatusEnum.Values.APPROVED
            : ExpenseReimbursementStatusEnum.Values.DECLINED,
        clientMessage: values.message,
      });
      await refetch();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setApproveModalType('');
    }
  };

  const initiateDocumentDownload = () => {
    if (documentFile.downloadUrl) {
      const el = document.createElement('a');
      el.href = documentFile.downloadUrl;
      el.download = documentFile.name;
      el.style.display = 'none';
      document.body.appendChild(el);
      el.click();
      document.body.removeChild(el);
      URL.revokeObjectURL(documentFile.downloadUrl);
      setDocumentFile({ ...documentFile, downloadUrl: '' });
    }
  };

  const handleDocumentDownload = async () => {
    try {
      setLoading('download');
      const response = await fetch(documentUploadUrl);
      const blob = await response.blob();

      let name = '';
      if (data.documentUpload) {
        name = data.documentUpload.fileName;
      }
      const downloadUrl = URL.createObjectURL(blob);

      setDocumentFile({ ...documentFile, name, downloadUrl });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setLoading('');
    }
  };

  const handleDocumentView = async () => {
    try {
      setViewDocument(true);
      setLoading('view');

      const response = await fetch(documentUploadUrl);
      const blob = await response.blob();

      const type = blob.type.includes('image') ? 'image' : 'pdf';
      const objectUrl = URL.createObjectURL(blob);

      setDocumentFile({ ...documentFile, type, objectUrl });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error('e ', e);
    } finally {
      setLoading('');
    }
  };

  if (isDataLoading) {
    return null;
  }

  return (
    <div className="bg-gray-100 min-h-screen pb-8">
      <div className="space-y-8 lg:max-w-6xl lg:mx-auto px-5">
        {!!warningMessage && (
          <div className="border-l-4 border-yellow-400 bg-yellow-50 p-6">
            <div className="flex items-center">
              <div className="flex-shrink-0">
                <ExclamationTriangleIcon
                  className="h-8 w-8 text-yellow-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3">
                <p className="text-yellow-700">{warningMessage}</p>
              </div>
            </div>
          </div>
        )}

        <div className="overflow-hidden space-y-8 px-8 py-12 bg-white shadow sm:rounded-lg">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-6">
              <Avatar
                name={data.workerProfile.fullName ?? ''}
                image={data.workerProfile.workerProfileUpload?.url ?? ''}
                className="w-[55px] h-[55px] bg-gray-200 text-3xl"
              />

              <span className="text-2xl font-medium text-gray-800 capitalize">
                {data.workerProfile.fullName}
              </span>
            </div>

            <div className="">
              <span
                className={`flex items-center gap-2 px-4 py-2 ${statusChipConfig[status].color} ${statusChipConfig[status].bgColor} rounded-full text-white text-sm capitalize`}
              >
                {statusChipConfig[status].icon} {status.toLowerCase()}
              </span>
            </div>
          </div>

          <div className="flex flex-col gap-1">
            <span className="font-medium text-gray-800">Requested on</span>

            <span className="font-light text-gray-500 break-word">
              {requestedDate.toFormat('y LLL dd')}
            </span>
          </div>

          <div className="flex flex-col gap-1">
            <span className="font-medium text-gray-800">Message</span>

            <span className="font-light text-gray-500 break-word">
              {data.description}
            </span>
          </div>
        </div>

        <div className="overflow-hidden px-8 py-12 bg-white shadow sm:rounded-lg">
          <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
            <div className="sm:col-span-1">
              <dt className="font-medium text-gray-800">Name</dt>

              <dd className="mt-1  text-gray-500 capitalize">
                {data.workerProfile.fullName}
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="font-medium text-gray-800">Expense Date</dt>
              <dd className="mt-1  text-gray-500">
                {expenseDate.toFormat('y LLL dd')}
              </dd>
            </div>

            <div className="sm:col-span-1">
              <dt className="font-medium text-gray-800">Amount</dt>
              <dd className="mt-1  text-gray-500">{amountInDollars}</dd>
            </div>

            <div className="sm:col-span-2 flex gap-3">
              <Button
                type="button"
                variant="secondary"
                text="View"
                icon={<EyeIcon className="h-4 w-4 mr-2 text-gray-600" />}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleDocumentView}
              />

              <Button
                type="button"
                text="Download"
                variant="secondary"
                icon={
                  <CloudArrowDownIcon className="h-4 w-4 mr-2 text-gray-600" />
                }
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={handleDocumentDownload}
                loading={loading === 'download'}
              />
            </div>
          </dl>

          <Modal isOpen={viewDocument} setIsOpen={() => setViewDocument(false)}>
            <div className="x-4 pt-5 pb-4 sm:my-12 sm:max-w-4xl sm:p-6 sm:w-[60vw] h-[80vh] w-[90vw]">
              {loading === 'view' ? (
                <div className="flex justify-center items-center h-full">
                  <Spinner />
                </div>
              ) : (
                <div className="h-full w-full mb-4">
                  {documentFile.type === 'image' ? (
                    <img
                      style={{ height: '100%', width: '100%' }}
                      title="image viewer"
                      alt="img"
                      src={documentFile.objectUrl}
                    />
                  ) : (
                    <iframe
                      style={{ height: '100%', width: '100%' }}
                      title="pdf viewer"
                      src={`${documentFile.objectUrl}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0`}
                    />
                  )}
                </div>
              )}

              <div className="flex gap-4 justify-end">
                <Button
                  type="button"
                  variant="secondary"
                  text="Cancel"
                  onClick={() => setViewDocument(false)}
                />

                <Button
                  type="button"
                  text="Download"
                  icon={<CloudArrowDownIcon className="h-4 w-4 text-gray-50" />}
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={handleDocumentDownload}
                  loading={loading === 'download'}
                />
              </div>
            </div>
          </Modal>

          <div className="mt-10 flex gap-4 justify-end">
            {canApproveOrDecline && (
              <>
                <Button
                  type="button"
                  variant="secondary"
                  disabled={
                    data.status ===
                    ExpenseReimbursementStatusEnum.Values.DECLINED
                  }
                  onClick={() => setApproveModalType(EApprovalModal.DECLINE)}
                  text="Decline"
                />

                <Button
                  type="button"
                  disabled={
                    data.status ===
                    ExpenseReimbursementStatusEnum.Values.APPROVED
                  }
                  onClick={() => setApproveModalType(EApprovalModal.APPROVE)}
                  text="Approve"
                />
              </>
            )}
          </div>

          <ApproveModal
            open={!!approveModalType}
            onClose={() => setApproveModalType('')}
            title={
              approveModalType === EApprovalModal.APPROVE
                ? 'Approve'
                : 'Decline'
            }
            onSubmit={onSubmit}
          />
        </div>
      </div>
    </div>
  );
}
