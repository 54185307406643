/* This example requires Tailwind CSS v2.0+ */
import { useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { CheckCircleIcon } from '@heroicons/react/20/solid';
import { classNames } from 'listo/src/utils/strings';
import { useAuth } from '../../hooks/useAuth';
import Button from '../Button';
import { SetStep } from './types';

const whoSignsOptions = [
  {
    id: 1,
    title: 'Myself',
    description: 'I am authorized to sign.',
  },
  {
    id: 2,
    title: 'Someone Else',
    description: 'Someone else will sign.',
  },
];

export function WhoSignsClient({
  onCancel,
  setStep,
}: {
  onCancel: (isOpen: boolean) => void;
  setStep: SetStep;
}) {
  const [whoSigns, setWhoSigns] = useState(whoSignsOptions[0]);
  const { claims } = useAuth();

  return (
    <div className="flex items-center justify-center bg-gray-100">
      <div className="px-4 py-5 bg-white shadow rounded-lg overflow-hidden sm:p-6">
        <RadioGroup value={whoSigns} onChange={setWhoSigns}>
          <RadioGroup.Label className="text-base font-medium text-gray-900">
            {`Who will sign for ${claims?.clientName ?? 'your company'}?`}
          </RadioGroup.Label>

          <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
            {whoSignsOptions.map((o) => (
              <RadioGroup.Option
                key={o.id}
                value={o}
                className={({ checked, active }) =>
                  classNames(
                    checked ? 'border-transparent' : 'border-gray-300',
                    active ? 'border-indigo-500 ring-2 ring-indigo-500' : '',
                    'relative bg-white border rounded-lg shadow-sm p-4 flex cursor-pointer focus:outline-none',
                  )
                }
              >
                {({ checked, active }) => (
                  <>
                    <span className="flex-1 flex">
                      <span className="flex flex-col">
                        <RadioGroup.Label
                          as="span"
                          className="block text-sm font-medium text-gray-900"
                        >
                          {o.title}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className="mt-1 flex items-center text-sm text-gray-500"
                        >
                          {o.description}
                        </RadioGroup.Description>
                      </span>
                    </span>
                    <CheckCircleIcon
                      className={classNames(
                        !checked ? 'invisible' : '',
                        'h-5 w-5 text-indigo-600',
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        active ? 'border' : 'border-2',
                        checked ? 'border-indigo-500' : 'border-transparent',
                        'absolute -inset-px rounded-lg pointer-events-none',
                      )}
                      aria-hidden="true"
                    />
                  </>
                )}
              </RadioGroup.Option>
            ))}
          </div>
        </RadioGroup>
        <div className="mt-6 float-right">
          <Button
            variant="secondary"
            text="Cancel"
            onClick={() => onCancel(false)}
          />
          <Button
            text="Next"
            className="ml-3"
            onClick={() => {
              if (whoSigns?.title === 'Myself') setStep('document');
              if (whoSigns?.title === 'Someone Else') setStep('gatherSigner');
            }}
          />
        </div>
      </div>
    </div>
  );
}
