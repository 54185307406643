import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { API_URL } from '../../lib/constants';
import { Spinner } from '../Loader/Loader';
import { SignatureSection } from './SignatureSection';

export function Document({
  customContractUrl,
  contractId,
  onCancel,
  signer,
  onSuccess,
}: {
  customContractUrl?: string;
  contractId: string;
  onCancel: () => void;
  signer: 'client' | 'worker';
  onSuccess: () => void;
}) {
  const jwt = useAuth((state) => state.jwt);
  const [isLoading, setIsLoading] = useState(true);
  const [searchParams] = useSearchParams();

  const source = customContractUrl
    ? `${customContractUrl}#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0`
    : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `${API_URL}/legal/document/${contractId}/?token=${
        jwt ?? searchParams.get('token') ?? ''
      }#toolbar=0&navpanes=0&scrollbar=0&statusbar=0&messages=0`;

  return (
    <div className="px-4 pt-5 pb-4 sm:my-8 sm:max-w-4xl sm:p-6 sm:w-[60vw] h-[80vh] w-[90vw]">
      <div
        className="overflow-auto"
        style={{ height: 'calc(100% - 184px)', borderRadius: 4 }}
      >
        {isLoading ? (
          <div className="flex justify-center items-center h-full">
            <Spinner />
          </div>
        ) : null}
        {jwt || searchParams.get('token') ? (
          <iframe
            style={{ height: '100%', width: '100%' }}
            title="contract"
            src={source}
            onLoad={() => setIsLoading(false)}
          />
        ) : null}
      </div>
      <SignatureSection
        signer={signer}
        contractId={contractId}
        onSuccess={onSuccess}
        onCancel={onCancel}
      />
    </div>
  );
}
