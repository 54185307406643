import { trpc } from '../../lib/trpc';
import { PayPeriodCard } from './PayPeriodCard';

export function PayPeriod({ contractId }: { contractId: string }) {
  const {
    data: payPeriod,
    error,
    isLoading,
  } = trpc.w.payPeriods.list.useQuery({ contractId });

  if (isLoading) {
    return null;
  }
  if (error) return <div>{error.message}</div>;

  if (!payPeriod) return null;
  return (
    <div className="sm:col-span-2">
      <PayPeriodCard
        key={payPeriod.id}
        payPeriod={payPeriod}
        contractId={contractId}
      />
    </div>
  );
}
