import TopBar from '../../components/TopBar';
import { Reimbursement } from './Reimbursement';

export function ReimbursementRequests() {
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Expense Reimbursement" />
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 mt-8">
        <Reimbursement />
      </div>
    </main>
  );
}
