import { titleCase } from 'listo/src/utils/strings';
import Stripe from 'stripe';
import { Button } from 'ui/src/components/Button';
import { RouterOutput, trpc } from '../../lib/trpc';

// function description(invoice: Invoice) {
//   if (invoice.status === 'GATHERING' || invoice.status === 'GENERATED') {
//     return 'Your invoice is being prepared. Hourly workers must submit timecards before Aug 25th, 2022. You can send an email reminder to team members who haven’t submitted their timecards using the table below.';
//   }
//   if (invoice.status === 'APPROVAL') {
//     return 'Your invoice is ready for approval. Please review and confirm the invoice timecards so we can send our your payments.';
//   }
//   if (invoice.status === 'INVOICED') {
//     return 'Your invoice is ready for payment. Make sure your payment method is up to date and we will withdraw funds from your account within 3 business days.';
//   }
//   if (invoice.status === 'COLLECTING') {
//     return 'We are confirming receipt of your payment. We will send you an email once your payment has been confirmed.';
//   }
//   if (invoice.status === 'DISTRIBUTING') {
//     return 'We are distributing your payment to your team members. We will send you an email once your payment has been distributed.';
//   }
//   return `Your invoice status is ${capitalize(invoice.status)}`;
// }

function stripeDescription(stripeInvoice: Stripe.Invoice) {
  if (stripeInvoice.status === 'draft') {
    return 'Your invoice is being prepared. Hourly workers must submit timecards. You can send an email reminder to team members who havent submitted their timecards.';
  }
  if (stripeInvoice.status === 'open') {
    return 'Your invoice is ready for payment. View and pay the invoice using the button below. We will withdraw funds from your account within 2 business days.';
  }
  if (stripeInvoice.status === 'paid') {
    return 'We are confirming receipt of your payment. We will send you an email once your payment has been confirmed.';
  }
  if (stripeInvoice.status === 'uncollectible') {
    return 'Your invoice was marked as uncollectible. Please update your payment method and try again.';
  }
  if (stripeInvoice.status === 'void') {
    return 'This invoice has been voided. Please contact support if you have any questions.';
  }
  return `Your invoice status is ${titleCase(stripeInvoice.status)}`;
}

// function ApprovalButton({ id }: { id: string }) {
//   const utils = trpc.useContext();
//   const { mutate, isLoading } = trpc.useMutation('u.invoices.approve', {
//     onSuccess() {
//       utils.invalidateQueries(['u.invoices.invoice', id]);
//     },
//   });

//   return (
//     <div className="justify-stretch mt-6 flex flex-col">
//       <Button
//         text="Approve"
//         loading={isLoading}
//         onClick={() => {
//           mutate({ invoiceId: id });
//         }}
//       />
//     </div>
//   );
// }

export function InvoiceActionPanel({
  invoice,
}: {
  invoice: RouterOutput['u']['invoices']['invoice'];
}) {
  const { data: stripeInvoice, isLoading } =
    trpc.u.invoices.stripeInvoice.useQuery({ invoiceId: invoice.id });

  if (isLoading) return null;
  if (!stripeInvoice) return null;

  const url = invoice.stripeHostedUrl ?? stripeInvoice.hosted_invoice_url;

  return (
    <section
      aria-labelledby="timeline-title"
      className="lg:col-span-1 lg:col-start-3"
    >
      <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:px-6">
        <h2 id="timeline-title" className="text-lg font-medium text-gray-900">
          {`Status: ${titleCase(stripeInvoice.status)}`}
        </h2>

        <p className="mt-2 text-sm text-gray-700 max-w-lg">
          {stripeDescription(stripeInvoice)}
        </p>
        {/* {invoice.status === 'APPROVAL' ? (
          <ApprovalButton id={invoice.id} />
        ) : null} */}
        {url ? (
          <div className="justify-stretch mt-6 flex flex-col">
            <Button
              onClick={() => {
                if (!url) return;
                window.open(url, '_blank');
              }}
              text="View Invoice"
            />
          </div>
        ) : null}
      </div>
    </section>
  );
}
