import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

export const options = {
  responsive: true,
};

const labels = Array.from(Array(31).keys()).map((key) => key + 1);

export const rawData = {
  labels,
  datasets: [
    {
      label: 'January',
      data: labels.map(() => Math.random() * 100),
      borderColor: 'rgb(255, 99, 132)',
      backgroundColor: 'rgba(255, 99, 132, 0.5)',
    },
    {
      label: 'December',
      data: labels.map(() => Math.random() * 100),
      borderColor: 'rgb(53, 162, 235)',
      backgroundColor: 'rgba(53, 162, 235, 0.5)',
    },
  ],
};

export default function LineChart({
  lastMonth,
  lastMonthLabel,
  currentMonth,
  currentMonthLabel,
}: {
  lastMonth: number[];
  lastMonthLabel: string;
  currentMonth: number[];
  currentMonthLabel: string;
}) {
  return (
    <Line
      options={options}
      data={{
        labels,
        datasets: [
          {
            label: lastMonthLabel,
            data: lastMonth,
            borderColor: '#4D46E3',
            backgroundColor: '#4D46E3',
          },
          {
            label: currentMonthLabel,
            data: currentMonth,
            borderColor: '#468DE3',
            backgroundColor: '#468DE3',
          },
        ],
      }}
    />
  );
}
