import TopBar from '../../components/TopBar';
import { RequestReimbursement } from './RequestReimbursement';

export function RequestReimbursementInfo() {
  return (
    <main className="bg-gray-100 flex-1 pb-8 min-h-screen">
      <TopBar title="Request Reimbursement" />

      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-6 mt-8">
        <RequestReimbursement />
      </div>
    </main>
  );
}
