import { Fragment, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import {
  DocumentArrowUpIcon,
  PaperClipIcon,
} from '@heroicons/react/24/outline';
import { classNames } from 'listo/src/utils/strings';
import { Failed, Success } from '../../components/Badge/Badge';
import UploadBox from '../../components/UploadBox';
import { trpc, RouterOutput } from '../../lib/trpc';

function UploadDocComponent({
  setUpload,
  upload,
  clientId,
  uploadDocument,
}: {
  setUpload: (value: string | null) => void;
  uploadDocument: (value: string) => Promise<void>;
  upload: string | null;
  clientId: string;
}) {
  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={!!upload} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={() => setUpload(null)}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full sm:p-6">
                <div>
                  <div className="mt-3 text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      Upload Document
                    </Dialog.Title>
                    <div className="mt-2">
                      <UploadBox
                        clientId={clientId}
                        allowedTypes={['.pdf', '.jpg', '.png']}
                        readOnly={false}
                        listoAssetType="documents"
                        onFileUploaded={(f) => {
                          uploadDocument(f.id).catch(() => {});
                        }}
                      />
                    </div>
                    <div className="mt-5 sm:mt-6">
                      <button
                        type="button"
                        className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                        onClick={() => setUpload(null)}
                        ref={cancelButtonRef}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

function DocumentElem({
  document,
  clientId,
}: {
  document: RouterOutput['u']['contracts']['contract']['documents'];
  clientId: string;
}) {
  const { id } = useParams();
  const [upload, setUpload] = useState<null | string>(null);
  const utils = trpc.useContext();
  const { mutateAsync: saveDocumentUploads } =
    trpc.w.documents.create.useMutation();

  const uploadFile = (
    doc: RouterOutput['u']['contracts']['contract']['documents'][0],
  ) => {
    if (doc.uploadId) return;
    setUpload(doc.id);
  };

  const uploadDocument = async (uploadId: string) => {
    if (!upload) return;
    await saveDocumentUploads({
      uploadId,
      documentId: upload,
    });
    setUpload(null);
    utils.w.contracts.contract.invalidate(id).catch(() => {});
  };

  return (
    <>
      <div className="p-3">
        <div className="flow-root mt-6">
          <div className="sm:flex sm:items-center mb-10">
            <div className="sm:flex-auto">
              <p className="text-sm text-gray-700 px-4">
                A list of documents required in this contract.
              </p>
            </div>
          </div>
          <ul className="my-5 divide-y divide-gray-200 border-t border-gray-200">
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {document?.length === 0 && (
              <li className="py-4">
                <div className="flex items-center space-x-4 ">
                  <p className="text-sm font-medium text-gray-900 truncate px-4">
                    No Documents Requested
                  </p>
                </div>
              </li>
            )}
            {/* eslint-disable-next-line @typescript-eslint/no-unnecessary-condition */}
            {document?.map((doc) => (
              <li key={doc.id} className="py-4">
                <div className="flex items-center space-x-4">
                  <div>
                    <PaperClipIcon className="h-5 w-5 rounded-full" />
                  </div>
                  <div className="flex-1 min-w-0">
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {doc.documentType}
                    </p>
                  </div>
                  <div>
                    {doc.uploadId ? (
                      <Success text="Uploaded" />
                    ) : (
                      <Failed text="Requested" />
                    )}
                  </div>
                  <div>
                    <button
                      type="button"
                      title="Upload Document"
                      disabled={!!doc.uploadId}
                      className={classNames(
                        doc.uploadId ? 'cursor-not-allowed' : '',
                      )}
                      onClick={() => uploadFile(doc)}
                    >
                      <DocumentArrowUpIcon className="w-5 h-5" />
                    </button>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {upload && (
        <UploadDocComponent
          setUpload={setUpload}
          upload={upload}
          clientId={clientId}
          uploadDocument={uploadDocument}
        />
      )}
    </>
  );
}

export default DocumentElem;
