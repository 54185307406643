/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { countryNameByIsoCode } from 'listo/src/countries';
import { classNames } from 'listo/src/utils/strings';
import { useEffect } from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import Avatar from '../../components/Avatar';
import EmptyState from '../../components/EmptyState';
import TableLoader from '../../components/TableLoader';
import { useDebounce } from '../../hooks/useDebounce';
import useIsFirstRender from '../../hooks/useIsFirstRender';
import { RouterOutput, trpc } from '../../lib/trpc';
import TableHeader, { Filters, SelectedFilter } from './TeamFilters';
import { useTeamFiltersStore } from './useFilterPersist';

function variables({
  page,
  searchTerm,
  sort,
  selectedFilters,
  limit,
}: {
  page: number;
  limit: number;
  searchTerm: string | undefined;
  sort: string | undefined;
  selectedFilters: SelectedFilter[];
}) {
  const countryFilters = selectedFilters
    .filter((filter) => filter.filterName === 'Country' && !!filter.optionValue)
    .map((filter) => filter.optionValue);

  const otherFilters = selectedFilters
    .filter(
      (f) =>
        f.filterName !== 'Country' &&
        f.filterName !== 'Engagement Type' &&
        !!f.optionValue,
    )
    .reduce<Record<string, boolean>>((acc, filter) => {
      if (!filter.optionValue) return acc;

      acc[filter.optionValue] = !!filter.optionValue;
      return acc;
    }, {});
  const engagmentFilters = selectedFilters
    .filter(
      (filter) =>
        filter.filterName === 'Engagement Type' && !!filter.optionValue,
    )
    .map((filter) => filter.optionValue);

  return {
    page,
    limit,
    searchTerm,
    sort,
    filters: {
      isoCountryCode: countryFilters.length
        ? countryFilters.join('|')
        : undefined,
      engagementTypeArray:
        engagmentFilters.length > 0 ? engagmentFilters : null,
      ...otherFilters,
    },
  };
}

function Pagination({
  pagination,
}: {
  pagination?: RouterOutput['u']['contracts']['list']['pagination'];
}) {
  const { setPageNumber, page } = useTeamFiltersStore();

  return (
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6 shadow overflow-hidden sm:rounded-b-md">
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          href="#"
          className={classNames(
            page ? '' : 'disabled',
            `relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`,
          )}
          onClick={() => setPageNumber(page - 1)}
        >
          Previous
        </a>
        <a
          href="#"
          className={classNames(
            pagination?.nextPage ? '' : 'disabled',
            `ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50`,
          )}
          onClick={() => setPageNumber(page + 1)}
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div />
        <div>
          <nav
            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
            aria-label="Pagination"
          >
            <a
              href="#"
              className={classNames(
                page ? '' : 'disabled',
                `relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`,
              )}
              onClick={() => setPageNumber(page - 1)}
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </a>

            <a
              href="#"
              className={classNames(
                pagination?.nextPage ? '' : 'disabled',
                `relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50`,
              )}
              onClick={() => setPageNumber(page + 1)}
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  );
}

function ContractLabel({
  c,
}: {
  c: RouterOutput['u']['contracts']['list']['contracts'][0];
}) {
  let color;

  if (c.status === 'DRAFT') {
    color = 'bg-red-100 text-red-800';
  } else if (c.status === 'PENDING_RELEASE') {
    color = 'bg-orange-100 text-orange-800';
  } else if (c.status === 'AWAITING_SIGNATURES') {
    color = 'bg-yellow-100 text-yellow-800';
  } else if (c.status === 'EXECUTED') {
    color = 'bg-green-100 text-green-800';
  } else if (c.status === 'OFFBOARDING') {
    color = 'bg-gray-100 text-gray-800';
  } else {
    color = 'bg-gray-100 text-gray-800';
  }

  return (
    <span
      className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${color}`}
    >
      {c.engagementType} - {c.status}
    </span>
  );
}

export default function TeamTable({ filters }: { filters: Filters }) {
  const isFirstRender = useIsFirstRender();
  const [searchParams] = useSearchParams();

  const {
    page,
    currentFilters,
    searchTerm,
    sortValue,
    setTheCurrentFilters,
    setSearchTerm,
    setSortValue,
  } = useTeamFiltersStore();

  const debouncedSearchTerm = useDebounce<string | undefined>(searchTerm, 275);

  const {
    data,
    isLoading: isLoadingContracts,
    error,
    refetch,
  } = trpc.u.contracts.list.useQuery(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    variables({
      limit: 25,
      page,
      searchTerm,
      sort: sortValue,
      selectedFilters: currentFilters,
    }),
  );

  useEffect(() => {
    if (isFirstRender) return;

    refetch().catch(() => {});
  }, [page, debouncedSearchTerm, filters, refetch, isFirstRender]);

  const countryParam = searchParams.get('country');

  useEffect(() => {
    if (!countryParam) return;

    setTheCurrentFilters({
      filterName: 'Country',
      optionLabel: 'Country',
      optionValue: countryParam,
    });
  }, [countryParam, setTheCurrentFilters]);

  if (error) return <div>Error</div>;

  return (
    <>
      <TableHeader
        filters={filters}
        setSearchTerm={setSearchTerm}
        searchTerm={searchTerm}
        sort={{
          name: 'Sort',
          options: [
            { label: 'Asc Name (a-z)', value: 'fullName.asc' },
            { label: 'Desc Name (z-a)', value: 'fullName.desc' },
            { label: 'Newest', value: 'createdAt.desc' },
            { label: 'Oldest', value: 'createdAt.asc' },
          ],
        }}
        currentFilters={currentFilters}
        sortValue={sortValue}
        setSortValue={setSortValue}
        setCurrentFilters={setTheCurrentFilters}
      />

      {data !== undefined && data.contracts.length === 0 && page === 0 ? (
        <EmptyState ctaRoute="/create-contract" entity="Contract" />
      ) : null}

      <div className="bg-white shadow overflow-hidden sm:rounded-md sm:rounded-b-none">
        <ul className="divide-y divide-gray-200">
          {!data?.contracts && isLoadingContracts ? <TableLoader /> : null}

          {data
            ? data.contracts.map((contract) => (
                <li key={contract.id}>
                  <Link
                    to={`/contracts/${contract.id}`}
                    className="block hover:bg-gray-50"
                  >
                    <div className="px-4 py-4 flex items-center sm:px-6">
                      <div className="min-w-0 flex-1 sm:flex sm:items-center sm:justify-between">
                        <div className="truncate">
                          <div className="flex text-sm items-center">
                            <div className="mt-4 flex-shrink-0 sm:mt-0">
                              <div className="flex overflow-hidden">
                                <Avatar
                                  image={
                                    contract.workerProfile.workerProfileUpload
                                      ?.url ?? ''
                                  }
                                  name={contract.workerProfile.fullName ?? ''}
                                  className="w-[25px] h-[25px]"
                                />
                              </div>
                            </div>
                            <p className="font-medium text-indigo-600 truncate ml-2 capitalize">
                              {contract.workerProfile.fullName}
                            </p>
                          </div>
                        </div>
                        <div className="mt-4 flex-shrink-0 sm:mt-0 sm:ml-5">
                          <div className="flex items-center">
                            <ContractLabel c={contract} />
                            <div className="ml-2 lg:ml-6">
                              <h3 className="text-sm">
                                {countryNameByIsoCode(contract.isoCountryCode)}
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="ml-5 flex-shrink-0">
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))
            : null}
        </ul>
      </div>

      {data !== undefined &&
      data.contracts.length === 0 &&
      page === 0 ? null : (
        <Pagination pagination={data?.pagination} />
      )}
    </>
  );
}
