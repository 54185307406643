import { useParams } from 'react-router-dom';
import TopBar from '../../components/TopBar';
import { QuoteModal } from './QuoteModal';

export function AcceptQuote() {
  const { quoteId } = useParams();
  if (!quoteId) throw new Error('No quoteId');
  return (
    <div className="min-h-screen bg-gray-100 flex-1 pb-8">
      <TopBar title="Quote" />
      <QuoteModal quoteId={quoteId} />
    </div>
  );
}
