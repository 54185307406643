import { useState } from 'react';
import { DateTime } from 'luxon';
import { orderBy } from 'lodash';
import { CalendarDaysIcon } from '@heroicons/react/24/outline';
import { Button } from 'ui/src/components/Button';
import { Input } from 'ui/src/components/Input';
import Modal from '../../components/Modal';
import { TimeOffRequestList } from '../../components/RequestTimeOffList';
import TopBar from '../../components/TopBar';
import {
  RequestTimeOffFilters,
  defaultFilter,
  dateRangeFilterValues,
  normalizeFiltersForMutation,
} from '../../components/RequestTimeOffFilter';
import { useAuth } from '../../hooks/useAuth';
import { trpc } from '../../lib/trpc';
import { TimeOffRequestForm } from './RequestTimeOffForm';

export function TimeOffRequest() {
  const { claims } = useAuth();

  const [openForm, setOpenForm] = useState(false);
  const [filterBy, setFilterBy] = useState([defaultFilter.value] as string[]);
  const [dateRange, setDateRange] = useState<DateTime[]>([
    DateTime.now().startOf('day'),
  ]);

  const workerProfileId = claims?.workerProfileId ?? '';
  const startDate = dateRange[0]?.toISO() ?? '';
  const endDate = dateRange[1]?.toISO() ?? '';

  const resetFilter = () =>
    setFilterBy((prev: string[]) => {
      const newValue = prev.filter(
        (v: string) => !dateRangeFilterValues.includes(v),
      );
      return newValue;
    });

  const handleSetFilterBy = (values: string[]) => {
    const hasDateFilters = values.some((v: string) =>
      dateRangeFilterValues.includes(v),
    );
    const hasNoDateFilters = values.every(
      (v: string) => !dateRangeFilterValues.includes(v),
    );
    const shouldAddDefaultDateRange = hasNoDateFilters && dateRange.length < 2;

    if (hasDateFilters) {
      setDateRange([]);
    }

    if (shouldAddDefaultDateRange) {
      setDateRange([
        DateTime.now().startOf('month'),
        DateTime.now().endOf('month'),
      ]);
    }
    return setFilterBy(values);
  };

  const { data, isLoading, refetch } = trpc.w.timeOffRequest.listAll.useQuery(
    normalizeFiltersForMutation({
      workerProfileId,
      startDate,
      endDate,
      filterBy,
    }),
  );

  return (
    <div className="flex-1 min-h-screen pb-8 bg-gray-100">
      <TopBar title="Request Time Off" />

      <div className="mt-8 max-w-screen-md lg:mx-auto m-auto">
        <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-end gap-6 flex-wrap mb-4">
            <div className="flex-auto flex items-center gap-6 flex-wrap">
              <RequestTimeOffFilters
                defaultValue={filterBy}
                onSubmit={handleSetFilterBy}
                popupDirection="left"
              />
              <div className="flex items-center gap-2">
                <Input
                  inputProps={{
                    value: dateRange[0]?.toISODate() ?? '',
                    onChange: ({ target: { value } }) => {
                      setDateRange([DateTime.fromISO(value).startOf('day')]);
                      resetFilter();
                    },
                  }}
                  inputType="date"
                />
                -
                <Input
                  inputProps={{
                    min: dateRange[0]?.toISODate() ?? '',
                    value: dateRange[1]?.toISODate() ?? '',
                    onChange: ({ target: { value } }) => {
                      if (dateRange[0]) {
                        setDateRange((prev) => {
                          if (prev[0]) {
                            return [
                              prev[0],
                              DateTime.fromISO(value).endOf('day'),
                            ];
                          }

                          return prev;
                        });
                      }

                      resetFilter();
                    },
                  }}
                  inputType="date"
                />
              </div>
            </div>

            <Button
              onClick={() => setOpenForm(true)}
              type="button"
              text="Create Request"
              className="w-full md:w-auto"
              icon={<CalendarDaysIcon className="h-6 w-6" />}
            />
          </div>

          <div className="bg-white shadow sm:rounded-lg">
            <div className="flex flex-col-reverse md:flex-row gap-6">
              <div className="flex-1">
                <TimeOffRequestList
                  isWorker
                  data={orderBy(data, 'createdAt', 'desc')}
                  refetch={refetch}
                  isLoading={isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={openForm} setIsOpen={setOpenForm}>
        <TimeOffRequestForm
          afterSubmit={() => {
            setOpenForm(false);
            setFilterBy((prev) => {
              const hasDefaultValue = prev.includes(defaultFilter.value);

              if (hasDefaultValue) {
                return prev;
              }

              return [...prev, defaultFilter.value];
            });
            refetch().catch((err) => {
              // eslint-disable-next-line no-console
              console.error(err);
            });
          }}
        />
      </Modal>
    </div>
  );
}
