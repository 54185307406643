import { formatDate } from 'listo/src/utils/dates';
import { classNames } from 'listo/src/utils/strings';
import { useEffect, useState } from 'react';
import {
  Link,
  Outlet,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import Avatar from '../../components/Avatar';
import ContractTile from '../../components/ContractTile';
import ErrorState from '../../components/ErrorState';
import Loader from '../../components/Loader';
import TopBar from '../../components/TopBar';
import { SetFlags, useFeatureFlags } from '../../hooks/useFeatureFlags';
// import { SetFlags, useFeatureFlags } from '../../hooks/useFeatureFlags';

import { trpc, RouterOutput } from '../../lib/trpc';

const tabs = [
  {
    name: 'Profile',
    href: 'profile-details',
    current: true,
  },
  {
    name: 'Documents',
    href: 'documents',
    current: false,
  },
  { name: 'Payments', href: 'payments', current: false },
  { name: 'Contracts', href: 'contracts', current: false },
];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function getTabs(flags: SetFlags | undefined) {
  const baseFlags = tabs;
  return [...baseFlags];
}

function Tabs() {
  const { pathname } = useLocation();
  // const pathnameParts = pathname.split('/');
  const flags = useFeatureFlags((state) => state.flags);

  const navigate = useNavigate();
  return (
    <>
      <div className="lg:hidden">
        <label htmlFor="selected-tab" className="sr-only">
          Select a tab
        </label>
        <select
          id="selected-tab"
          name="selected-tab"
          className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.href === pathname)?.name}
          onChange={(event) => {
            const newTab = tabs.find((tab) => tab.name === event.target.value);
            if (newTab) navigate(newTab.href);
          }}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden lg:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8">
            {getTabs(flags).map((tab) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  pathname.includes(tab.href)
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm',
                )}
              >
                {tab.name}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}

export function Profile() {
  const { id, contractId } = useParams();
  const [selectedContract, setSelectedContract] = useState<
    RouterOutput['u']['workers']['worker']['contracts'][0] | null
  >();

  if (!id) throw new Error('No id');

  const { data, isLoading, error } = trpc.u.workers.worker.useQuery(id);

  useEffect(() => {
    if (data) {
      const { contracts } = data;

      const c =
        contracts.find((co) => co.id === contractId) ??
        contracts.sort(
          (a, b) =>
            +new Date(b.createdAt as unknown as string) -
            +new Date(a.createdAt as unknown as string),
        )[0];

      setSelectedContract(c);
    }
  }, [contractId, data]);

  if (isLoading) return <Loader />;
  if (error)
    return <ErrorState trpcError={error} parentComponentName="Profile.tsx" />;

  return (
    <div className="min-h-screen bg-gray-100">
      <TopBar title="Profile" />
      <main className="py-10">
        {/* Page header */}
        <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
          <div className="flex items-center space-x-5">
            <div className="flex-shrink-0">
              <div className="relative">
                <Avatar
                  className="h-16 w-16 rounded-full !bg-white"
                  name={data.workerProfile.fullName ?? ''}
                  image={data.workerProfile.workerProfileUpload?.url ?? ''}
                />
                {/* <img
                  className="h-16 w-16 rounded-full"
                  src="https://images.unsplash.com/photo-1463453091185-61582044d556?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                  alt=""
                />
                <span
                  className="absolute inset-0 shadow-inner rounded-full"
                  aria-hidden="true"
                /> */}
              </div>
            </div>
            <div>
              <h1 className="text-2xl font-bold text-gray-900 capitalize">
                {data.workerProfile.fullName}
              </h1>
              <p className="text-sm font-medium text-gray-500">
                Team member since{' '}
                <time dateTime="2020-08-25">
                  {formatDate(data.workerProfile.createdAt)}
                </time>
              </p>
            </div>
          </div>
        </div>

        <div className="mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
          <div className="lg:col-start-3 lg:col-span-1">
            <ContractTile contract={selectedContract ?? null} worker={data} />
          </div>

          <div className="space-y-6 lg:col-start-1 lg:col-span-2">
            <section aria-labelledby="applicant-information-title">
              <div className="px-4 sm:px-6 md:px-0">
                <div className="p-4 sm:p-6 bg-white lg:rounded">
                  <Tabs />
                  <Outlet />
                </div>
              </div>
            </section>
          </div>
        </div>
      </main>
    </div>
  );
}
