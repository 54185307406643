import { CheckBadgeIcon } from '@heroicons/react/24/outline';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../components/Button';
import Notification from '../../components/Notification';
import RadixAvatar from '../../components/RadixAvatar';
import { trpc } from '../../lib/trpc';

export function ContratorSignature({
  status,
  contractId,
  workerId,
  invitationLink,
  workerSignature,
  workerEmail,
}: {
  contractId: string;
  workerId: string | null;
  invitationLink: string | null;
  workerSignature: string | null;
  workerEmail?: string | null;
  status?: string;
}) {
  const [emailSent, setEmailSent] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);

  const { id } = useParams();
  const utils = trpc.useContext();

  const invite = trpc.u.contracts.invite.useMutation({
    onSuccess: () => {
      setEmailSent(true);
      utils.u.workers.worker.invalidate(id).catch(() => {});
      utils.u.contracts.contract.invalidate(contractId).catch(() => {});
    },
  });
  const { mutateAsync: copyLink } = trpc.u.contracts.copyLink.useMutation();

  useEffect(() => {
    if (!linkCopied) return;

    const timer = setTimeout(() => setLinkCopied(false), 2000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [linkCopied]);

  useEffect(() => {
    if (!emailSent) return;

    const timer = setTimeout(() => setEmailSent(false), 2000);

    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timer);
  }, [emailSent]);

  if ((workerId && workerSignature) || status === 'EXECUTED')
    return (
      <div>
        <div>
          <p className="text-sm font-medium text-gray-500 flex">
            Contractor signature
            <CheckBadgeIcon className="h-5 w-5 text-green-500" />
          </p>
          <div className="flex">
            <p
              style={{
                fontFamily: "'Dancing Script', cursive",
                fontSize: 30,
              }}
              className="flex items-center justify-center text-gray-900"
            >
              {workerSignature}
            </p>
          </div>
        </div>
      </div>
    );

  if (workerId && invitationLink && !workerSignature)
    return (
      <>
        <Notification
          show={emailSent}
          title="Invitation sent"
          message="Contract has been successfully sent"
          onDismiss={() => setEmailSent(false)}
        />

        <Notification
          title="Link copied"
          message="Link copied to clipboard"
          show={linkCopied}
          onDismiss={() => setLinkCopied(false)}
        />
        <div className="flex mt-4">
          <RadixAvatar fallbackString={workerEmail ?? ''} />
          <div className="ml-3 overflow-hidden">
            <p className="text-sm font-medium text-gray-900">
              {workerEmail ?? ''}
            </p>
            <p className="text-sm text-gray-500 truncate pb-5 ">
              {invitationLink}
            </p>
          </div>
        </div>
        {!emailSent ? (
          <div className="flex lg:justify-between sm:justify-end">
            <Button
              variant="secondary"
              text="Resend Invite"
              className="flex px-2"
              onClick={() => {
                invite.mutate({ email: workerEmail ?? '', contractId });
              }}
            />
            <div className="flex sm:mx-1">
              <Button
                text="Copy link"
                className="border-x-8"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={() =>
                  copyLink({ email: workerEmail ?? '', contractId })
                    .then((newInvitationLink) => {
                      navigator.clipboard
                        .writeText(newInvitationLink)
                        .catch(() => {});
                    })
                    .catch((err) => {
                      // eslint-disable-next-line no-console
                      console.error('Error copying link', err);
                    })
                    .finally(() => {
                      setLinkCopied(true);
                    })
                }
              />
            </div>
          </div>
        ) : null}
      </>
    );

  return (
    <div className="mt-6">
      <p className="text-sm font-medium text-gray-500">Invite</p>

      <div className="mt-1 sm:flex sm:items-center">
        <div className="w-full sm:max-w-xs truncate">
          <label htmlFor="email" className="sr-only">
            Contractor Personal Email
          </label>
          <p className="text-l font-bold text-gray-600">{workerEmail ?? ''}</p>
        </div>
        <Button
          className="block ml-3"
          loading={invite.isLoading}
          text="Invite"
          onClick={() => {
            invite.mutate({ email: workerEmail ?? '', contractId });
          }}
        />
      </div>
    </div>
  );
}
