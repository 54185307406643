import { formatDate, stringFromDateOrString } from 'listo/src/utils/dates';
import { Input } from 'ui/src/components/Input';
import { Button } from 'ui/src/components/Button';
import { useNotification } from 'ui/src/components/Notification/useNotification';
import {
  OffboardContractsType,
  offboardContractsZod,
} from 'listo/src/zodObjects/contracts';
import { zodResolver } from '@hookform/resolvers/zod';
import { SubmitHandler, useForm } from 'react-hook-form';
import { RouterOutput, trpc } from '../../lib/trpc';

type OffboardContractType = RouterOutput['u']['contracts']['contract'];

function offboardText({ contract }: { contract: OffboardContractType }) {
  if (contract.contractEndDate)
    return `The contract end date is ${formatDate(contract.contractEndDate)}.`;
  if (contract.engagementType === 'CONTRACTOR') {
    return 'Listo standard contractor agreement requires 10 days written notice in order to terminate a contract. Listo will send a termination notice to the contractor on your behalf.';
  }
  if (contract.engagementType === 'EOR') {
    return 'EOR engagements are subject to specific regulatory requirements. Our team at Listo will contact you to discuss the termination process.';
  }
  return 'Listo standard contractor agreement requires 10 days written notice in order to terminate a contract. Listo will send a termination notice to the contractor on your behalf.';
}

export function OffboardContract({
  contract,
}: {
  contract: OffboardContractType;
}) {
  const setNotification = useNotification((state) => state.setNotification);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<OffboardContractsType>({
    resolver: zodResolver(offboardContractsZod),
  });

  const utils = trpc.useContext();
  const { mutate: initiateOffboarding, isLoading } =
    trpc.u.contracts.offboard.useMutation({
      onSuccess: () => {
        utils.u.contracts.contract.invalidate(contract.id).catch(() => {});

        setNotification({
          type: 'success',
          title: 'Success',
          message: 'Offboard initiated',
        });
      },
    });

  const onSubmit: SubmitHandler<OffboardContractsType> = ({
    effectiveDate,
  }) => {
    initiateOffboarding({
      contractId: contract.id,
      effectiveDate,
    });
  };

  return (
    <div className="md:grid md:grid-cols-3 md:gap-6">
      <div className="md:col-span-1">
        <div className="px-4 sm:px-0">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Offboard
          </h3>
          <p className="mt-1 text-sm text-gray-600">
            Listo will begin the process of compliantly offboarding this
            contract.
          </p>
        </div>
      </div>
      <div className="mt-5 md:col-span-2 md:mt-0">
        {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="shadow sm:overflow-hidden sm:rounded-md">
            <div className="space-y-6 bg-white px-4 pt-5">
              <p className="mt-1 text-sm text-gray-600">
                {offboardText({ contract })}
              </p>
            </div>

            <div className="w-full flex justify-end bg-white">
              <div className="w-full sm:w-1/3 px-4 py-5">
                <Input
                  label="Effective Date"
                  inputType="date"
                  inputProps={{
                    defaultValue: stringFromDateOrString(
                      contract.contractEndDate
                        ? contract.contractEndDate
                        : new Date(),
                    ).split('T')[0],
                  }}
                  reactHookForm={{
                    register,
                    fieldName: 'effectiveDate',
                    errors,
                  }}
                />
              </div>
            </div>

            <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
              <Button
                type="submit"
                loading={isLoading}
                text="Initiate Offboard"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
