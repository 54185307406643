import axios from 'axios';
import { API_URL } from './constants';

export const listoAxios = axios.create({
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});
