import create from 'zustand';

export const flagsInUse = {
  canAddPaymentMethods: 'canAddPaymentMethods',
  recruiting: 'recruiting',
  invoices: 'invoices',
  reports: 'reports',
  contracts: 'contracts',
};

type FlagsInUse = typeof flagsInUse;
export type FlagsKeys = keyof FlagsInUse;
export type SetFlags = Record<FlagsKeys, boolean>;

interface FeatureFlags {
  setFlags: (flags: Record<Partial<FlagsKeys>, boolean>) => void;
  flags: undefined | SetFlags;
  flagsLoaded: boolean;
  setFlagsLoaded: (flagsLoaded: boolean) => void;
}

export const flagsArray = (Object.keys(flagsInUse) as FlagsKeys[]).map(
  (key) => flagsInUse[key],
) as FlagsKeys[];

export const useFeatureFlags = create<FeatureFlags>((set) => ({
  flagsLoaded: false,
  setFlagsLoaded: (loaded: boolean) =>
    set((state) => ({ ...state, flagsLoaded: loaded })),
  flags: undefined,
  setFlags: (flags) =>
    set((state) =>
      state.flags ? { flags: { ...state.flags, ...flags } } : { flags },
    ),
}));
