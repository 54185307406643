import { CheckCircleIcon } from '@heroicons/react/24/outline';
import Button from '../Button';

export function EmailSent({
  onCancel,
}: {
  onCancel: (isOpen: boolean) => void;
}) {
  return (
    <div className="flex items-center justify-center h-full sm:w-96 p-4 sm:p-6">
      <div className="">
        <CheckCircleIcon className="h-20 w-20" color="#21c55e" />
        <h1 className="text-base font-medium text-gray-900">Email Sent!</h1>
        <div className="mt-4 flex justify-center">
          <Button
            variant="secondary"
            text="Close"
            onClick={() => onCancel(false)}
          />
        </div>
      </div>
    </div>
  );
}
