export default function TeamStats({
  isLoading,
  totalWorkers,
  totalCountries,
}: {
  isLoading: boolean;
  totalWorkers: number;
  totalCountries: number;
}) {
  return (
    <div>
      <dl className="mt-5 grid grid-cols-1 rounded-lg bg-white overflow-hidden shadow divide-y divide-gray-200 md:grid-cols-2 md:divide-y-0 md:divide-x">
        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">Team</dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
              {!isLoading && totalWorkers ? totalWorkers : '-'}
              <span className="ml-2 text-sm font-medium text-gray-500">
                {/* {item.previousStat} */}
              </span>
            </div>
          </dd>
        </div>

        <div className="px-4 py-5 sm:p-6">
          <dt className="text-base font-normal text-gray-900">Countries</dt>
          <dd className="mt-1 flex justify-between items-baseline md:block lg:flex">
            <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
              {!isLoading && totalCountries ? totalCountries : '-'}
              <span className="ml-2 text-sm font-medium text-gray-500">
                {/* {item.previousStat} */}
              </span>
            </div>
          </dd>
        </div>
      </dl>
    </div>
  );
}
