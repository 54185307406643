import { EorContractCreate } from 'listo/src/zodObjects/contracts';
import create from 'zustand';
import { persist } from 'zustand/middleware';

interface EorForm {
  setFields: (fields: Partial<EorContractCreate>) => void;
  fields: undefined | Partial<EorContractCreate>;
  resetForm: () => void;
}

export const useEorForm = create<EorForm>()(
  persist(
    (set) => ({
      fields: undefined,
      setFields: (fields: Partial<EorContractCreate>) =>
        set((state) => ({ fields: { ...state.fields, ...fields } })),
      resetForm: () => set(() => ({ fields: undefined })),
    }),
    {
      name: 'create-contract-form',
    },
  ),
);
